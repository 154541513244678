import fordStyles from "./OrderDetailNotifications.module.scss";
import lincolnStyles from "./OrderDetailNotificationsLincoln.module.scss";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import HasPermissionTo from "../../../common/HasPermissionTo";
import React, { useState } from "react";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";
import { ReactComponent as LincolnInfoIcon } from "../../../imgs/lincoln-tooltip-icon.svg";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { Grid, Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import EVTaxCreditLinksDialog from "../modelEDetails/EVTaxCreditLinksDialog";
import { Link } from "react-router-dom";
import { trackReferralExit } from "../../../shared/services/analytics/AnalyticsService";

export const OrderDetailNotifications = (props) => {
  const styles = IS_FORD_BRAND(props.user.brand) ? fordStyles : lincolnStyles;

  const shortenInfo = (action) =>
    action === "INFORMATION ONLY" ? "INFO ONLY" : action;

  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Grid
        data-testid="viewDetailsNotification"
        container
        spacing={0}
        justifyContent="flex-start"
        alignItems="center"
        className={
          props.isAccessoriesNotification
            ? `${styles.actionNotificationBox} ${styles.warningBoxColorYellow}`
            : props.warningColor
            ? `${styles.actionNotificationBox} ${styles.warningBoxColor}`
            : `${styles.actionNotificationBox} ${styles.whiteBoxColor}`
        }
      >
        <Grid
          container
          item
          style={{
            fontSize: 0,
            flex: "1",
            flexWrap: "nowrap",
            width: "auto",
            alignItems: "center",
          }}
        >
          <Box mr={1} style={{ flex: "0 0 auto" }}>
            {props.warningColor ? (
              <ReportProblemOutlinedIcon />
            ) : IS_FORD_BRAND(props.user.brand) ? (
              <InfoOutlinedIcon />
            ) : (
              <LincolnInfoIcon />
            )}
          </Box>
          <Box mr={2} style={{ flex: "0 0 auto" }}>
            <p>{shortenInfo(props.showActionRequired)}</p>
          </Box>
          <Box style={{ flex: "0 1 auto" }}>
            <p>{props.showMessage}</p>
          </Box>
        </Grid>

        {!props.isAccessoriesNotification && props.notificationGroup && (
          <Grid item>
            <p>{props.notificationGroup}</p>
          </Grid>
        )}

        <HasPermissionTo
          perform={["USOrderDetailsNotifications:write"]}
          permissions={props.user.permissions.rolePermissions}
          condition={props.showCloseIcon}
          render={() => {
            return (
              <Grid
                item
                style={{
                  fontSize: 0,
                  cursor: "pointer",
                  display: "grid",
                  justifySelf: "flex-end",
                }}
              >
                <Box ml={1}>
                  <CloseIcon
                    data-testid="closeNotification"
                    onClick={() => props.handleClose()}
                  />
                </Box>
              </Grid>
            );
          }}
        />

        {props.showMoreInfoLink && (
          <Grid item style={{ display: "grid", justifySelf: "flex-end" }}>
            <Box>
              {props.customLink ? (
                <Link
                  to={{ pathname: props.customLink }}
                  target="_blank"
                  referrer="noreferrer"
                  className={styles.moreInfoLink}
                  data-testid="more-info-link"
                  onClick={() => {
                    trackReferralExit(
                      props.customLink,
                      props.orderId,
                      props.user
                    );
                  }}
                >
                  {props.customLinkText}
                </Link>
              ) : (
                <EMPButton
                  buttonText={props.customLinkText}
                  buttonType="link"
                  testId="more-info-link"
                  onClick={openDialog}
                ></EMPButton>
              )}
            </Box>
          </Grid>
        )}
      </Grid>

      <EVTaxCreditLinksDialog
        open={dialogOpen}
        hasCloseIcon={true}
        handleClose={handleClose}
        t={props.t}
      />
    </>
  );
};
