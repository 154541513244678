import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { formatPriceValue } from "../../../../utils/FormatPrice";
import {
  StyledBigTableHeader,
  StyledTableHeader,
  StyledTableRow,
} from "../../../../shared/Tables/TableRow";
import {
  IndentedStyledExpansionPanel,
  StyledExpansionPanel,
  StyledExpansionPanelSummary,
} from "../../../../shared/ExpansionPanels/ExpansionPanel";
import SharedToolTip from "../../../../shared/sharedToolTip/SharedToolTip";
import {
  FINANCE_PRODUCT_TYPE,
  PIPELINE,
  PURCHASE_TYPES,
} from "../../../../common/Constants";
import LowerCaseText from "../../../../utils/LowerCaseText";
import crStylesFord from "../../crStyles";
import crStylesLincoln from "../../crStylesLincoln";
import fordStyles from "../../orderDetails/NAOrderDetailsNewVersion.module.scss";
import lincolnStyles from "../../orderDetails/NAOrderDetailsNewVersionLincoln.module.scss";
import { IS_FORD_BRAND } from "../../../../utils/EmpUtil";
import EMPEmptyState from "../../../../shared/components/EMPEmptyState/EMPEmptyState";
import UpperCaseText from "../../../../utils/UpperCaseText";

class PricingSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricingSummaryExpansion: false,
      dealerPriceExpansion: false,
      totalEstimatedTaxAndFees: false,
      estTotalDue: false,
      capitalizedCostReduction: false,
      totalFundsExpansion: false,
    };
    this.pricingItems = {
      vehicleDealer: [
        "baseMSRP",
        "totalOptions",
        "destinationDeliveryCharges",
        "totalMSRP",
        "dealerAdjustmentPrice",
        "totalAXZPlanPricing",
      ],
      additions: [
        "chargingPriceTotal",
        "accessoriesPriceTotal",
        "protectionPlanTotal",
        "purchaseDeposit",
        "downPayment",
        "tradeInPrice",
        "totalIncentivesPrice",
        "totalPreTaxPrice",
      ],
      listOfFees: [
        { name: "totalEstimatedTaxAndFees", list: "taxesAndFees" },
        { name: "estTotalDue", list: "vlcTaxesAndFees" },
      ],
      fees: [
        { name: "regLicTitleFeesTotal", list: "regLicTitleFees" },
        { name: "otherTaxesTotal", list: "otherFees" },
      ],
    };

    this.index = 1;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({ pricingSummaryExpansion: this.props.expandAccordian });
    }
  }

  flipIcon() {
    this.setState({
      pricingSummaryExpansion: !this.state.pricingSummaryExpansion,
    });
  }

  expandDealerPrice() {
    this.setState({ dealerPriceExpansion: !this.state.dealerPriceExpansion });
  }

  expandAccordion(name) {
    this.setState({ [name]: !this.state[name] });
  }

  getValue(value, type) {
    const data = this.props.pricingStack;
    if (data && data[type]) return data[type][value];
  }

  getCommonPricingDetails = (data) => {
    const allFeatures =
      data &&
      data.userSelectedFeatures &&
      data.userSelectedFeatures.concat(data.includedFeatures);

    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    const crStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? crStylesFord
        : crStylesLincoln;

    return (
      <div className={styles.specsContainer}>
        <StyledTableRow
          isLincolnBrand={
            this.props.user && !IS_FORD_BRAND(this.props.user.brand)
          }
          text={this.props.t("OrderDetailsPricingSummary.baseMSRP")}
          indent={false}
          data={formatPriceValue(data.baseMSRP)}
          index={++this.index}
        />
        <StyledExpansionPanel
          defaultExpanded={true}
          onChange={() => this.expandAccordion("dealerPriceExpansion")}
        >
          <StyledExpansionPanelSummary style={crStyles.headerBarNeutral}>
            <div className={styles.headerBar}>
              <div className={styles.regularHeader}>
                {this.props.t("OrderDetailsPricingSummary.totalOptions")}
              </div>
              <div className={styles.acceptRejectDiv}>
                <div className={styles.regularHeaderValue}>
                  {formatPriceValue(data && data.totalOptions)}
                </div>
                {!this.state.dealerPriceExpansion ? (
                  <ExpandLessIcon data-testid="more" />
                ) : (
                  <ExpandMoreIcon data-testid="less" />
                )}
              </div>
            </div>
          </StyledExpansionPanelSummary>
          {allFeatures &&
            allFeatures
              .filter((feature) => !!feature)
              .map((feature, index) => (
                <StyledTableRow
                  key={index}
                  isLincolnBrand={
                    this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                  }
                  text={feature.type}
                  indent={true}
                  data={formatPriceValue(feature.value)}
                  index={++this.index}
                />
              ))}
        </StyledExpansionPanel>
        <StyledTableRow
          isLincolnBrand={
            this.props.user && !IS_FORD_BRAND(this.props.user.brand)
          }
          text={this.props.t(
            "OrderDetailsPricingSummary.destinationDeliveryCharges"
          )}
          indent={false}
          data={formatPriceValue(data.destinationDeliveryCharges)}
          index={++this.index}
        />
        <StyledTableRow
          isLincolnBrand={
            this.props.user && !IS_FORD_BRAND(this.props.user.brand)
          }
          text={
            this.props.isPurchaseRequest
              ? this.props.t("OrderDetailsPricingSummary.totalMsrp")
              : this.props.t("OrderDetailsPaymentMethod.estimatedTotalMsrp")
          }
          indent={false}
          data={formatPriceValue(data.totalMSRP)}
          index={++this.index}
        />
      </div>
    );
  };

  getDownPayment = () => {
    let downPayment = this.props.t("PurchaseDetails.paymentMethod.downPayment");

    if (this.props.isDownPaymentEnabled) {
      downPayment += this.props.t(
        "PurchaseDetails.paymentMethod.authorizedBelow"
      );
    } else {
      downPayment += this.props.t("PurchaseDetails.paymentMethod.notCollected");
    }

    return downPayment;
  };

  getFeesLists = (item, data, paymentType) => {
    const docFeeInTaxesAndFees =
      data && data.taxesAndFees.docFeeName && data.taxesAndFees.docFee;

    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    const crStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? crStylesFord
        : crStylesLincoln;

    return (
      <StyledExpansionPanel
        defaultExpanded={false}
        onChange={() => this.expandAccordion(item.name)}
      >
        <StyledExpansionPanelSummary style={crStyles.headerBarNeutral}>
          <div className={styles.headerBar}>
            <div className={styles.header}>
              {this.props.t(`OrderDetailsPricingSummary.${item.name}`)}
              {item.name === "estTotalDue" && (
                <SharedToolTip
                  {...this.props}
                  openTestid={item.name}
                  closeTestid={item.name}
                  body={this.props.t(
                    `OrderDetailsConsumerDeposit.${item.name}TooltipBody`
                  )}
                />
              )}
            </div>
            <div className={styles.acceptRejectDiv}>
              {formatPriceValue(
                this.getValue("totalEstimatedTaxAndFees", item.list)
              )}
              {this.state[item.name] ? (
                <ExpandLessIcon data-testid="less" />
              ) : (
                <ExpandMoreIcon data-testid="more" />
              )}
            </div>
          </div>
        </StyledExpansionPanelSummary>

        <StyledTableRow
          isLincolnBrand={
            this.props.user && !IS_FORD_BRAND(this.props.user.brand)
          }
          text={this.props.t("OrderDetailsPricingSummary.taxes")}
          indent={true}
          data={formatPriceValue(this.getValue("taxes", item.list))}
          index={++this.index}
        />

        {LowerCaseText(paymentType) === FINANCE_PRODUCT_TYPE.LEASE &&
          item.list === "taxesAndFees" && (
            <StyledTableRow
              isLincolnBrand={
                this.props.user && !IS_FORD_BRAND(this.props.user.brand)
              }
              text={this.props.t("OrderDetailsPricingSummary.acquisitionFee")}
              indent={true}
              data={formatPriceValue(data && data["acquisitionFee"])}
              index={++this.index}
            />
          )}

        {item.name === "totalEstimatedTaxAndFees" &&
          docFeeInTaxesAndFees &&
          this.getValue("docFee", item.list) && (
            <StyledTableRow
              isLincolnBrand={
                this.props.user && !IS_FORD_BRAND(this.props.user.brand)
              }
              text={this.getValue("docFeeName", item.list)}
              indent={true}
              data={formatPriceValue(this.getValue("docFee", item.list))}
              index={++this.index}
            />
          )}

        {this.pricingItems.fees.map((specificFees, index) => {
          return (
            <IndentedStyledExpansionPanel
              key={index}
              defaultExpanded={false}
              onChange={() => this.expandAccordion(specificFees.name)}
            >
              <StyledExpansionPanelSummary style={crStyles.headerBarNeutral}>
                <div className={styles.headerBar}>
                  <div className={styles.regularHeader}>
                    {this.props.t(
                      `OrderDetailsPricingSummary.${specificFees.name}`
                    )}
                  </div>
                  <div className={styles.acceptRejectDiv}>
                    <div className={styles.regularHeaderValue}>
                      {formatPriceValue(data[item.list][specificFees.name])}
                    </div>
                    {this.state[specificFees.name] ? (
                      <ExpandLessIcon data-testid="less" />
                    ) : (
                      <ExpandMoreIcon data-testid="more" />
                    )}
                  </div>
                </div>
              </StyledExpansionPanelSummary>

              {data[item.list][specificFees.list] &&
                data[item.list][specificFees.list].map((fee, index) => {
                  return (
                    <StyledTableRow
                      key={index}
                      isLincolnBrand={
                        this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                      }
                      text={fee.name}
                      indent={true}
                      data={formatPriceValue(fee.amount, item.list)}
                      index={++this.index}
                    />
                  );
                })}
            </IndentedStyledExpansionPanel>
          );
        })}
      </StyledExpansionPanel>
    );
  };

  getCapitalizedCostReductionAccordion = (data) => {
    const { customerReservation } = this.props;

    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    const crStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? crStylesFord
        : crStylesLincoln;

    const orderDeposit =
      customerReservation &&
      customerReservation.orderPaymentInfo &&
      customerReservation.orderPaymentInfo.depositAmount;

    const orderDepositRefund =
      customerReservation &&
      customerReservation.orderPaymentInfo &&
      customerReservation.orderPaymentInfo.refundAmount;

    const orderDepositRefundDealerAction =
      customerReservation && customerReservation.orderDepositRefundDealerAction;

    const reservationDeposit =
      customerReservation &&
      customerReservation.reservationPaymentInfo &&
      customerReservation.reservationPaymentInfo.depositAmount;

    const reservationDepositRefund =
      customerReservation &&
      customerReservation.reservationPaymentInfo &&
      customerReservation.reservationPaymentInfo.refundAmount;

    const purchaseDeposit =
      customerReservation &&
      customerReservation.vehicleHoldDepositInfo &&
      customerReservation.vehicleHoldDepositInfo.depositAmount;

    const getReservationDepositInfo = () => {
      let amt = "";
      if (reservationDeposit) {
        amt = reservationDeposit;
      }

      if (!reservationDeposit && reservationDepositRefund) {
        amt = "$0.00";
      }

      return amt;
    };

    return (
      <StyledExpansionPanel
        defaultExpanded={false}
        onChange={() => this.expandAccordion("capitalizedCostReduction")}
      >
        <StyledExpansionPanelSummary style={crStyles.headerBarNeutral}>
          <div className={styles.headerBar}>
            <div className={styles.header}>
              {this.props.t(
                `OrderDetailsPricingSummary.capitalizedCostReduction`
              )}
            </div>
            <div className={styles.acceptRejectDiv}>
              {formatPriceValue(data && data["capitalizedCostReduction"])}
              {this.state.capitalizedCostReduction ? (
                <ExpandLessIcon data-testid="less" />
              ) : (
                <ExpandMoreIcon data-testid="more" />
              )}
            </div>
          </div>
        </StyledExpansionPanelSummary>
        {getReservationDepositInfo() !== "" && (
          <StyledTableRow
            isLincolnBrand={
              this.props.user && !IS_FORD_BRAND(this.props.user.brand)
            }
            text={this.props.t("OrderDetailsPricingSummary.reservationDeposit")}
            indent={true}
            data={getReservationDepositInfo()}
            index={++this.index}
          />
        )}
        {!orderDeposit && orderDepositRefundDealerAction && (
          <StyledTableRow
            isLincolnBrand={
              this.props.user && !IS_FORD_BRAND(this.props.user.brand)
            }
            text={this.props.t("OrderDetailsPaymentMethod.orderDeposit")}
            indent={true}
            data={
              orderDepositRefund
                ? "$0.00"
                : orderDeposit
                ? orderDeposit
                : "$0.00"
            }
            index={++this.index}
          />
        )}
        {orderDeposit && (
          <StyledTableRow
            isLincolnBrand={
              this.props.user && !IS_FORD_BRAND(this.props.user.brand)
            }
            text={this.props.t("OrderDetailsPaymentMethod.orderDeposit")}
            indent={true}
            data={
              orderDepositRefund
                ? "$0.00"
                : orderDeposit
                ? orderDeposit
                : "$0.00"
            }
            index={++this.index}
          />
        )}
        {!this.props.isModelEOrder && (
          <StyledTableRow
            isLincolnBrand={
              this.props.user && !IS_FORD_BRAND(this.props.user.brand)
            }
            text={this.getDownPayment()}
            indent={true}
            data={formatPriceValue(
              customerReservation &&
                customerReservation.financeDataQuote &&
                customerReservation.financeDataQuote["deposit"]
            )}
            index={++this.index}
          />
        )}

        {this.props.isModelEOrder && (
          <>
            <StyledTableRow
              isLincolnBrand={
                this.props.user && !IS_FORD_BRAND(this.props.user.brand)
              }
              text={this.props.t("OrderDetailsPricingSummary.purchaseDeposit")}
              indent={true}
              data={purchaseDeposit}
              index={++this.index}
            />
            <StyledTableRow
              isLincolnBrand={
                this.props.user && !IS_FORD_BRAND(this.props.user.brand)
              }
              text={this.props.t("PurchaseDetails.paymentMethod.downPayment")}
              indent={true}
              data={formatPriceValue(
                customerReservation &&
                  customerReservation.financeDataQuote &&
                  customerReservation.financeDataQuote["deposit"]
              )}
              index={++this.index}
            />
          </>
        )}
        <StyledTableRow
          isLincolnBrand={
            this.props.user && !IS_FORD_BRAND(this.props.user.brand)
          }
          text={this.props.t("OrderDetailsPricingSummary.totalIncentivesPrice")}
          indent={true}
          data={formatPriceValue(data && data["totalIncentivesPrice"])}
          index={++this.index}
        />
        <StyledTableRow
          isLincolnBrand={
            this.props.user && !IS_FORD_BRAND(this.props.user.brand)
          }
          text={this.props.t(
            "OrderDetailsPricingSummary.estimatedNetTradeInAmount"
          )}
          indent={true}
          data={formatPriceValue(data && data["tradeInPrice"])}
          index={++this.index}
        />
      </StyledExpansionPanel>
    );
  };

  render() {
    this.index = 0;

    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    const crStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? crStylesFord
        : crStylesLincoln;

    const data = this.props.pricingStack;
    const amountFinanced = this.props.amountFinanced;
    const paymentType = this.props.paymentType;
    const totalEstimatedTaxesAndFees = this.pricingItems.listOfFees[0];
    const estTotalDue = this.pricingItems.listOfFees[1];

    const { customerReservation } = this.props;

    const orderDeposit =
      customerReservation &&
      customerReservation.orderPaymentInfo &&
      customerReservation.orderPaymentInfo.depositAmount;

    const orderDepositRefund =
      customerReservation &&
      customerReservation.orderPaymentInfo &&
      customerReservation.orderPaymentInfo.refundAmount;

    const reservationDeposit =
      customerReservation &&
      customerReservation.reservationPaymentInfo &&
      customerReservation.reservationPaymentInfo.depositAmount;

    const reservationDepositRefund =
      customerReservation &&
      customerReservation.reservationPaymentInfo &&
      customerReservation.reservationPaymentInfo.refundAmount;

    const isOffline =
      customerReservation &&
      customerReservation.orderChannel &&
      customerReservation.orderChannel === "InStore" ? (
        <span className={styles.headerStatus}>
          {this.props.t(
            "OrderDetailsPricingSummary.offlineOrderPricingMessage"
          )}
        </span>
      ) : null;

    let totalFundsHeld = 0;
    if (orderDeposit) {
      totalFundsHeld += customerReservation.orderPaymentInfo.depositAmountInt;
    }
    if (orderDepositRefund) {
      totalFundsHeld -= customerReservation.orderPaymentInfo.refundAmountInt;
    }
    if (reservationDeposit) {
      totalFundsHeld +=
        customerReservation.reservationPaymentInfo.depositAmountInt;
    }
    if (reservationDepositRefund) {
      totalFundsHeld -=
        customerReservation.reservationPaymentInfo.refundAmountInt;
    }

    const purchaseTypeIsValid =
      this.props.customerReservation &&
      this.props.customerReservation.purchaseInfo &&
      this.props.customerReservation.purchaseInfo.purchaseType;

    const purchaseTypeIsSignAtDealer =
      purchaseTypeIsValid &&
      this.props.customerReservation.purchaseInfo.purchaseType ===
        PURCHASE_TYPES.SIGN_AT_DEALER;

    const purchaseTypeIsSignOnline =
      purchaseTypeIsValid &&
      this.props.customerReservation.purchaseInfo.purchaseType ===
        PURCHASE_TYPES.SIGN_ONLINE;

    const showEstimatedTaxesAndFees = !(
      this.props.isModelEOrder &&
      (purchaseTypeIsSignAtDealer || purchaseTypeIsSignOnline) &&
      (this.props.customerReservation.financeDataQuote.financeProductType ===
        FINANCE_PRODUCT_TYPE.FINANCE ||
        this.props.customerReservation.financeDataQuote.financeProductType ===
          FINANCE_PRODUCT_TYPE.OTHER ||
        this.props.customerReservation.financeDataQuote.financeProductType ===
          FINANCE_PRODUCT_TYPE.FORD_OPTIONS ||
        this.props.customerReservation.financeDataQuote.financeProductType ===
          FINANCE_PRODUCT_TYPE.CASH)
    );

    const showPreTaxPrice = !(
      this.props.isModelEOrder && purchaseTypeIsSignOnline
    );

    const docFeeInDealerSellingPrice =
      data &&
      data.dealerSellingPriceDocFeeName &&
      data.dealerSellingPriceDocFee;

    const hasDownPayment =
      this.props.customerReservation &&
      this.props.customerReservation.financeDataQuote &&
      this.props.customerReservation.financeDataQuote.deposit;

    const hasPurchaseDeposit =
      this.props.customerReservation &&
      this.props.customerReservation.vehicleHoldDepositInfo &&
      this.props.customerReservation.vehicleHoldDepositInfo.depositAmount;

    const showFederalTaxCredit =
      this.props.isModelEOrder &&
      data &&
      data.federalTaxCreditAmount !== null &&
      this.props.paymentType !== FINANCE_PRODUCT_TYPE.LEASE;

    return (
      <div>
        <StyledExpansionPanel
          onChange={() => this.flipIcon()}
          expanded={this.state.pricingSummaryExpansion}
          data-testid="pricingDropDown"
        >
          <StyledExpansionPanelSummary
            style={crStyles.headerBarBg}
            data-testid="pricingSummary"
          >
            <div className={styles.headerBar}>
              <div className={styles.header}>
                {this.props.t("OrderDetailsPricingSummary.title")}
                {isOffline}
              </div>
              <div>
                {this.state.pricingSummaryExpansion ? (
                  <ExpandLessIcon data-testid="lessSummary" />
                ) : (
                  <ExpandMoreIcon data-testid="moreSummary" />
                )}
              </div>
            </div>
          </StyledExpansionPanelSummary>
          {!this.props.isPurchaseRequest ? (
            this.props.isOrder ? (
              <div>
                {this.getCommonPricingDetails(data)}
                <StyledTableRow
                  isLincolnBrand={
                    this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                  }
                  text={this.props.t(
                    "OrderDetailsPricingSummary.dealerAdjustmentPrice"
                  )}
                  indent={false}
                  data={formatPriceValue(data.dealerAdjustmentPrice)}
                  index={++this.index}
                />
                {data.dealerSellingPriceDocFee && (
                  <StyledTableRow
                    isLincolnBrand={
                      this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                    }
                    text={data.dealerSellingPriceDocFeeName}
                    indent={false}
                    data={formatPriceValue(data.dealerSellingPriceDocFee)}
                    index={++this.index}
                  />
                )}
                <StyledTableRow
                  isLincolnBrand={
                    this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                  }
                  text={this.props.t(
                    "OrderDetailsPricingSummary.accessoriesPriceTotal"
                  )}
                  indent={false}
                  data={formatPriceValue(data.accessoriesPriceTotal)}
                  index={++this.index}
                />
                <StyledTableRow
                  isLincolnBrand={
                    this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                  }
                  text={this.props.t(
                    "OrderDetailsPricingSummary.totalIncentivesPrice"
                  )}
                  indent={false}
                  data={formatPriceValue(data.totalIncentivesPrice)}
                  index={++this.index}
                />

                <StyledBigTableHeader
                  isLincolnBrand={
                    this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                  }
                  text={this.props.t(
                    "OrderDetailsPricingSummary.dealerSellingPrice"
                  )}
                  data={formatPriceValue(data.promotionalDealerPrice)}
                />
                {(orderDeposit || reservationDeposit) && (
                  <StyledExpansionPanel
                    defaultExpanded={true}
                    onChange={() => this.expandAccordion("totalFundsExpansion")}
                  >
                    <StyledExpansionPanelSummary
                      style={crStyles.headerBarNeutral}
                    >
                      <div className={styles.headerBar}>
                        <div className={styles.regularHeader}>
                          {this.props.t(
                            "OrderDetailsPricingSummary.totalFundsHeldByDealer"
                          )}
                        </div>
                        <div className={styles.acceptRejectDiv}>
                          <div className={styles.regularHeaderValue}>
                            {formatPriceValue(totalFundsHeld)}
                          </div>
                          {!this.state.totalFundsExpansion ? (
                            <ExpandLessIcon data-testid="more" />
                          ) : (
                            <ExpandMoreIcon data-testid="less" />
                          )}
                        </div>
                      </div>
                    </StyledExpansionPanelSummary>

                    {reservationDeposit && (
                      <StyledTableRow
                        isLincolnBrand={
                          this.props.user &&
                          !IS_FORD_BRAND(this.props.user.brand)
                        }
                        text={this.props.t(
                          "OrderDetailsPaymentMethod.reservationDeposit"
                        )}
                        indent={true}
                        data={
                          reservationDepositRefund
                            ? "$0.00"
                            : reservationDeposit
                        }
                        index={++this.index}
                      />
                    )}

                    {orderDeposit && (
                      <StyledTableRow
                        isLincolnBrand={
                          this.props.user &&
                          !IS_FORD_BRAND(this.props.user.brand)
                        }
                        text={this.props.t(
                          "OrderDetailsPricingSummary.orderDeposit"
                        )}
                        indent={true}
                        data={orderDepositRefund ? "$0.00" : orderDeposit}
                        index={++this.index}
                      />
                    )}
                  </StyledExpansionPanel>
                )}
              </div>
            ) : (
              <>{this.getCommonPricingDetails(data)}</>
            )
          ) : (
            <>
              <StyledExpansionPanel
                defaultExpanded={true}
                onChange={() => this.expandDealerPrice()}
              >
                <StyledExpansionPanelSummary style={crStyles.headerBarNeutral}>
                  <div className={styles.headerBar}>
                    <div className={styles.header}>
                      {this.props.t(
                        "OrderDetailsPricingSummary.totalDealerPrice"
                      )}
                    </div>
                    <div className={styles.acceptRejectDiv}>
                      {formatPriceValue(data && data.totalDealerPrice)}
                      {!this.state.dealerPriceExpansion ? (
                        <ExpandLessIcon data-testid="more" />
                      ) : (
                        <ExpandMoreIcon data-testid="less" />
                      )}
                    </div>
                  </div>
                </StyledExpansionPanelSummary>

                {this.pricingItems.vehicleDealer.map((item, index) => {
                  return (
                    <StyledTableRow
                      isLincolnBrand={
                        this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                      }
                      text={this.props.t(`OrderDetailsPricingSummary.${item}`)}
                      indent={true}
                      data={formatPriceValue(data && data[item])}
                      index={++this.index}
                      key={index}
                    />
                  );
                })}

                {data && docFeeInDealerSellingPrice && (
                  <StyledTableRow
                    isLincolnBrand={
                      this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                    }
                    text={data["dealerSellingPriceDocFeeName"]}
                    indent={true}
                    data={formatPriceValue(
                      data && data["dealerSellingPriceDocFee"]
                    )}
                    index={++this.index}
                  />
                )}

                <StyledTableRow
                  isLincolnBrand={
                    this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                  }
                  text={this.props.t(
                    "OrderDetailsPricingSummary.totalAddOnsPrice"
                  )}
                  indent={true}
                  data={formatPriceValue(data && data["totalAddOnsPrice"])}
                  index={++this.index}
                />
              </StyledExpansionPanel>

              {this.pricingItems.additions.map((item, index) => {
                return (
                  (showPreTaxPrice ||
                    (!showPreTaxPrice && !(item === "totalPreTaxPrice"))) && (
                    <StyledTableRow
                      isLincolnBrand={
                        this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                      }
                      text={this.props.t(`OrderDetailsPricingSummary.${item}`)}
                      indent={false}
                      data={
                        data && Object.hasOwn(data, item) ? (
                          formatPriceValue(data && data[item])
                        ) : item === "downPayment" && hasDownPayment ? (
                          formatPriceValue(
                            this.props.customerReservation.financeDataQuote
                              .deposit
                          )
                        ) : item === "purchaseDeposit" && hasPurchaseDeposit ? (
                          this.props.customerReservation.vehicleHoldDepositInfo
                            .depositAmount
                        ) : (
                          <EMPEmptyState />
                        )
                      }
                      index={++this.index}
                      key={index}
                    />
                  )
                );
              })}

              <div>
                {LowerCaseText(paymentType) === FINANCE_PRODUCT_TYPE.LEASE && (
                  <>
                    {data &&
                      data[totalEstimatedTaxesAndFees.list] &&
                      showEstimatedTaxesAndFees &&
                      Boolean(
                        data[totalEstimatedTaxesAndFees.list]
                          .totalEstimatedTaxAndFees
                      ) &&
                      this.getFeesLists(
                        totalEstimatedTaxesAndFees,
                        data,
                        paymentType
                      )}
                    <StyledTableHeader
                      isLincolnBrand={
                        this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                      }
                      text={this.props.t(
                        "OrderDetailsPricingSummary.estimatedGrossCapitalizedCost"
                      )}
                      data={
                        this.props.isModelEOrder &&
                        UpperCaseText(customerReservation.subJourneyType) ===
                          PIPELINE
                          ? formatPriceValue(
                              customerReservation?.financeDataQuote
                                ?.estimatedGrossCapitalizedCost
                            )
                          : formatPriceValue(
                              data && data["estimatedGrossCapitalizedCost"]
                            )
                      }
                    />

                    {this.getCapitalizedCostReductionAccordion(data)}
                    <StyledTableHeader
                      isLincolnBrand={
                        this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                      }
                      text={this.props.t(
                        "OrderDetailsPricingSummary.estimatedAdjustedCapitalizedCost"
                      )}
                      data={
                        this.props.isModelEOrder &&
                        UpperCaseText(customerReservation.subJourneyType) ===
                          PIPELINE
                          ? formatPriceValue(
                              customerReservation?.financeDataQuote
                                ?.estimatedAdjustedCapitalizedCost
                            )
                          : formatPriceValue(
                              data && data["estimatedAdjustedCapitalizedCost"]
                            )
                      }
                    />
                    {data &&
                      data[estTotalDue.list] &&
                      showEstimatedTaxesAndFees &&
                      Boolean(
                        data[estTotalDue.list].totalEstimatedTaxAndFees
                      ) &&
                      this.getFeesLists(estTotalDue, data, paymentType)}
                  </>
                )}

                {LowerCaseText(paymentType) !== FINANCE_PRODUCT_TYPE.LEASE && (
                  <>
                    {this.pricingItems.listOfFees.map((item, index) => {
                      return (
                        <div key={index}>
                          {data &&
                            data[item.list] &&
                            showEstimatedTaxesAndFees &&
                            Boolean(data[item.list].totalEstimatedTaxAndFees) &&
                            this.getFeesLists(item, data, paymentType)}
                        </div>
                      );
                    })}
                    {showFederalTaxCredit && (
                      <StyledTableRow
                        isLincolnBrand={
                          this.props.user &&
                          !IS_FORD_BRAND(this.props.user.brand)
                        }
                        text={this.props.t(
                          "OrderDetailsPricingSummary.federalTaxCredit"
                        )}
                        data={formatPriceValue(data.federalTaxCreditAmount)}
                        index={0}
                      />
                    )}
                    {LowerCaseText(paymentType) !== FINANCE_PRODUCT_TYPE.CASH &&
                      Boolean(amountFinanced) && (
                        <StyledBigTableHeader
                          isLincolnBrand={
                            this.props.user &&
                            !IS_FORD_BRAND(this.props.user.brand)
                          }
                          text={this.props.t(
                            "OrderDetailsPricingSummary.estAmtFinanced"
                          )}
                          data={formatPriceValue(amountFinanced)}
                        />
                      )}
                  </>
                )}

                {LowerCaseText(paymentType) === FINANCE_PRODUCT_TYPE.CASH && (
                  <StyledBigTableHeader
                    isLincolnBrand={
                      this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                    }
                    text={this.props.t(
                      "OrderDetailsPricingSummary.estimatedCashDueAtDealer"
                    )}
                    indent={false}
                    data={
                      data && data.totalPriceWithAddOns ? (
                        formatPriceValue(data.totalPriceWithAddOns)
                      ) : (
                        <EMPEmptyState />
                      )
                    }
                  />
                )}
              </div>
            </>
          )}
        </StyledExpansionPanel>
      </div>
    );
  }
}

export default withTranslation("emp")(PricingSummary);
