import axios from "axios";
import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";
import { getHeaders } from "../utils/AuthorizationUtil";
import { SHARED_SERVICE_URL } from "../utils/EmpUtil";
import { is401, is404 } from "../errors/ErrorConstants";

export default class DealerGroupingClient {
  static baseUrl = SHARED_SERVICE_URL;

  static getDealerGroup(user, salesCode, page, isFieldUser = false, paCode) {
    const resourcePath =
      user.market.toUpperCase() === "CAN"
        ? paCode
          ? `dealer/${paCode}`
          : `dealer/${user.dealerGroupId}`
        : paCode
        ? `dealer/${paCode}`
        : user.generateResourcePath();
    let url = `${this.baseUrl}/${resourcePath}/dealer-group`;
    if (salesCode) {
      url += "?salesCode=" + salesCode;
    }

    if (isFieldUser) {
      url += salesCode ? "&isFieldUser=true" : "?isFieldUser=true";
    }

    if (page) {
      url += salesCode ? "&page=" + page : "?page=" + page;
    }

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data.result.dealerGroupings;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        } else if (is404(error)) {
          return Promise.reject(error);
        } else {
          user.history.push("/error");
        }

        return Promise.reject(error);
      });
  }

  static getDealerInfoCanada(user) {
    const url = `${this.baseUrl}/can/dealer-info`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        } else {
          user.history.push("/error");
        }
        return Promise.reject(error);
      });
  }
}
