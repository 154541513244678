import React, { Component } from "react";
import fordStyles from "./CheckBox.module.scss";
import lincolnStyles from "./CheckBox-Lincoln.module.scss";

class CheckBox extends Component {
  handleOnChange() {
    this.checkboxRef.onChange();
  }

  checkboxRef = React.createRef();

  compateCheckBoxItemToString(item, comparator) {
    return (
      typeof item === "string" &&
      typeof comparator === "string" &&
      item.toLowerCase().includes(comparator)
    );
  }

  render() {
    const styles =
      this.props.isFordBrand === false ? lincolnStyles : fordStyles;
    const hideCheckbox = this.props.hideCheckbox ? styles.checkboxHidden : "";
    const checkboxStyle = this.props.boldCheck ? styles.bold : "";
    return (
      <div className={`${hideCheckbox} ${styles.checkboxContainer}`}>
        <div className={styles.checkboxWrapper}>
          <input
            disabled={this.props.isDisabled}
            type="checkbox"
            ref={this.checkboxRef}
            id={this.props.id}
            className={`${styles.checkbox} ${checkboxStyle}`}
            onChange={(e) => this.props.onChange(e)}
            data-testid={this.props.id}
            checked={this.props.checked}
            value={this.props.value}
          />
          <label htmlFor={this.props.id} onClick={() => this.handleOnChange} />
        </div>
        {this.props.labelList && this.props.labelList.length > 0 ? (
          <div>
            <div className={styles.label} data-testid={this.props.id + "Text"}>
              {this.props.label}
            </div>
            <ul className={styles.labelList}>
              {this.props.labelList.map((item, index) =>
                this.compateCheckBoxItemToString(item, "recall") ? (
                  <li key={index}>
                    <a
                      href={encodeURIComponent(this.props.recallLink)}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "#4a4a4a" }}
                    >
                      {item}
                    </a>
                  </li>
                ) : (
                  <li key={index}>{item}</li>
                )
              )}
            </ul>
          </div>
        ) : (
          <div
            className={this.props.textClass || styles.label}
            data-testid={this.props.id + "Text"}
          >
            {this.props.label}
          </div>
        )}
      </div>
    );
  }
}

export default CheckBox;
