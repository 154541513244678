import React, { Component } from "react";
import styles from "./NAOrderDetailsNewVersion.module.scss";
import { Button, Grid, IconButton } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import UpperCaseText from "../../../utils/UpperCaseText";
import PricingSummary from "../purchaseDetails/addOns/PricingSummary";
import ConsumerDeposit from "./ConsumerDeposit";
import PaymentMethod from "./PaymentMethod";
import {
  FINANCE_PRODUCT_TYPE,
  NA_DEFAULT_CURRENCY,
} from "../../../common/Constants";
import LowerCaseText from "../../../utils/LowerCaseText";
import OrderInformation from "./OrderInformation";
import CustomerInformation from "./CustomerInformation";
import VehicleDetails from "./VehicleDetails";
import DetailsHeader from "../common/DetailsHeader";
import CustomerReservationsClient from "../../../clients/CustomerReservationsClient";
import { ofType } from "../../../common/PubSub";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import { displayError, LOAD_ERROR } from "../../../errors/ErrorConstants";
import { formatPriceValue } from "../../../utils/FormatPrice";
import DealerInformation from "./DealerInformation";
import { IS_CANADA_MARKET } from "../../../utils/EmpUtil";
import { handleBack } from "../../../utils/GoBack";
import NADealerNotificationsClient from "../../../clients/NADealerNotificationsClient";
import HasPermissionTo from "../../../common/HasPermissionTo";
import NADealerNotifications from "../notifications/NADealerNotifications";
import PurchaseAccessories from "../purchaseDetails/addOns/PurchaseAccessories";
import { logMsg } from "../../../common/Logger";
import OffersAndIncentives from "../purchaseDetails/offersAndIncentives/OffersAndIncentives";
import ProgressTracker from "./progressTracker/ProgressTracker";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import DealerNotes from "./dealerNotes/DealerNotes";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import * as AnalyticsService from "../../../shared/services/analytics/AnalyticsService";
import MotionChevronLeft from "../../../shared/Icons/MotionChevronLeft";
import SubNav from "../../../shared/subnav/SubNav";

class NAOrderDetailsNewVersion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetail: {},
      notifications: [],
      hasError: [],
      pricingStack: {},
      expandAll: true,
    };
    ofType("language").subscribe((response) => {
      this.updateLanguage(response);
    });
    this.getOrderDetails = this.getOrderDetails.bind(this);
  }

  componentDidMount() {
    if (this.props.fromCrc || this.props.fromFieldView) {
      const encodedCommonId =
        this.props.orderDetail &&
        this.props.orderDetail.dealerInfo &&
        this.props.orderDetail.dealerInfo.commonId &&
        this.props.orderDetail.dealerInfo.commonId.split("|").join("%7C");

      const orderId =
        this.props.orderDetail &&
        this.props.orderDetail.customerReservation &&
        this.props.orderDetail.customerReservation.id;

      const unBuildable =
        this.props.orderDetail &&
        this.props.orderDetail.customerReservation &&
        this.props.orderDetail.customerReservation.unBuildable;

      this.setState({ orderDetail: this.props.orderDetail });

      if (encodedCommonId !== null) {
        this.getNADealerNotifications(
          this.props.user,
          encodedCommonId,
          orderId,
          unBuildable
        );
      }
    } else {
      this.getOrderDetails(this.props.orderId, this.props.commonId);
    }
    this.getPriceStackData(
      this.props.orderId
        ? this.props.orderId
        : this.props.orderDetail &&
            this.props.orderDetail.customerReservation &&
            this.props.orderDetail.customerReservation.id
    );
    window.addEventListener("scroll", this.listenToScroll);

    if (!this.state.orderDetail.customerReservation) {
      let num = 0;

      const eventInterval = setInterval(() => {
        num += 1;

        if (this.state.orderDetail.customerReservation) {
          AnalyticsService.trackPageLoadDetailEvent(
            "fv:emp:customer handling:orders details",
            "emp:order details:order id",
            this.props.orderId,
            this.props.user
          );
          clearInterval(eventInterval);
        }

        if (num === 10) {
          clearInterval(eventInterval);
        }
      }, 1000);
    } else {
      AnalyticsService.trackPageLoadDetailEvent(
        "fv:emp:customer handling:orders details",
        "emp:order details:order id",
        this.props.orderId,
        this.props.user
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    this.setState({
      theposition: scrolled,
    });
  };

  expandAll = () => {
    this.setState({ expandAll: !this.state.expandAll });
  };

  updateLanguage = (response) => {
    if (response) {
      this.props.user.setLanguageCode(response.data);
      this.getOrderDetails(this.props.orderId, this.props.commonId);
    }
  };

  getNADealerNotifications = (user, encodedCommonId, orderId, unBuildable) => {
    this.props.hideOrShow(true);
    const notifications = [];
    if (unBuildable) {
      notifications.push({
        notificationType: "NOTIFICATION",
        custom: true,
        params: {
          NotificationMsg: this.props.t("OrderDetails.unbuildableNotifyMsg"),
        },
      });
    }
    NADealerNotificationsClient.getNADealerNotifications(
      user,
      encodedCommonId,
      orderId
    )
      .then((data) =>
        data.ordersData.forEach((notification) => {
          notifications.push(notification);
        })
      )
      .catch((error) => logMsg("Failed to get notifications: ", error))
      .finally(() => {
        this.setState({ notifications });
        this.props.hideOrShow(false);
      });
  };

  getOrderDetails = (orderId, commonDealerId, scroll = true) => {
    this.props.hideOrShow(true);
    CustomerReservationsClient.getCustomerOrderDetails(
      { token: this.props.token },
      orderId,
      commonDealerId,
      false,
      this.props.user.lang,
      null
    )
      .then((response) => {
        this.setState({
          orderDetail: response,
        });

        this.getNADealerNotifications(
          this.props.user,
          this.props.user && this.props.user.getEncodedCommonId(),
          orderId,
          response &&
            response.customerReservation &&
            response.customerReservation.unBuildable
        );
      })
      .catch(() => {
        this.setState({
          hasError: [LOAD_ERROR],
        });
      })
      .finally(() => {
        scroll && window.scrollTo(0, 0);
        this.props.hideOrShow(false);
      });
  };

  getPriceStackData = (orderId) => {
    const commonId =
      this.props.user.commonId ||
      (this.props.orderDetail &&
        this.props.orderDetail.dealerInfo &&
        this.props.orderDetail.dealerInfo.commonId);

    this.props.hideOrShow(true);
    CustomerReservationsClient.getOrderPricingStack(
      this.props.user,
      orderId,
      this.state.selectedMarket,
      commonId,
      this.props.user.lang
    )
      .then((result) => {
        this.setState({
          pricingStack: result,
        });
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  formatPhoneNumber(phoneNumberString) {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  getFullName(value) {
    let fullName = "";
    if (value) {
      if (value.firstName) fullName += value.firstName;
      if (value.lastName)
        fullName.length > 0
          ? (fullName += " " + value.lastName)
          : (fullName += value.lastName);
    }
    return fullName;
  }

  formatPriceValue = (value) => {
    const defaultCurrency =
      (this.props.localizedInfo &&
        this.props.localizedInfo.defaultCurrency &&
        this.props.localizedInfo.defaultCurrency.symbol) ||
      NA_DEFAULT_CURRENCY;

    if (value !== null && value !== undefined) {
      value = value.toString();
      value =
        value.charAt(0) === "+" || value.charAt(0) === "-"
          ? value.charAt(0).concat(defaultCurrency, value.substr(1))
          : defaultCurrency.concat(value);
    } else {
      value = "--";
    }
    return value;
  };

  formatPriceValueForPaymentMethod = (value) => {
    return formatPriceValue(value);
  };

  openPrint = () => {
    window.print();
  };

  deleteNotification = (id) => {
    NADealerNotificationsClient.deleteNotification(this.props.user, id).then(
      () => {
        this.setState({
          notifications: this.state.notifications.filter(
            (notification) => notification.uid !== id
          ),
        });
      }
    );
  };

  refreshReservationDetailsWithNotifications = () => {
    this.getOrderDetails(this.props.orderId, this.props.user.commonId, false);
  };

  isOrder = (orderType) => {
    return UpperCaseText(orderType) === "ORDER";
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  render() {
    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    const { customerReservation, customerInfo, dealerInfo } =
      this.state.orderDetail;

    const orderType = UpperCaseText(
      customerReservation && customerReservation.orderType
    );
    const isOrder = this.isOrder(orderType);

    const cancelledReservationInCanada = () => {
      return (
        customerReservation?.orderType?.toLowerCase() === "reservation" &&
        customerReservation?.businessStatus?.toLowerCase() === "cancelled" &&
        this.props.user?.market === "CAN"
      );
    };

    return customerReservation ? (
      <div className={`pageWrapper ${styles.whiteBg}`}>
        {this.state.theposition > 0.1 && (
          <div
            className={styles.floatingActionButtonDiv}
            style={{ position: "fixed", bottom: 100, right: 50 }}
          >
            <Button
              className={styles.floatingActionButton}
              onClick={this.scrollToTop}
            >
              <ArrowUpwardIcon /> {this.props.t("GeneralEMP.backToTop")}
            </Button>
          </div>
        )}
        <SubNav
          id="subNav"
          dashboard={false}
          title={this.props.t("GlobalNavBar.dashboard")}
          {...this.props}
          isDealer={true}
        />
        <HasPermissionTo
          perform={["USOrderDetailsNotifications:view"]}
          permissions={this.props.user.permissions.rolePermissions}
          condition={customerReservation.displayNotifications}
          render={() => (
            <NADealerNotifications
              {...this.props}
              customerReservation={customerReservation}
              notifications={this.state.notifications}
              deleteNotification={this.deleteNotification}
            />
          )}
          noRender={() => null}
        />

        <Grid
          container
          className={styles.componentContainer}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <EMPButton
              buttonText={this.props.t("PurchaseDetails.backToList")}
              testId="backButton"
              buttonStyle="text"
              leftIcon={<MotionChevronLeft />}
              onClick={() => {
                this.props.fromFieldView
                  ? handleBack(false, this.props.history)
                  : handleBack(this.props.fromCrc, this.props.history);
              }}
            ></EMPButton>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="Print page content"
              id="printButton"
              data-testid="printButton"
              onClick={this.openPrint}
              edge="end"
              disableRipple
            >
              <PrintOutlinedIcon className={styles.printIcon} size="small" />
            </IconButton>
          </Grid>
        </Grid>

        <div
          className={`${styles.componentContainer} ${styles.customerHeading}`}
        >
          <DetailsHeader
            {...this.props}
            customerReservation={customerReservation}
            customerInfo={customerInfo}
          />
        </div>

        <div className={`customerOrderDetails ${styles.componentContainer}`}>
          <Grid
            container
            justifyContent="flex-end"
            className={styles.expandContainer}
          >
            <Grid item onClick={() => this.expandAll()}>
              {`${this.state.expandAll ? "Collapse" : "Expand"} all rows`}
            </Grid>
          </Grid>
          <div className={styles.expansionBox}>
            <OrderInformation
              {...this.props}
              refreshReservationDetailsWithNotifications={
                this.refreshReservationDetailsWithNotifications
              }
              customerReservation={customerReservation}
              expandAccordian={this.state.expandAll}
            />
            {!cancelledReservationInCanada() && (
              <CustomerInformation
                {...this.props}
                customerReservation={customerReservation}
                customerInfo={customerInfo}
                formatPhoneNumber={(phoneNumber) =>
                  this.formatPhoneNumber(phoneNumber)
                }
                getFullName={(value) => this.getFullName(value)}
                expandAccordian={this.state.expandAll}
              />
            )}
            {(this.props.fromCrc || this.props.fromFieldView) && (
              <DealerInformation
                {...this.props}
                dealerInfo={dealerInfo}
                expandAccordian={this.state.expandAll}
              />
            )}

            <VehicleDetails
              {...this.props}
              customerReservation={customerReservation}
              vehicleFeatures={
                customerReservation ? customerReservation.vehicleNAFeatures : {}
              }
              dealerInfo={dealerInfo}
              expandAccordian={this.state.expandAll}
            />

            <HasPermissionTo
              perform={["dealerNotesView"]}
              permissions={this.props.user.permissions.rolePermissions}
              render={() => (
                <DealerNotes
                  {...this.props}
                  orderId={customerReservation.id}
                  expandAccordion={this.state.expandAll}
                />
              )}
            />
          </div>
        </div>

        <div className={styles.componentContainer}>
          <ProgressTracker isEU={false} reservation={customerReservation} />
        </div>

        {isOrder && (
          <>
            <div className={styles.componentContainer}>
              <PurchaseAccessories
                {...this.props}
                isDealerInstalledOptionEnabled={false}
                accessories={customerReservation.allAccessories}
                expandAccordian={this.state.expandAll}
              />
            </div>
            <div className={styles.componentContainer}>
              <OffersAndIncentives
                {...this.props}
                pricingStack={this.state.pricingStack}
                expandAccordian={this.state.expandAll}
              />
            </div>
          </>
        )}

        {UpperCaseText(customerReservation.orderType) === "ORDER" && (
          <div className={styles.componentContainer}>
            <PricingSummary
              {...this.props}
              paymentType={
                customerReservation && customerReservation.paymentType
              }
              customerReservation={customerReservation}
              isOrder={UpperCaseText(customerReservation.orderType) === "ORDER"}
              pricingStack={this.state.pricingStack}
              formatPriceValue={this.formatPriceValue}
              expandAccordian={this.state.expandAll}
            />
          </div>
        )}

        {customerReservation &&
          customerReservation.financeDataQuote &&
          customerReservation.financeDataQuote.financeProductType &&
          LowerCaseText(
            customerReservation.financeDataQuote.financeProductType
          ) !== FINANCE_PRODUCT_TYPE.CASH && (
            <div className={styles.componentContainer}>
              <PaymentMethod
                pricingStack={
                  customerReservation && customerReservation.pricingStack
                }
                financeDataQuote={
                  customerReservation && customerReservation.financeDataQuote
                }
                formatPriceValue={this.formatPriceValueForPaymentMethod}
                financeProductType={
                  customerReservation.financeDataQuote.financeProductType
                }
                customerReservation={customerReservation}
                expandAccordian={this.state.expandAll}
              />
            </div>
          )}

        <Grid container className={styles.newOrdersDiv}>
          <Grid item xs={4} className={styles.leftPadding}>
            {!IS_CANADA_MARKET(this.props.user.market) && (
              <span>{this.props.t("PurchaseDetails.dealerActions")}</span>
            )}
          </Grid>
        </Grid>

        <div className={styles.componentContainer}>
          <ConsumerDeposit
            {...this.props}
            refreshReservationDetailsWithNotifications={
              this.refreshReservationDetailsWithNotifications
            }
            isPurchaseOrder={false}
            customerReservation={customerReservation}
            expandAccordian={this.state.expandAll}
          />
        </div>
      </div>
    ) : (
      ""
    );
  }
}

export default withTranslation("emp")(NAOrderDetailsNewVersion);
