import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  TableRow,
  TableCell,
  Collapse,
  Box,
  IconButton,
  makeStyles,
  Grid,
  Typography,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import UiDateUtils from "../../../utils/UiDateUtils";
import MotionChevronRight from "../../Icons/MotionChevronRight";
import UpperCaseText from "../../../utils/UpperCaseText";
import TitleCase from "../../../utils/TitleCase";
import { styled } from "@material-ui/core/styles";
import MotionPlus from "../../Icons/MotionPlus";
import MotionMinus from "../../Icons/MotionMinus";
import EMPButton from "../EMPButton/EMPButton";
import EMPEmptyState from "../EMPEmptyState/EMPEmptyState";

const useStyles = makeStyles({
  root: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 14,
    lineHeight: "24px",
  },
  body1: {
    color: "black",
  },
  body2: {
    color: "#4d4d4d",
    fontSize: 13,
  },
  h6: {
    fontFamily: "'FordAntennaBold', sans-serif",
    color: "#142742",
    fontSize: 14,
  },
  tableRowRoot: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  underlineNone: {
    fontFamily: "'FordAntennaBold', sans-serif",
    display: "flex",
    alignItems: "center",
    color: "#0068D0",

    "&:hover": {
      background: "none",
      color: "#0142BD",
    },
  },
  tableCellRoot: {
    padding: "16px 8px",

    "&:first-of-type": {
      padding: "8px",
    },
  },
});

const defaultColor = {
  color: "#00095b",
};

const disabledColor = {
  color: "#4d4d4d",
};

const warningColor = {
  color: "#D62D0A",
};

const successColor = {
  color: "#008200",
};

const StyledBox = styled(Box)({
  backgroundColor: "#f2f2f2",
  height: "100%",
  padding: "8px",
  boxSizing: "border-box",
});

const EMPExpandableTableRow = (props) => {
  const { appointmentDate, order, status, orderLabel, customerName } = props;
  const {
    modelYear,
    nameplate,
    vinNumber,
    id,
    purchaseRequestDate,
    purchaseRequestStatus,
    subJourneyType,
  } = order;
  const [color, setColor] = useState(defaultColor);
  const classes = useStyles();
  const updateColor = (statusColorProp) => {
    const colorMap = {
      disabled: disabledColor,
      warning: warningColor,
      default: defaultColor,
      success: successColor,
    };

    setColor(colorMap[statusColorProp]);
  };

  useEffect(() => {
    updateColor(status.statusColor);
  }, [status]);

  const showVin = !(UpperCaseText(subJourneyType) === "PIPELINE" && !vinNumber);

  return (
    <>
      <TableRow classes={{ root: classes.tableRowRoot }}>
        <TableCell classes={{ root: classes.tableCellRoot }}>
          {orderLabel && orderLabel}
        </TableCell>
        <TableCell classes={{ root: classes.tableCellRoot }}>
          {customerName ? (
            <Typography
              variant="body1"
              classes={{ root: classes.root, body1: classes.body1 }}
            >
              {customerName}
            </Typography>
          ) : (
            <EMPEmptyState />
          )}
          {showVin && (
            <Typography
              data-testid="vin-display"
              variant="body2"
              classes={{ root: classes.root, body2: classes.body2 }}
            >{`${props.t("ModelEOrders.vin")}: ${vinNumber}`}</Typography>
          )}
        </TableCell>
        <TableCell classes={{ root: classes.tableCellRoot }}>
          <Typography
            variant="body1"
            classes={{ root: classes.root, body1: classes.body1 }}
            style={{ color: color.color }}
            data-testid="table-row-message"
          >
            {status.message}
          </Typography>
          {appointmentDate &&
            !["CANCELED", "COMPLETED"].includes(
              UpperCaseText(status.status)
            ) && (
              <Typography
                variant="body2"
                classes={{ root: classes.root, body2: classes.body2 }}
              >
                {UiDateUtils.formatToDayDateTime(appointmentDate)}
              </Typography>
            )}
        </TableCell>

        <TableCell classes={{ root: classes.tableCellRoot }}>
          <EMPButton
            testId="orderDetailLink"
            buttonElement={Link}
            buttonType="text"
            rightIcon={<MotionChevronRight />}
            buttonText={props.t("ModelEOrders.viewDetails")}
            link={props.rowLink}
          ></EMPButton>
        </TableCell>
        <TableCell classes={{ root: classes.tableCellRoot }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={props.onShow}
            disableRipple
          >
            {props.isOpen ? <MotionMinus /> : <MotionPlus />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={7}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
            <Box
              pb={3}
              style={{
                paddingLeft: "98px",
              }}
            >
              <Grid container justifyContent="flex-start">
                <Grid item xs={12}>
                  <Box mb={1}>
                    <Typography
                      variant="h6"
                      classes={{ root: classes.root, h6: classes.h6 }}
                    >
                      {props.t("ModelEOrders.additionalInfo")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <StyledBox>
                      <Typography
                        display="inline"
                        variant="h6"
                        classes={{
                          root: classes.root,
                          h6: classes.h6,
                        }}
                      >
                        {`${props.t("ModelEOrders.purchaseNumber")} `}
                      </Typography>
                      <Typography
                        display="inline"
                        variant="body1"
                        classes={{
                          root: classes.root,
                          body1: classes.body1,
                        }}
                      >
                        {id || <EMPEmptyState />}
                      </Typography>
                    </StyledBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <StyledBox>
                      <Typography
                        display="inline"
                        variant="h6"
                        classes={{
                          root: classes.root,
                          h6: classes.h6,
                        }}
                      >
                        {`${props.t("ModelEOrders.status")}: `}
                      </Typography>
                      <Typography
                        display="inline"
                        variant="body1"
                        classes={{
                          root: classes.root,
                          body1: classes.body1,
                        }}
                      >
                        {TitleCase(purchaseRequestStatus) || <EMPEmptyState />}
                      </Typography>
                    </StyledBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                spacing={1}
                alignContent="stretch"
                style={{ marginTop: "4px" }}
              >
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <StyledBox data-testid="date-placed-box">
                    <Typography
                      display="inline"
                      variant="h6"
                      classes={{
                        root: classes.root,
                        h6: classes.h6,
                      }}
                    >
                      {`${props.t("ModelEOrders.datePlaced")}: `}
                    </Typography>
                    <Typography
                      display="inline"
                      variant="body1"
                      classes={{
                        root: classes.root,
                        body1: classes.body1,
                      }}
                    >
                      {purchaseRequestDate || <EMPEmptyState />}
                    </Typography>
                  </StyledBox>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <StyledBox>
                    <Typography
                      display="inline"
                      variant="h6"
                      classes={{
                        root: classes.root,
                        h6: classes.h6,
                      }}
                    >
                      {`${props.t("ModelEOrders.vehicle")}: `}
                    </Typography>
                    <Typography
                      noWrap
                      display="inline"
                      variant="body1"
                      classes={{
                        root: classes.root,
                        body1: classes.body1,
                      }}
                    >
                      {`${modelYear} ${nameplate}` || <EMPEmptyState />}
                    </Typography>
                  </StyledBox>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default withTranslation("emp")(EMPExpandableTableRow);
