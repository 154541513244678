const AVAILABLE_MARKETS = {
  FRA: "France",
  DEU: "Germany",
  NLD: "Netherlands",
  NOR: "Norway",
  CHE: "Switzerland",
  GBR: "United Kingdom",
  ESP: "Spain",
  ITA: "Italy",
  AUT: "Austria",
  BEL: "Belgium",
  FIN: "Finland",
  DNK: "Denmark",
};

export { AVAILABLE_MARKETS };
