import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./PaymentMethod.module.scss";
import Grid from "@material-ui/core/Grid";
import { NOTNULL_OR_NOTUNDEFINED } from "../../../../utils/EmpUtil";
import { StyledTableRow } from "../../../../shared/Tables/TableRow";
import { formatPriceValue } from "../../../../utils/FormatPrice";
import {
  StyledExpansionPanel,
  StyledExpansionPanelSummary,
} from "../../../../shared/ExpansionPanels/ExpansionPanel";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import crStyles from "../../crStyles";
import UpperCaseText from "../../../../utils/UpperCaseText";
import { NON_PIPELINE, PIPELINE } from "../../../../common/Constants";

class LeasePaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandEstimatedAmountDueAtSigning: true,
      expandHowEstimatedAmountsWillBePaid: true,
    };
  }

  expandEstimatedAmountDueAtSigning() {
    this.setState((prevState) => ({
      expandEstimatedAmountDueAtSigning:
        !prevState.expandEstimatedAmountDueAtSigning,
    }));
  }

  expandHowEstimatedAmountsWillBePaid() {
    this.setState((prevState) => ({
      expandHowEstimatedAmountsWillBePaid:
        !prevState.expandHowEstimatedAmountsWillBePaid,
    }));
  }

  getValue = (field) => {
    const { financeDataQuote } = this.props.customerReservation;

    return financeDataQuote && financeDataQuote[field];
  };

  getDownPayment = () => {
    let downPayment = this.props.t("PurchaseDetails.paymentMethod.downPayment");

    if (this.props.authorizedBelow) {
      downPayment += this.props.t(
        "PurchaseDetails.paymentMethod.authorizedBelow"
      );
    } else {
      downPayment += this.props.t("PurchaseDetails.paymentMethod.notCollected");
    }

    return downPayment;
  };

  formatMileage = (value) => {
    return NOTNULL_OR_NOTUNDEFINED(value)
      ? Number(parseFloat(value)).toLocaleString("en")
      : "--";
  };

  render() {
    const { pricingStack: reservationPricingStack, financeType } =
      this.props.customerReservation;

    const { customerReservation } = this.props;

    const orderDeposit =
      customerReservation &&
      customerReservation.orderPaymentInfo &&
      customerReservation.orderPaymentInfo.depositAmount;

    const reservationDeposit =
      customerReservation &&
      customerReservation.reservationPaymentInfo &&
      customerReservation.reservationPaymentInfo.depositAmount;

    const pricingStack = this.props.pricingStack;

    const paymentWithTaxes = pricingStack && pricingStack.paymentWithTaxes;

    const monthlyPaymentWithTaxes =
      paymentWithTaxes && pricingStack && pricingStack.monthlyPayment;

    const estimatedBasePayment =
      this.props.isModelEOrder &&
      !customerReservation.spotBuy &&
      (customerReservation.subJourneyType === null ||
        UpperCaseText(customerReservation.subJourneyType) === NON_PIPELINE)
        ? formatPriceValue(pricingStack && pricingStack.baseMonthlyPayment)
        : formatPriceValue(
            (reservationPricingStack &&
              reservationPricingStack.baseMonthlyPayment) ||
              this.getValue("baseMonthlyPayment") ||
              this.getValue("estimatedBasePayment") ||
              (pricingStack && pricingStack.baseMonthlyPayment)
          );
    // eslint-disable-next-line no-extra-boolean-cast
    const monthlyPayment = !!paymentWithTaxes
      ? monthlyPaymentWithTaxes
      : this.getValue("monthlyPayment");

    let monthlyTax;

    if (
      reservationPricingStack &&
      typeof reservationPricingStack.monthlyUseTax === "number"
    )
      monthlyTax = reservationPricingStack.monthlyUseTax;
    else if (pricingStack && typeof pricingStack.monthlyUseTax === "number")
      monthlyTax = pricingStack.monthlyUseTax;
    else monthlyTax = this.getValue("estimatedMonthlyTax");

    this.index = 0;

    return (
      <div>
        <StyledTableRow
          text={this.props.t("PurchaseDetails.paymentMethod.type")}
          data={
            financeType
              ? this.props.t(`PurchaseDetails.paymentMethod.${financeType}`)
              : "--"
          }
          index={++this.index}
          testID="type"
        />

        <StyledTableRow
          text={this.props.t(
            "PurchaseDetails.paymentMethod.estimatedBasePayment"
          )}
          data={estimatedBasePayment}
          index={++this.index}
          testID="estimatedBasePayment"
        />

        <StyledTableRow
          text={this.props.t(
            "PurchaseDetails.paymentMethod.estimatedMonthlyTax"
          )}
          data={formatPriceValue(monthlyTax)}
          index={++this.index}
          testID="estimatedMonthlyTax"
        />

        <StyledTableRow
          text={this.props.t(
            "PurchaseDetails.paymentMethod.estimatedMonthlyVehiclePayment"
          )}
          data={
            NOTNULL_OR_NOTUNDEFINED(monthlyPayment)
              ? formatPriceValue(monthlyPayment).concat(
                  this.props.t("PurchaseDetails.paymentMethod.perMonth")
                )
              : "--"
          }
          index={++this.index}
          testID="estimatedMonthlyVehiclePayment"
        />

        <StyledTableRow
          text={this.props.t("PurchaseDetails.paymentMethod.annualMiles")}
          data={this.formatMileage(this.getValue("annualMileage"))}
          index={++this.index}
          testID="annualMiles"
        />

        <StyledTableRow
          text={this.props.t("PurchaseDetails.paymentMethod.term")}
          data={
            NOTNULL_OR_NOTUNDEFINED(this.getValue("term"))
              ? this.getValue("term")
              : "--"
          }
          index={++this.index}
          testID="term"
        />

        <StyledTableRow
          text={this.props.t("PurchaseDetails.paymentMethod.residualValue")}
          data={formatPriceValue(
            (reservationPricingStack &&
              reservationPricingStack.residualAmount) ||
              this.getValue("residualAmount") ||
              (pricingStack && pricingStack.residualAmount)
          )}
          index={++this.index}
          testID="residualValue"
        />

        <StyledExpansionPanel
          expanded={this.state.expandEstimatedAmountDueAtSigning}
          onChange={() => this.expandEstimatedAmountDueAtSigning()}
        >
          <StyledExpansionPanelSummary style={crStyles.nestedAccordionHeader}>
            <Grid
              container
              className={styles.creditApplication}
              data-testid="estimatedAmountDueAtSigning"
            >
              <Grid item xs={10}>
                <div className={styles.creditTitle}>
                  {this.props.t(
                    "PurchaseDetails.paymentMethod.estimatedAmountDueAtSign"
                  )}
                </div>
              </Grid>
            </Grid>
            <div className={styles.arrowDiv}>
              {this.state.expandEstimatedAmountDueAtSigning ? (
                <ExpandLessIcon data-testid="more" />
              ) : (
                <ExpandMoreIcon data-testid="less" />
              )}
            </div>
          </StyledExpansionPanelSummary>

          <StyledTableRow
            text={this.props.t(
              "PurchaseDetails.paymentMethod.firstEstimatedMonthlyPayment"
            )}
            data={formatPriceValue(this.getValue("monthlyPayment"))}
            index={++this.index}
            testID="firstEstimatedMonthlyPayment"
          />

          {reservationDeposit && (
            <StyledTableRow
              text={this.props.t(
                "OrderDetailsPaymentMethod.reservationDeposit"
              )}
              indent={true}
              data={reservationDeposit}
              index={++this.index}
              testID="reservationDeposit"
            />
          )}
          {orderDeposit && (
            <StyledTableRow
              text={this.props.t("OrderDetailsPaymentMethod.orderDeposit")}
              indent={true}
              data={orderDeposit}
              index={++this.index}
              testID="orderDeposit"
            />
          )}

          {this.props.isModelEOrder ? (
            <StyledTableRow
              text={this.props.t("PurchaseDetails.paymentMethod.downPayment")}
              indent={true}
              data={formatPriceValue(
                customerReservation &&
                  customerReservation.financeDataQuote &&
                  customerReservation.financeDataQuote["deposit"]
              )}
              index={++this.index}
              testID="downPayment"
            />
          ) : (
            <StyledTableRow
              text={this.getDownPayment()}
              indent={true}
              data={formatPriceValue(this.getValue("deposit"))}
              index={++this.index}
              testID="downPayment"
            />
          )}

          <StyledTableRow
            text={this.props.t(
              "PurchaseDetails.paymentMethod.capitalizedCostReduction"
            )}
            data={formatPriceValue(
              (reservationPricingStack &&
                reservationPricingStack.capitalizedCostReduction) ||
                (pricingStack && pricingStack.capitalizedCostReduction)
            )}
            index={++this.index}
            testID="capitalizedCostReduction"
          />
        </StyledExpansionPanel>

        <StyledExpansionPanel
          expanded={this.state.expandHowEstimatedAmountsWillBePaid}
          onChange={() => this.expandHowEstimatedAmountsWillBePaid()}
        >
          <StyledExpansionPanelSummary style={crStyles.nestedAccordionHeader}>
            <Grid container className={styles.creditApplication}>
              <Grid item xs={10}>
                <div className={styles.creditTitle}>
                  {this.props.t(
                    "PurchaseDetails.paymentMethod.howEstimatedAmountsWillBePaid"
                  )}
                </div>
              </Grid>
            </Grid>
            <div className={styles.arrowDiv}>
              {this.state.expandHowEstimatedAmountsWillBePaid ? (
                <ExpandLessIcon data-testid="more" />
              ) : (
                <ExpandMoreIcon data-testid="less" />
              )}
            </div>
          </StyledExpansionPanelSummary>
          <StyledTableRow
            text={this.props.t(
              "PurchaseDetails.paymentMethod.currentAvailableIncentivesAndNonCashCredits"
            )}
            indent={true}
            data={
              this.props.isModelEOrder &&
              UpperCaseText(customerReservation.subJourneyType) === PIPELINE
                ? formatPriceValue(
                    pricingStack &&
                      pricingStack.availableIncentivesAndNonCashCredits
                  )
                : formatPriceValue(
                    (reservationPricingStack &&
                      reservationPricingStack.availableIncentivesAndNonCashCredits) ||
                      (pricingStack &&
                        pricingStack.availableIncentivesAndNonCashCredits)
                  )
            }
            index={++this.index}
            testID="currentAvailableIncentivesAndNonCashCredits"
          />

          <StyledTableRow
            text={this.props.t(
              "PurchaseDetails.paymentMethod.estimatedNetTradeInAmount"
            )}
            indent={true}
            data={formatPriceValue(
              reservationPricingStack && reservationPricingStack.tradeInPrice
            )}
            index={++this.index}
            testID="estimatedNetTradeInAmount"
          />

          <StyledTableRow
            text={this.props.t(
              "PurchaseDetails.paymentMethod.estimatedAmountsToBePaidInCash"
            )}
            indent={true}
            data={
              this.props.isModelEOrder &&
              UpperCaseText(customerReservation.subJourneyType) === PIPELINE
                ? formatPriceValue(this.getValue("cashDueAtSigning"))
                : formatPriceValue(
                    (reservationPricingStack &&
                      reservationPricingStack.cashDueAtSigning) ||
                      this.getValue("cashDueAtSigning") ||
                      (pricingStack && pricingStack.cashDueAtSigning)
                  )
            }
            index={++this.index}
            testID="estimatedAmountsToBePaidInCash"
          />

          <StyledTableRow
            text={this.props.t(
              "PurchaseDetails.paymentMethod.howEstimatedAmountsWillBePaidSumTotal"
            )}
            data={
              this.props.isModelEOrder &&
              UpperCaseText(customerReservation.subJourneyType) === PIPELINE
                ? formatPriceValue(this.getValue("totalAmountDueAtSigning"))
                : formatPriceValue(
                    pricingStack && pricingStack.totalAmountDueAtSigning
                  )
            }
            index={++this.index}
            testID="howEstimatedAmountsWillBePaid"
          />
        </StyledExpansionPanel>
      </div>
    );
  }
}

export default withTranslation("emp")(LeasePaymentMethod);
