import React from "react";

const DatePickerIcon = () => {
  return (
    <svg width="24" height="26" xmlns="http://www.w3.org/2000/svg">
      <g stroke="#102B4E" strokeWidth="2" fill="none" fillRule="evenodd">
        <path strokeLinejoin="round" d="M1 4h22v21H1z" />
        <path
          strokeLinecap="square"
          d="M2.5 10.5h20M2.5 17.5h20M8.687 11.02v13"
        />
        <path strokeLinecap="round" d="M4.687 1.02v3" />
        <path strokeLinecap="square" d="M15.687 11.02v13" />
        <path strokeLinecap="round" d="M19.687 1.02v3" />
      </g>
    </svg>
  );
};

export default DatePickerIcon;
