import React, { Component } from "react";
import styles from "./ProgressTracker.module.scss";
import { withTranslation } from "react-i18next";
import UpperCaseText from "../../../../utils/UpperCaseText";
import { ORDER_STATUS, RESERVATION_STATUS } from "../../../../common/Constants";
import LowerCaseText from "../../../../utils/LowerCaseText";

class ProgressTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [],
      language: localStorage.getItem("lang"),
    };

    this.preOrderPlacedStatuses = this.props.preOrderPlacedStatuses;
    this.orderPlacedStatuses = ["ORDER_CONFIRMED", "ORDERED"];
    this.cancelledStatuses = [
      "DEALER_CANCELLED",
      "CONTRACT_CANCELLED",
      "RESERVATION_CANCELLED",
      "ORDER_CANCELLED",
      "DEALER_REJECTED",
    ];
  }

  componentDidMount() {
    this.setState({ steps: this.getSteps() });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.language !== localStorage.getItem("lang")) {
      this.setState({
        steps: this.getSteps(),
        language: localStorage.getItem("lang"),
      });
    }
  }

  getSteps() {
    this.allActiveReservationStepsForItaly = [
      {
        number: 1,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.orderPlacedEU")
          : this.props.t("ProgressTracker.orderPlacedNA"),
        style: styles.active,
      },
      {
        number: 2,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.inProductionEU")
          : this.props.t("ProgressTracker.inProductionNA"),
        style: styles.active,
      },
      {
        number: 3,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.shippingEU")
          : this.props.t("ProgressTracker.shippingNA"),
        style: styles.active,
      },
      {
        number: 4,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.deliveredEU")
          : this.props.t("ProgressTracker.deliveredNA"),
        style: styles.active,
      },
    ];
    this.allActiveReservationSteps = [
      {
        number: 1,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.reservationPlacedEU")
          : this.props.t("ProgressTracker.reservationPlacedNA"),
        style: styles.active,
      },
      {
        number: 2,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.orderPlacedEU")
          : this.props.t("ProgressTracker.orderPlacedNA"),
        style: styles.active,
      },
      {
        number: 3,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.inProductionEU")
          : this.props.t("ProgressTracker.inProductionNA"),
        style: styles.active,
      },
      {
        number: 4,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.shippingEU")
          : this.props.t("ProgressTracker.shippingNA"),
        style: styles.active,
      },
      {
        number: 5,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.deliveredEU")
          : this.props.t("ProgressTracker.deliveredNA"),
        style: styles.active,
      },
    ];
    this.allActiveOrderSteps = [
      {
        number: 1,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.orderPlacedEU")
          : this.props.t("ProgressTracker.orderPlacedNA"),
        style: styles.active,
      },
      {
        number: 2,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.inProductionEU")
          : this.props.t("ProgressTracker.inProductionNA"),
        style: styles.active,
      },
      {
        number: 3,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.shippingEU")
          : this.props.t("ProgressTracker.shippingNA"),
        style: styles.active,
      },
      {
        number: 4,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.deliveredEU")
          : this.props.t("ProgressTracker.deliveredNA"),
        style: styles.active,
      },
    ];
    this.allDisabledReservationSteps = [
      {
        number: 1,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.reservationPlacedEU")
          : this.props.t("ProgressTracker.reservationPlacedNA"),
        style: "",
      },
      {
        number: 2,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.orderPlacedEU")
          : this.props.t("ProgressTracker.orderPlacedNA"),
        style: "",
      },
      {
        number: 3,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.inProductionEU")
          : this.props.t("ProgressTracker.inProductionNA"),
        style: "",
      },
      {
        number: 4,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.shippingEU")
          : this.props.t("ProgressTracker.shippingNA"),
        style: "",
      },
      {
        number: 5,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.deliveredEU")
          : this.props.t("ProgressTracker.deliveredNA"),
        style: "",
      },
    ];
    this.allDisabledOrderSteps = [
      {
        number: 1,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.orderPlacedEU")
          : this.props.t("ProgressTracker.orderPlacedNA"),
        style: "",
      },
      {
        number: 2,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.inProductionEU")
          : this.props.t("ProgressTracker.inProductionNA"),
        style: "",
      },
      {
        number: 3,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.shippingEU")
          : this.props.t("ProgressTracker.shippingNA"),
        style: "",
      },
      {
        number: 4,
        name: this.props.isEU
          ? this.props.t("ProgressTracker.deliveredEU")
          : this.props.t("ProgressTracker.deliveredNA"),
        style: "",
      },
    ];

    const isReservation =
      UpperCaseText(this.props.reservation.orderType) === "RESERVATION";
    const wasReservation = this.props.reservation.conversionDate;
    const isCancelled = this.cancelledStatuses.includes(
      this.props.reservation.status
    );

    const isRejected = this.props.reservation.dealerRejectedDate ? true : false;

    if (isCancelled || isRejected) {
      if (isReservation || wasReservation) {
        return this.getCancelledReservationSteps(
          LowerCaseText(this.props.reservation.vehicleStatusCode),
          this.props.reservation.status,
          wasReservation,
          isRejected
        );
      } else {
        return this.getCancelledOrderSteps(
          LowerCaseText(this.props.reservation.vehicleStatusCode),
          this.props.reservation.status,
          isRejected
        );
      }
    } else {
      if (isReservation || wasReservation) {
        return this.getReservationSteps(
          LowerCaseText(this.props.reservation.vehicleStatusCode),
          wasReservation
        );
      } else {
        return this.getOrderSteps(
          LowerCaseText(this.props.reservation.vehicleStatusCode)
        );
      }
    }
  }

  addReservationSteps(index, cancelled) {
    const allSteps = [];

    for (let i = 0; i < 5; i++) {
      if (i > index && cancelled) {
        break;
      } else if (i > index) {
        allSteps.push(this.allDisabledReservationSteps[i]);
      } else {
        allSteps.push(this.allActiveReservationSteps[i]);
      }
    }

    return allSteps;
  }

  getReservationSteps(status, wasReservation) {
    let index = wasReservation ? 1 : 0;
    if (status) {
      index = this.getIndex(status, false);
    }
    index = index === undefined ? -1 : index;
    return this.addReservationSteps(index, false);
  }

  getCancelledReservationSteps(
    status,
    cancelStatus,
    wasReservation,
    isRejected
  ) {
    let index = wasReservation ? 1 : 0;

    if (status) {
      index = this.getIndex(status, false);
    }

    const steps = this.addReservationSteps(index, true);

    steps.push({
      number: index + 2,
      name: this.props.isEU
        ? this.props.t("ProgressTracker.cancelledEU")
        : isRejected
        ? this.props.t("ProgressTracker.rejected")
        : this.props.t("ProgressTracker.cancelledNA"),
      style: styles.active,
    });

    return steps;
  }

  addOrderSteps(index, cancelled) {
    const allSteps = [];

    for (let i = 0; i < 4; i++) {
      if (i > index && cancelled) {
        break;
      } else if (i > index) {
        allSteps.push(this.allDisabledOrderSteps[i]);
      } else {
        allSteps.push(this.allActiveOrderSteps[i]);
      }
    }

    return allSteps;
  }

  getIndex(status, type) {
    if (type) {
      for (const [key, value] of Object.entries(ORDER_STATUS)) {
        for (let i = 0; i < value.length; i++) {
          if (value[i] === status) {
            return parseInt(key);
          }
        }
      }
    } else {
      for (const [key, value] of Object.entries(RESERVATION_STATUS)) {
        for (let i = 0; i < value.length; i++) {
          if (value[i] === status) {
            return parseInt(key);
          }
        }
      }
    }
  }

  getOrderSteps(status) {
    let index = 0;
    if (status) {
      index = this.getIndex(status, true);
    }
    return this.addOrderSteps(index, false);
  }

  getCancelledOrderSteps(status, cancelStatus, isRejected) {
    let index = 0;

    if (status) {
      index = this.getIndex(status, true);
    }

    const steps = this.addOrderSteps(index, true);

    steps.push({
      number: index + 2,
      name: this.props.isEU
        ? this.props.t("ProgressTracker.cancelledEU")
        : isRejected
        ? this.props.t("ProgressTracker.rejected")
        : this.props.t("ProgressTracker.cancelledNA"),
      style: styles.active,
    });

    return steps;
  }

  getStyle(currentStatusIndex, stepIndex) {
    return currentStatusIndex >= stepIndex ? styles.active : "";
  }

  getInfo() {
    const vista = this.props.reservation.vistaOrderNumber;
    const orbit = this.props.reservation.orbitId;
    const created = this.props.reservation.orbitIdCreatedDate;
    return (
      <div className={styles.subheaderDiv}>
        {vista && (
          <div className={styles.subheaderText}>
            {this.props.t("ProgressTracker.addedToVista")}
          </div>
        )}
        <div className={styles.subheaderTextBold}>
          {vista && (
            <span>
              {this.props.t("ProgressTracker.vista")}
              {vista}
            </span>
          )}
          {vista && orbit && <span>&nbsp;|&nbsp;</span>}
          {orbit && (
            <span>
              {this.props.t("ProgressTracker.orbit")}
              {orbit}
            </span>
          )}
          {(vista || orbit) && created && (
            <span>
              &nbsp;({this.props.t("ProgressTracker.entered")}{" "}
              {created.replace(/-/gi, "/")})
            </span>
          )}
        </div>
      </div>
    );
  }

  getCancelledInfo(status) {
    const cancelledBy =
      status === "DEALER_CANCELLED" || status === "ORDER_CANCELLED"
        ? this.props.t("ProgressTracker.dealer")
        : this.props.t("ProgressTracker.consumer");
    return (
      <div className={styles.subheaderDiv}>
        <div className={styles.subheaderTextBold}>
          {this.props.t("ProgressTracker.cancelledBy")} {cancelledBy}
        </div>
        <div className={styles.subheaderText}>
          {this.props.t("ProgressTracker.cancelledSubheader")}
        </div>
      </div>
    );
  }

  render() {
    const orderCancelled = this.cancelledStatuses.includes(
      this.props.reservation.status
    );

    return (
      <div className={styles.progressTrackerWrapper}>
        <div className={styles.progressTrackerHeader}>
          {this.props.t("ProgressTracker.header")}
        </div>
        <div
          className={
            orderCancelled ? styles.progressBarCancelled : styles.progressBar
          }
        >
          {this.state.steps.map((step, index) => {
            return (
              <div className={styles.navTab} key={index}>
                {index > 0 ? (
                  <div className={styles.connectingLine}>
                    <span className={step.style}>&nbsp;</span>
                  </div>
                ) : (
                  ""
                )}
                <div className={styles.navStepButton}>
                  <div className={`${styles.progressTabNumber} ${step.style}`}>
                    <span
                      data-testid={`${step.style}${step.number}`}
                      className={`${styles.stepNumber} ${step.style}`}
                    >
                      {step.number}
                    </span>
                  </div>
                  <div className={`${styles.progressTab} ${step.style}`}>
                    {step.name}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {this.props.isEU &&
          (orderCancelled
            ? this.getCancelledInfo(this.props.reservation.status)
            : this.getInfo())}
      </div>
    );
  }
}

export default withTranslation("emp")(ProgressTracker);
