import FormControl from "@material-ui/core/FormControl";
import React from "react";
import { withTranslation } from "react-i18next";
import EMPSelectField from "../../../shared/components/EMPSelectField/EMPSelectField";

const select = "select";

function getMenuItems(props) {
  const menuItems = [
    {
      text: props.t("AccessoriesSearch.select"),
      value: select,
      disabled: true,
    },
  ];
  props.categoryDropDownValues.forEach((category) => {
    menuItems.push({
      text: category,
      value: category,
    });
  });
  return menuItems;
}
class CategoryDropDown extends React.Component {
  render() {
    const props = this.props;
    return (
      <>
        <div className={props.styles.subTitle}>
          {props.t("AccessoriesSearch.category")}
        </div>
        <div
          data-testid="categoryDropdown"
          className={props.styles.modelDropdownDiv}
        >
          <FormControl className={props.styles.modelFormControl}>
            <EMPSelectField
              testId="categorySelect"
              value={props.selectedCategory}
              onChange={(event) => props.updateCategory(event)}
              menuItems={getMenuItems(props)}
            />
          </FormControl>
        </div>
      </>
    );
  }
}

export default withTranslation("emp")(CategoryDropDown);
