import DeliveryStatuses from "./DeliveryStatuses";

// centralized logic around delivery status hierarchy

const getDeliveryStatus = (appointment) => {
  if (appointment) {
    if (appointment.appointmentStatus) {
      if (
        appointment.appointmentStatus === "COMPLETE" &&
        appointment.dealershipEmployee
      ) {
        return DeliveryStatuses.deliveryCompleted;
      } else if (
        appointment.appointmentStatus === "SCHEDULED" &&
        appointment.date &&
        appointment.dealershipEmployee === null
      ) {
        return DeliveryStatuses.deliveryRequested;
      } else if (
        appointment.appointmentStatus === "SCHEDULED" &&
        appointment.dealershipEmployee &&
        appointment.date
      ) {
        return DeliveryStatuses.deliveryConfirmed;
      } else if (appointment.appointmentStatus === "CANCELED") {
        return DeliveryStatuses.deliveryCanceled;
      } else {
        return DeliveryStatuses.deliveryUnableToComplete;
      }
    } else {
      return DeliveryStatuses.deliveryRequested;
    }
  } else {
    return DeliveryStatuses.deliveryNotScheduled;
  }
};

export default getDeliveryStatus;
