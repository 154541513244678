import React, { Component } from "react";
import fordStyles from "./../orderDetails/NAOrderDetailsNewVersion.module.scss";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { StyledBasicExpansionPanel } from "../../../shared/ExpansionPanels/ExpansionPanel";
import DataCard from "../../../shared/components/DataCard/DataCard";
import KeyValueField from "../../../shared/components/DataFields/KeyValueField";
import UpperCaseText from "../../../utils/UpperCaseText";
import { AccordionSummary, withStyles } from "@material-ui/core";
import {
  DOC_UPLOAD_STATUS_COMPLETE,
  DOC_UPLOAD_STATUS_LOCKED,
  DOC_UPLOAD_STATUS_READY,
  PDS_STATUS_ACCEPT,
  PDS_STATUS_READY,
  PDS_STATUS_REJECT,
} from "../../../common/Constants";

const StyledAccordionSummary = withStyles({
  root: {
    fontFamily: "FordAntennaRegular",
    fontSize: 24,
    paddingLeft: 24,
    paddingRight: 24,
    color: "#1C1C1E",
  },
  content: {
    marginTop: 16,
    marginBottom: 16,
  },
})(AccordionSummary);

class CustomerContracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandCustomerContract: false,
    };
    this.preDeliverySummaryStatus = this.getPreDeliverySummaryStatus(
      this.props.preDeliverySummary
    );
    this.docUploadStatus = this.getDocUploadStatus(
      this.props.docUploadDataStatus
    );
    this.docUploadToolTipMessage = this.getDocUploadToolTipMessage(
      this.props.docUploadDataStatus
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({ expandCustomerContract: this.props.expandAccordian });
    }
  }
  expandCustomerInfo = () => {
    this.setState({
      expandCustomerContract: !this.state.expandCustomerContract,
    });
  };

  getPreDeliverySummaryStatus(preDeliverySummary) {
    if (preDeliverySummary) {
      switch (preDeliverySummary.systemStatus) {
        case PDS_STATUS_READY:
          return this.props.t("ModelEOrders.pdsReadyStatus");
        case PDS_STATUS_ACCEPT:
          return this.props.t("ModelEOrders.pdsAcceptedStatus");
        case PDS_STATUS_REJECT:
          return this.props.t("ModelEOrders.pdsRejectedStatus");
        default:
          return null;
      }
    }
    return null;
  }

  getDocUploadStatus(docUploadStatus) {
    if (docUploadStatus) {
      switch (UpperCaseText(docUploadStatus)) {
        case DOC_UPLOAD_STATUS_COMPLETE:
          return this.props.t("ModelEOrders.complete");
        case DOC_UPLOAD_STATUS_READY:
          return this.props.t("ModelEOrders.ready");
        case DOC_UPLOAD_STATUS_LOCKED:
          return this.props.t("ModelEOrders.locked");
        default:
          return null;
      }
    } else {
      return null;
    }
  }

  getDocUploadToolTipMessage(docUploadStatus) {
    if (docUploadStatus) {
      switch (UpperCaseText(docUploadStatus)) {
        case DOC_UPLOAD_STATUS_COMPLETE:
          return this.props.t("ModelEOrders.allRequestedDocumentationUploaded");
        case DOC_UPLOAD_STATUS_READY:
          return this.props.t("ModelEOrders.documentRequestSubmitted");
        case DOC_UPLOAD_STATUS_LOCKED:
          return this.props.t("ModelEOrders.documentUploadLocked");
        default:
          return "";
      }
    } else {
      return "";
    }
  }

  render() {
    const hasDocUploadData =
      this.props.docUploadDataStatus !== undefined &&
      this.props.docUploadDataStatus !== null;
    return (
      <StyledBasicExpansionPanel
        onChange={() => this.expandCustomerInfo()}
        expanded={this.state.expandCustomerContract}
      >
        <StyledAccordionSummary
          expandIcon={<ExpandLessIcon />}
          IconButtonProps={{ disableRipple: true }}
          id="exp"
        >
          {this.props.t("ModelEOrders.customerContractsAccordionHeader")}
        </StyledAccordionSummary>
        <div className={fordStyles.customerInfoGrid}>
          <Grid container>
            <Grid item className={fordStyles.cardGridItemWrapper}>
              <DataCard
                cardHeader={this.props.t(
                  "ModelEOrders.customerContractsHeader"
                )}
              >
                <KeyValueField
                  {...this.props}
                  dataKey={this.props.t("ModelEOrders.documentUpload")}
                  dataValue={this.docUploadStatus}
                  hasValueToolTip={
                    hasDocUploadData && this.docUploadToolTipMessage !== ""
                  }
                  valueToolTipBody={this.docUploadToolTipMessage}
                ></KeyValueField>
                <KeyValueField
                  dataKey={this.props.t("ModelEOrders.dealJacket")}
                  dataValue={null}
                ></KeyValueField>

                <KeyValueField
                  dataKey={this.props.t("ModelEOrders.preDeliverySummary")}
                  dataValue={this.preDeliverySummaryStatus}
                ></KeyValueField>
              </DataCard>
            </Grid>
          </Grid>
        </div>
      </StyledBasicExpansionPanel>
    );
  }
}

export default withTranslation("emp")(CustomerContracts);
