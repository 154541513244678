import React from "react";
import { Button, makeStyles } from "@material-ui/core";

// Available props:
// buttonText: string
// disabled: Boolean
// buttonType: string ("primary", "outlined", "text", "link")
// buttonElement: string ("a", "button")
// buttonHref: string (use if buttonElement is "a")
// onClick: Func
// rightIcon: Component (icon)
// leftIcon : Component (icon)
// downloadIcon: Boolean
// testId: string
// submitButton: Boolean (true sets type="submit")

const useStyles = makeStyles(
  {
    root: {
      fontFamily: "'Ford Antenna Bold', 'FordAntennaBold', sans-serif",
      padding: "8px 20px",
      fontSize: 14,
      lineHeight: "24px",
      borderRadius: 24,
      textTransform: "none",
      letterSpacing: "normal",
      fontWeight: "unset",
      transition: "none",

      "&:hover": {
        background: "none",
      },

      "&.link-button": {
        background: "none",
        minWidth: 0,
        border: "none",
        color: "#066FEF",
        padding: 0,
        textDecorationLine: "underline",

        "&:hover": {
          background: "none",
          color: "#0559BF",
          textDecorationLine: "underline",
        },
      },
    },
    label: {
      pointerEvents: "inherit",
    },
    contained: {
      background: "#066FEF",
      color: "#ffffff",
      "&:hover": {
        background: "#0559BF",
      },
      "&:disabled": {
        pointerEvents: "unset",
      },
    },
    outlined: {
      background: "none",
      border: "1px solid #066FEF",
      color: "#066FEF",

      "&:hover": {
        color: "#0559BF",
        borderColor: "#0559BF",
      },
    },
    text: {
      background: "none",
      border: "none",
      color: "#066FEF",
      paddingLeft: 0,
      paddingRight: 0,

      "&:hover": {
        background: "none",
        color: "#0559BF",
      },

      "&:disabled": {
        background: "none",
      },
    },
    disabled: {
      background: "rgb(217, 217, 217)",
      color: "rgb(155, 155, 155)",
    },
  },
  {
    name: "EMPButton",
  }
);

const EMPButton = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    disabled,
    buttonText,
    buttonElement,
    buttonType,
    rightIcon,
    leftIcon,
    onClick,
    testId,
    buttonHref,
    submitButton,
    link,
    id,
  } = props;

  return (
    <Button
      classes={{
        root: classes.root,
        contained: classes.contained,
        disabled: classes.disabled,
        outlined: classes.outlined,
        text: classes.text,
        label: classes.label,
        iconSizeMedium: classes.iconSizeMedium,
      }}
      className={buttonType === "link" ? "link-button" : null}
      ref={ref}
      disableRipple={true}
      disableTouchRipple={true}
      component={buttonElement}
      disabled={disabled}
      disableElevation={true}
      variant={buttonType !== "link" ? buttonType : null}
      startIcon={leftIcon ? leftIcon : null}
      endIcon={rightIcon ? rightIcon : null}
      onClick={onClick}
      data-testid={testId}
      href={buttonHref ? buttonHref : null}
      type={submitButton ? "submit" : "button"}
      to={link ? link : null}
      id={id}
    >
      {buttonText}
    </Button>
  );
});

export default EMPButton;
