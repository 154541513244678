import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import UpperCaseText from "../../../utils/UpperCaseText";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";
import fordStylesOverride from "./ReportPreviewStylesOverride";
import lincolnStylesOverride from "./ReportPreviewLincolnStylesOverride";
import fordStyles from "./ReportPreview-Ford.module.scss";
import lincolnStyles from "./ReportPreview-Lincoln.module.scss";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";

let styles = fordStyles;
let isFordBrand = true;
let stylesOverride = fordStylesOverride;

const StyledTable = withStyles({
  root: {
    width: "62%",
  },
})(Table);

const StyledTableRow = withStyles({
  root: {
    height: "30px",
  },
})(TableRow);

const StyledTableCell = withStyles({
  root: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "0px",
    paddingBottom: "0px",
    textAlign: "center",
    border: "solid 1.5px #979797",
    height: "30px",
  },
})(TableCell);

const StyledTableCellNoBorder = withStyles({
  root: {
    paddingLeft: "5px",
    paddingRight: "20px",
    paddingTop: "0px",
    paddingBottom: "0px",
    textAlign: "left",
    borderTop: "solid 1.5px #979797",
    borderBottom: "solid 1.5px #979797",
    borderRight: "solid 1.5px #979797",
    width: "12%",
  },
})(TableCell);

const updateStyles = (props) => {
  isFordBrand = IS_FORD_BRAND(props.user.dealerBrand);
  stylesOverride = isFordBrand ? fordStylesOverride : lincolnStylesOverride;
  document.body.style.backgroundColor = isFordBrand ? "#ffffff" : "#f6f7f7";
  styles = isFordBrand ? fordStyles : lincolnStyles;
};

const ReservationsPreview = (props) => {
  updateStyles(props);

  const dealerName =
    props.dealerInfo && props.dealerInfo[0]
      ? props.dealerInfo[0].dealerName + " - "
      : "";

  return (
    <div className={styles.tablePadding}>
      <div className={styles.info}>
        {props.t("fieldReporting.table.pleaseSelectReport")}
      </div>
      <div className={styles.dealerName}>
        {props.t("fieldReporting.table.reservationConversionDetails", {
          filterString: `${props.selectionTypes.model}, ${props.selectionTypes.modelYear}`,
        })}
      </div>
      <div className={styles.dealerInfo}>
        {isFordBrand
          ? UpperCaseText(dealerName) +
            UpperCaseText(props.selectionTypes.filterType) +
            props.selectionTypes.dateRange
          : UpperCaseText(dealerName) +
            props.selectionTypes.filterType +
            props.selectionTypes.dateRange}
      </div>
      <div className={styles.info}>
        <span>{props.t("fieldReporting.table.modelGroupingReportInfo")}</span>
        <EMPButton
          testId="downloadReport"
          disabled={!props.renderPreview}
          onClick={() => props.downloadFieldReport()}
          buttonType="text"
          leftIcon={<CloudDownloadIcon />}
          buttonText={props.t("fieldReporting.download")}
          submitButton
        ></EMPButton>
      </div>
      <StyledTable className={styles.table}>
        <TableHead>
          <TableRow>
            <StyledTableCellNoBorder style={stylesOverride.cellGreyBackground}>
              <div>{props.t("fieldReporting.table.description")}</div>
            </StyledTableCellNoBorder>
            <StyledTableCell style={stylesOverride.cellGreyBackground}>
              <div>{props.t("fieldReporting.table.active")}</div>
            </StyledTableCell>
            <StyledTableCell style={stylesOverride.cellGreyBackground}>
              <div>{props.t("fieldReporting.table.canceled")}</div>
            </StyledTableCell>
            <StyledTableCell style={stylesOverride.cellGreyBackground}>
              <div>{props.t("fieldReporting.table.total")}</div>
            </StyledTableCell>
            <StyledTableCell style={stylesOverride.cellGreyBackground}>
              <div>{props.t("fieldReporting.table.depositsRefunded")}</div>
            </StyledTableCell>
            <StyledTableCell style={stylesOverride.cellGreyBackground}>
              <div>
                {props.t("fieldReporting.table.unconvertedReservations")}
              </div>
            </StyledTableCell>
            {/*<StyledTableCell style={stylesOverride.cellGreyBackground}>*/}
            {/*  <div>{props.t("fieldReporting.table.conversionRate")}</div>*/}
            {/*</StyledTableCell>*/}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data !== null &&
            props.data.map((item, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCellNoBorder
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.isTotal
                      ? props.selectionTypes.totalType
                      : item.description}
                  </StyledTableCellNoBorder>
                  <StyledTableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.active}
                  </StyledTableCell>
                  <StyledTableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.cancelled}
                  </StyledTableCell>
                  <StyledTableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.total}
                  </StyledTableCell>
                  <StyledTableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.depositsRefunded}
                  </StyledTableCell>
                  <StyledTableCell
                    style={
                      item.isTotal
                        ? stylesOverride.cellGreyBackgroundBold
                        : stylesOverride.cell
                    }
                  >
                    {item.unconvertedReservations}
                  </StyledTableCell>
                  {/*<StyledTableCell*/}
                  {/*  style={*/}
                  {/*    item.isTotal*/}
                  {/*      ? stylesOverride.cellGreyBackgroundBold*/}
                  {/*      : stylesOverride.cell*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  {item.conversionRate}%*/}
                  {/*</StyledTableCell>*/}
                </StyledTableRow>
              );
            })}
        </TableBody>
      </StyledTable>
    </div>
  );
};

export default withTranslation("emp")(ReservationsPreview);
