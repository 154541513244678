import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import SubNav from "../../shared/subnav/SubNav";
import { CircularProgress, Grid } from "@material-ui/core";
import fordStyles from "../dashboard/dealerDashBoard/DealerReporting/DealerReporting.module.scss";
import lincolnStyles from "../dashboard/dealerDashBoard/DealerReporting/DealerReportingLincoln.module.scss";
import { IS_FORD_BRAND, NOTNULL_OR_NOTUNDEFINED } from "../../utils/EmpUtil";
import Item from "../../shared/item/Item";
import SortingIcon from "../../shared/sortingIcon/SortingIcon";
import BrandSettingsClient from "../../clients/BrandSettingsClient";
import { logMsg } from "../../common/Logger";
import DealerReportingClient from "../../clients/DealerReportingClient";
import { base64FileDownload } from "../../utils/FileDownloadUtil";
import SortData from "../../utils/SortUtil";
import HasPermissionTo from "../../common/HasPermissionTo";
import UpperCaseText from "../../utils/UpperCaseText";
import UiDateUtils from "../../utils/UiDateUtils";
import ReportFilter from "./ReportFilter";
import * as AnalyticsService from "../../shared/services/analytics/AnalyticsService";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

class NADealerReporting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortKey: null,
      sortDirection: null,
      showProgress: false,
      dateRangeMethod: "auto",
      dateRange: this.props.t("fieldReporting.dropDowns.last30Days"),
      dateRanges: [
        this.props.t("fieldReporting.dropDowns.last30Days"),
        this.props.t("fieldReporting.dropDowns.last90Days"),
        this.props.t("fieldReporting.dropDowns.yearToDate"),
      ],
      fromDate: new Date(),
      toDate: new Date(),
      reports: [
        {
          key: "report3",
          name: this.props.t("dealerReporting.report3Name"),
          downloadLink: this.downloadDealerReservationReport.bind(this),
        },
        {
          key: "report4",
          name: this.props.t("dealerReporting.report4Name"),
          downloadLink: this.downloadOrderConversionReport.bind(this),
        },
        {
          key: "report2",
          name: this.props.t("dealerReporting.report2Name"),
          downloadLink: this.downloadPurchaseRequestReport.bind(this),
        },
        {
          key: "report6",
          name: this.props.t("dealerReporting.report6Name"),
          downloadLink: this.downloadModelEReport.bind(this),
        },
      ],
    };
  }

  componentDidMount() {
    AnalyticsService.trackPageLoadEvent("fv:emp:reporting", this.props.user);
    this.dealerBrandSettingsCheck();
  }

  handleFromDateChange = (date) => {
    if (date !== null) {
      this.setState({
        fromDate: date,
      });
    }
  };

  handleToDateChange = (date) => {
    if (date !== null) {
      this.setState({
        toDate: date,
      });
    }
  };

  handleDateRangeChange = (e) => {
    this.setState({ dateRange: e.target.value });
  };

  toggleRadio = (e) => {
    this.setState({ dateRangeMethod: e.target.value });
  };

  trackUserAction = (reportName) => {
    AnalyticsService.trackOnDownloadEvent(
      `fv:emp:reporting`,
      `emp:reporting:download:report name:${reportName}`,
      `fv:emp:reporting:download`,
      this.props.user
    );
  };

  dealerBrandSettingsCheck() {
    BrandSettingsClient.getDealerBrandAdjustments(this.props.user)
      .then((data) => {
        const labourRate = data.laborRate;
        const markup = data.markup;
        if (
          NOTNULL_OR_NOTUNDEFINED(labourRate) &&
          NOTNULL_OR_NOTUNDEFINED(markup)
        ) {
          const accessoryPricingReport = {
            key: "report5",
            name: this.props.t("dealerReporting.report5Name"),
            downloadLink: this.redirectToAccessoryPricingSearch.bind(this),
          };
          this.setState({
            reports: [...this.state.reports, accessoryPricingReport],
          });
        }
      })
      .catch((error) => {
        logMsg("reason", error);
      });
  }

  getTodayDate() {
    const today = new Date();
    return `${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}`;
  }

  downloadPurchaseRequestReport = (report) => {
    this.setState({ showProgress: true });
    const reqData = {
      dateRange:
        this.state.dateRangeMethod !== "manual" ? this.state.dateRange : "",
      dateFrom:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.fromDate)
          : "",
      dateTo:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.toDate)
          : "",
    };
    DealerReportingClient.downloadPurchaseRequestReportInCsv(
      this.props.user,
      this.props.user.lang,
      "",
      true,
      true,
      reqData,
      UpperCaseText(this.props.user.userType) === "FIELD_VIEW"
    )
      .then((res) => {
        const filename =
          this.props.t(report.name) + " - " + this.getTodayDate() + ".xlsx";
        const BOM = "\uFEFF";
        const response = BOM + res;
        base64FileDownload(
          decodeURIComponent(response),
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          filename
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => this.setState({ showProgress: false }));
  };

  downloadModelEReport = (report) => {
    this.setState({ showProgress: true });
    const reqData = {
      dateRange:
        this.state.dateRangeMethod !== "manual" ? this.state.dateRange : "",
      dateFrom:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.fromDate)
          : "",
      dateTo:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.toDate)
          : "",
    };
    DealerReportingClient.downloadModelEReportInCsv(
      this.props.user,
      this.props.user.lang,
      "",
      true,
      true,
      reqData,
      UpperCaseText(this.props.user.userType) === "FIELD_VIEW"
    )
      .then((res) => {
        const filename =
          this.props.t(report.name) + " - " + this.getTodayDate() + ".xlsx";
        const BOM = "\uFEFF";
        const response = BOM + res;
        base64FileDownload(
          decodeURIComponent(response),
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          filename
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => this.setState({ showProgress: false }));
  };

  downloadDealerReservationReport = (report) => {
    this.setState({ showProgress: true });
    const reqData = {
      dateRange:
        this.state.dateRangeMethod !== "manual" ? this.state.dateRange : "",
      dateFrom:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.fromDate)
          : "",
      dateTo:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.toDate)
          : "",
    };
    DealerReportingClient.downloadDealerReservationReportInCsv(
      this.props.user,
      this.props.user.lang,
      "",
      true,
      reqData,
      UpperCaseText(this.props.user.userType) === "FIELD_VIEW"
    )
      .then((res) => {
        const filename =
          this.props.t(report.name) + " - " + this.getTodayDate() + ".xlsx";
        const BOM = "\uFEFF";
        const response = BOM + res;
        base64FileDownload(
          decodeURIComponent(response),
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          filename
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => this.setState({ showProgress: false }));
  };

  redirectToAccessoryPricingSearch() {
    this.props.history.push({
      pathname: `/pricing/accessories`,
      state: { redirectToAccSearch: true },
    });
  }

  downloadOrderConversionReport = (report) => {
    this.setState({ showProgress: true });
    const reqData = {
      dateRange:
        this.state.dateRangeMethod !== "manual" ? this.state.dateRange : "",
      dateFrom:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.fromDate)
          : "",
      dateTo:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.toDate)
          : "",
    };
    DealerReportingClient.downloadOrderConversionReportInCsv(
      this.props.user,
      this.props.user.lang,
      "",
      true,
      reqData,
      UpperCaseText(this.props.user.userType) === "FIELD_VIEW"
    )
      .then((res) => {
        const filename =
          this.props.t(report.name) + " - " + this.getTodayDate() + ".xlsx";
        const BOM = "\uFEFF";
        const response = BOM + res;
        base64FileDownload(
          decodeURIComponent(response),
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          filename
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => this.setState({ showProgress: false }));
  };

  sortColumns = (sortByCol) => {
    let sortDir = "DSC";
    if (sortByCol === this.state.sortKey) {
      sortDir = this.state.sortDirection === "ASC" ? "DSC" : "ASC";
    }
    const that = this;
    SortData(
      sortByCol,
      sortDir,
      "alphanumeric",
      this.state.reports,
      function (sortedData) {
        that.setState({
          sortDirection: sortDir,
          sortKey: sortByCol,
          reports: sortedData,
        });
      }
    );
  };

  getReport = (report, styles) => {
    return (
      <React.Fragment key={report.key}>
        <Grid container className={styles.gridBody}>
          <Grid item xs={6} sm={9}>
            <p className={styles.colType}>{report.name}</p>
          </Grid>
          <Grid item xs={6} sm={3}>
            <EMPButton
              onClick={() => {
                this.trackUserAction(report.name);
                report.downloadLink(report);
              }}
              buttonType="text"
              leftIcon={<CloudDownloadIcon />}
              buttonText={this.props.t("dealerReporting.download")}
            ></EMPButton>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  getReportWithPermission = (report, styles, props) => {
    const isFordBrand = IS_FORD_BRAND(props.user.brand);
    if (report.key === "report2") {
      return (
        <HasPermissionTo
          permissions={this.props.user.permissions.rolePermissions}
          perform={["purchaseRequestsReportNA"]}
          render={() => this.getReport(report, styles, props)}
        />
      );
    } else if (report.key === "report3") {
      return (
        <HasPermissionTo
          permissions={this.props.user.permissions.rolePermissions}
          perform={["reservationsReportNA"]}
          render={() => this.getReport(report, styles, props)}
          condition={isFordBrand}
        />
      );
    } else if (report.key === "report4") {
      return (
        <HasPermissionTo
          permissions={this.props.user.permissions.rolePermissions}
          perform={["ordersReportNA"]}
          render={() => this.getReport(report, styles, props)}
          condition={isFordBrand}
        />
      );
    } else if (report.key === "report5") {
      return (
        <HasPermissionTo
          permissions={this.props.user.permissions.rolePermissions}
          perform={["accessoriesReportNA"]}
          render={() => {
            return (
              <React.Fragment key={report.key}>
                <Grid container className={styles.gridBody}>
                  <Grid item xs={6} sm={9}>
                    <p className={styles.colType}>{report.name}</p>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <EMPButton
                      onClick={() => report.downloadLink(report)}
                      buttonType="text"
                      leftIcon={<VisibilityIcon />}
                      buttonText={this.props.t("dealerReporting.view")}
                    ></EMPButton>
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          }}
        />
      );
    } else if (report.key === "report6") {
      return (
        <HasPermissionTo
          permissions={this.props.user.permissions.rolePermissions}
          perform={["modelEReportNA"]}
          render={() => this.getReport(report, styles, props)}
          condition={isFordBrand}
        />
      );
    }
  };

  render() {
    const styles = IS_FORD_BRAND(this.props.user.brand)
      ? fordStyles
      : lincolnStyles;

    return (
      <div>
        <SubNav
          dashboard={false}
          title={this.props.t("dealerReporting.title")}
          {...this.props}
        />
        <div className={styles.pageContainer}>
          <div className={styles.subHeader}>
            <HasPermissionTo
              permissions={this.props.user.permissions.rolePermissions}
              perform={[
                "purchaseRequestsReportNA",
                "reservationsReportNA",
                "ordersReportNA",
                "modelEReportNA",
              ]}
              render={() => (
                <p>{this.props.t("dealerReporting.subHeaderWithFilter")}</p>
              )}
              noRender={() => (
                <p>{this.props.t("dealerReporting.subHeader")}</p>
              )}
            />
            <p>
              {this.state.showProgress && (
                <Grid container justifyContent="flex-start" alignItems="center">
                  <Grid item xs="auto" style={{ paddingRight: 10 }}>
                    <p>Downloading</p>
                  </Grid>
                  <Grid item xs>
                    <CircularProgress size={20} />
                  </Grid>
                </Grid>
              )}
            </p>
          </div>

          <HasPermissionTo
            permissions={this.props.user.permissions.rolePermissions}
            perform={[
              "purchaseRequestsReportNA",
              "reservationsReportNA",
              "ordersReportNA",
              "modelEReportNA",
            ]}
            render={() => (
              <ReportFilter
                dateRangeMethod={this.state.dateRangeMethod}
                dateRange={this.state.dateRange}
                dateRanges={this.state.dateRanges}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
                toggleRadio={this.toggleRadio}
                handleDateRangeChange={this.handleDateRangeChange}
                handleFromDateChange={this.handleFromDateChange}
                handleToDateChange={this.handleToDateChange}
                brand={this.props.userDetails.brand}
              />
            )}
          />
          <Grid container className={styles.gridHeader}>
            <Grid
              item
              xs={6}
              sm={9}
              className={styles.typeHeader}
              onClick={() => this.sortColumns("name")}
            >
              <Item value={this.props.t("dealerReporting.report")} />
              <span className={styles.sorting}>
                <SortingIcon
                  currentKey="name"
                  sortKey={this.state.sortKey}
                  sortDirection={this.state.sortDirection}
                />
              </span>
            </Grid>
            <Grid item xs={6} sm={3} className={styles.typeHeader}>
              <Item value={this.props.t("dealerReporting.action")} />
            </Grid>
          </Grid>

          {this.state.reports.map((report) =>
            this.getReportWithPermission(report, styles, this.props)
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(NADealerReporting);
