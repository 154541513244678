import React, { Component } from "react";
import styles from "./NAOrderDetailsNewVersion.module.scss";
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { StyledBasicExpansionPanel } from "../../../shared/ExpansionPanels/ExpansionPanel";
import { InfoDetail } from "./InfoDetail";
import { AccordionSummary, withStyles } from "@material-ui/core";
import DataCard from "../../../shared/components/DataCard/DataCard";

const StyledAccordionSummary = withStyles({
  root: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 24,
    paddingLeft: 24,
    paddingRight: 24,
    color: "#1C1C1E",
  },
  content: {
    marginTop: 16,
    marginBottom: 16,
  },
})(AccordionSummary);

class DealerInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandDealerInfo: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({ expandDealerInfo: this.props.expandAccordian });
    }
  }
  expandDealerInfo = () => {
    this.setState((prevState) => ({
      expandDealerInfo: !prevState.expandDealerInfo,
    }));
  };

  render() {
    const { dealerInfo } = this.props;

    return (
      <StyledBasicExpansionPanel
        defaultExpanded={true}
        onChange={() => this.expandDealerInfo()}
        expanded={this.state.expandDealerInfo}
      >
        <StyledAccordionSummary
          id="exp"
          expandIcon={<ExpandLessIcon />}
          IconButtonProps={{ disableRipple: true }}
        >
          {this.props.t("DealerInfo.dealerInformation")}
        </StyledAccordionSummary>

        {dealerInfo ? (
          <div className={styles.customerInfoGrid}>
            <Grid container spacing={2}>
              <Grid item className={styles.cardGridItemWrapper}>
                <DataCard
                  item
                  cardHeader={this.props.t("DealerInfo.dealerDetails")}
                >
                  <InfoDetail
                    header={this.props.t("DealerInfo.dealerName")}
                    value={dealerInfo.name}
                  />
                  <>
                    <div className={styles.itemHeaderText}>
                      {this.props.t("DealerInfo.dealerAddress")}
                    </div>

                    <div
                      className={`${styles.smallHeaderMargin} ${styles.customerInfoContainer}`}
                    >
                      <div className={styles.customerInfoTextBold}>
                        {dealerInfo.addressLine1}
                      </div>
                      <div className={styles.customerInfoTextBold}>
                        {dealerInfo.addressLine2}
                      </div>
                    </div>
                  </>
                  <InfoDetail
                    header={this.props.t("DealerInfo.dealerPhone")}
                    value={dealerInfo.phone}
                  />
                  <InfoDetail
                    header={this.props.t("DealerInfo.dealerPACode")}
                    value={dealerInfo.paCode}
                  />
                  <InfoDetail
                    header={this.props.t("DealerInfo.dealerSalesCode")}
                    value={dealerInfo.salesCode}
                  />
                </DataCard>
              </Grid>
            </Grid>
          </div>
        ) : (
          <span className={styles.labelError}>
            {" "}
            {this.props.t("DealerInfo.errorLoadingDealerInfo")}
          </span>
        )}
      </StyledBasicExpansionPanel>
    );
  }
}

export default withTranslation("emp")(DealerInformation);
