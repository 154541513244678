import React, { Component } from "react";
import {
  DEALER_ACTIONS,
  FINANCE_PRODUCT_TYPE,
  PURCHASE_DEPOSIT_STATUS,
  PURCHASE_REFUND_STATUS,
  PURCHASE_REQUEST_BUSINESS_STATUS,
  PURCHASE_TYPES,
  REFUND_TYPE,
} from "../../../../common/Constants";
import CustomerReservationsClient from "../../../../clients/CustomerReservationsClient";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import { withTranslation } from "react-i18next";
import UpperCaseText from "../../../../utils/UpperCaseText";
import SharedToolTip from "../../../../shared/sharedToolTip/SharedToolTip";
import { InfoDetail } from "../InfoDetail";
import OfflineRefundCalendarDialog from "../offlineRefundDialog/OfflineRefundCalendarDialog";
import OfflineRefundConfirmDialog from "../offlineRefundDialog/OfflineRefundConfirmDialog";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Tooltip } from "@material-ui/core";
import {
  displayError,
  ERROR_PROCESSING,
  is400,
} from "../../../../errors/ErrorConstants";
import RefundHoldDepositDialog from "../holdDeposit/dialogs/RefundHoldDepositDialog";
import fordStyles from "../NAOrderDetailsNewVersion.module.scss";
import lincolnStyles from "../NAOrderDetailsNewVersionLincoln.module.scss";
import { IS_FORD_BRAND } from "../../../../utils/EmpUtil";
import isOlderThan730Days from "../../../../utils/DateRange";
import DataCard from "../../../../shared/components/DataCard/DataCard";
import CancelOrderDialog from "../../CancelOrderDialog";
import * as AnalyticsService from "../../../../shared/services/analytics/AnalyticsService";

const StyledTooltip = withStyles({
  popper: {
    opacity: 1,
    pointerEvents: "auto",
  },
  tooltip: {
    background: "white",
    boxShadow: "1px 1px 9px 0 rgba(0, 0, 0, 0.27)",
    paddingTop: 28,
    paddingLeft: 28,
    paddingRight: 28,
    paddingBottom: 18,
    maxWidth: 350,
  },
})(Tooltip);

class PurchaseDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAcceptPurchaseDepositDialog: false,
      openRejectPurchaseDepositDialog: false,
      openRefundPurchaseDepositDialog: false,
      openOfflineRefundPurchaseDepositConfirmDialog: false,
      openOfflineRefundPurchaseDepositDialog: false,
      hasError: [],
    };
  }

  onConfirm = (action, dealerRejectedReason) => {
    const request = {
      reservationId: this.props.customerReservation.id,
      dealerAction: action,
    };

    if (dealerRejectedReason) {
      request.dealerRejectedReason = dealerRejectedReason;
    }

    this.props.hideOrShow(true);

    CustomerReservationsClient.updateOrder(
      this.props.user.token,
      this.props.customerReservation.dealerId,
      request,
      this.props.user.userId
    )
      .catch((error) => {
        if (is400(error)) {
          this.props.processFordPayError(error);
        } else {
          this.setState({ hasError: [ERROR_PROCESSING] });
        }
      })
      .finally(() => {
        this.toggleDialog("openAcceptPurchaseDepositDialog");
        this.props.refreshReservationDetailsWithNotifications();
        this.props.hideOrShow(false);

        if (this.props.isModelE) {
          const reason =
            action === DEALER_ACTIONS.PURCHASE_DEPOSIT_ACCEPTED
              ? "accept"
              : "reject";
          AnalyticsService.trackOnClickDetailEvent(
            `fv:emp:model e details:${reason} purchase deposit popup`,
            `emp:model e details:${reason} purchase deposit popup`,
            `emp:model e:order id:${this.props.customerReservation.id}`,
            this.props.customerReservation.id,
            `emp:model e details:${reason} purchase deposit popup:content:confirm`,
            `fv:emp:model e details:${reason} purchase deposit popup:content action`,
            this.props.user
          );
        }
      });
  };

  toggleDialog = (dialogType) => {
    this.setState((prevState) => ({
      [dialogType]: !prevState[dialogType],
    }));
  };

  openOfflineRefundConfirmDialog = (date) => {
    this.refundDate = date;
    this.setState({
      openOfflineRefundPurchaseDepositDialog: false,
      openOfflineRefundPurchaseDepositConfirmDialog: true,
    });
  };

  openOfflineRefundCalendarDialog = () => {
    this.setState({
      openOfflineRefundPurchaseDepositDialog: true,
      openOfflineRefundPurchaseDepositConfirmDialog: false,
    });
  };

  isPurchaseRequestAccepted = () => {
    const customerReservation = this.props.customerReservation;
    return (
      customerReservation.purchaseInfo &&
      customerReservation.purchaseInfo.purchaseRequestDealerAction &&
      UpperCaseText(
        customerReservation.purchaseInfo.purchaseRequestDealerAction
          .dealerAction
      ) === PURCHASE_REQUEST_BUSINESS_STATUS.ACCEPTED
    );
  };

  isPurchaseRequestCancelled = () => {
    const customerReservation = this.props.customerReservation;
    return (
      customerReservation.purchaseInfo &&
      customerReservation.purchaseInfo.purchaseRequestDealerAction &&
      UpperCaseText(
        customerReservation.purchaseInfo.purchaseRequestDealerAction
          .dealerAction
      ) === PURCHASE_REQUEST_BUSINESS_STATUS.CANCELLED
    );
  };

  showAcceptRejectPurchaseDepositLink = () => {
    const acceptRejectStatus =
      this.props.customerReservation.purchaseDeposit.acceptRejectStatus;
    const refundStatus =
      this.props.customerReservation.purchaseDeposit.refundStatus;
    return !(
      acceptRejectStatus === PURCHASE_DEPOSIT_STATUS.ACCEPTED ||
      acceptRejectStatus === PURCHASE_DEPOSIT_STATUS.REJECTED ||
      acceptRejectStatus === PURCHASE_DEPOSIT_STATUS.DECLINED ||
      Object.values(PURCHASE_REFUND_STATUS).includes(refundStatus)
    );
  };

  isAcceptRejectPurchaseDepositDisabled = () => {
    return (
      !this.isPurchaseRequestAccepted() ||
      this.props.customerReservation.depositDisabled24Hours
    );
  };

  isRefundEligible = () => {
    const purchaseDeposit = this.props.customerReservation.purchaseDeposit;
    const purchaseInfo = this.props.customerReservation.purchaseInfo;
    const acceptRejectStatus = purchaseDeposit.acceptRejectStatus;
    const refundStatus = purchaseDeposit.refundStatus;

    const isAcceptRejectStatusInvalid =
      acceptRejectStatus === PURCHASE_DEPOSIT_STATUS.DECLINED ||
      acceptRejectStatus === PURCHASE_DEPOSIT_STATUS.FAILED;

    const isRefundStatusInvalid =
      refundStatus === PURCHASE_REFUND_STATUS.ACCEPTED ||
      refundStatus === PURCHASE_REFUND_STATUS.DECLINED ||
      refundStatus === PURCHASE_REFUND_STATUS.REFUNDED_OFFLINE;

    if (isAcceptRejectStatusInvalid || isRefundStatusInvalid) {
      return false;
    }

    const isPurchaseRequestValid =
      purchaseInfo &&
      (this.isPurchaseRequestAccepted() || this.isPurchaseRequestCancelled()) &&
      acceptRejectStatus &&
      PURCHASE_DEPOSIT_STATUS.ACCEPTED === acceptRejectStatus &&
      PURCHASE_DEPOSIT_STATUS.REJECTED !== acceptRejectStatus;

    return (
      isPurchaseRequestValid || PURCHASE_REFUND_STATUS.FAILED === refundStatus
    );
  };

  getPaymentStatus = (acceptRejectStatus, refundStatus) => {
    if (Object.values(PURCHASE_REFUND_STATUS).includes(refundStatus)) {
      return this.props.t(
        "OrderDetailsConsumerDeposit.purchasedepositaccepted"
      );
    }

    if (
      UpperCaseText(acceptRejectStatus) ===
      UpperCaseText(PURCHASE_DEPOSIT_STATUS.ACCEPTED)
    ) {
      return this.props.t(
        "OrderDetailsConsumerDeposit.purchasedepositaccepted"
      );
    }

    if (
      UpperCaseText(acceptRejectStatus) ===
      UpperCaseText(PURCHASE_DEPOSIT_STATUS.REJECTED)
    ) {
      return this.props.t(
        "OrderDetailsConsumerDeposit.purchasedepositrejected"
      );
    }
  };

  getInvoice(value) {
    return value ? value.substr(value.length - 12, value.length) : null;
  }

  getAcceptPurchaseDepositDialogProps = () => {
    return {
      title: this.props.t(
        "OrderDetailsConsumerDeposit.acceptPurchaseDepositTitle"
      ),
      body: this.props.t(
        "OrderDetailsConsumerDeposit.acceptPurchaseDepositSubTitle"
      ),
      checkbox: this.props.t(
        "OrderDetailsConsumerDeposit.acceptPurchaseDepositConfirmText"
      ),
      checkboxList: [
        this.props.t(
          "OrderDetailsConsumerDeposit.acceptPurchaseDepositBullet1"
        ),
        this.props.t(
          "OrderDetailsConsumerDeposit.acceptPurchaseDepositBullet2"
        ),
      ],
      confirm: this.props.t(
        "OrderDetailsConsumerDeposit.acceptPurchaseDepositDialogConfirm"
      ),
      cancel: this.props.t(
        "OrderDetailsConsumerDeposit.acceptPurchaseDepositDialogCancel"
      ),
    };
  };
  getRejectPurchaseDepositDialogProps = () => {
    return {
      title: this.props.t(
        "OrderDetailsConsumerDeposit.rejectPurchaseDepositTitle"
      ),
      body: this.props.t(
        "OrderDetailsConsumerDeposit.rejectPurchaseDepositSubTitle"
      ),
      checkbox: this.props.t(
        "OrderDetailsConsumerDeposit.rejectPurchaseDepositConfirmText"
      ),
      checkboxList: [
        this.props.t(
          "OrderDetailsConsumerDeposit.rejectPurchaseDepositBullet1"
        ),
        this.props.t(
          "OrderDetailsConsumerDeposit.rejectPurchaseDepositBullet2"
        ),
        this.props.t(
          "OrderDetailsConsumerDeposit.rejectPurchaseDepositBullet3"
        ),
      ],
      confirm: this.props.t(
        "OrderDetailsConsumerDeposit.rejectPurchaseDepositDialogConfirm"
      ),
      cancel: this.props.t(
        "OrderDetailsConsumerDeposit.rejectPurchaseDepositDialogCancel"
      ),
    };
  };

  trackDepositModalOpen = (type) => {
    this.props.isModelE &&
      AnalyticsService.trackPageLoadDetailEvent(
        `fv:emp:model e details:${type} purchase deposit popup`,
        `emp:model e details:${type} purchase deposit popup`,
        this.props.customerReservation.id,
        this.props.user
      );
  };

  render() {
    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    const { customerReservation } = this.props;

    const { purchaseDeposit, showroomJourneyType } =
      this.props.customerReservation;
    const isPurchaseDepositAccepted =
      UpperCaseText(purchaseDeposit && purchaseDeposit.acceptRejectStatus) ===
      UpperCaseText(PURCHASE_DEPOSIT_STATUS.ACCEPTED);

    const disableDealerActionsFlag =
      this.props.disableDealerActionsFlagForPurchaseRequests;

    const isOlderThan2Years =
      customerReservation && isOlderThan730Days(customerReservation.date);

    const isModelEOlderThan2Years =
      customerReservation &&
      isOlderThan730Days(customerReservation.purchaseInfo.purchaseRequestDate);

    const isPurchaseRequestOlderThan2Years =
      customerReservation &&
      customerReservation.purchaseInfo &&
      customerReservation.purchaseInfo.purchaseRequestDate &&
      isOlderThan730Days(customerReservation.purchaseInfo.purchaseRequestDate);

    const isShowroomPurchaseRequest = showroomJourneyType !== false;

    let disableRefundCTA;
    if (isShowroomPurchaseRequest) {
      disableRefundCTA = isPurchaseRequestOlderThan2Years;
    } else if (this.props.isModelE) {
      disableRefundCTA = isModelEOlderThan2Years;
    } else {
      disableRefundCTA = disableDealerActionsFlag || isOlderThan2Years;
    }

    const hasJourneyType =
      this.props.customerReservation &&
      this.props.customerReservation.journeyType;

    const purchaseTypeIsCash =
      this.props.customerReservation &&
      this.props.customerReservation.purchaseInfo &&
      this.props.customerReservation.purchaseInfo.purchaseType ===
        PURCHASE_TYPES.CASH;

    const showDealerUserID = !(
      hasJourneyType &&
      this.props.customerReservation.journeyType === "Model-E" &&
      (purchaseTypeIsCash ||
        this.props.customerReservation.financeDataQuote.financeProductType ===
          FINANCE_PRODUCT_TYPE.THIRD_PARTY ||
        this.props.customerReservation.financeDataQuote.financeProductType ===
          FINANCE_PRODUCT_TYPE.DEALER_FINANCING_OPTIONS)
    );
    return (
      <DataCard
        {...this.props}
        cardHeader={this.props.t(
          "OrderDetailsConsumerDeposit.purchaseDepositTitle"
        )}
        hasToolTip={true}
        toolTipBody={this.props.t(
          "OrderDetailsConsumerDeposit.purchaseDepositTooltipBody1"
        )}
        openTestid="openTooltipId"
        headerDetail={
          <>
            <HasPermissionTo
              perform={["acceptDepositNA:write"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.showAcceptRejectPurchaseDepositLink()}
              render={() => {
                return !this.isAcceptRejectPurchaseDepositDisabled() ? (
                  <button
                    id="acceptPurchaseDepositBtn"
                    data-testid="acceptPurchaseDepositBtn"
                    className={styles.refundDepositNA}
                    onClick={() =>
                      this.toggleDialog("openAcceptPurchaseDepositDialog")
                    }
                    disabled={disableDealerActionsFlag}
                  >
                    {this.props.t("OrderDetailsConsumerDeposit.accept")}
                  </button>
                ) : (
                  <StyledTooltip
                    placement="bottom-start"
                    title={
                      !this.props.customerReservation.depositDisabled24Hours ? (
                        <div className={styles.bodyContent}>
                          {this.props.t(
                            "OrderDetailsConsumerDeposit.purchaseRequestNotYetAcceptedAcceptTooltipBody"
                          )}
                        </div>
                      ) : (
                        ""
                      )
                    }
                  >
                    <button
                      className={styles.disabledRefundDepositNA}
                      data-testid="must-accept-request-Purchase-deposit"
                      disabled={disableDealerActionsFlag}
                    >
                      {this.props.t("OrderDetailsConsumerDeposit.accept")}
                    </button>
                  </StyledTooltip>
                );
              }}
            />

            <HasPermissionTo
              perform={["rejectDepositNA:write"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.showAcceptRejectPurchaseDepositLink()}
              render={() => {
                return !this.isAcceptRejectPurchaseDepositDisabled() ? (
                  <button
                    id="rejectPurchaseDepositBtn"
                    data-testid="rejectPurchaseDepositBtn"
                    className={styles.refundDepositNA}
                    onClick={() =>
                      this.toggleDialog("openRejectPurchaseDepositDialog")
                    }
                    disabled={disableDealerActionsFlag}
                  >
                    {this.props.t("OrderDetailsConsumerDeposit.reject")}
                  </button>
                ) : (
                  <StyledTooltip
                    placement="bottom-start"
                    title={
                      !this.props.customerReservation.depositDisabled24Hours ? (
                        <div className={styles.bodyContent}>
                          {this.props.t(
                            "OrderDetailsConsumerDeposit.purchaseRequestNotYetAcceptedRejectTooltipBody"
                          )}
                        </div>
                      ) : (
                        ""
                      )
                    }
                  >
                    <button
                      className={styles.disabledRefundDepositNA}
                      data-testid="accept-reject-trade-in"
                      disabled={disableDealerActionsFlag}
                    >
                      {this.props.t("OrderDetailsConsumerDeposit.reject")}
                    </button>
                  </StyledTooltip>
                );
              }}
            />
            <Grid container>
              <HasPermissionTo
                perform={["refundPurchaseDepositNA:write"]}
                permissions={this.props.user.permissions.rolePermissions}
                condition={this.isRefundEligible()}
                render={() => {
                  return (
                    <div>
                      <button
                        id="refundPurchaseDepositBtn"
                        data-testid="refundPurchaseDepositBtn"
                        className={styles.refundDepositNA}
                        onClick={() =>
                          this.toggleDialog("openRefundPurchaseDepositDialog")
                        }
                        disabled={disableRefundCTA}
                      >
                        {this.props.t("OrderDetailsConsumerDeposit.refund")}
                      </button>
                    </div>
                  );
                }}
              />
              <HasPermissionTo
                perform={["refundPurchaseDepositNA:write"]}
                permissions={this.props.user.permissions.rolePermissions}
                condition={this.isRefundEligible()}
                render={() => {
                  return (
                    <div>
                      <button
                        id="offlineRefundPurchaseDepositBtn"
                        data-testid="offlineRefundPurchaseDepositBtn"
                        className={styles.refundDepositNA}
                        onClick={this.openOfflineRefundCalendarDialog}
                        disabled={disableRefundCTA}
                      >
                        {this.props.t(
                          "OrderDetailsConsumerDeposit.offlineRefund"
                        )}
                      </button>
                    </div>
                  );
                }}
              />
            </Grid>
          </>
        }
      >
        <InfoDetail
          isFordBrand={this.props.user && IS_FORD_BRAND(this.props.user.brand)}
          header={this.props.t("OrderDetailsConsumerDeposit.depositAmount")}
          value={purchaseDeposit.depositAmount}
        />
        <InfoDetail
          isFordBrand={this.props.user && IS_FORD_BRAND(this.props.user.brand)}
          header={this.props.t("OrderDetailsConsumerDeposit.last4DigCC")}
          value={purchaseDeposit.cardLastFourDigits}
        />

        <HasPermissionTo
          permissions={this.props.user.permissions.rolePermissions}
          perform={["transactionId:view"]}
          render={() => {
            return (
              <div className={styles.itemHeaderSpan}>
                <InfoDetail
                  isFordBrand={
                    this.props.user && IS_FORD_BRAND(this.props.user.brand)
                  }
                  header={this.props.t(
                    "OrderDetailsConsumerDeposit.purchaseDepositInvoiceNumber"
                  )}
                  value={this.getInvoice(
                    this.props.customerReservation.purchaseDeposit
                      .paymentTransactionId
                  )}
                  headerTooltip={
                    <SharedToolTip
                      {...this.props}
                      openTestid="openPurchaseInvoiceId"
                      closeTestid="closePurchaseInvoiceId"
                      title={this.props.t(
                        "OrderDetailsConsumerDeposit.invoiceTooltipHeader"
                      )}
                      body={this.props.t(
                        "OrderDetailsConsumerDeposit.invoiceTooltipBody"
                      )}
                    />
                  }
                />
              </div>
            );
          }}
        />
        {Object.values(PURCHASE_DEPOSIT_STATUS)
          .slice(1, 3)
          .includes(purchaseDeposit.acceptRejectStatus) ? (
          <InfoDetail
            isFordBrand={
              this.props.user && IS_FORD_BRAND(this.props.user.brand)
            }
            header={this.props.t("OrderDetailsConsumerDeposit.paymentStatus")}
            value={
              purchaseDeposit.acceptRejectStatus &&
              this.props.t(
                `OrderDetailsConsumerDeposit.${purchaseDeposit.acceptRejectStatus}`
              )
            }
            tooltip={
              <SharedToolTip
                {...this.props}
                openTestid="failedPaymentStatusTooltipId"
                closeTestid="closeTooltipId"
                title={
                  purchaseDeposit.acceptRejectStatus &&
                  this.props.t(
                    `OrderDetailsConsumerDeposit.${purchaseDeposit.acceptRejectStatus}`
                  )
                }
                body={
                  purchaseDeposit.acceptRejectStatus &&
                  this.props.t(
                    `OrderDetailsConsumerDeposit.tooltip${purchaseDeposit.acceptRejectStatus}`
                  )
                }
              />
            }
          />
        ) : (
          <InfoDetail
            isFordBrand={
              this.props.user && IS_FORD_BRAND(this.props.user.brand)
            }
            header={this.props.t("OrderDetailsConsumerDeposit.paymentStatus")}
            value={
              purchaseDeposit &&
              this.getPaymentStatus(
                purchaseDeposit.acceptRejectStatus,
                purchaseDeposit.refundStatus
              )
            }
          />
        )}

        {isPurchaseDepositAccepted && (
          <InfoDetail
            isFordBrand={
              this.props.user && IS_FORD_BRAND(this.props.user.brand)
            }
            header={this.props.t(
              "OrderDetailsConsumerDeposit.holdDepositAcceptedDate"
            )}
            value={purchaseDeposit.acceptRejectDate}
          />
        )}

        <InfoDetail
          isFordBrand={this.props.user && IS_FORD_BRAND(this.props.user.brand)}
          header={this.props.t("OrderDetails.dealerUserId")}
          value={
            customerReservation.purchaseDeposit &&
            customerReservation.purchaseDeposit.acceptRejectDealerUserId
          }
        />

        {Object.values(PURCHASE_REFUND_STATUS)
          .slice(1, 3)
          .includes(purchaseDeposit.refundStatus) ? (
          <InfoDetail
            isFordBrand={
              this.props.user && IS_FORD_BRAND(this.props.user.brand)
            }
            header={
              this.props.isModelE
                ? this.props.t(
                    "OrderDetailsConsumerDeposit.purchaseDepositRefundStatus"
                  )
                : this.props.t(
                    "OrderDetailsConsumerDeposit.holdDepositRefundStatus"
                  )
            }
            value={
              purchaseDeposit.refundStatus &&
              this.props.t(
                `OrderDetailsConsumerDeposit.${purchaseDeposit.refundStatus}`
              )
            }
            tooltip={
              <SharedToolTip
                {...this.props}
                openTestid="failedPaymentStatusTooltipId"
                closeTestid="closeTooltipId"
                title={
                  purchaseDeposit.refundStatus &&
                  this.props.t(
                    `OrderDetailsConsumerDeposit.${purchaseDeposit.refundStatus}`
                  )
                }
                body={
                  purchaseDeposit.refundStatus &&
                  this.props.t(
                    `OrderDetailsConsumerDeposit.tooltip${purchaseDeposit.refundStatus}`
                  )
                }
              />
            }
          />
        ) : (
          <InfoDetail
            isFordBrand={
              this.props.user && IS_FORD_BRAND(this.props.user.brand)
            }
            header={
              this.props.isModelE
                ? this.props.t(
                    "OrderDetailsConsumerDeposit.purchaseDepositRefundStatus"
                  )
                : this.props.t(
                    "OrderDetailsConsumerDeposit.holdDepositRefundStatus"
                  )
            }
            value={
              purchaseDeposit.refundStatus ===
              PURCHASE_REFUND_STATUS.REFUNDED_OFFLINE
                ? this.props.t(
                    "OrderDetailsConsumerDeposit.refundedOfflineDialogRefundedOffline"
                  )
                : purchaseDeposit.refundStatus ===
                    PURCHASE_REFUND_STATUS.ACCEPTED &&
                  this.props.t(
                    `OrderDetailsConsumerDeposit.${purchaseDeposit.refundStatus}`
                  )
            }
          />
        )}

        <InfoDetail
          isFordBrand={this.props.user && IS_FORD_BRAND(this.props.user.brand)}
          header={
            this.props.isModelE
              ? this.props.t(
                  "OrderDetailsConsumerDeposit.purchaseDepositRefundDate"
                )
              : this.props.t(
                  "OrderDetailsConsumerDeposit.holdDepositRefundDate"
                )
          }
          value={this.props.customerReservation.purchaseDeposit.refundDate}
        />
        <InfoDetail
          isFordBrand={this.props.user && IS_FORD_BRAND(this.props.user.brand)}
          header={
            this.props.isModelE
              ? this.props.t(
                  "OrderDetailsConsumerDeposit.purchaseDepositRefundAmount"
                )
              : this.props.t(
                  "OrderDetailsConsumerDeposit.holdDepositRefundAmount"
                )
          }
          value={this.props.customerReservation.purchaseDeposit.refundAmount}
        />
        <div className={styles.itemHeaderSpan}>
          <InfoDetail
            isFordBrand={
              this.props.user && IS_FORD_BRAND(this.props.user.brand)
            }
            header={
              this.props.isModelE
                ? this.props.t(
                    "OrderDetailsConsumerDeposit.purchaseDepositRefundInvoiceNumber"
                  )
                : this.props.t(
                    "OrderDetailsConsumerDeposit.holdDepositRefundInvoiceNumber"
                  )
            }
            value={this.getInvoice(
              this.props.customerReservation.purchaseDeposit.refundTransactionId
            )}
            headerTooltip={
              <SharedToolTip
                {...this.props}
                openTestid="openPurchaseDepositRefundInvoiceId"
                closeTestid="closePurchaseDepositRefundInvoiceId"
                title={this.props.t(
                  "OrderDetailsConsumerDeposit.invoiceTooltipHeader"
                )}
                body={this.props.t(
                  "OrderDetailsConsumerDeposit.invoiceTooltipBody"
                )}
              />
            }
          />
        </div>

        {showDealerUserID && (
          <InfoDetail
            isFordBrand={
              this.props.user && IS_FORD_BRAND(this.props.user.brand)
            }
            header={this.props.t("OrderDetails.dealerUserId")}
            value={
              customerReservation.purchaseDeposit &&
              customerReservation.purchaseDeposit.refundDealerUserId
            }
          />
        )}

        {this.state.openAcceptPurchaseDepositDialog && (
          <CancelOrderDialog
            {...this.props}
            dialogProps={this.getAcceptPurchaseDepositDialogProps()}
            open={this.state.openAcceptPurchaseDepositDialog}
            onClose={() => this.toggleDialog("openAcceptPurchaseDepositDialog")}
            handleConfirm={() =>
              this.onConfirm(DEALER_ACTIONS.PURCHASE_DEPOSIT_ACCEPTED)
            }
            onEnter={this.trackDepositModalOpen("accept")}
          />
        )}

        {this.state.openRejectPurchaseDepositDialog && (
          <CancelOrderDialog
            {...this.props}
            dialogProps={this.getRejectPurchaseDepositDialogProps()}
            open={this.state.openRejectPurchaseDepositDialog}
            onClose={() => this.toggleDialog("openRejectPurchaseDepositDialog")}
            onEnter={this.trackDepositModalOpen("reject")}
            handleConfirm={() =>
              this.onConfirm(DEALER_ACTIONS.PURCHASE_DEPOSIT_REJECTED)
            }
            isRefund
          />
        )}

        {this.state.openRefundPurchaseDepositDialog && (
          <RefundHoldDepositDialog
            {...this.props}
            open={this.state.openRefundPurchaseDepositDialog}
            onClose={() => this.toggleDialog("openRefundPurchaseDepositDialog")}
            handleConfirm={() =>
              this.onConfirm(DEALER_ACTIONS.PURCHASE_DEPOSIT_REFUND)
            }
          />
        )}

        {this.state.openOfflineRefundPurchaseDepositDialog && (
          <OfflineRefundCalendarDialog
            {...this.props}
            refundType={REFUND_TYPE.HOLD_DEPOSIT}
            open={this.state.openOfflineRefundPurchaseDepositDialog}
            todayDate={this.props.customerReservation.todayDate}
            onToggle={this.openOfflineRefundConfirmDialog}
            onClose={() =>
              this.toggleDialog("openOfflineRefundPurchaseDepositDialog")
            }
            onGoBack={() =>
              this.toggleDialog("openOfflineRefundPurchaseDepositDialog")
            }
          />
        )}

        {this.state.openOfflineRefundPurchaseDepositConfirmDialog && (
          <OfflineRefundConfirmDialog
            {...this.props}
            open={this.state.openOfflineRefundPurchaseDepositConfirmDialog}
            refundDate={this.refundDate}
            refundType={REFUND_TYPE.HOLD_DEPOSIT}
            onToggle={this.openOfflineRefundCalendarDialog}
            onClose={() =>
              this.toggleDialog("openOfflineRefundPurchaseDepositConfirmDialog")
            }
          />
        )}
      </DataCard>
    );
  }
}

export default withTranslation("emp")(PurchaseDeposit);
