import React from "react";
import "../../../../styles/sharedStyles.scss";
import Grid from "@material-ui/core/Grid";
import styles from "./RejectDialog.module.scss";
import { withTranslation } from "react-i18next";
import { DECISION, REJECT, REJECT_CONFIRM } from "../../../../common/Constants";
import FormControl from "@material-ui/core/FormControl";
import DatePicker from "react-datepicker";
import * as DateConstants from "../../../../shared/constants/DateConstants";
import RejectConfirmDialog from "../rejectConfirm/RejectConfirmDialog";
import EMPSelectField from "../../../../shared/components/EMPSelectField/EMPSelectField";
import EMPDialog from "../../../../shared/components/EMPDialog/EMPDialog";

class RejectDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        reasonCode: "pleaseSelect",
        reasonText: "",
        dateVehicleSeen: null,
      },
      enableSave: false,
    };

    this.initialState = this.state.data;

    this.cancellationReasons = [
      {
        code: "1",
        displayText: this.props.t(
          "TradeIn.US.dialogs.rejectDialog.rejectReason1"
        ),
      },
      {
        code: "2",
        displayText: this.props.t(
          "TradeIn.US.dialogs.rejectDialog.rejectReason2"
        ),
      },
      {
        code: "3",
        displayText: this.props.t(
          "TradeIn.US.dialogs.rejectDialog.rejectReason3"
        ),
      },
      {
        code: "4",
        displayText: this.props.t(
          "TradeIn.US.dialogs.rejectDialog.rejectReason4"
        ),
      },
    ];
  }

  handleConfirm = (e) => {
    this.props.onConfirm(e);
  };

  handleClose = () => {
    this.props.onClose(REJECT);
  };

  handleSave = () => {
    this.props.onDialogChange(REJECT_CONFIRM, REJECT);
  };

  updateDropdown = (event) => {
    const reasonCode = event.target.value;
    const selectedReason = this.cancellationReasons.find(
      (reason) => reason.code === reasonCode
    );
    const reasonText = selectedReason ? selectedReason.displayText : "";

    this.setState(
      (prevState) => {
        const data = { ...prevState.data };
        data["reasonCode"] = reasonCode;
        data["reasonText"] = reasonText;
        return { data };
      },
      () => {
        this.enableSaveBtn();
      }
    );
  };

  enableSaveBtn = () => {
    this.setState((prevState) => ({
      enableSave:
        prevState.data.reasonCode !== "pleaseSelect" &&
        prevState.data.dateVehicleSeen,
    }));
  };

  selectDateVehicleSeen = (date) => {
    if (date !== null) {
      this.setState(
        (prevState) => {
          const data = { ...prevState.data };
          data["dateVehicleSeen"] = date;
          return { data };
        },
        () => {
          this.enableSaveBtn();
        }
      );
    }
  };

  goBack = () => {
    this.props.onDialogChange(DECISION, REJECT);
  };

  render() {
    const menuItems = [
      {
        text: this.props.t("GeneralEMP.Select"),
        value: "pleaseSelect",
        disabled: true,
      },
    ];
    this.cancellationReasons &&
      this.cancellationReasons.forEach((item) => {
        menuItems.push({
          value: item.code,
          text: item.displayText,
        });
      });
    return (
      <>
        {this.props.open && (
          <EMPDialog
            open={this.props.open}
            handleClose={this.handleClose}
            dialogTitle={this.props.t("TradeIn.US.dialogs.rejectDialog.title")}
            hasCloseIcon
            dialogActionsDataTestId="rejectDialogActions"
            hasActionButton
            actionButtonText={this.props.t(
              "TradeIn.US.dialogs.rejectDialog.save"
            )}
            onConfirm={this.handleSave}
            disableActionButton={!this.state.enableSave}
            hasSecondaryActionButton
            secondaryActionButtonText={this.props.t(
              "TradeIn.US.dialogs.rejectDialog.goBack"
            )}
            onSecondaryAction={this.goBack}
          >
            <Grid
              container
              data-testid="rejectTradeInDialog"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <div className={styles.body}>
                  <div>
                    {this.props.t("TradeIn.US.dialogs.rejectDialog.body.title")}
                  </div>
                  <ul>
                    <li>
                      {this.props.t(
                        "TradeIn.US.dialogs.rejectDialog.body.bullet1"
                      )}
                    </li>
                    <li>
                      {this.props.t(
                        "TradeIn.US.dialogs.rejectDialog.body.bullet2"
                      )}
                    </li>
                    <li>
                      {this.props.t(
                        "TradeIn.US.dialogs.rejectDialog.body.bullet3"
                      )}
                    </li>
                    <li>
                      {this.props.t(
                        "TradeIn.US.dialogs.rejectDialog.body.bullet4"
                      )}
                    </li>
                  </ul>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div>
                  <div className={styles.rejectTitle}>
                    {this.props.t(
                      "TradeIn.US.dialogs.rejectDialog.dateVehicleSeen"
                    )}
                  </div>
                  <DatePicker
                    selected={this.state.data.dateVehicleSeen}
                    onChange={this.selectDateVehicleSeen}
                    maxDate={new Date()}
                    className={styles.datepicker}
                    id="rejectTradeInDateVehicleSeen"
                    dateFormat={DateConstants.MMddyyyy}
                    placeholderText={DateConstants.MM_DD_YYYY}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div>
                  <div className={styles.rejectTitle}>
                    {this.props.t(
                      "TradeIn.US.dialogs.rejectDialog.rejectReason"
                    )}
                  </div>
                  <FormControl fullWidth={true}>
                    <EMPSelectField
                      testId="select-rejectTradeInDropDown"
                      value={this.state.data.reasonCode}
                      onChange={(event) => this.updateDropdown(event)}
                      menuItems={menuItems}
                    />
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </EMPDialog>
        )}
        <RejectConfirmDialog
          {...this.props}
          open={this.props.dialogBoxes[REJECT_CONFIRM]}
          data={this.state.data}
          onClose={this.props.onClose}
          onDialogChange={this.props.onDialogChange}
        />
      </>
    );
  }
}

export default withTranslation("emp")(RejectDialog);
