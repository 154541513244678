import React from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useLoaderStyles = makeStyles({
  root: {
    fontFamily: "'FordAntennaRegular', sans-serif",
  },
  subtitle1: {
    fontSize: "16px",
    color: "#000000",
  },
});

const EMPLoader = ({ loadingMessage }) => {
  const classes = useLoaderStyles();

  return (
    <Grid container spacing={2}>
      <Grid item>
        <CircularProgress size={24} />
      </Grid>
      <Grid item>
        <Typography
          variant="subtitle1"
          classes={{
            root: classes.root,
            subtitle1: classes.subtitle1,
          }}
          align="center"
        >
          {loadingMessage}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EMPLoader;
