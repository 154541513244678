import React, { Component } from "react";
import styles from "./DealerShipDetails.module.scss";
import { Button, Grid, Box, withStyles } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import MultiToggleWidget from "../../../shared/toggles/multiToggleWidget/MultiToggleWidget";
import EditIcon from "../../../shared/Edit/EditIcon";
import ClearIcon from "@material-ui/icons/Clear";
import { withTranslation } from "react-i18next";
import HasPermissionTo from "../../../common/HasPermissionTo";
import DealerDetailsClient from "../../../clients/DealerDetailsClient";
import UpperCaseText from "../../../utils/UpperCaseText";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";

const EMPTextValidator = withStyles({
  root: {
    "& .MuiFormHelperText-root": {
      fontFamily: "'FordAntennaRegular', sans-serif",
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "20px",
      color: "#BF152C",
      marginBottom: 0,
    },
    "& .MuiInputBase-root": {
      borderRadius: 8,
      outline: "1px solid #6B7786",
      padding: "8px 16px",
      backgroundColor: "#FFFFFF",
      fontFamily: "'FordAntennaRegular', sans-serif",
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "20px",
      color: "#00142E",
    },
    "& .Mui-error.MuiInputBase-root": {
      borderRadius: 8,
      outline: "1px solid #BF152C",
    },
    "& .MuiInputBase-input": {
      padding: 0,
    },
    "& .Mui-focused.MuiInputBase-root": {
      outline: "2px solid #066FEF",
    },
    "& .Mui-error.Mui-focused.MuiInputBase-root": {
      outline: "2px solid #BF152C",
    },
  },
})(TextValidator);

class DealerEmployeeTable extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      employees: [],
      addNewRowActive: false,
      newEmployee: this.refreshNewEmployee(),
      employeesToEdit: new Map(),
      employeeLimitReached: false,
    };

    this.currentDealer = this.props.dealers;
  }

  componentDidMount() {
    this.getDealerEmployees();
  }

  onSelectEmailNotification = (employee, event) => {
    const selectedStatus = event.target.checked;

    DealerDetailsClient.updateDealerEmployeeEmailNotifications(
      this.props.user,
      employee,
      selectedStatus
    ).then(() => {
      const copy = [...this.state.employees];
      const index = this.state.employees.indexOf(
        this.state.employees.filter((emp) => emp.id === employee.id)[0]
      );
      const employeeToChange = copy[index];
      employeeToChange.receiveEmailsForNotifications = selectedStatus;
      copy.splice(index, 1, employeeToChange);
      this.setState({ employees: copy });
      this.resetRow(employee.id);
    });
  };

  onSelectPendingActionNotification = (employee, event) => {
    const selectedStatus = event.target.checked;

    DealerDetailsClient.updateDealerEmployeePendingActionsEmails(
      this.props.user,
      employee,
      selectedStatus
    ).then(() => {
      const copy = [...this.state.employees];
      const index = this.state.employees.indexOf(
        this.state.employees.filter((emp) => emp.id === employee.id)[0]
      );
      const employeeToChange = copy[index];
      employeeToChange.receiveEmailsForPendingActions = selectedStatus;
      copy.splice(index, 1, employeeToChange);
      this.setState({ employees: copy });
      this.resetRow(employee.id);
    });
  };

  refreshNewEmployee() {
    return {
      id: "",
      dealerId: this.props.dealers.completeDealerId,
      fullName: "",
      emailAddress: "",
      receiveEmailsForNotifications: true,
      receiveEmailsForPendingActions: true,
    };
  }

  getDealerEmployees = () => {
    DealerDetailsClient.getDealerEmployees(
      this.props.user,
      UpperCaseText(this.props.user.userType) === "FIELD_VIEW" &&
        UpperCaseText(this.props.user.market) === "CAN"
        ? `CAN${this.currentDealer.paCode}`
        : this.currentDealer.completeDealerId
    )
      .then((employees) => {
        this.setState({
          employees: employees,
          employeeLimitReached: employees.length > 9,
        });

        this.props.disableLiveUpdateEmployees();
      })
      .catch(() => {});
  };

  refreshEmployees = () => {
    this.props.enableLiveUpdateEmployees();
  };

  editExistingEmployee = (event) => {
    const editEmployee = this.state.employees.filter(
      (employee) => employee.id === parseInt(event.currentTarget.value)
    )[0];
    const copy = new Map(this.state.employeesToEdit);
    copy.set(editEmployee.id, editEmployee);
    this.setState({ employeesToEdit: copy });
  };

  deleteExistingEmployee = (event) => {
    const employeeId = parseInt(event.currentTarget.value);
    DealerDetailsClient.deleteDealerEmployee(this.props.user, employeeId).then(
      () => {
        this.resetRow(employeeId);
        const copy = [...this.state.employees];
        const index = this.state.employees.indexOf(
          this.state.employees.filter((emp) => emp.id === employeeId)[0]
        );
        copy.splice(index, 1);
        this.setState({
          employees: copy,
          employeeLimitReached: copy.length > 9,
        });
      }
    );
  };

  editEmployeeNameOnChange = (employeeId, event) => {
    const modifiedName = event.target.value;
    this.setState((prevState) => {
      const copy = new Map(prevState.employeesToEdit);
      const newEmployee = {
        ...copy.get(employeeId),
        fullName: modifiedName,
      };
      return { employeesToEdit: copy.set(employeeId, newEmployee) };
    });
  };

  editEmployeeEmailOnChange = (employeeId, event) => {
    const modifiedEmail = event.target.value;
    this.setState((prevState) => {
      const copy = new Map(prevState.employeesToEdit);
      const newEmployee = {
        ...copy.get(employeeId),
        emailAddress: modifiedEmail,
      };
      return { employeesToEdit: copy.set(employeeId, newEmployee) };
    });
  };

  updateEmployee = (employee) => {
    DealerDetailsClient.updateDealerEmployee(this.props.user, employee).then(
      (updateEmployee) => {
        const copy = [...this.state.employees];
        const index = this.state.employees.indexOf(
          this.state.employees.filter((emp) => emp.id === updateEmployee.id)[0]
        );
        copy.splice(index, 1, updateEmployee);
        this.setState({ employees: copy });
        this.resetRow(updateEmployee.id);
        this.refreshEmployees();
      }
    );
  };

  cancelEdit = (event) => {
    this.resetRow(event.currentTarget.value);
  };

  resetRow = (employeeId) => {
    const empId = parseInt(employeeId);
    if (this.state.employeesToEdit.has(empId)) {
      const idToRemove = this.state.employeesToEdit.get(empId).id;
      const copy = new Map(this.state.employeesToEdit);
      copy.delete(idToRemove);
      this.setState({ employeesToEdit: copy });
    }
  };

  addNewEmployee = () => {
    DealerDetailsClient.addEmployee(
      this.props.user,
      this.state.newEmployee,
      this.currentDealer.completeDealerId
    ).then((newEmployee) => {
      this.setState({
        employees: this.state.employees.concat(newEmployee),
        addNewRowActive: false,
        newEmployee: {
          id: "",
          dealerId: this.currentDealer.completeDealerId,
          fullName: "",
          emailAddress: "",
          receiveEmailsForNotifications: true,
          receiveEmailsForPendingActions: true,
        },
      });
      this.refreshEmployees();
    });
  };

  cancelNewEmployee = () => {
    this.setState({
      addNewRowActive: false,
      newEmployee: this.refreshNewEmployee(),
    });
  };

  showNewEmployeeRow = () => {
    this.setState({ addNewRowActive: true });
  };

  newEmployeeEmailOnChange = (event) => {
    this.setState({
      newEmployee: {
        ...this.state.newEmployee,
        emailAddress: event.target.value,
      },
    });
  };

  newEmployeeNameOnChange = (event) => {
    this.setState({
      newEmployee: {
        ...this.state.newEmployee,
        fullName: event.target.value,
      },
    });
  };

  render() {
    return (
      <>
        {this.props.renderAgain && this.getDealerEmployees()}
        <div className={styles.employeeEmailHeader}>
          <div className={styles.sectionHeaderEmployees}>
            {this.props.t("DealerShipDetails.employeeHeader")}
          </div>
          {this.state.employees.length > 0 && (
            <>
              <Grid container className={styles.subHeader}>
                <Grid item md={3} className={styles.employeeInfoHeader}>
                  {this.props.t("DealerShipDetails.employees")}
                </Grid>
                <Grid item md={3} className={styles.employeeInfoHeader}>
                  {this.props.t("DealerShipDetails.employeesEmail")}
                </Grid>
                <Grid item md={2} className={styles.notifications}>
                  <div>
                    {this.props.t("DealerShipDetails.employeesNotifications")}
                  </div>
                </Grid>

                <Grid item md={2} className={styles.notifications}>
                  <HasPermissionTo
                    perform={["receivePendingActionColumn"]}
                    permissions={this.props.user.permissions.rolePermissions}
                    render={() => (
                      <div>
                        {this.props.t(
                          "DealerShipDetails.employeesPendingActions"
                        )}
                      </div>
                    )}
                  />
                </Grid>
                <Grid item md={2} className={styles.employeeInfoHeader}>
                  &nbsp;
                </Grid>
              </Grid>

              {this.state.employees.map((employee, index) => (
                <div key={`employee_${index}`}>
                  {this.state.employeesToEdit.has(employee.id) ? (
                    <ValidatorForm
                      onSubmit={() =>
                        this.updateEmployee(
                          this.state.employeesToEdit.get(employee.id)
                        )
                      }
                    >
                      <Grid
                        container
                        className={`${styles.tableBody} ${styles.employeeInfo}`}
                      >
                        <Grid item md={3}>
                          <Box pr={2}>
                            <EMPTextValidator
                              name={`fullName_${employee.id}`}
                              value={
                                this.state.employeesToEdit.get(employee.id)
                                  .fullName
                              }
                              validators={["required"]}
                              onChange={(e) =>
                                this.editEmployeeNameOnChange(employee.id, e)
                              }
                              errorMessages={[
                                this.props.t("Error.requiredField"),
                              ]}
                              InputProps={{
                                disableUnderline: true,
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item md={3}>
                          <Box pr={2}>
                            <EMPTextValidator
                              name={`email_${employee.id}`}
                              value={
                                this.state.employeesToEdit.get(employee.id)
                                  .emailAddress
                              }
                              validators={["required", "isEmail"]}
                              onChange={(e) =>
                                this.editEmployeeEmailOnChange(employee.id, e)
                              }
                              errorMessages={[
                                this.props.t("Error.requiredField"),
                                this.props.t("Error.invalidInput"),
                              ]}
                              InputProps={{
                                disableUnderline: true,
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item className={styles.employeeSwitches} md={2}>
                          {employee.receiveEmailsForNotifications
                            ? this.props.t("DealerShipDetails.yes")
                            : this.props.t("DealerShipDetails.no")}
                        </Grid>
                        <HasPermissionTo
                          perform={["receivePendingActionColumn"]}
                          permissions={
                            this.props.user.permissions.rolePermissions
                          }
                          render={() => (
                            <Grid
                              item
                              className={styles.employeeSwitches}
                              md={2}
                            >
                              {employee.receiveEmailsForPendingActions
                                ? this.props.t("DealerShipDetails.yes")
                                : this.props.t("DealerShipDetails.no")}
                            </Grid>
                          )}
                          noRender={() => <Grid item md={2} />}
                        />
                        <Grid item md={2} className={styles.editDiv}>
                          <EMPButton
                            testId={`updateEmployee_${employee.id}`}
                            submitButton
                            disabled={
                              UpperCaseText(this.props.user.userType) ===
                              "FIELD_VIEW"
                            }
                            buttonText={this.props.t("BusinessSettings.save")}
                          ></EMPButton>
                          <EMPButton
                            testId={`cancelEdit_${employee.id}`}
                            onClick={this.cancelEdit}
                            buttonText={this.props.t(
                              "BusinessSettings.cancelButton"
                            )}
                          ></EMPButton>
                        </Grid>
                      </Grid>
                    </ValidatorForm>
                  ) : (
                    <Grid container className={styles.employeeInfo} key={index}>
                      <Grid
                        item
                        md={3}
                        id={`name_${index}`}
                        className={styles.employeeName}
                      >
                        {employee.fullName}
                      </Grid>
                      <Grid
                        item
                        md={3}
                        id={`email_${index}`}
                        className={styles.employeeEmail}
                      >
                        {employee.emailAddress}
                      </Grid>

                      <>
                        {!this.props.isMainViewingSDLR(this.props.dealers) &&
                        !this.props.user.isDealerUsedCarsSalesManager ? (
                          <>
                            <Grid item md={2} className={styles.switches}>
                              <MultiToggleWidget
                                settings={{
                                  acceptedText: this.props.t(
                                    "DealerShipDetails.yes"
                                  ),
                                  rejectedText: this.props.t(
                                    "DealerShipDetails.no"
                                  ),
                                  suppClass: `${styles.toggleWrapper}`,
                                  toggleLabel: `${styles.activationToggle}`,
                                }}
                                fromDealerTable={true}
                                isMainViewingSDLR=""
                                toggleName={`emailNot_${employee.id}`}
                                currentStatus={
                                  employee.receiveEmailsForNotifications
                                    ? "yes"
                                    : "no"
                                }
                                onSelect={(e) =>
                                  this.onSelectEmailNotification(employee, e)
                                }
                              />
                            </Grid>
                            <HasPermissionTo
                              perform={["receivePendingActionColumn"]}
                              permissions={
                                this.props.user.permissions.rolePermissions
                              }
                              render={() => (
                                <Grid item md={2} className={styles.switches}>
                                  <MultiToggleWidget
                                    settings={{
                                      acceptedText: this.props.t(
                                        "DealerShipDetails.yes"
                                      ),
                                      rejectedText: this.props.t(
                                        "DealerShipDetails.no"
                                      ),
                                      suppClass: `${styles.toggleWrapper}`,
                                      toggleLabel: `${styles.activationToggle}`,
                                    }}
                                    fromDealerTable={true}
                                    toggleName={`pendingAcNot_${employee.id}`}
                                    currentStatus={
                                      employee.receiveEmailsForPendingActions
                                        ? "yes"
                                        : "no"
                                    }
                                    onSelect={(e) =>
                                      this.onSelectPendingActionNotification(
                                        employee,
                                        e
                                      )
                                    }
                                  />
                                </Grid>
                              )}
                              noRender={() => <Grid item md={2}></Grid>}
                            />
                          </>
                        ) : (
                          <>
                            <Grid
                              item
                              md={2}
                              id={`toggle1_${index}`}
                              className={`${styles.employeeSwitches} switch-3 switch-candy`}
                            >
                              {employee.receiveEmailsForNotifications
                                ? this.props.t("DealerShipDetails.yes")
                                : this.props.t("DealerShipDetails.no")}
                            </Grid>
                            <Grid
                              item
                              md={2}
                              id={`toggle2_${index}`}
                              className={`${styles.employeeSwitches} switch-3 switch-candy`}
                            >
                              <HasPermissionTo
                                perform={["receivePendingActionColumn"]}
                                permissions={
                                  this.props.user.permissions.rolePermissions
                                }
                                render={() => (
                                  <div>
                                    {employee.receiveEmailsForPendingActions
                                      ? this.props.t("DealerShipDetails.yes")
                                      : this.props.t("DealerShipDetails.no")}
                                  </div>
                                )}
                              />
                            </Grid>
                          </>
                        )}

                        <HasPermissionTo
                          perform={["dealershipDetails:write"]}
                          permissions={
                            this.props.user.permissions.rolePermissions
                          }
                          condition={
                            !this.props.isMainViewingSDLR(this.props.dealers)
                          }
                          render={() => (
                            <Grid item md={2} className={styles.editDiv}>
                              <Button
                                id={`editEmployee_${employee.id}`}
                                data-testid={`editEmployee_${employee.id}`}
                                value={employee.id}
                                onClick={this.editExistingEmployee}
                              >
                                <EditIcon />
                              </Button>
                              <Button
                                id={`deleteEmployee_${employee.id}`}
                                data-testid={`deleteEmployee_${employee.id}`}
                                value={employee.id}
                                onClick={this.deleteExistingEmployee}
                              >
                                <ClearIcon />
                              </Button>
                            </Grid>
                          )}
                        />
                      </>
                    </Grid>
                  )}
                </div>
              ))}
            </>
          )}
          {this.state.addNewRowActive && (
            <ValidatorForm onSubmit={this.addNewEmployee}>
              <Grid className={styles.newEmployeeContainer} container>
                <Grid item md={3}>
                  <EMPTextValidator
                    style={{ width: "70%" }}
                    name={`inputName_${this.currentDealer.regionalId}`}
                    value={this.state.newEmployee.fullName}
                    placeholder={this.props.t("DealerShipDetails.fullName")}
                    onChange={this.newEmployeeNameOnChange}
                    validators={["required"]}
                    errorMessages={[this.props.t("Error.requiredField")]}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <EMPTextValidator
                    style={{ width: "70%" }}
                    name={`inputEmail_${this.currentDealer.regionalId}`}
                    value={this.state.newEmployee.emailAddress}
                    placeholder={this.props.t("DealerShipDetails.emailAddress")}
                    onChange={this.newEmployeeEmailOnChange}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      this.props.t("Error.requiredField"),
                      this.props.t("Error.invalidInput"),
                    ]}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
                <Grid item md={4} />
                <Grid className={styles.actionDiv} item md={2}>
                  <EMPButton
                    testId={`submit_${this.currentDealer.regionalId}`}
                    submitButton
                    disabled={
                      UpperCaseText(this.props.user.userType) === "FIELD_VIEW"
                    }
                    buttonText={this.props.t("BusinessSettings.save")}
                  ></EMPButton>
                  <EMPButton
                    testId={`cancelAdd_${this.currentDealer.regionalId}`}
                    onClick={this.cancelNewEmployee}
                    buttonText={this.props.t("BusinessSettings.cancelButton")}
                  ></EMPButton>
                </Grid>
              </Grid>
            </ValidatorForm>
          )}
        </div>
        <HasPermissionTo
          perform={["dealershipDetails:write"]}
          permissions={this.props.user.permissions.rolePermissions}
          condition={!this.props.isMainViewingSDLR(this.props.dealers)}
          render={() => (
            <div className="pagePaddingLeft pagePaddingRight">
              <Box mt={2}>
                <EMPButton
                  testId={`addEmployee_${this.currentDealer.regionalId}`}
                  disabled={
                    this.state.addNewRowActive ||
                    this.state.employeeLimitReached
                  }
                  onClick={this.showNewEmployeeRow}
                  buttonText={this.props.t("DealerShipDetails.addButton")}
                  buttonElement="button"
                  buttonType="contained"
                ></EMPButton>

                {this.state.employeeLimitReached && (
                  <div className={styles.maximumEmployee}>
                    {this.props.t("DealerShipDetails.maxEmployee")}
                  </div>
                )}
              </Box>
            </div>
          )}
        />
      </>
    );
  }
}

export default withTranslation("emp")(DealerEmployeeTable);
