import axios from "axios";
import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";
import { getHeaders } from "../utils/AuthorizationUtil";
import { BASE_URL, NOTNULL_OR_NOTUNDEFINED } from "../utils/EmpUtil";
import { is401 } from "../errors/ErrorConstants";

export default class PendingActionClient {
  static baseUrl = BASE_URL + "/new";

  static getNscPendingActionsNotifications(user) {
    const url = `${this.baseUrl}/nsc/pending-actions`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data.result)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getDealerPendingActionsNotifications(user) {
    const url = `${
      this.baseUrl
    }/dealer/pending-actions?dealerIds=${user.getDealerIdsWithSDLR()}`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data.result)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static removeNscPendingAction(user, action, vehicleLines) {
    let url = `${this.baseUrl}/pending-actions?action=${action}`;
    if (NOTNULL_OR_NOTUNDEFINED(vehicleLines)) {
      url += "&vehicleLines=" + vehicleLines;
    }
    return this.deletePA(user, url);
  }

  static deletePA(user, url) {
    return axios.delete(url, getHeaders(user.token)).catch((error) => {
      if (is401(error)) {
        AuthenticationFailureHandler.execute();
      }
      return Promise.reject(error);
    });
  }

  static addPA(user, action) {
    const url = `${this.baseUrl}/pending-actions?action=${action}`;
    return axios.put(url, null, getHeaders(user.token)).catch((error) => {
      if (is401(error)) {
        AuthenticationFailureHandler.execute();
      }
      return Promise.reject(error);
    });
  }

  static removeDealerPendingAction(user, dealerIds, action, vehicleLines) {
    let url = `${this.baseUrl}/pending-actions?action=${action}&dealerIds=${dealerIds}`;
    if (NOTNULL_OR_NOTUNDEFINED(vehicleLines)) {
      url += "&vehicleLines=" + vehicleLines;
    }
    return this.deletePA(user, url);
  }

  static getHasNscCompletedPA(user, version) {
    const url = `${this.baseUrl}/pending-actions/completion?version=${version}`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
