import React from "react";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";

export default {
  deliveryNotReady: {
    status: "notReady",
    statusColor: "disabled",
    message: "Not ready to be scheduled",
  },
  deliveryNotScheduled: {
    status: "notScheduled",
    statusColor: "disabled",
    message: "Delivery not scheduled",
  },
  deliveryCanceled: {
    status: "canceled",
    statusColor: "default",
    message: "Appointment canceled",
  },
  deliveryRequested: {
    status: "requested",
    statusColor: "warning",
    message: "Appointment requested",
    actionText: "Confirm",
    icon: (
      <EventOutlinedIcon
        color="inherit"
        fontSize="large"
        data-testid="event-icon"
      />
    ),
  },
  deliveryConfirmed: {
    status: "confirmed",
    statusColor: "default",
    message: "Appointment confirmed",
    actionText: "Make changes",
    icon: (
      <EventAvailableOutlinedIcon
        color="inherit"
        fontSize="large"
        data-testid="event-icon"
      />
    ),
  },
  deliveryInProgress: {
    status: "confirmed",
    statusColor: "default",
    message: "Appointment in progress",
    actionText: "Make changes",
    icon: (
      <EventAvailableOutlinedIcon
        color="inherit"
        fontSize="large"
        data-testid="event-icon"
      />
    ),
  },
  deliveryScheduled: {
    status: "confirmed",
    statusColor: "default",
    message: "Appointment scheduled",
    actionText: "Make changes",
    icon: (
      <EventAvailableOutlinedIcon
        color="inherit"
        fontSize="large"
        data-testid="event-icon"
      />
    ),
  },
  deliveryCompleted: {
    status: "completed",
    statusColor: "success",
    message: "Delivery completed",
    actionText: "",
    icon: (
      <CheckCircleOutlinedIcon
        color="inherit"
        fontSize="large"
        data-testid="check-icon"
      />
    ),
  },
  deliveryUnableToComplete: {
    status: "Unable To Complete",
    statusColor: "warning",
    message: "Delivery incomplete",
    actionText: "Reschedule",
    icon: (
      <EventOutlinedIcon
        color="inherit"
        fontSize="large"
        data-testid="event-icon"
      />
    ),
  },
};
