import React, { Component } from "react";
import GlobalNavBar from "../shared/globalNavBar/GlobalNavBar";
import Search from "../components/search/Search";
import { withTranslation } from "react-i18next";
import Footer from "../shared/Footer/Footer";
import Crc from "./pure/Crc";
import withIdleTimer from "../common/WithIdleTimer";
import { Route, Router } from "react-router-dom";
import ErrorScreen from "../errors/ErrorScreen";
import { FORD_ENGLISH } from "../common/Constants";

class CRCApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderApp: false,
    };
    this.currentCrc = new Crc(
      this.props.token,
      this.props.userDetails,
      this.props.history
    );
  }

  componentDidMount() {
    this.props.history.push(`/`);
    this.props.i18n.changeLanguage(FORD_ENGLISH);
    this.setState({ renderApp: true });
  }

  render() {
    return (
      this.state.renderApp && (
        <Router history={this.props.history}>
          <div className="appWrapper">
            <GlobalNavBar
              {...this.props}
              urlPath={window.location.pathname}
              user={this.currentCrc}
            />
            <Route
              exact
              path="/"
              component={withIdleTimer(() => (
                <Search {...this.props} user={this.currentCrc} />
              ))}
            />
            <Route
              exact
              path="/error"
              render={(props) => <ErrorScreen {...props} />}
            />
            <Footer
              user={this.currentCrc}
              footerTitle={this.props.t("Footer.baseTitle")}
              helpSupportTitle={this.props.t("Footer.help")}
              permissions={this.props.userDetails.permissions}
            />
          </div>
        </Router>
      )
    );
  }
}

export default withTranslation("emp")(CRCApp);
