import React, { Component } from "react";
import TranslationTable from "./TranslationTable";
import { logMsg } from "../../common/Logger";
import NewComponentTranslation from "./NewComponentTranslation";
import styles from "./Translation.module.scss";
import TranslationsClient from "./TranslationsClient";
import {
  LANCODES,
  MARKET_LANGUAGES,
  TRANSLATION_TOOL_REGIONS,
} from "../../common/Constants";

import EMPSelectField from "../../shared/components/EMPSelectField/EMPSelectField";
import FormControl from "@material-ui/core/FormControl";

import EMPButton from "../../shared/components/EMPButton/EMPButton";
import { Box } from "@material-ui/core";

class TranslationDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDataLoaded: false,
      addNewComponent: false,
      disableAddComponent: true,
      showNewComponentTranslation: true,
      translation: [],
      currentLan: null,
      fetchFailed: false,
    };
    this.marketLan = null;
  }

  componentDidMount() {
    this.getLangDropDown();
    this.getTranslations(
      this.props.localizedInfo.defaultLanguage.isocode.substr(0, 2)
    );
  }

  onClickAddComponent = () => {
    this.setState({ disableAddComponent: false });
    this.setState({ addNewComponent: true, showNewComponentTranslation: true });
  };

  handleInputChange = (event) => {
    if (
      event.target.value !== this.state.currentLan &&
      event.target.value !== undefined
    ) {
      this.setState({ currentLan: event.target.value });
      this.getTranslations(event.target.value);
    }
  };

  getTranslations = (lanCode) => {
    this.props.hideOrShow(true);
    this.setState({ isDataLoaded: false });
    TranslationsClient.getTranslationsLanguage(
      lanCode,
      this.props.userDetails.market,
      this.props.token
    )
      .then((response) => {
        const translation = [];
        this.setState({
          translation: Object.assign({ ...translation }, { ...response }),
        });
      })
      .catch((error) => {
        logMsg("the error is: ", error);
        this.setState({
          fetchFailed: true,
        });
      })
      .finally(() => {
        this.props.hideOrShow(false);
        this.setState({ isDataLoaded: true });
      });
  };

  getLangDropDown = () => {
    let currentLan = null;
    this.marketLan = MARKET_LANGUAGES[this.props.user.market];
    currentLan = this.props.localizedInfo.defaultLanguage.isocode.substr(0, 2);
    this.setState({ currentLan });
  };

  addTranslationComponent = (updatedLanguage) => {
    this.props.hideOrShow(true);
    TranslationsClient.postAddComponentTranslations(
      updatedLanguage,
      this.getMarketsForNewTranslations(),
      this.props.token
    )
      .then(() => {
        this.getTranslations(this.state.currentLan);
      })
      .finally(() => {
        this.props.hideOrShow(false);
        this.setState({
          disableAddComponent: true,
          showNewComponentTranslation: false,
        });
      });
  };

  getMarketsForNewTranslations = () => {
    for (const key in TRANSLATION_TOOL_REGIONS) {
      if (
        TRANSLATION_TOOL_REGIONS[key].includes(this.props.userDetails.market)
      ) {
        return TRANSLATION_TOOL_REGIONS[key];
      }
    }
    return null;
  };

  showNewComponentTranslation() {
    const current = this.state.showNewComponentTranslation;
    this.setState({ showNewComponentTranslation: !current });
  }

  cancelTranslation = () => {
    this.setState({ disableAddComponent: true });
    this.setState({
      addNewComponent: false,
      showNewComponentTranslation: false,
    });
  };

  render() {
    const menuItems = [];
    this.marketLan &&
      this.marketLan.forEach((state) => {
        menuItems.push({
          text: LANCODES[state],
          value: state,
        });
      });

    if (this.state.fetchFailed) {
      return (
        <div className={styles.errorText}>
          <h2>{this.props.t("FetchError.errorText")}</h2>
        </div>
      );
    } else {
      return (
        <div>
          {this.state.isDataLoaded && (
            <div>
              {this.marketLan && (
                <div className={styles.dropdownDiv}>
                  <span className={styles.inputLabel}>
                    {this.props.t("Translations.translationLanguage")}
                  </span>

                  <FormControl fullWidth={false} className={styles.formcontrol}>
                    <EMPSelectField
                      testId="pickUplang"
                      value={this.state.currentLan}
                      onChange={this.handleInputChange}
                      menuItems={menuItems}
                    />
                  </FormControl>
                </div>
              )}
              {Object.keys(this.state.translation.translations).length !== 0 &&
                Object.keys(this.state.translation.translations).map((id) => {
                  return (
                    <TranslationTable
                      {...this.props}
                      currentLan={this.state.currentLan}
                      languageSelected={this.props.localizedInfo.defaultLanguage.isocode.substr(
                        0,
                        2
                      )}
                      translations={this.state.translation.translations[id]}
                      key={this.state.translation.translations[id].moduleKey}
                      getTranslations={this.getTranslations}
                      getMarketsForNewTranslations={
                        this.getMarketsForNewTranslations
                      }
                    />
                  );
                })}
            </div>
          )}
          <br />

          <div>
            <div style={{ position: "center" }}>
              {this.state.disableAddComponent && (
                <Box mt={2} textAlign="center">
                  <EMPButton
                    buttonElement="button"
                    buttonType="outlined"
                    onClick={this.onClickAddComponent}
                    buttonText={this.props.t("Translations.addNewComponent")}
                  ></EMPButton>
                </Box>
              )}
            </div>
            {this.state.addNewComponent && (
              <NewComponentTranslation
                {...this.props}
                showNewComponentTranslation={this.showNewComponentTranslation}
                showNewComponentTranslationvalue={
                  this.state.showNewComponentTranslation
                }
                currentLan={this.state.currentLan}
                languageSelected={this.props.localizedInfo.defaultLanguage.isocode.substr(
                  0,
                  2
                )}
                addTranslationComponent={this.addTranslationComponent}
                cancelTranslation={this.cancelTranslation}
              />
            )}
          </div>
        </div>
      );
    }
  }
}

export default TranslationDetails;
