import React from "react";
import fordStyles from "./SharedToolTip.module.scss";
import lincolnStyles from "./LincolnSharedToolTip.module.scss";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import Info from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import LincolnTooltipIcon from "../../imgs/lincoln-tooltip-icon.svg";
import { IS_FORD_BRAND } from "../../utils/EmpUtil";

const StyledInfoIcon = withStyles({
  root: {
    fill: "#4a90e2",
    width: 14,
    height: 14,
    cursor: "pointer",
  },
})(Info);

const StyledTooltip = withStyles({
  popper: {
    opacity: 1,
    pointerEvents: "auto",
  },
  tooltip: {
    padding: 3,
    background: "white",
    boxShadow: "1px 1px 9px 0 rgba(0, 0, 0, 0.27)",
    maxWidth: 300,
  },
  arrow: {
    color: "white",
  },
})(Tooltip);

const SharedToolTip = (props) => {
  const isFordBrand = props.user && IS_FORD_BRAND(props.user.brand);

  const styles = !isFordBrand ? lincolnStyles : fordStyles;
  const placement = props.placement ? props.placement : "top";

  return (
    <StyledTooltip
      enterTouchDelay={0}
      leaveTouchDelay={3000}
      arrow
      placement={placement}
      title={
        <div className={styles.tooltipContainer}>
          {props.title && <div className={styles.header}>{props.title}</div>}
          <div className={styles.bodyContent}>{props.body}</div>
        </div>
      }
    >
      {!isFordBrand ? (
        <img
          alt="Tooltip"
          data-testid={props.openTestid}
          className={styles.tooltipImg}
          onClick={(event) => props.openTooltip(event)}
          src={LincolnTooltipIcon}
        />
      ) : (
        <StyledInfoIcon
          data-testid={props.openTestid}
          className={props.className}
          fontSize="small"
        />
      )}
    </StyledTooltip>
  );
};

export default withTranslation("emp")(SharedToolTip);
