import {
  CA_BRONCO_CATALOG_ID,
  CA_BRONCO_SPORT_CATALOG_ID,
  CA_F150_CATALOG_ID,
  CA_MACHE_CATALOG_ID,
  US_BRONCO_22_CATALOG_ID,
  US_BRONCO_CATALOG_ID,
  US_BRONCO_SPORT_22_CATALOG_ID,
  US_BRONCO_SPORT_CATALOG_ID,
  US_F150_CATALOG_ID,
  US_F150_22_CATALOG_ID,
  US_MACHE_22_CATALOG_ID,
  US_MACHE_CATALOG_ID,
  CA_MACHE_22_CATALOG_ID,
  CA_BRONCO_22_CATALOG_ID,
  CA_BRONCO_SPORT_22_CATALOG_ID,
  CA_F150_22_CATALOG_ID,
  US_MACHE_23_CATALOG_ID,
  CA_MACHE_23_CATALOG_ID,
} from "../common/Constants";

const GetVehiclePermissions = (catalogId, vehiclePermissions) => {
  switch (catalogId) {
    case US_MACHE_CATALOG_ID:
    case CA_MACHE_CATALOG_ID:
    case US_MACHE_22_CATALOG_ID:
    case CA_MACHE_22_CATALOG_ID:
    case US_MACHE_23_CATALOG_ID:
    case CA_MACHE_23_CATALOG_ID:
    case US_BRONCO_CATALOG_ID:
    case CA_BRONCO_CATALOG_ID:
    case US_BRONCO_22_CATALOG_ID:
    case CA_BRONCO_22_CATALOG_ID:
    case US_BRONCO_SPORT_CATALOG_ID:
    case CA_BRONCO_SPORT_CATALOG_ID:
    case US_BRONCO_SPORT_22_CATALOG_ID:
    case CA_BRONCO_SPORT_22_CATALOG_ID:
    case CA_F150_CATALOG_ID:
    case CA_F150_22_CATALOG_ID:
    case US_F150_CATALOG_ID:
    case US_F150_22_CATALOG_ID:
      return vehiclePermissions[catalogId];
    default:
      return {};
  }
};

export default GetVehiclePermissions;
