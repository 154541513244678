import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import EMPTextAreaField from "../../../../shared/components/EMPTextAreaField/EMPTextAreaField";
import EMPDialog from "../../../../shared/components/EMPDialog/EMPDialog";

class DealerNotesDialogEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: this.props.content,
    };
  }

  handleClose = () => {
    this.props.closeDialog();
    this.setState({ content: null });
  };

  handleChange = (event) => {
    this.setState({ content: event.target.value });
  };

  render() {
    const props = this.props;

    return (
      <EMPDialog
        open={this.props.open}
        dialogTitle={props.t("DealerNotes.editNote")}
        hasActionButton
        actionButtonText={props.t("DealerNotes.save")}
        onConfirm={() =>
          this.props.handleUpdate(this.state.content, this.props.id)
        }
        hasSecondaryActionButton
        secondaryActionButtonText={props.t("DealerNotes.cancel")}
        onSecondaryAction={this.handleClose}
        actionLoading={this.props.loading}
        disableActionButton={this.props.loading}
      >
        <EMPTextAreaField
          value={this.state.content}
          hasCharacterCount
          maxCharacters={this.props.maxCharacters}
          onChange={this.handleChange}
          minRows={4}
        ></EMPTextAreaField>
      </EMPDialog>
    );
  }
}

export default withTranslation("emp")(DealerNotesDialogEdit);
