import { Dialog } from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import fordStyles from "./DealerNotes.module.scss";
import lincolnStyles from "./DealerNotes-Lincoln.module.scss";
import StyledCloseIcon from "../../../../shared/Icons/StyledCloseIcon";
import DealerNote from "./DealerNote";
import { generateNoteText } from "./DealerNoteUtil";
import StyledCaretRightIcon from "../../../../shared/Icons/StyledCaretRightIcon";
import StyledCaretLeftIcon from "../../../../shared/Icons/StyledCaretLeftIcon";

const StyledDialog = withStyles({
  paper: {
    width: 1059,
    maxWidth: 1059,
  },
})(Dialog);

const DealerNotesViewAllDialog = (props) => {
  const {
    closeDialog,
    openViewAllDialog,
    isFordBrand,
    toggleOverflowMenu,
    setErrorStatus,
    popperOpen,
    dealerNotesRetrieveError,
    notes,
    pagination,
    getDealerNotes,
    pageSize,
  } = props;

  const handlePrev = () => {
    getDealerNotes(pagination.currentPage - 1, pageSize);
  };

  const handleNext = () => {
    getDealerNotes(pagination.currentPage + 1, pageSize);
  };

  const getPages = (pageCount, currPage) => {
    const pageArr = [];
    for (let i = 0; i < pageCount; ++i) {
      pageArr.push(String(i + 1));
    }
    if (pageCount <= 4) return pageArr;

    if (currPage < 3) {
      return [...pageArr.slice(0, 3), "...", String(pageCount)];
    } else {
      return ["1", "...", ...pageArr.slice(pageCount - 3)];
    }
  };
  const dealerNotesStyles = isFordBrand ? fordStyles : lincolnStyles;
  const closeButtonColor = isFordBrand ? "#102b4e" : "#324047";
  const popperId = popperOpen ? "simple-popper" : undefined;

  return (
    <StyledDialog open={openViewAllDialog} disableEnforceFocus>
      <div className={dealerNotesStyles.dialogWrapperViewAll}>
        <button className={dealerNotesStyles.dialogClose} onClick={closeDialog}>
          <StyledCloseIcon isFordBrand={isFordBrand} color={closeButtonColor} />
        </button>
        <div
          className={`${dealerNotesStyles.dialogHeader} ${dealerNotesStyles.viewAllTitleColor}`}
        >
          {props.t("DealerNotes.dealerNotes")}
        </div>
        {!dealerNotesRetrieveError ? (
          <div className={dealerNotesStyles.dealerNotesContainerViewAll}>
            <div className={dealerNotesStyles.noteCountText}>
              {notes && generateNoteText(pagination.totalNotes, props)}
            </div>
            {notes.map((note) => (
              <DealerNote
                key={note.id}
                note={note}
                replies={note.replies}
                isFordBrand={isFordBrand}
                popperId={popperId}
                toggleOverflowMenu={toggleOverflowMenu}
                setErrorStatus={setErrorStatus}
                {...props}
              />
            ))}
            {pagination.totalPages > 1 && pagination.pageSize === pageSize && (
              <div className={dealerNotesStyles.paginationRow}>
                <button
                  className={`${dealerNotesStyles.paginationCaret} ${
                    !pagination.currentPage &&
                    dealerNotesStyles.paginationDisabled
                  }`}
                  onClick={pagination.currentPage && handlePrev}
                >
                  <div className={dealerNotesStyles.caretOffsetRight}>
                    <StyledCaretLeftIcon />
                  </div>
                  {props.t("DealerNotes.prev")}
                </button>
                {getPages(pagination.totalPages, pagination.currentPage).map(
                  (page, index) => (
                    <button
                      key={index}
                      disabled={page === "..."}
                      className={`${dealerNotesStyles.pagination} ${
                        pagination.currentPage + 1 === Number(page) &&
                        dealerNotesStyles.paginationUnderline
                      } ${
                        page === "..." && dealerNotesStyles.paginationDisabled
                      }`}
                      onClick={
                        page === "..."
                          ? null
                          : () => getDealerNotes(Number(page - 1), pageSize)
                      }
                    >
                      {page}
                    </button>
                  )
                )}
                <button
                  className={`${dealerNotesStyles.paginationCaret} ${
                    pagination.currentPage === pagination.totalPages - 1 &&
                    dealerNotesStyles.paginationDisabled
                  }`}
                  onClick={
                    pagination.currentPage !== pagination.totalPages - 1 &&
                    handleNext
                  }
                >
                  {props.t("DealerNotes.next")}
                  <div className={dealerNotesStyles.caretOffsetLeft}>
                    <StyledCaretRightIcon />
                  </div>
                </button>
              </div>
            )}
          </div>
        ) : (
          <span className={dealerNotesStyles.labelError}>
            {" "}
            {props.t("DealerNotes.retrieveDealerNotesErrorText")}
          </span>
        )}
      </div>
    </StyledDialog>
  );
};

export default withTranslation("emp")(DealerNotesViewAllDialog);
