import { ReactComponent as CloseComponent } from "../../imgs/close-dialog.svg";
import { ReactComponent as LincolnCloseComponent } from "../../imgs/lincoln-close-dialog.svg";
import React from "react";

const StyledCloseIcon = ({
  size = "32px",
  color = "white",
  isFordBrand = true,
}) => {
  const Close = isFordBrand ? CloseComponent : LincolnCloseComponent;
  return <Close style={{ color, height: size, width: size }} />;
};
export default StyledCloseIcon;
