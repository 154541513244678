import React from "react";
import Grid from "@material-ui/core/Grid";
import fordStyle from "./Table.module.scss";
import lincolnStyle from "./TableLincoln.module.scss";
import SharedToolTip from "../sharedToolTip/SharedToolTip";
import EMPEmptyState from "../components/EMPEmptyState/EMPEmptyState";

const CHARS_PER_LINE = 75;

export const StyledTableRow = (props) => {
  const {
    text,
    data,
    indent,
    index,
    testID,
    isLincolnBrand,
    additionalTextPadding,
  } = props;
  const style = isLincolnBrand ? lincolnStyle : fordStyle;

  const splitIntoLines = (text) => {
    if (!text || text.length < CHARS_PER_LINE) return [text];

    const wordArr = text.split(" ");
    const lines = [];
    let currLine = "";

    while (wordArr.length) {
      if (currLine.length + wordArr[0].length <= CHARS_PER_LINE) {
        currLine += ` ${wordArr.shift()}`;
      } else {
        lines.push(currLine);
        currLine = "";
      }
    }

    if (currLine.length) lines.push(currLine);

    return lines;
  };

  const lines = splitIntoLines(text);
  const rowHeight = `${lines.length * 40}px`;

  const TextWrap = ({ lines }) => {
    return lines.map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  if ((index && index % 2 === 0) || index === 0) {
    return (
      <Grid
        container
        alignItems="center"
        className={indent ? style.greyIndent : style.greyRegular}
        style={{ height: rowHeight }}
      >
        <Grid
          item
          xs={data !== undefined ? 8 : 12}
          style={{ padding: additionalTextPadding }}
        >
          <TextWrap lines={lines} />
          {props.tooltip && (
            <SharedToolTip
              openTestid={props.tooltip.openTestid}
              closeTestid={props.tooltip.closeTestid}
              title={props.tooltip.title}
              body={props.tooltip.body}
              isLincolnBrand={isLincolnBrand}
            />
          )}
        </Grid>
        {data !== undefined && (
          <Grid item xs={4} className={style.rightCell} data-testid={testID}>
            {data !== null ? data : <EMPEmptyState />}
          </Grid>
        )}
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        alignItems="center"
        className={indent ? style.indent : style.regular}
        style={{ height: rowHeight }}
      >
        <Grid
          item
          xs={data !== undefined ? 8 : 12}
          style={{ padding: additionalTextPadding }}
        >
          <TextWrap lines={lines} />
          {props.tooltip && (
            <SharedToolTip
              openTestid={props.tooltip.openTestid}
              closeTestid={props.tooltip.closeTestid}
              title={props.tooltip.title}
              body={props.tooltip.body}
              isLincolnBrand={isLincolnBrand}
            />
          )}
        </Grid>
        {data !== undefined && (
          <Grid item xs={4} className={style.rightCell} data-testid={testID}>
            {data !== null ? data : <EMPEmptyState />}
          </Grid>
        )}
      </Grid>
    );
  }
};

export const StyledBigTableHeader = (props) => {
  const { text, data, testID, isLincolnBrand } = props;
  const style = isLincolnBrand ? lincolnStyle : fordStyle;

  return (
    <Grid container alignItems="center" className={style.bigText}>
      <Grid item xs={8}>
        {text}
      </Grid>
      <Grid item xs={4} className={style.rightCell} data-testid={testID}>
        {data !== null ? data : <EMPEmptyState />}
      </Grid>
    </Grid>
  );
};

export const StyledTableHeader = (props) => {
  const { text, data, testID, isLincolnBrand } = props;
  const style = isLincolnBrand ? lincolnStyle : fordStyle;

  return (
    <Grid container alignItems="center" className={style.headerText}>
      <Grid item xs={8}>
        {text}
      </Grid>
      <Grid item xs={4} className={style.rightCell} data-testid={testID}>
        {data !== null ? data : <EMPEmptyState />}
      </Grid>
    </Grid>
  );
};
