import { ENV } from "../../../utils/EmpUtil";
import {
  PREPROD_DYNATRACE_URL,
  PROD_DYNATRACE_URL,
} from "../../../common/Constants";

const initDynatrace = () => {
  const script = document.createElement("script");
  script.src = ENV !== "prod" ? PREPROD_DYNATRACE_URL : PROD_DYNATRACE_URL;
  script.async = true;

  document.head.appendChild(script);
};

export { initDynatrace };
