import React from "react";
import { withTranslation } from "react-i18next";
import EMPDialog from "../../../../shared/components/EMPDialog/EMPDialog";

const DealerNotesDialogDelete = ({
  closeDialog,
  open,
  note,
  deleteDealerNotes,
  loading,
  t,
}) => {
  return (
    <EMPDialog
      open={open}
      hasCloseIcon
      handleClose={closeDialog}
      dialogTitle={t("DealerNotes.deleteTitle")}
      hasActionButton
      actionButtonText={t("DealerNotes.confirm")}
      onConfirm={() => deleteDealerNotes(note.id)}
      hasSecondaryActionButton
      secondaryActionButtonText={t("DealerNotes.cancel")}
      onSecondaryAction={closeDialog}
      actionLoading={loading}
      disableActionButton={loading}
    ></EMPDialog>
  );
};

export default withTranslation("emp")(DealerNotesDialogDelete);
