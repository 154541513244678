/**
 * @return {string}
 */
import UpperCaseText from "./UpperCaseText";
import { CHECURRENCY } from "../common/Constants";

export default function CheckCurrencyValue(
  currency,
  value,
  toFixed = false,
  fallback = "--"
) {
  if (value === null) {
    return fallback;
  } else if (!isNaN(value)) {
    const fixedValue = toFixed
      ? parseFloat(value).toFixed(2)
      : parseFloat(value);
    if (UpperCaseText(currency) === CHECURRENCY) {
      return currency.concat(" ".concat(fixedValue));
    } else {
      return currency.concat(fixedValue);
    }
  } else {
    return fallback;
  }
}
