import React from "react";
import "../../../../styles/sharedStyles.scss";
import Grid from "@material-ui/core/Grid";
import styles from "./RejectConfirmDialog.module.scss";
import { withTranslation } from "react-i18next";
import {
  DEALER_ACTIONS,
  REJECT,
  REJECT_CONFIRM,
} from "../../../../common/Constants";
import UiDateUtils from "../../../../utils/UiDateUtils";
import EMPDialog from "../../../../shared/components/EMPDialog/EMPDialog";

class RejectConfirmDialog extends React.Component {
  handleClose = () => {
    this.props.onClose(REJECT_CONFIRM);
  };

  handleConfirm = () => {
    const rejectTradeInRequest = {
      reservationId: this.props.tradeInDetails.orderId,
      dealerAction: DEALER_ACTIONS.TRADE_IN_REJECTED,
      acceptedDate: this.props.data.dateVehicleSeen,
      dealerRejectedReason: this.props.data.reasonText,
    };

    this.props.onSubmit(rejectTradeInRequest);
    this.handleClose();
  };

  editRejectConfirm = () => {
    this.props.onDialogChange(REJECT, REJECT_CONFIRM);
  };

  render() {
    return this.props.open ? (
      <EMPDialog
        open={this.props.open}
        handleClose={this.handleClose}
        dialogTitle={this.props.t("TradeIn.US.dialogs.rejectConfirm.title")}
        hasCloseIcon
        dialogActionsDataTestId="rejectConfirmDialogActions"
        hasActionButton
        actionButtonText={this.props.t(
          "TradeIn.US.dialogs.rejectConfirm.confirm"
        )}
        onConfirm={this.handleConfirm}
        disableActionButton={
          !this.props.user.permissions.rolePermissions.hasOwnProperty(
            "tradeInSubmit"
          )
        }
        hasSecondaryActionButton
        secondaryActionButtonText={this.props.t(
          "TradeIn.US.dialogs.rejectConfirm.edit"
        )}
        onSecondaryAction={this.editRejectConfirm}
      >
        <Grid
          container
          data-testid="rejectTradeInConfirmDialog"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <div className={styles.body}>
              <div>
                {this.props.t("TradeIn.US.dialogs.rejectConfirm.body.title")}
              </div>
              <ul>
                <li>
                  {this.props.t(
                    "TradeIn.US.dialogs.rejectConfirm.body.bullet1"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "TradeIn.US.dialogs.rejectConfirm.body.bullet2"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "TradeIn.US.dialogs.rejectConfirm.body.bullet3"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "TradeIn.US.dialogs.rejectConfirm.body.bullet4"
                  )}
                </li>
              </ul>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={styles.wrapper}>
              <div className={styles.rejectTitle}>
                {this.props.t(
                  "TradeIn.US.dialogs.rejectConfirm.dateVehicleSeen"
                )}
              </div>

              <div className={styles.rejectText}>
                {UiDateUtils.formatDateMmDdYyyy(
                  this.props.data.dateVehicleSeen
                )}
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={styles.wrapper}>
              <div className={styles.rejectTitle}>
                {this.props.t("TradeIn.US.dialogs.rejectConfirm.rejectReason")}
              </div>

              <div className={styles.rejectText}>
                {this.props.data.reasonText}
              </div>
            </div>
          </Grid>
        </Grid>
      </EMPDialog>
    ) : (
      ""
    );
  }
}

export default withTranslation("emp")(RejectConfirmDialog);
