import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { logMsg } from "../../common/Logger";
import ReleaseNotesClient from "../../clients/ReleaseNotesClient";
import { trackPageLoadEvent } from "../../shared/services/analytics/AnalyticsService";

const usePageHeaderStyles = makeStyles({
  root: {
    fontFamily: "'FordAntennaRegular', sans-serif",
  },
  h1: {
    fontSize: "24px",
    fontFamily: "'FordAntennaBold', sans-serif",
    color: "#142742",
  },
  h6: {
    fontSize: "16px",
    fontFamily: "'FordAntennaBold', sans-serif",
    color: "#000000",
  },
  body1: {
    fontSize: "16px",
    color: "#000000",
  },
});

const PageHeader = ({ title, subtitle, classes }) => {
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h1" classes={{ ...classes }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="body1" classes={{ ...classes }}>
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const Hero = ({ title, subtitle, classes }) => {
  const matches = useMediaQuery("(min-width:1024px)");
  return (
    <Box
      style={{ backgroundColor: "whitesmoke" }}
      px={matches ? 6 : 3}
      py={3}
      mb={3}
    >
      <PageHeader title={title} subtitle={subtitle} classes={classes} />
    </Box>
  );
};

const Notes = ({ notes, classes }) => {
  const matches = useMediaQuery("(min-width:1024px)");

  return (
    <Box px={matches ? 6 : 3} py={3}>
      {notes.map((note, index) => (
        <Box
          key={index}
          mb={6}
          sx={{
            borderBottom: "1px solid #f2f2f2",
            paddingBottom: "16px",
          }}
        >
          <Typography
            data-testid={`notes_title_${index}`}
            variant="h6"
            classes={{ ...classes }}
          >
            {note.text[0]}
          </Typography>

          <ul>
            {note.text.map(
              (item, index) =>
                index > 0 && (
                  <li key={index}>
                    <Typography variant="body1" classes={{ ...classes }}>
                      {item}
                    </Typography>
                  </li>
                )
            )}
          </ul>
        </Box>
      ))}
    </Box>
  );
};

const ReleaseNotes = (props) => {
  const { t, hideOrShow, token, user } = props;
  const classes = usePageHeaderStyles();

  const { pathname } = useLocation();
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getReleaseNotes();
  }, [pathname]);

  useEffect(() => {
    trackPageLoadEvent("fv:emp:release note", user);
  }, [user]);

  const getReleaseNotes = async () => {
    hideOrShow(true);
    await ReleaseNotesClient.getReleaseNotes(token)
      .then((response) => {
        response !== null &&
          response.sort((a, b) => new Date(b.date) - new Date(a.date));

        setNotes(response);
      })
      .catch(() => {
        logMsg("Error fetching release notes.");
      })
      .finally(() => {
        hideOrShow(false);
      });
  };

  const addStyle = (() => {
    const style = document.createElement("style");
    document.body.append(style);
    return (styleString) => (style.textContent = styleString);
  })();

  addStyle(`
  footer {
    position: relative !important;
  }
`);

  return (
    notes && (
      <section>
        <Hero
          classes={classes}
          title={t("ReleaseNotes.title")}
          subtitle={t("ReleaseNotes.subtitle")}
        />
        <Grid container>
          <Grid item xs={12} sm={12} md={10}>
            <Notes notes={notes} classes={classes} />
          </Grid>
        </Grid>
      </section>
    )
  );
};

export default withTranslation("emp")(ReleaseNotes);
