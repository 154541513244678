import React from "react";
import { SvgIcon } from "@material-ui/core";

const MotionChevronDown = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icon">
          <path
            id="Vector Path"
            d="M11.9998 16.8L19.1998 9.60001L18.3598 8.76001L11.9998 15.12L5.6398 8.76001L4.7998 9.60001L11.9998 16.8Z"
            fill="#1F3047"
          />
        </g>
      </svg>
      ;
    </SvgIcon>
  );
};

export default MotionChevronDown;
