import React, { Component } from "react";
import { Accordion, AccordionSummary } from "@material-ui/core";
import styles from "./SearchResultsTable.module.scss";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import HasPermissionTo from "../../common/HasPermissionTo";
import TitleCase from "../../utils/TitleCase";
import UiDateUtils from "../../utils/UiDateUtils";
import LowerCaseText from "../../utils/LowerCaseText";
import crStyles from "../customerHandling/crStyles";
import DataCard from "../../shared/components/DataCard/DataCard";
import KeyValueField from "../../shared/components/DataFields/KeyValueField";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import MotionChevronRight from "../../shared/Icons/MotionChevronRight";

class SearchResultsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconUp: true,
    };
    this.statusMap = {
      CREATED: "created",
      RESERVATION_FAILED: "created",
      ORDER_RESERVED: "created",
      CONTRACT_REQUESTED: "confirmed",
      CONTRACT_REQUEST_FAILED: "confirmed",
      CONTRACT_FAILED: "confirmed",
      CONTRACT_CANCEL_REQUESTED: "ordered",
      CONTRACT_CANCEL_REQUEST_FAILED: "ordered",
      RESERVATION_CANCELLED: "cancelled",
      DEALER_CANCELLED: "cancelled",
      CONTRACT_CANCELLED: "cancelled",
      CONVERTED_TO_OFFLINE: "amended",
      AMENDED: "amended",
      ORDER_CONTRACTED: "contracted",
      ORDER_CONFIRMED: "submitted",
      CONTRACT_CANCEL_FAILED: "ordered",
      RESERVATION_CANCELLATION_FAILED: "contracted",
      AMEND_CONTRACT_REQUEST_FAILED: "contracted",
      AMEND_CONTRACT_CANCEL_REQUEST_FAILED: "contracted",
      ORDERED: "ordered",
    };
  }

  determineRegionalId() {
    const filteredDealer = this.props.user.dealerGroup.filter(
      (dealer) => dealer.commonId === this.props.commonDealerId
    );
    if (filteredDealer && filteredDealer.length === 1)
      return filteredDealer[0].regionalId;
  }

  flipIcon() {
    const current = this.state.iconUp;
    this.setState({ iconUp: !current });
  }

  resolveInlineStyle(status) {
    switch (status) {
      case "created":
      case "contracted":
      case "confirmed":
      case "ordered":
        return crStyles.confirmedStatus;
      case "cancelled":
        return crStyles.cancelledStatus;
      case "amended":
        return crStyles.amendedStatus;
      default:
        return crStyles.commonStatusStyle;
    }
  }

  translateOrderType(reservation) {
    //Purchase Request
    if (reservation.purchaseType || reservation.purchaseRequestDate) {
      return this.props.t("CustomerReservations.purchaseRequest");
    }
    //Order
    else if (LowerCaseText(reservation.orderType).includes("order")) {
      return this.props.t("CustomerReservations.order");
    } else return reservation.orderType;
  }

  render() {
    return (
      <div key={this.props.index} className={styles.tablePadding}>
        <Accordion
          styles={crStyles.expansionPanel}
          defaultExpanded={true}
          style={{ boxShadow: "none" }}
          onChange={() => this.flipIcon()}
        >
          <AccordionSummary style={crStyles.headerBar} id="exp">
            <div className={styles.headerBar}>
              <Grid container>
                <Grid item xs={6} style={crStyles.gridItem}>
                  <HasPermissionTo
                    perform={["reservationsRegionalId:view"]}
                    permissions={this.props.user.permissions.rolePermissions}
                    render={() => (
                      <span className={styles.pre}>
                        {this.determineRegionalId() + "  |  "}
                      </span>
                    )}
                  />
                  {this.props.reservations[0].dealerName.toUpperCase()}
                </Grid>
                <Grid item xs>
                  {this.props.totalNumberOfResults}{" "}
                  {this.props.totalNumberOfResults === 1
                    ? this.props.t("CustomerReservations.item")
                    : this.props.t("CustomerReservations.items")}
                </Grid>
                <Grid item xs style={crStyles.dropdown}>
                  {this.state.iconUp ? (
                    <ExpandLessIcon id="less" />
                  ) : (
                    <ExpandMoreIcon id="more" />
                  )}
                </Grid>
              </Grid>
            </div>
            <br />
          </AccordionSummary>

          {this.props.reservations.map((reservation, index) => (
            <div className={styles.orderCard} key={index}>
              <DataCard
                cardHeader={reservation.nameplate + " " + reservation.modelYear}
              >
                <Grid container>
                  <Grid item className={styles.subGridWrapper}>
                    <Grid container className={styles.centerVertical}>
                      <Grid item className={styles.cardGridItemWrapper}>
                        <KeyValueField
                          verticalAlignment="center"
                          dataKey={
                            this.props.t("CustomerReservations.orderType") + ":"
                          }
                          dataValue={this.translateOrderType(reservation)}
                        ></KeyValueField>
                      </Grid>
                      <Grid item className={styles.cardGridItemWrapper}>
                        <KeyValueField
                          verticalAlignment="center"
                          dataKey={this.props.t("CustomerReservations.id")}
                          dataValue={reservation.id}
                        ></KeyValueField>
                      </Grid>
                      <Grid item className={styles.cardGridItemWrapper}>
                        <KeyValueField
                          verticalAlignment="center"
                          dataKey={
                            this.props.t("CustomerReservations.customerName") +
                            ":"
                          }
                          dataValue={
                            reservation.customerName
                              ? reservation.customerName
                              : null
                          }
                        ></KeyValueField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={styles.subGridWrapper}>
                    <Grid container className={styles.centerVertical}>
                      <Grid item className={styles.cardGridItemWrapper}>
                        <KeyValueField
                          verticalAlignment="center"
                          dataKey={
                            this.props.t("CustomerReservations.date") + ":"
                          }
                          dataValue={UiDateUtils.formattedDate(
                            reservation.date
                          )}
                        ></KeyValueField>
                      </Grid>
                      <Grid item className={styles.cardGridItemWrapper}>
                        <KeyValueField
                          verticalAlignment="center"
                          dataKey={
                            this.props.t("CustomerReservations.status") + ":"
                          }
                          dataValue={
                            reservation.purchaseRequestStatus
                              ? TitleCase(reservation.purchaseRequestStatus)
                              : TitleCase(reservation.businessStatus)
                          }
                        ></KeyValueField>
                      </Grid>
                      <Grid item className={styles.cardGridItemWrapper}>
                        <HasPermissionTo
                          perform={["crcOrderDetails:view"]}
                          permissions={
                            this.props.user.permissions.rolePermissions
                          }
                          render={() => (
                            <EMPButton
                              buttonText={this.props.t(
                                "CustomerReservations.view"
                              )}
                              buttonType="text"
                              id="viewDetailsButton"
                              testId="crcView"
                              buttonElement="button"
                              onClick={() =>
                                this.props.showOrderDetails(
                                  this.props.commonDealerId,
                                  reservation.id,
                                  true
                                )
                              }
                              rightIcon={<MotionChevronRight />}
                            ></EMPButton>
                          )}
                          noRender={() => (
                            <Link
                              id="reservationLink"
                              to={`/dealer/${this.props.commonDealerId}/customer-reservations/order-details/${reservation.id}`}
                              className={styles.tableLink}
                            >
                              {this.props.t("CustomerReservations.view")}
                              <MotionChevronRight style={crStyles.viewIcon} />
                            </Link>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DataCard>
            </div>
          ))}
        </Accordion>
      </div>
    );
  }
}

export default withTranslation("emp")(SearchResultsTable);
