import React from "react";
import {
  InputLabel,
  TextareaAutosize,
  Typography,
  makeStyles,
} from "@material-ui/core";
import textAreaStyles from "./EMPTextAreaField.module.scss";

// available props
// placeholder: string
// label: string,
// testId: string
// disabled: bool
// required: bool,
// hasCharacterCount: bool,
// maxCharacters: number,

const useTextStyles = makeStyles({
  root: {
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
    fontSize: 12,
    color: "#4D5B6D",
    fontWeight: "400",
    lineHeight: "16px",
    marginTop: 8,
  },
  label: {
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
    fontSize: 14,
    color: "#000000",
    fontWeight: "400",
    lineHeight: "20px",
    marginBottom: 8,
  },
  colorError: {
    color: "#BF152C",
  },
});

const EMPTextAreaField = ({
  placeholder,
  required,
  maxCharacters,
  disabled,
  label,
  hasCharacterCount,
  testId,
  onChange,
  minRows,
  value,
}) => {
  const classes = useTextStyles();
  const [text, setText] = React.useState(value || "");

  const handleChange = (event) => {
    setText(event.target.value);
    onChange(event);
  };

  return (
    <>
      <InputLabel
        disableAnimation
        shrink={false}
        required={required}
        classes={{ root: classes.label }}
      >
        {label}
      </InputLabel>
      <TextareaAutosize
        className={textAreaStyles.empTextArea}
        value={value}
        minRows={minRows}
        maxRows={4}
        placeholder={placeholder}
        required={required}
        maxLength={hasCharacterCount ? maxCharacters : null}
        onChange={(e) => handleChange(e)}
        disabled={disabled}
        data-testid={testId}
      />
      {hasCharacterCount && (
        <Typography
          classes={{ root: classes.root, colorError: classes.colorError }}
          color={text.length === maxCharacters ? "error" : "inherit"}
        >{`${text.length}/${maxCharacters} characters`}</Typography>
      )}
    </>
  );
};

export default EMPTextAreaField;
