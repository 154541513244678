import Grid from "@material-ui/core/Grid";
import styles from "./Search.module.scss";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import React from "react";
import { withTranslation } from "react-i18next";
import stylesOverride from "./CrcSearchStylesOvverride";
import { IS_CANADA_MARKET } from "../../utils/EmpUtil";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import { makeStyles } from "@material-ui/core";

const EMPInputStyles = makeStyles({
  root: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 14,
    fontWeight: "400",
    lineHeight: "20px",
    borderRadius: 8,
    outline: "1px solid #6B7786",
    color: "#00142E",
    padding: "8px 16px",
  },
  disabled: {
    borderRadius: 8,
    backgroundColor: "#E6E8EA",
  },
  focused: {
    outline: "2px solid #066FEF",
  },
  colorSecondary: {
    outlineColor: "#008200",
    "&:focused": {
      outlineColor: "#008200",
    },
  },
  error: {
    outlineColor: "#BF152C",
    "&:focused": {
      outlineColor: "#BF152C",
    },
  },
  input: {
    padding: 0,
    height: "auto",
    "&::placeholder": {
      fontFamily: "'FordAntennaRegular', sans-serif",
      fontSize: 14,
      lineHeight: "20px",
    },
  },
});

const useHelperTextStyles = makeStyles({
  root: {
    fontSize: "12px",
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
    lineHeight: "16px",
    letterSpacing: "0px",
    paddingTop: "4px",
    "&.Mui-error": {
      color: "#BF152C",
    },
  },
});

const CustomerEmailOrFullNameAndZipSearch = (props) => {
  const classes = EMPInputStyles();
  const helperTextClasses = useHelperTextStyles();
  return (
    <>
      <Grid container>
        <Grid item>
          <div className={styles.subheaderPadding}>
            <p className={styles.subheader}>
              {props.t("CrcOrderSearch.subNav")}
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md="auto" lg="auto">
          <div className={styles.searchFormWrapper}>
            <div className={styles.searchFormHeader}>
              {props.t("CrcOrderSearch.searchBy")}
            </div>
            <div style={stylesOverride.textInputGridItem}>
              <ValidatorForm
                className={styles.form}
                onSubmit={props.searchOrders}
              >
                <div className={styles.formSection}>
                  <div
                    className={
                      props.orderSearchMethod === "searchByEmail"
                        ? styles.inputLabel
                        : styles.inputLabelInactive
                    }
                  >
                    {props.t("CrcOrderSearch.customerEmailOrOrderId")}
                  </div>
                  <TextValidator
                    onClick={props.toggleRadio}
                    value={props.searchQuery}
                    id="crcEmailInput"
                    name="searchByEmail"
                    autoComplete="off"
                    placeholder="Customer email or order ID"
                    onChange={(e) => props.updateSearchQuery("searchQuery", e)}
                    required
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        root: classes.root,
                        disabled: classes.disabled,
                        focused: classes.focused,
                        colorSecondary: classes.colorSecondary,
                        error: classes.error,
                        input: classes.input,
                      },
                    }}
                    FormHelperTextProps={{
                      classes: {
                        ...helperTextClasses,
                      },
                    }}
                  />
                </div>
                <div className={styles.buttonContainer}>
                  <EMPButton
                    buttonText={props.t("CrcOrderSearch.search")}
                    buttonType="contained"
                    disabled={props.orderSearchMethod !== "searchByEmail"}
                    id="emailSearchBtn"
                    testId="searchBtn"
                    buttonElement="button"
                    submitButton={true}
                  ></EMPButton>
                </div>
              </ValidatorForm>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg="auto">
          <p className={styles.orSection}>{props.t("CrcOrderSearch.or")}</p>
        </Grid>
        <Grid item xs={12} sm={12} md="auto" lg="auto">
          <div className={styles.searchFormWrapper}>
            <div className={styles.searchFormHeader}>
              {props.t("CrcOrderSearch.searchBy")}
            </div>
            <div style={stylesOverride.textInputGridItem}>
              <ValidatorForm
                className={styles.form}
                onSubmit={props.searchOrdersWithLastNameZipCode}
              >
                <div className={styles.formSection}>
                  <div
                    className={
                      props.orderSearchMethod === "searchByNameAndZip"
                        ? styles.inputLabel
                        : styles.inputLabelInactive
                    }
                  >
                    {props.t("CrcOrderSearch.firstAndLastName")}
                  </div>
                  <TextValidator
                    onClick={props.toggleRadio}
                    value={props.searchFullNameQuery}
                    id="crcFullNameInput"
                    name="searchByNameAndZip"
                    autoComplete="off"
                    placeholder="John Smith"
                    onChange={(e) =>
                      props.updateSearchQuery("searchFullNameQuery", e)
                    }
                    validators={[
                      "required",
                      "matchRegexp:^[a-zA-Z \\'\\,\\.\\-]+$",
                    ]}
                    errorMessages={[
                      props.t("CrcOrderSearch.invalid"),
                      props.t("CrcOrderSearch.invalid"),
                    ]}
                    required
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        root: classes.root,
                        disabled: classes.disabled,
                        focused: classes.focused,
                        colorSecondary: classes.colorSecondary,
                        error: classes.error,
                        input: classes.input,
                      },
                    }}
                    FormHelperTextProps={{
                      classes: {
                        ...helperTextClasses,
                      },
                    }}
                  />
                </div>
                <div className={styles.formSection}>
                  <div
                    className={
                      props.orderSearchMethod === "searchByNameAndZip"
                        ? styles.inputLabel
                        : styles.inputLabelInactive
                    }
                  >
                    {!IS_CANADA_MARKET(props.user.market)
                      ? props.t("CrcOrderSearch.zip")
                      : props.t("CrcOrderSearch.postalCode")}
                  </div>
                  {!IS_CANADA_MARKET(props.user.market) ? (
                    <TextValidator
                      onClick={props.toggleRadio}
                      value={props.searchZipQuery}
                      id="crcZipInput"
                      name="searchByNameAndZip"
                      autoComplete="off"
                      placeholder="48326"
                      onChange={(e) =>
                        props.handleZipCodeFormat("searchZipQuery", e)
                      }
                      validators={[
                        "minNumber:0",
                        "isNumber",
                        "matchRegexp:^\\d{5}(?:[- ]?\\d{4})?$",
                      ]}
                      errorMessages={[
                        props.t("CrcOrderSearch.invalid"),
                        props.t("CrcOrderSearch.invalid"),
                        props.t("CrcOrderSearch.invalid"),
                      ]}
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          root: classes.root,
                          disabled: classes.disabled,
                          focused: classes.focused,
                          colorSecondary: classes.colorSecondary,
                          error: classes.error,
                          input: classes.input,
                        },
                      }}
                      FormHelperTextProps={{
                        classes: {
                          ...helperTextClasses,
                        },
                      }}
                    />
                  ) : (
                    <TextValidator
                      onClick={props.toggleRadio}
                      value={props.searchZipQuery}
                      id="crcZipInput"
                      name="searchByNameAndZip"
                      autoComplete="off"
                      placeholder="A1A 1A1"
                      onChange={(e) =>
                        props.handleZipCodeFormat("searchZipQuery", e, true)
                      }
                      validators={[
                        "required",
                        "matchRegexp:^[ABCEGHJ-NPRSTVXYabceghj-nprstvxy]\\d[ABCEGHJ-NPRSTV-Zabceghj-nnprstv-z][ -]?\\d[ABCEGHJ-NPRSTV-Zabcegh-nprstv-z]\\d$",
                      ]}
                      errorMessages={[
                        props.t("CrcOrderSearch.invalid"),
                        props.t("CrcOrderSearch.invalid"),
                        props.t("CrcOrderSearch.invalid"),
                      ]}
                      required
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          root: classes.root,
                          disabled: classes.disabled,
                          focused: classes.focused,
                          colorSecondary: classes.colorSecondary,
                          error: classes.error,
                          input: classes.input,
                        },
                      }}
                      FormHelperTextProps={{
                        classes: {
                          ...helperTextClasses,
                        },
                      }}
                    />
                  )}
                </div>
                <div className={styles.buttonContainer}>
                  <EMPButton
                    buttonText={props.t("CrcOrderSearch.search")}
                    buttonType="contained"
                    disabled={props.orderSearchMethod !== "searchByNameAndZip"}
                    id="fullNameAndZipSearchBtn"
                    testId="fullNameAndZipSearchBtn"
                    buttonElement="button"
                    submitButton={true}
                  ></EMPButton>
                </div>
              </ValidatorForm>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default withTranslation("emp")(CustomerEmailOrFullNameAndZipSearch);
