import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  makeStyles,
  Box,
  CircularProgress,
  InputLabel,
} from "@material-ui/core";
import EMPAccordion from "../../../../shared/components/EMPAccordion/EMPAccordion";
import EMPInteractiveList from "../../../../shared/components/EMPInteractiveList/EMPInteractiveList";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import DeliveryClient from "../../../../clients/DeliveryClient";
import EMPDialog from "../../../../shared/components/EMPDialog/EMPDialog";
import EMPTextAreaField from "../../../../shared/components/EMPTextAreaField/EMPTextAreaField";
import EMPInputField from "../../../../shared/components/EMPInputField/EMPInputField";
import { withTranslation } from "react-i18next";
import * as AnalyticsService from "../../../../shared/services/analytics/AnalyticsService";

const usePageHeaderStyles = makeStyles({
  root: {
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
  },
  h1: {
    fontSize: "24px",
    fontFamily: "'FordAntennaBold', sans-serif",
    color: "#142742",
  },
  subtitle1: {
    fontSize: "16px",
    color: "#000000",
  },
  body1: {
    fontSize: "16px",
    color: "#000000",
  },
  colorError: {
    fontSize: "14px",
    backgroundColor: "rgba(246, 229, 231, 1.00)",
    color: "#000000",
    padding: "8px 16px",
    lineHeight: "20px",
  },
});

const useLabelStyles = makeStyles({
  root: {
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
    fontSize: 14,
    color: "#000000",
    fontWeight: "400",
    lineHeight: "20px",
    marginBottom: 8,
  },
});

const initialFormState = {
  taskType: "",
  taskName: "",
  taskDescription: "",
  taskUrl: "",
};

const Loader = ({ classes, t }) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <CircularProgress size={24} />
      </Grid>
      <Grid item>
        <Typography
          variant="subtitle1"
          classes={{
            root: classes.root,
            subtitle1: classes.subtitle1,
          }}
          align="center"
        >
          {t("DeliveryTasks.loadingTasks")}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ConfirmationDialog = ({
  open,
  type,
  dialogTitle,
  classes,
  handleClose,
  id,
  user,
  t,
}) => {
  const queryClient = useQueryClient();
  const deleteTask = useMutation({
    mutationFn: (id) =>
      DeliveryClient.removePreDeliveryTask(user.token, { id }),
    onSuccess: ({ data }) => {
      handleClose();
      queryClient.setQueryData(["preDeliveryTasks"], data);
      AnalyticsService.trackOnClickEvent(
        `fv:emp:delivery tools:pre-delivery tasks:remove a ${type} popup`,
        `emp:delivery tools:pre-delivery tasks:remove a ${type} popup:content:save`,
        `fv:emp:delivery tools:pre-delivery tasks:remove a ${type} popup:content action`,
        user
      );
    },
  });

  const trackOpen = () => {
    AnalyticsService.trackPageLoadEvent(
      `fv:emp:delivery tools:pre-delivery tasks:remove a ${type} popup`,
      user
    );
  };

  const secondaryAction = () => {
    handleClose();
    AnalyticsService.trackOnClickEvent(
      `fv:emp:delivery tools:pre-delivery tasks:remove a ${type} popup`,
      `emp:delivery tools:pre-delivery tasks:remove a ${type} popup:content:cancel`,
      `fv:emp:delivery tools:pre-delivery tasks:remove a ${type} popup:content action`,
      user
    );
  };

  const checkToDisableRemoveButton = () => {
    return (
      deleteTask.isLoading ||
      deleteTask.isError ||
      !user.permissions?.rolePermissions?.hasOwnProperty(
        "preDeliveryTasksSubmit"
      )
    );
  };

  return (
    <EMPDialog
      open={open}
      dialogTitle={dialogTitle}
      hasCloseIcon
      handleClose={handleClose}
      hasActionButton
      actionButtonText={t("DeliveryTasks.removeTask")}
      onConfirm={() => deleteTask.mutate(id)}
      actionLoading={deleteTask.isLoading}
      disableActionButton={checkToDisableRemoveButton()}
      hasSecondaryActionButton
      secondaryActionButtonText={t("common.cancel")}
      onSecondaryAction={secondaryAction}
      onEnter={trackOpen}
    >
      <Box mb={2}>
        <Typography
          variant="body1"
          classes={{ root: classes.root, body1: classes.body1 }}
        >
          {t("DeliveryTasks.deleteConfirmationText")}
        </Typography>
      </Box>
      {deleteTask.isError && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            color="error"
            classes={{
              root: classes.root,
              subtitle1: classes.subtitle1,
              colorError: classes.colorError,
            }}
          >
            {`${t("common.error")}: ${deleteTask.error.message}`}
            <br />
            {t("DeliveryTasks.taskCouldNotBeRemoved")}
          </Typography>
        </Grid>
      )}
    </EMPDialog>
  );
};

const TasksList = ({
  classes,
  tasks,
  actionOnClick,
  userAddedTaskLimit,
  type,
  primaryActionOnClick,
  secondaryActionOnClick,
  t,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  tasks && tasks.sort((a, b) => b.id - a.id);

  const defaultTasks = tasks && tasks.filter((task) => task.isDefault);
  const userAddedTasks = tasks && tasks.filter((task) => !task.isDefault);

  const descriptions = {
    vehicle: {
      heading: t("DeliveryTasks.vehicleTasksHeading"),
      subHeading: t("DeliveryTasks.vehicleTasksSubHeading"),
    },
    dealer: {
      heading: t("DeliveryTasks.dealerTasksHeading"),
      subHeading: t("DeliveryTasks.dealerTasksSubHeading"),
    },
  };

  return (
    <Box>
      <EMPAccordion
        heading={descriptions[type].heading}
        subHeading={descriptions[type].subHeading}
        expanded={isExpanded}
        onChange={(_, expanded) => setIsExpanded(expanded)}
      >
        <Box mb={2}>
          <Typography variant="body1" classes={classes}>
            You can add up to {userAddedTaskLimit} {type} tasks.
          </Typography>
        </Box>
        <Box>
          <EMPInteractiveList
            testId={`${type}-user-list`}
            hasListAction
            listActionText={`Add new ${type} task`}
            listActionOnClick={actionOnClick}
            items={userAddedTasks.length > 0 && userAddedTasks}
            disableListAction={userAddedTasks.length >= userAddedTaskLimit}
            hasActions
            primaryActionText={t("DeliveryTasks.editTask")}
            primaryActionOnClick={primaryActionOnClick}
            secondaryActionText={t("DeliveryTasks.removeTask")}
            secondaryActionOnClick={secondaryActionOnClick}
          ></EMPInteractiveList>
        </Box>

        {defaultTasks && defaultTasks.length > 0 && (
          <>
            <Box mt={5} mb={2}>
              <Typography variant="body1" classes={classes}>
                {t("DeliveryTasks.requiredTasks")}
              </Typography>
            </Box>
            <Box>
              <EMPInteractiveList
                items={defaultTasks}
                testId={`${type}-default-list`}
                disableAppear
              ></EMPInteractiveList>
            </Box>
          </>
        )}
      </EMPAccordion>
    </Box>
  );
};

const TaskEditor = ({
  user,
  open,
  handleClose,
  classes,
  action,
  type,
  formData,
  t,
}) => {
  const [payload, setPayload] = useState({
    ...initialFormState,
  });

  const labelClasses = useLabelStyles();
  const queryClient = useQueryClient();

  function hasKeys(obj) {
    return Object.keys(obj).length > 0;
  }

  useEffect(() => {
    if (action === "edit" && hasKeys(formData)) {
      setPayload(formData);
    }
  }, [formData, action]);

  const trackOpenAndClearForm = () => {
    AnalyticsService.trackPageLoadEvent(
      `fv:emp:delivery tools:pre-delivery tasks:${action} a ${type} popup`,
      user
    );

    collectFormData("taskType", type);
  };

  const confirmAction = (payload) => {
    action === "edit"
      ? updateTaskMutation.mutate(payload)
      : addTaskMutation.mutate(payload);
    AnalyticsService.trackOnClickEvent(
      `fv:emp:delivery tools:pre-delivery tasks:${action} a ${type} popup`,
      `emp:delivery tools:pre-delivery tasks:${action} a ${type} popup:content:save`,
      `fv:emp:delivery tools:pre-delivery tasks:${action} a ${type} popup:content action`,
      user
    );
  };

  const secondaryAction = () => {
    handleClose();
    AnalyticsService.trackOnClickEvent(
      `fv:emp:delivery tools:pre-delivery tasks:${action} a ${type} popup`,
      `emp:delivery tools:pre-delivery tasks:${action} a ${type} popup:content:cancel`,
      `fv:emp:delivery tools:pre-delivery tasks:${action} a ${type} popup:content action`,
      user
    );
  };

  const addTaskMutation = useMutation({
    mutationFn: (payload) =>
      DeliveryClient.addPreDeliveryTask(user.token, user.dealerId, payload),
    retry: 3,
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["preDeliveryTasks"], data);
      handleClose();
    },
  });

  const updateTaskMutation = useMutation({
    mutationFn: (payload) =>
      DeliveryClient.updatePreDeliveryTask(user.token, user.dealerId, payload),
    retry: 3,
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["preDeliveryTasks"], data);
      handleClose();
    },
  });

  const checkToDisableAddOrEditButton = () => {
    return (
      !hasTaskDetails(payload) ||
      addTaskMutation.isLoading ||
      updateTaskMutation.isLoading ||
      !user.permissions?.rolePermissions?.hasOwnProperty(
        "preDeliveryTasksSubmit"
      )
    );
  };

  const collectFormData = (fieldKey, value) => {
    setPayload((prevState) => {
      return { ...prevState, [fieldKey]: value };
    });
  };

  const isValidUrlOrEmpty = (url) => {
    const urlRegex = new RegExp("^(http|https)://", "i");
    if (url === "") {
      return true;
    } else {
      return urlRegex.test(url);
    }
  };

  const hasTaskDetails = (task) =>
    task.hasOwnProperty("taskName") &&
    task.taskName !== "" &&
    task.hasOwnProperty("taskDescription") &&
    task.taskDescription !== "" &&
    task.hasOwnProperty("taskUrl") &&
    isValidUrlOrEmpty(task.taskUrl);

  return (
    <EMPDialog
      dialogTitle={`${action === "edit" ? "Edit" : "Add new"} ${type} task`}
      open={open}
      hasCloseIcon
      handleClose={handleClose}
      onEnter={trackOpenAndClearForm}
      onExit={() => {
        addTaskMutation.reset();
        updateTaskMutation.reset();
        setPayload(initialFormState);
      }}
      hasActionButton
      actionButtonText="Save"
      onConfirm={() => confirmAction(payload)}
      hasSecondaryActionButton
      secondaryActionButtonText="Cancel"
      onSecondaryAction={() => secondaryAction()}
      actionLoading={addTaskMutation.isLoading || updateTaskMutation.isLoading}
      disableActionButton={checkToDisableAddOrEditButton()}
    >
      <Box mb={2}>
        <Typography
          variant="body1"
          classes={{ root: classes.root, body1: classes.body1 }}
        >
          {`You can ${action} your own ${type} task here.`}
        </Typography>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <Box mb={2}>
            <EMPTextAreaField
              testId="task-name"
              label={t("DeliveryTasks.formLabels.taskName")}
              required
              hasCharacterCount
              maxCharacters={50}
              minRows={2}
              value={payload.taskName}
              onChange={(e) => collectFormData("taskName", e.target.value)}
            ></EMPTextAreaField>
          </Box>
          <Box mb={2}>
            <EMPTextAreaField
              testId="task-description"
              label={t("DeliveryTasks.formLabels.taskDetails")}
              required
              hasCharacterCount
              maxCharacters={50}
              minRows={3}
              value={payload.taskDescription}
              onChange={(e) =>
                collectFormData("taskDescription", e.target.value)
              }
            ></EMPTextAreaField>
          </Box>
          <Box mb={2}>
            <InputLabel disableAnimation shrink={false} classes={labelClasses}>
              {t("DeliveryTasks.formLabels.additionalInfo")}
            </InputLabel>
            <EMPInputField
              testId="task-url"
              value={payload.taskUrl}
              helperText={
                !isValidUrlOrEmpty(payload.taskUrl)
                  ? t("DeliveryTasks.helperText1")
                  : t("DeliveryTasks.helperText2")
              }
              onChange={(e) => collectFormData("taskUrl", e.target.value)}
              error={!isValidUrlOrEmpty(payload.taskUrl)}
            />
          </Box>
        </Grid>
        {addTaskMutation.isError && (
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="error"
              classes={{
                root: classes.root,
                subtitle1: classes.subtitle1,
                colorError: classes.colorError,
              }}
            >
              {`${t("common.error")}: ${addTaskMutation.error.message}`}
              <br />
              {t("DeliveryTasks.taskCouldNotBeSaved")}
            </Typography>
          </Grid>
        )}
        {updateTaskMutation.isError && (
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="error"
              classes={{
                root: classes.root,
                subtitle1: classes.subtitle1,
                colorError: classes.colorError,
              }}
            >
              {`${t("common.error")}: ${updateTaskMutation.error.message}`}
              <br />
              {t("DeliveryTasks.taskCouldNotBeEdited")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </EMPDialog>
  );
};

const PreDeliveryTasks = ({ user, t }) => {
  const [editorOpen, setEditorOpen] = useState({
    open: false,
    action: "",
    type: "",
    payload: {},
  });

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState({
    open: false,
    type: "",
    id: null,
  });

  const classes = usePageHeaderStyles();

  useEffect(() => {
    AnalyticsService.trackPageLoadEvent(
      "fv:emp:delivery tools:pre-delivery tasks",
      user
    );
  }, [user]);

  const { isFetching, isError, data, error } = useQuery({
    queryKey: ["preDeliveryTasks"],
    queryFn: async () => {
      const { data } = await DeliveryClient.getPreDeliveryTasks(
        user.token,
        user.dealerId
      );
      return data;
    },
  });

  const vehicleTasks = data?.vehiclePreDeliveryTasks;
  const dealerTasks = data?.dealerPreDeliveryTasks;

  return (
    <section>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={9} lg={7}>
          <Box mb={5}>
            <Typography
              variant="subtitle1"
              classes={{ root: classes.root, subtitle1: classes.subtitle1 }}
            >
              {t("DeliveryTasks.pageSubHeading")}
            </Typography>
          </Box>
          <Box mb={5}>
            {isFetching ? (
              <Loader classes={classes} t={t} />
            ) : isError ? (
              <Typography
                variant="subtitle1"
                color="error"
                classes={{
                  root: classes.root,
                  subtitle1: classes.subtitle1,
                  colorError: classes.colorError,
                }}
              >
                {`${t("common.error")}: ${error.message}`}
                <br />
                {t("DeliveryTasks.listFailedToLoad")}
              </Typography>
            ) : (
              <>
                <TasksList
                  t={t}
                  type="vehicle"
                  classes={classes}
                  tasks={vehicleTasks}
                  actionOnClick={() =>
                    setEditorOpen({
                      open: true,
                      action: "add",
                      type: "vehicle",
                      payload: {},
                    })
                  }
                  userAddedTaskLimit={10}
                  primaryActionOnClick={(payload) =>
                    setEditorOpen({
                      open: true,
                      action: "edit",
                      type: "vehicle",
                      payload,
                    })
                  }
                  secondaryActionOnClick={(id) =>
                    setConfirmationDialogOpen({
                      open: true,
                      type: "vehicle",
                      id,
                    })
                  }
                ></TasksList>
                <TasksList
                  t={t}
                  type="dealer"
                  classes={classes}
                  tasks={dealerTasks}
                  actionOnClick={() =>
                    setEditorOpen({
                      open: true,
                      action: "add",
                      type: "dealer",
                      payload: {},
                    })
                  }
                  userAddedTaskLimit={10}
                  primaryActionOnClick={(payload) =>
                    setEditorOpen({
                      open: true,
                      action: "edit",
                      type: "dealer",
                      payload,
                    })
                  }
                  secondaryActionOnClick={(id) =>
                    setConfirmationDialogOpen({
                      open: true,
                      type: "dealer",
                      id,
                    })
                  }
                ></TasksList>
                <TaskEditor
                  t={t}
                  user={user}
                  classes={classes}
                  open={editorOpen.open}
                  type={editorOpen.type}
                  action={editorOpen.action}
                  formData={editorOpen.payload}
                  handleClose={() =>
                    setEditorOpen({ open: false, type: "", payload: {} })
                  }
                ></TaskEditor>
                <ConfirmationDialog
                  t={t}
                  user={user}
                  open={confirmationDialogOpen.open}
                  type={confirmationDialogOpen.type}
                  dialogTitle={t("DeliveryTasks.deleteConfirmationHeading")}
                  id={confirmationDialogOpen.id}
                  classes={classes}
                  handleClose={() =>
                    setConfirmationDialogOpen({
                      open: false,
                      type: "",
                      id: null,
                    })
                  }
                />
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm="auto" md={3} lg={5}></Grid>
      </Grid>
    </section>
  );
};

export default withTranslation("emp")(PreDeliveryTasks);
