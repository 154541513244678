import React from "react";
import { FormControl, withStyles } from "@material-ui/core";
import "../../../../styles/sharedStyles.scss";
import styles from "./AcceptAndSubmitDialog.module.scss";
import {
  ACCEPT_AND_SUBMIT,
  ACCEPT_AND_SUBMIT_DROP_OFF_CONFIRM,
  ACCEPT_AND_SUBMIT_PICK_UP_CONFIRM,
  AUCTION_SITE_MAP,
  DECISION,
  STATE_LIST,
  REGEX_LIST,
} from "../../../../common/Constants";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { withTranslation } from "react-i18next";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AcceptAndSubmitDropOffConfirmDialog from "../acceptAndSubmitConfirm/dropOff/AcceptAndSubmitDropOffConfirmDialog";
import AcceptAndSubmitPickUpConfirmDialog from "../acceptAndSubmitConfirm/pickUp/AcceptAndSubmitPickUpConfirmDialog";
import * as DateConstants from "../../../../shared/constants/DateConstants";
import DatePicker from "react-datepicker";
import ValidationRules from "../../../../common/ValidationRules";
import EMPSelectField from "../../../../shared/components/EMPSelectField/EMPSelectField";
import EMPDialog from "../../../../shared/components/EMPDialog/EMPDialog";

const StyledTextValidator = withStyles({
  root: {
    width: "100%",
  },
})(TextValidator);

const StyledRadio = withStyles({
  root: {
    color: "#102b4e",
    "&$checked": {
      color: "#102b4e",
    },
  },
  checked: {},
})(Radio);

const StyledRadioGroup = withStyles({
  root: {
    "flex-direction": "row",
  },
})(RadioGroup);

const PickUpStyledValidator = withStyles({
  root: {
    width: "100%",
  },
})(TextValidator);

class AcceptAndSubmitDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saveButtonEnabled: false,
      data: {
        handoverMethod: undefined,
        contactName: undefined,
        contactEmail: undefined,
        contactPhone: undefined,
        redemptionMileage: undefined,
        dateVehicleSeen: undefined,
        vinBeingPurchased: undefined,
        //Drop Off data
        auctionLocationDropOff: "",
        arriveByDate: undefined,
        // Pickup
        auctionLocationPickup: "",
        email: undefined,
        name: undefined,
        phone: undefined,
        address: undefined,
        city: undefined,
        state: "",
        zip: undefined,
      },
      errors: {},
    };
    this.handlePhoneAutoFormat = this.handlePhoneAutoFormat.bind(this);
  }

  componentDidMount() {
    ValidationRules();
  }

  handleInputChange = (event) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [event.target.name]: false,
      },
    });
    const data = { ...this.state.data };
    data[event.target.name] = event.target.value;

    this.isSaveBtnEnabled(data);
  };

  handleClose = () => {
    this.props.onClose(ACCEPT_AND_SUBMIT);
  };

  handleSave = () => {
    const openKey =
      this.state.data.handoverMethod === "dropOff"
        ? ACCEPT_AND_SUBMIT_DROP_OFF_CONFIRM
        : ACCEPT_AND_SUBMIT_PICK_UP_CONFIRM;
    this.props.onDialogChange(openKey, ACCEPT_AND_SUBMIT, {});
  };

  handlePhoneAutoFormat({ target: { value } }) {
    this.setState((prevState) => {
      const data = Object.assign({}, prevState.data);
      data.contactPhone = this.autoFormatPhone(
        value,
        prevState.data.contactPhone
      );
      return { data };
    });
  }

  autoFormatPhone = (value, previousValue) => {
    // return nothing if no value
    if (!value) return value;

    const currentValue = value.replace(/[^\d]/g, "");
    const phoneLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (phoneLength < 4) {
        return currentValue;
      }

      if (phoneLength < 7) {
        return `(${currentValue.slice(0, 3)})${currentValue.slice(3)}`;
      }
      return `(${currentValue.slice(0, 3)})${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };

  selectDateVehicleSeen = (date) => {
    if (date !== null) {
      const data = { ...this.state.data };
      data["dateVehicleSeen"] = date;

      this.isSaveBtnEnabled(data);
    }
  };

  toggleRadio = (event) => {
    const data = { ...this.state.data };
    data["handoverMethod"] = event.target.value;

    this.isSaveBtnEnabled(data);
  };

  isSaveBtnEnabled = (data) => {
    const commonFields =
      data.contactName &&
      data.contactEmail &&
      data.contactPhone &&
      data.redemptionMileage &&
      data.dateVehicleSeen &&
      data.vinBeingPurchased;

    const pickUpFields =
      data.auctionLocationPickup &&
      data.email &&
      data.name &&
      data.phone &&
      data.address &&
      data.city &&
      data.state &&
      data.zip;

    const dropOffFields = data.auctionLocationDropOff;

    const isEnabled =
      data.handoverMethod === "dropOff"
        ? commonFields && dropOffFields
        : data.handoverMethod === "pickUp"
        ? commonFields && pickUpFields
        : false;

    this.setState({ saveButtonEnabled: isEnabled, data });
  };

  validate = (event, regex) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [event.target.name]: !(
          event.target.value.match(REGEX_LIST[regex]) ||
          event.target.value === ""
        ),
      },
    });
  };

  getBody = () => {
    return (
      <div data-testid="tradeInBodyText">
        {this.props.t("TradeIn.US.dialogs.acceptAndSubmit.body1")}
        <span className={styles.bodyBold}>
          {this.props.t("TradeIn.US.dialogs.acceptAndSubmit.boldedBody")}
        </span>
        {this.props.t("TradeIn.US.dialogs.acceptAndSubmit.body2")}
      </div>
    );
  };

  errorCheck = () => {
    return Object.values(this.state.errors).includes(true);
  };

  getAuctionSiteMenuItems = () => {
    const menuItems = [
      {
        text: this.props.t("GeneralEMP.Select"),
        value: "",
        disabled: true,
      },
    ];
    Object.keys(AUCTION_SITE_MAP).forEach((code) => {
      menuItems.push({
        text: AUCTION_SITE_MAP[code],
        value: AUCTION_SITE_MAP[code],
      });
    });
    return menuItems;
  };

  getStateMenuItems = () => {
    const menuItems = [
      {
        text: this.props.t("GeneralEMP.Select"),
        value: "",
        disabled: true,
      },
    ];
    STATE_LIST.forEach((state) => {
      menuItems.push({
        text: state,
        value: state,
      });
    });
    return menuItems;
  };
  render() {
    return (
      <>
        <EMPDialog
          open={this.props.open}
          handleClose={this.handleClose}
          dialogTitle={this.props.t("TradeIn.US.dialogs.acceptAndSubmit.title")}
          hasCloseIcon
          dialogActionsDataTestId="acceptSubmitDialogActions"
          hasActionButton
          actionButtonText={this.props.t(
            "TradeIn.US.dialogs.acceptAndSubmit.save"
          )}
          onConfirm={this.handleSave}
          disableActionButton={
            !this.state.saveButtonEnabled || this.errorCheck()
          }
          hasSecondaryActionButton
          secondaryActionButtonText={this.props.t(
            "TradeIn.US.dialogs.acceptAndSubmit.goBack"
          )}
          onSecondaryAction={() =>
            this.props.onDialogChange(DECISION, ACCEPT_AND_SUBMIT, {})
          }
        >
          <div data-testid="acceptAndSubmitTradeInDialog">
            <div className={styles.body}>{this.getBody()}</div>

            <ValidatorForm id="valuationForm" onSubmit={this.handleSave}>
              <div className={styles.splitContainer}>
                <div className={styles.leftContainer}>
                  <span className={styles.inputLabel}>
                    {this.props.t(
                      "TradeIn.US.dialogs.acceptAndSubmit.contactName"
                    )}
                  </span>
                  <div className={styles.textBox}>
                    <StyledTextValidator
                      placeholder=""
                      name="contactName"
                      autoComplete="off"
                      id="acceptAndSubmitContactName"
                      value={this.state.data.contactName}
                      disabled={false}
                      variant="outlined"
                      onChange={this.handleInputChange}
                      validators={[
                        "matchRegexp:^[a-zA-Z \\'\\,\\.\\-]+$",
                        `maxLength:${55}`,
                      ]}
                      errorMessages={[
                        this.props.t("TradeIn.invalidInput"),
                        this.props.t("TradeIn.maxLength"),
                      ]}
                    />
                  </div>
                </div>
                <div className={styles.rightContainer}>
                  <span className={styles.inputLabel}>
                    {this.props.t(
                      "TradeIn.US.dialogs.acceptAndSubmit.contactEmail"
                    )}
                  </span>
                  <div className={styles.textBox}>
                    <StyledTextValidator
                      placeholder=""
                      name="contactEmail"
                      autoComplete="off"
                      id="acceptAndSubmitContactEmail"
                      value={this.state.data.contactEmail}
                      disabled={false}
                      variant="outlined"
                      onChange={this.handleInputChange}
                      onBlur={(e) => this.validate(e, "email")}
                      error={this.state.errors.contactEmail}
                      helperText={
                        this.state.errors.contactEmail &&
                        this.props.t("TradeIn.invalidEmail")
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={styles.splitContainer}>
                <div className={styles.leftContainer}>
                  <span className={styles.inputLabel}>
                    {this.props.t(
                      "TradeIn.US.dialogs.acceptAndSubmit.contactPhone"
                    )}
                  </span>
                  <div className={styles.textBox}>
                    <StyledTextValidator
                      placeholder="(123) 456-7890"
                      name="contactPhone"
                      autoComplete="off"
                      id="acceptAndSubmitContactPhone"
                      value={this.state.data.contactPhone}
                      disabled={false}
                      variant="outlined"
                      onChange={this.handlePhoneAutoFormat}
                      onBlur={(e) => this.validate(e, "phone")}
                      error={this.state.errors.contactPhone}
                      helperText={
                        this.state.errors.contactPhone &&
                        this.props.t("TradeIn.invalidInput")
                      }
                    />
                  </div>
                </div>
                <div className={styles.rightContainer}>
                  <span className={styles.inputLabel}>
                    {this.props.t(
                      "TradeIn.US.dialogs.acceptAndSubmit.redemptionMileage"
                    )}
                  </span>
                  <div className={styles.textBox}>
                    <StyledTextValidator
                      placeholder=""
                      name="redemptionMileage"
                      autoComplete="off"
                      id="acceptAndSubmitRedemptionMileage"
                      value={this.state.data.redemptionMileage}
                      disabled={false}
                      variant="outlined"
                      onChange={this.handleInputChange}
                      validators={[
                        "matchRegexp:^[0-9]+$",
                        `maxValue:${999999}`,
                      ]}
                      errorMessages={[
                        this.props.t("TradeIn.invalidInputMileage"),
                        this.props.t("TradeIn.maxValue"),
                      ]}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.splitContainer}>
                <div className={styles.leftContainer}>
                  <span className={styles.inputLabel}>
                    {this.props.t(
                      "TradeIn.US.dialogs.acceptAndSubmit.dateVehicleSeen"
                    )}
                  </span>
                  <div className={styles.popupDatePicker}>
                    <DatePicker
                      selected={this.state.data.dateVehicleSeen}
                      onChange={this.selectDateVehicleSeen}
                      className={styles.datepicker}
                      id="acceptAndSubmitDateVehicleSeen"
                      dateFormat={DateConstants.MMddyyyy}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
                <div className={styles.rightContainer}>
                  <span className={styles.inputLabel}>
                    {this.props.t("TradeIn.US.dialogs.acceptAndSubmit.vin")}
                  </span>
                  <div className={styles.textBox}>
                    <StyledTextValidator
                      placeholder=""
                      name="vinBeingPurchased"
                      autoComplete="off"
                      id="acceptAndSubmitVin"
                      value={this.state.data.vinBeingPurchased}
                      disabled={false}
                      variant="outlined"
                      onChange={this.handleInputChange}
                      onBlur={(e) => this.validate(e, "vinBeingPurchased")}
                      error={this.state.errors.vinBeingPurchased}
                      helperText={
                        this.state.errors.vinBeingPurchased &&
                        this.props.t("TradeIn.invalidVin")
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={styles.radioContainer}>
                <span className={styles.handover}>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndSubmit.handoverMethod"
                  )}
                </span>
                <FormControl component="fieldset">
                  <StyledRadioGroup
                    value={this.state.data.handoverMethod}
                    onChange={this.toggleRadio}
                  >
                    <FormControlLabel
                      value="dropOff"
                      control={<StyledRadio />}
                      data-testid="dropOff"
                      label={this.props.t(
                        "TradeIn.US.dialogs.acceptAndSubmit.dropOff"
                      )}
                    />
                    <FormControlLabel
                      value="pickUp"
                      control={<StyledRadio />}
                      data-testid="pickUp"
                      label={this.props.t(
                        "TradeIn.US.dialogs.acceptAndSubmit.pickUp"
                      )}
                    />
                  </StyledRadioGroup>
                </FormControl>
              </div>

              {this.state.data.handoverMethod === "dropOff" && (
                <div data-testid="dropOffBox">
                  <div className={styles.information}>
                    <span>
                      {this.props.t(
                        "TradeIn.US.dialogs.acceptAndSubmit.dropOffInfo.title"
                      )}
                    </span>
                    <ul>
                      <li>
                        {this.props.t(
                          "TradeIn.US.dialogs.acceptAndSubmit.dropOffInfo.bullet1"
                        )}
                      </li>
                      <li>
                        {this.props.t(
                          "TradeIn.US.dialogs.acceptAndSubmit.dropOffInfo.bullet2"
                        )}
                      </li>
                      <li>
                        {this.props.t(
                          "TradeIn.US.dialogs.acceptAndSubmit.dropOffInfo.bullet3"
                        )}
                      </li>
                    </ul>
                    <span>
                      {this.props.t(
                        "TradeIn.US.dialogs.acceptAndSubmit.dropOffInfo.specify"
                      )}
                    </span>
                  </div>
                  <div className={styles.inputWrapper}>
                    <div className={styles.splitContainer}>
                      <div className={styles.leftContainer}>
                        <span className={styles.inputLabel}>
                          {this.props.t(
                            "TradeIn.US.dialogs.acceptAndSubmit.dropOffInfo.auctionLocation"
                          )}
                        </span>
                        <FormControl fullWidth={true}>
                          <EMPSelectField
                            testId="select-auctionLocationDropOff"
                            name="auctionLocationDropOff"
                            value={this.state.data.auctionLocationDropOff}
                            onChange={this.handleInputChange}
                            menuItems={this.getAuctionSiteMenuItems()}
                          />
                        </FormControl>
                      </div>
                      <div className={styles.rightContainer}>
                        <span className={styles.inputLabel}>
                          {this.props.t(
                            "TradeIn.US.dialogs.acceptAndSubmit.dropOffInfo.arriveByDate"
                          )}
                        </span>
                        <div className={styles.textBox}>
                          <PickUpStyledValidator
                            placeholder=""
                            name="arriveByDate"
                            id="arriveByDate"
                            autoComplete="off"
                            value={this.props.tradeInDetails.arriveByDate}
                            disabled={true}
                            variant="outlined"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.data.handoverMethod === "pickUp" && (
                <div data-testid="pickUpBox">
                  <div className={styles.information}>
                    <span>
                      {this.props.t(
                        "TradeIn.US.dialogs.acceptAndSubmit.pickUpInfo.title"
                      )}
                    </span>
                  </div>
                  <div className={styles.inputWrapper}>
                    <div className={styles.splitContainer}>
                      <div className={styles.leftContainer}>
                        <span className={styles.inputLabel}>
                          {this.props.t(
                            "TradeIn.US.dialogs.acceptAndSubmit.pickUpInfo.auctionHouse"
                          )}
                        </span>
                        <FormControl fullWidth={true}>
                          <EMPSelectField
                            testId="select-auctionLocationPickup"
                            name="auctionLocationPickup"
                            value={this.state.data.auctionLocationPickup}
                            onChange={this.handleInputChange}
                            menuItems={this.getAuctionSiteMenuItems()}
                          />
                        </FormControl>
                      </div>
                      <div className={styles.rightContainer}>
                        <span className={styles.inputLabel}>
                          {this.props.t(
                            "TradeIn.US.dialogs.acceptAndSubmit.pickUpInfo.email"
                          )}
                        </span>
                        <div className={styles.textBox}>
                          <PickUpStyledValidator
                            placeholder=""
                            name="email"
                            id="pickUpEmail"
                            autoComplete="off"
                            value={this.state.data.email}
                            disabled={false}
                            variant="outlined"
                            onChange={this.handleInputChange}
                            onBlur={(e) => this.validate(e, "email")}
                            error={this.state.errors.email}
                            helperText={
                              this.state.errors.email &&
                              this.props.t("TradeIn.invalidEmail")
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.splitContainer}>
                      <div className={styles.leftContainer}>
                        <span className={styles.inputLabel}>
                          {this.props.t(
                            "TradeIn.US.dialogs.acceptAndSubmit.pickUpInfo.name"
                          )}
                        </span>
                        <div className={styles.textBox}>
                          <PickUpStyledValidator
                            placeholder=""
                            name="name"
                            id="pickUpName"
                            autoComplete="off"
                            value={this.state.data.name}
                            disabled={false}
                            variant="outlined"
                            onChange={this.handleInputChange}
                            validators={[
                              "matchRegexp:^[a-zA-Z \\'\\,\\.\\-]+$",
                              `maxLength:${55}`,
                            ]}
                            errorMessages={[
                              this.props.t("TradeIn.invalidInput"),
                              this.props.t("TradeIn.maxLength"),
                            ]}
                          />
                        </div>
                      </div>
                      <div className={styles.rightContainer}>
                        <span className={styles.inputLabel}>
                          {this.props.t(
                            "TradeIn.US.dialogs.acceptAndSubmit.pickUpInfo.phone"
                          )}
                        </span>
                        <div className={styles.textBox}>
                          <PickUpStyledValidator
                            placeholder=""
                            name="phone"
                            id="pickUpPhone"
                            autoComplete="off"
                            value={this.state.data.phone}
                            disabled={false}
                            variant="outlined"
                            onChange={this.handleInputChange}
                            onBlur={(e) => this.validate(e, "phone")}
                            error={this.state.errors.phone}
                            helperText={
                              this.state.errors.phone &&
                              this.props.t("TradeIn.invalidInput")
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.splitContainer}>
                      <div className={styles.leftContainer}>
                        <span className={styles.inputLabel}>
                          {this.props.t(
                            "TradeIn.US.dialogs.acceptAndSubmit.pickUpInfo.address"
                          )}
                        </span>
                        <div className={styles.textBox}>
                          <PickUpStyledValidator
                            placeholder=""
                            name="address"
                            id="pickUpAddress"
                            autoComplete="off"
                            value={this.state.data.address}
                            disabled={false}
                            variant="outlined"
                            onChange={this.handleInputChange}
                            validators={[
                              "matchRegexp:^[a-zA-Z0-9 #'\\,-\\.]+$",
                              `maxLength:${40}`,
                            ]}
                            errorMessages={[
                              this.props.t("TradeIn.invalidInput"),
                              this.props.t("TradeIn.maxLength"),
                            ]}
                          />
                        </div>
                      </div>
                      <div className={styles.rightContainer}>
                        <span className={styles.inputLabel}>
                          {this.props.t(
                            "TradeIn.US.dialogs.acceptAndSubmit.pickUpInfo.city"
                          )}
                        </span>
                        <div className={styles.textBox}>
                          <PickUpStyledValidator
                            placeholder=""
                            name="city"
                            id="pickUpCity"
                            autoComplete="off"
                            value={this.state.data.city}
                            disabled={false}
                            variant="outlined"
                            onChange={this.handleInputChange}
                            validators={[
                              "matchRegexp:^[a-zA-Z '-]+$",
                              `maxLength:${30}`,
                            ]}
                            errorMessages={[
                              this.props.t("TradeIn.invalidInput"),
                              this.props.t("TradeIn.maxLength"),
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.splitContainer}>
                      <div className={styles.leftContainer}>
                        <span className={styles.inputLabel}>
                          {this.props.t(
                            "TradeIn.US.dialogs.acceptAndSubmit.pickUpInfo.state"
                          )}
                        </span>

                        <FormControl fullWidth={true}>
                          <EMPSelectField
                            testId="pickUpState"
                            name="state"
                            value={this.state.data.state}
                            onChange={this.handleInputChange}
                            menuItems={this.getStateMenuItems()}
                          />
                        </FormControl>
                      </div>
                      <div className={styles.rightContainer}>
                        <span className={styles.inputLabel}>
                          {this.props.t(
                            "TradeIn.US.dialogs.acceptAndSubmit.pickUpInfo.zip"
                          )}
                        </span>
                        <div className={styles.textBox}>
                          <PickUpStyledValidator
                            placeholder=""
                            name="zip"
                            id="pickUpZip"
                            autoComplete="off"
                            value={this.state.data.zip}
                            disabled={false}
                            variant="outlined"
                            onChange={this.handleInputChange}
                            onBlur={(e) => this.validate(e, "zip")}
                            error={this.state.errors.zip}
                            helperText={
                              this.state.errors.zip &&
                              this.props.t("TradeIn.invalidInput")
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.splitContainer}>
                      <div className={styles.leftContainer}>
                        <span className={styles.inputLabel}>
                          {this.props.t(
                            "TradeIn.US.dialogs.acceptAndSubmit.dropOffInfo.arriveByDate"
                          )}
                        </span>
                        <div className={styles.textBox}>
                          <PickUpStyledValidator
                            placeholder=""
                            name="arriveByDate"
                            id="arriveByDate"
                            autoComplete="off"
                            value={this.props.tradeInDetails.arriveByDate}
                            disabled={true}
                            variant="outlined"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </ValidatorForm>
          </div>
        </EMPDialog>
        <AcceptAndSubmitDropOffConfirmDialog
          {...this.props}
          data={this.state.data}
          open={this.props.dialogBoxes[ACCEPT_AND_SUBMIT_DROP_OFF_CONFIRM]}
          onClose={this.props.onClose}
          onDialogChange={this.props.onDialogChange}
        />

        <AcceptAndSubmitPickUpConfirmDialog
          {...this.props}
          data={this.state.data}
          open={this.props.dialogBoxes[ACCEPT_AND_SUBMIT_PICK_UP_CONFIRM]}
          onClose={this.props.onClose}
          onDialogChange={this.props.onDialogChange}
        />
      </>
    );
  }
}

export default withTranslation("emp")(AcceptAndSubmitDialog);
