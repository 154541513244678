export default function CapitalizeEachWord(text) {
  if (text) {
    const lowerCasedText = text.toLowerCase();
    const formattedText = lowerCasedText.split("_").join(" ");
    const words = formattedText.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
  } else return "";
}
