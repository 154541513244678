import axios from "axios";
import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";
import { getHeaders } from "../utils/AuthorizationUtil";
import { BASE_URL } from "../utils/EmpUtil";
import { is401 } from "../errors/ErrorConstants";

export default class DealerDeliveryToolClient {
  static baseUrl = BASE_URL;

  static getDeliveryAppointments(token, currentDealerId) {
    const url = `${this.baseUrl}/dealer/deliverytool/appointments?dealerPACode=${currentDealerId}`;
    return axios.get(url, getHeaders(token));
  }

  static getAppointments(token, currentDealerId) {
    const url = `${this.baseUrl}/dealer/deliverytool/appointments?dealerPACode=${currentDealerId}`;

    return axios
      .get(url, getHeaders(token))
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getDeliveryModuleInfo(token, currentDealerId, orderId) {
    const url = `${this.baseUrl}/dealer/delivery-module?dealerPACode=${currentDealerId}&orderId=${orderId}`;

    return axios
      .get(url, getHeaders(token))
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
