import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styles from "./NAOrderDetailsNewVersion.module.scss";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import LowerCaseText from "../../../utils/LowerCaseText";
import { FINANCE_PRODUCT_TYPE } from "../../../common/Constants";
import {
  StyledExpansionPanel,
  StyledExpansionPanelSummary,
} from "../../../shared/ExpansionPanels/ExpansionPanel";
import { formatPriceValue } from "../../../utils/FormatPrice";
import { StyledTableRow } from "../../../shared/Tables/TableRow";
import crStyles from "../crStyles";

class PaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethodExpanded: false,
    };
    this.pricingStackItems = [
      {
        translationKey: "estimateBaseMsrp",
        fieldKey: "baseMSRP",
      },
      {
        translationKey: "totalOptions",
        fieldKey: "totalOptions",
      },
      {
        translationKey: "destinationDeliveryCharges",
        fieldKey: "destinationDeliveryCharges",
      },
      {
        translationKey: "totalBaseMsrp",
        fieldKey: "totalMSRP",
      },
      {
        translationKey: "differenceFromMsrp",
        fieldKey: "dealerAdjustmentPrice",
      },
      {
        translationKey: "dealerSellingPrice",
        fieldKey: "promotionalDealerPrice",
      },
      {
        translationKey: "totalAvailableIncentives",
        fieldKey: "totalIncentivesPrice",
      },
    ];
    this.index = 1;
  }

  flipIcon() {
    this.setState({
      paymentMethodExpanded: !this.state.paymentMethodExpanded,
    });
  }

  render() {
    const financeProductType = LowerCaseText(this.props.financeProductType);
    const paymentMethodTitle =
      financeProductType === FINANCE_PRODUCT_TYPE.FINANCE
        ? this.props.t("OrderDetailsPaymentMethod.finance")
        : financeProductType === FINANCE_PRODUCT_TYPE.FLEX_BUY
        ? this.props.t("OrderDetailsPaymentMethod.flexBuy")
        : financeProductType === FINANCE_PRODUCT_TYPE.LEASE
        ? this.props.t("OrderDetailsPaymentMethod.lease")
        : financeProductType === FINANCE_PRODUCT_TYPE.FORD_OPTIONS
        ? this.props.t("OrderDetailsPaymentMethod.fordOptionsPlan")
        : null;

    const { pricingStack, financeDataQuote, customerReservation } = this.props;

    const orderDeposit =
      customerReservation &&
      customerReservation.orderPaymentInfo &&
      customerReservation.orderPaymentInfo.depositAmount;

    const reservationDeposit =
      customerReservation &&
      customerReservation.reservationPaymentInfo &&
      customerReservation.reservationPaymentInfo.depositAmount;
    return (
      <div>
        <StyledExpansionPanel onChange={() => this.flipIcon()}>
          <StyledExpansionPanelSummary
            style={crStyles.headerBarBg}
            data-testid="paymentMethod"
          >
            <div className={styles.headerBar}>
              <div className={styles.header}>
                <span>{this.props.t("OrderDetailsPaymentMethod.title")}</span>
                {paymentMethodTitle && (
                  <span>{` - ${paymentMethodTitle}`}</span>
                )}
              </div>
              <div>
                {this.state.paymentMethodExpanded ? (
                  <ExpandLessIcon data-testid="lessPaymentMethod" />
                ) : (
                  <ExpandMoreIcon data-testid="morePaymentMethod" />
                )}
              </div>
            </div>
          </StyledExpansionPanelSummary>
          <Grid container className={styles.specsContainer}>
            {pricingStack &&
              this.pricingStackItems &&
              this.pricingStackItems.map((item, index) => (
                <StyledTableRow
                  key={index}
                  text={this.props.t(
                    `OrderDetailsPaymentMethod.${item.translationKey}`
                  )}
                  data={formatPriceValue(pricingStack[item.fieldKey])}
                  index={index + 1}
                  testID={item.translationKey}
                />
              ))}
            {reservationDeposit && (
              <StyledTableRow
                text={this.props.t(
                  "OrderDetailsPaymentMethod.reservationDeposit"
                )}
                data={reservationDeposit}
                index={++this.index}
                testID="reservationDeposit"
              />
            )}
            {orderDeposit && (
              <StyledTableRow
                text={this.props.t("OrderDetailsPaymentMethod.orderDeposit")}
                data={orderDeposit}
                index={++this.index}
                testID="orderDeposit"
              />
            )}
            {financeDataQuote && (
              <>
                <StyledTableRow
                  text={this.props.t(
                    "OrderDetailsPaymentMethod.estimatedTotalAmountFinanced"
                  )}
                  data={formatPriceValue(
                    financeDataQuote.totalAmountFinancedStandard
                  )}
                  index={++this.index}
                  testID="estimatedTotalAmountFinanced"
                />
                <StyledTableRow
                  text={this.props.t(
                    "OrderDetailsPaymentMethod.estimatedMonthlyPayment"
                  )}
                  data={
                    // eslint-disable-next-line no-extra-boolean-cast
                    !!financeDataQuote.monthlyPayment
                      ? formatPriceValue(
                          financeDataQuote.monthlyPayment
                        ).concat(
                          this.props.t("OrderDetailsPaymentMethod.perMonth")
                        )
                      : "--"
                  }
                  index={++this.index}
                  testID="estimatedMonthlyPayment"
                />
                <StyledTableRow
                  text={this.props.t("OrderDetailsPaymentMethod.term")}
                  data={
                    // eslint-disable-next-line no-extra-boolean-cast
                    !!financeDataQuote.termInMonths
                      ? financeDataQuote.termInMonths
                      : "--"
                  }
                  index={++this.index}
                  testID="termInMonths"
                />
                {financeProductType === FINANCE_PRODUCT_TYPE.FORD_OPTIONS && (
                  <>
                    <StyledTableRow
                      text={this.props.t(
                        "OrderDetailsPaymentMethod.annualMiles"
                      )}
                      data={
                        financeDataQuote.mileage !== null
                          ? financeDataQuote.mileage.concat(
                              financeDataQuote.mileageUnit
                            )
                          : "--"
                      }
                      index={++this.index}
                      testID="annualMiles"
                    />
                    <StyledTableRow
                      text={this.props.t(
                        "OrderDetailsPaymentMethod.ballonPayment"
                      )}
                      data={formatPriceValue(financeDataQuote.ballonAmount)}
                      index={++this.index}
                      testID="ballonAmount"
                    />
                  </>
                )}
                {financeProductType === FINANCE_PRODUCT_TYPE.FINANCE && (
                  <StyledTableRow
                    text={this.props.t("OrderDetailsPaymentMethod.apr")}
                    data={
                      financeDataQuote.APR !== null
                        ? `${financeDataQuote.APR}%`
                        : "--"
                    }
                    index={++this.index}
                    testID="apr"
                  />
                )}
              </>
            )}
          </Grid>
        </StyledExpansionPanel>
      </div>
    );
  }
}

export default withTranslation("emp")(PaymentMethod);
