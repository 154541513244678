import React, { createRef, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import styles from "../../DeliveryTools.module.scss";
import {
  Box,
  makeStyles,
  Typography,
  Collapse,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import EMPButton from "../../../../shared/components/EMPButton/EMPButton";
import CheckIcon from "@material-ui/icons/Check";
import iPad22x from "./img/ipad_final2@2x.png";
import iPad2x from "./img/ipad_final@2x.png";
import iPad32x from "./img/ipad_final3@2x.png";
import instruction1 from "./img/instruction1.png";
import instruction2 from "./img/instruction2.png";
import instruction3 from "./img/instruction3.png";
import instruction4 from "./img/instruction4.png";
import instruction5 from "./img/instruction5.png";
import ddtQrLocal from "./img/ddt-qr_local.png";
import ddtQrDev from "./img/ddt-qr_dev.png";
import ddtQrQa from "./img/ddt-qr_qa.png";
import ddtQrStg from "./img/ddt-qr_stg.png";
import ddtQrProd from "./img/ddt-qr_prod.png";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { withTranslation } from "react-i18next";
import * as AnalyticsService from "../../../../shared/services/analytics/AnalyticsService";
import {
  PREPROD_DDT_DOWNLOAD_URL,
  PROD_DDT_DOWNLOAD_URL,
} from "../../../../common/Constants";
import { ENV } from "../../../../utils/EmpUtil";
import { fromEvent } from "rxjs";
import EMPDialog from "../../../../shared/components/EMPDialog/EMPDialog";

const useStyles = makeStyles({
  h1: {
    fontFamily: "'FordAntennaBold', sans-serif",
    fontSize: 32,
    marginBottom: 16,
    color: "#00095b",
  },
  h6: {
    fontFamily: "'FordAntennaBold', sans-serif",
    fontSize: 10,
    color: "#00095b",
  },
  body1: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 16,
    lineHeight: "24px",
    marginBottom: 24,
    color: "#00152e",
  },
  body2: {
    fontFamily: "'FordAntennaBold', sans-serif",
    fontSize: 18,
    color: "#00152e",
  },
  subtitle1: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 11,
    color: "#444444",
  },
  subtitle2: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 14,
    color: "#8c8c8c",
  },
  paperRoot: {
    marginBottom: "16px",
    padding: "16px",
    backgroundColor: "#e4effb",
  },
});

// Analytics Data
const analyticsPageName = "fv:emp:delivery tools";

const Hero = (props) => {
  const { classes, t, buttonRef, onClick } = props;

  const deviceIsIpad = () => {
    if (
      window.navigator.userAgent.match(/iPad/i) ||
      (window.navigator.userAgent.match(/Macintosh/i) &&
        navigator.maxTouchPoints > 1)
    ) {
      return true;
    }
  };

  const qrCodeSelect = (ENV) => {
    const images = {
      local: ddtQrLocal,
      dev: ddtQrDev,
      qa: ddtQrQa,
      stg: ddtQrStg,
      prod: ddtQrProd,
    };

    return images[ENV];
  };

  return (
    <Grid container justifyContent="center" className={styles.hero}>
      <Grid item xs={12} sm={10} md={6} lg={5}>
        <Box textAlign="center" pt={6} pb={4}>
          <Typography variant="h1" classes={{ h1: classes.h1 }}>
            {t("DeliveryTools.title")}
          </Typography>

          <Typography variant="body1" classes={{ body1: classes.body1 }}>
            {t("DeliveryTools.subTitle")}
          </Typography>
          {deviceIsIpad() ? (
            <EMPButton
              ref={buttonRef}
              buttonElement="a"
              buttonHref={
                ENV !== "prod"
                  ? PREPROD_DDT_DOWNLOAD_URL
                  : PROD_DDT_DOWNLOAD_URL
              }
              buttonText={t("DeliveryTools.callToActionDevice")}
              buttonType="contained"
              testId="app-download-button"
            ></EMPButton>
          ) : (
            <Grid container justifyContent="center" alignItems="center">
              <Box
                p={1}
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: 8,
                  boxShadow: "0px 2px 5px -2px #ccc",
                }}
              >
                <Box textAlign="center">
                  <img src={qrCodeSelect(ENV)} width="150px"></img>
                </Box>
                <Box textAlign="center" style={{ width: "200px" }}>
                  <Typography
                    variant="h6"
                    classes={{ root: classes.root, h6: classes.h6 }}
                  >
                    Scan the QR Code from your iPad® to link directly to this
                    page.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}

          <Box mt={1}>
            <EMPButton
              buttonText="Important: View download instructions"
              buttonType="text"
              onClick={onClick}
            ></EMPButton>
          </Box>

          <Box mt={1}>
            <Typography
              variant="subtitle1"
              classes={{ subtitle1: classes.subtitle1 }}
            >
              {t("DeliveryTools.disclaimer1")}
              <br />
              {t("DeliveryTools.disclaimer2")}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const Checklist = (props) => {
  const { classes, text } = props;

  return (
    <Grid container spacing={4} justifyContent="center">
      {text.map((item, index) => {
        return (
          <Grid
            item
            key={index}
            wrap="nowrap"
            xs={12}
            sm={6}
            md={6}
            lg={6}
            container
          >
            <Box mr={1} style={{ color: "#2575fc" }}>
              <CheckIcon color="inherit" />
            </Box>
            <Box>
              <Typography variant="body2" classes={{ body2: classes.body2 }}>
                {item.title}
              </Typography>
              <Typography
                variant="subtitle2"
                classes={{
                  subtitle2: classes.subtitle2,
                }}
              >
                {item.subtitle}
              </Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

const Slideshow = (props) => {
  const { slides } = props;
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={8}>
        <Box>
          <Fade transitionDuration={500} duration={4000}>
            {slides.map((slide, index) => (
              <div key={index}>
                <img style={{ width: "100%" }} src={slide.image} alt="slide" />
              </div>
            ))}
          </Fade>
        </Box>
      </Grid>
    </Grid>
  );
};

const DownloadInstructions = (props) => {
  const [troubleshootVisible, setTroubleshootVisible] = useState(false);
  const { slides, classes, t, downloadTriggered } = props;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography
                variant="subtitle1"
                classes={{ subtitle1: classes.subtitle1 }}
              >
                {t("DeliveryTools.downloadInstructions.downloadNote")}
              </Typography>
              {!troubleshootVisible ? (
                <Typography
                  variant="subtitle1"
                  classes={{ subtitle1: classes.subtitle1 }}
                >
                  {t("DeliveryTools.troubleshootingInstructions.downloadNote1")}
                  <span
                    style={{ color: "#2575fc", cursor: "pointer" }}
                    onClick={() => setTroubleshootVisible(!troubleshootVisible)}
                  >
                    click here
                  </span>{" "}
                  {t("DeliveryTools.troubleshootingInstructions.downloadNote2")}
                </Typography>
              ) : (
                <Typography
                  variant="subtitle1"
                  classes={{ subtitle1: classes.subtitle1 }}
                >
                  <span
                    style={{ color: "#2575fc", cursor: "pointer" }}
                    onClick={() => setTroubleshootVisible(!troubleshootVisible)}
                  >
                    Click here
                  </span>{" "}
                  {t("DeliveryTools.troubleshootingInstructions.goBack")}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
        {!troubleshootVisible ? (
          slides.slice(downloadTriggered ? 1 : 0).map((slide, index) => (
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              key={index}
              spacing={3}
            >
              <Grid item xs={12} sm={5}>
                <img style={{ width: "100%" }} src={slide.image} alt="slide" />
              </Grid>
              <Grid item xs={12} sm={7}>
                <Typography variant="body1" classes={{ body1: classes.body1 }}>
                  {slide.caption}
                </Typography>
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1" classes={{ body1: classes.body1 }}>
                {t("DeliveryTools.troubleshootingInstructions.intro")}
              </Typography>
              <Typography variant="body1" classes={{ body1: classes.body1 }}>
                {t("DeliveryTools.troubleshootingInstructions.1")}
              </Typography>
              <Typography variant="body1" classes={{ body1: classes.body1 }}>
                {t("DeliveryTools.troubleshootingInstructions.2")}
                <b>
                  itms-services://?action=download-manifest&url=https://tistatic.ford.com/manifest.plist
                </b>
              </Typography>
              <Typography variant="body1" classes={{ body1: classes.body1 }}>
                {t("DeliveryTools.troubleshootingInstructions.3")}
                <b>https://ppq.apple.com</b>. Further instructions{" "}
                <a
                  href="https://support.apple.com/en-us/HT204460"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#2575fc", cursor: "pointer" }}
                >
                  here
                </a>
                .
              </Typography>
              <Typography variant="body1" classes={{ body1: classes.body1 }}>
                {t("DeliveryTools.troubleshootingInstructions.4")}
                <b>{t("DeliveryTools.troubleshootingInstructions.url")}</b>
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const DownloadModal = (props) => {
  const { open, handleClose, children, classes, downloadTriggered, onEnter } =
    props;
  const [displayMessage, setDisplayMessage] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const showAndHideMessage = () => {
    setDisplayMessage(true);
    const id = setTimeout(() => setDisplayMessage(false), 20000);
    setTimeoutId(id);
  };

  const cancelTimeout = () => {
    setDisplayMessage(false);
    clearTimeout(timeoutId);
    setTimeoutId(null);
  };

  useEffect(() => {
    if (downloadTriggered) {
      const id = setTimeout(showAndHideMessage, 500);
      setTimeoutId(id);
    } else {
      cancelTimeout();
    }
  }, [downloadTriggered]);

  return (
    <EMPDialog
      open={open}
      handleClose={handleClose}
      dialogTitle="Download instructions"
      hasCloseIcon
      onEnter={onEnter}
    >
      <Collapse in={displayMessage}>
        <Paper
          elevation={0}
          square
          classes={{ root: classes.paperRoot }}
          data-testid="download-message"
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs="auto">
              <CircularProgress size={20} />
            </Grid>
            <Grid item xs={10}>
              <Typography
                variant="h6"
                classes={{
                  h6: classes.h6,
                }}
              >
                The app is downloading to this iPad now. Please read through
                these important instructions before attempting to open the app.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Collapse>
      {children}
    </EMPDialog>
  );
};

const DownloadTool = (props) => {
  const classes = useStyles();
  const { t, user } = props;
  const downloadButtonRef = createRef();
  const [open, setOpen] = useState(false);
  const [downloadTriggered, setDownloadTriggered] = useState(false);

  useEffect(() => {
    // initial page load
    AnalyticsService.trackPageLoadEvent(analyticsPageName, user);

    if (downloadButtonRef.current) {
      // watcher for button clicks
      const clickWatcher = fromEvent(downloadButtonRef["current"], "click");

      clickWatcher.subscribe(() => {
        AnalyticsService.trackOnDownloadEvent(
          analyticsPageName,
          "emp:delivery tools:download:delivery day app",
          "fv:emp:delivery tools:download",
          user
        );

        setDownloadTriggered(true);
        setOpen(true);
      });
    }
  }, []);

  const modalOpenAnalytics = () => {
    AnalyticsService.trackOnClickEvent(
      analyticsPageName,
      "emp:delivery tools:content:view download instructions",
      "fv:emp:delivery tools:content action",
      user
    );
  };

  const checklist = [
    {
      title: t("DeliveryTools.checklist.1.title"),
      subtitle: t("DeliveryTools.checklist.1.subtitle"),
    },
    {
      title: t("DeliveryTools.checklist.2.title"),
      subtitle: t("DeliveryTools.checklist.2.subtitle"),
    },
    {
      title: t("DeliveryTools.checklist.3.title"),
      subtitle: t("DeliveryTools.checklist.3.subtitle"),
    },
    {
      title: t("DeliveryTools.checklist.4.title"),
      subtitle: t("DeliveryTools.checklist.4.subtitle"),
    },
  ];

  const screenshots = [
    { image: iPad2x },
    { image: iPad22x },
    { image: iPad32x },
  ];

  const instructions = [
    {
      image: instruction1,
      caption: t("DeliveryTools.downloadInstructions.1"),
    },
    {
      image: instruction2,
      caption: t("DeliveryTools.downloadInstructions.2"),
    },
    {
      image: instruction3,
      caption: t("DeliveryTools.downloadInstructions.3"),
    },
    { image: instruction4, caption: t("DeliveryTools.downloadInstructions.4") },
    {
      image: instruction5,
      caption: t("DeliveryTools.downloadInstructions.5"),
    },
  ];

  return (
    <section className={styles.pageContainer}>
      <Hero
        classes={classes}
        t={t}
        buttonRef={downloadButtonRef}
        onClick={() => setOpen(true)}
      ></Hero>
      <Grid container justifyContent="center" className={styles.paddedSection}>
        <Grid item xs={12} sm={12} md={12} lg={8} container>
          <Checklist text={checklist} classes={classes} />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" className={styles.paddedSection}>
        <Grid item xs={12} sm={12} md={12} lg={8} container>
          <Slideshow slides={screenshots} />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" className={styles.paddedSection}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <Box mb={2}>
            <Typography
              variant="subtitle2"
              classes={{
                subtitle2: classes.subtitle2,
              }}
            >
              {t("DeliveryTools.metricsExplanation.title")}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography
              variant="subtitle2"
              classes={{
                subtitle2: classes.subtitle2,
              }}
            >
              {t("DeliveryTools.metricsExplanation.1")}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography
              variant="subtitle2"
              classes={{
                subtitle2: classes.subtitle2,
              }}
            >
              {t("DeliveryTools.metricsExplanation.2")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <DownloadModal
        open={open}
        downloadTriggered={downloadTriggered}
        handleClose={() => {
          setOpen(false);
          setDownloadTriggered(false);
        }}
        classes={classes}
        onEnter={() => modalOpenAnalytics()}
      >
        <DownloadInstructions
          classes={classes}
          slides={instructions}
          t={t}
          downloadTriggered={downloadTriggered}
        />
      </DownloadModal>
    </section>
  );
};

export default withTranslation("emp")(DownloadTool);
