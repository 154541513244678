import React from "react";
import styles from "./DataCard.module.scss";
import SharedToolTip from "../../sharedToolTip/SharedToolTip";
import CapitalizeText from "../../../utils/CapitalizeText";

const hasText = (text) => text && text !== "";

const DataCard = (props) => {
  const {
    cardHeader,
    hasToolTip,
    toolTipBody,
    openTestid,
    headerDetail,
    children,
    outlined,
  } = props;
  return (
    <div
      className={
        outlined
          ? `${styles.cardWrapper} ${styles.outlined}`
          : styles.cardWrapper
      }
    >
      <div className={styles.cardHeader}>
        {hasText(cardHeader) && (
          <h6>
            {CapitalizeText(cardHeader)}
            {hasToolTip && hasToolTip === true && (
              <span>
                <SharedToolTip
                  {...props}
                  body={toolTipBody}
                  openTestid={openTestid}
                />
              </span>
            )}
          </h6>
        )}
        <div className={styles.headerDetail}>{headerDetail}</div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default DataCard;
