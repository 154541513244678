const stylesOverride = {
  tableRow: {
    verticalAlign: "bottom",
  },
  partNumber: {
    width: "105px",
  },
  partNumberHeader: {
    width: "105px",
    padding: "5px 1% 5px 0px",
  },
  dealerSellingPrice: {
    paddingRight: "2%",
    width: "100px",
  },
  dealerSellingPriceHeader: {
    paddingRight: "2%",
    width: "100px",
    padding: "5px 1% 5px 0px",
  },
  srp: {
    width: "60px",
  },
  srpHeader: {
    width: "60px",
    padding: "5px 1% 5px 0px",
  },
  accessorySearchHeader: {
    padding: "5px 1% 5px 0px",
  },
  accessoriesHeaderBarBg: {
    marginTop: "5px",
    backgroundColor: "#e5e5e5",
    maxHeight: "40px",
    minHeight: "40px",
    padding: 0,
  },
};

export default stylesOverride;
