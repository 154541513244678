import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import UploadNewTermsAndConditions from "./UploadNewTermsAndConditions";
import TermsAndConditionsHistory from "./TermsAndConditionsHistory";
import { withTranslation } from "react-i18next";
import HasPermissionTo from "../../../common/HasPermissionTo";

class LoadTermsAndConditions extends Component {
  componentDidMount() {
    this.props.loadTermsAndConditionsHistory();
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <HasPermissionTo
            permissions={this.props.user.permissions.rolePermissions}
            perform={["uploadNewTermsConditions:write"]}
            condition={!this.props.isDealer}
            render={() => <UploadNewTermsAndConditions {...this.props} />}
          />
          <TermsAndConditionsHistory {...this.props} />
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation("emp")(LoadTermsAndConditions);
