import React, { Component } from "react";
import fordStyles from "./PurchaseAccessories.module.scss";
import lincolnStyles from "./PurchaseAccessoriesLincoln.module.scss";
import fordSharedStyles from "../../orderDetails/NAOrderDetailsNewVersion.module.scss";
import lincolnSharedStyles from "../../orderDetails/NAOrderDetailsNewVersionLincoln.module.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { withTranslation } from "react-i18next";
import UpperCaseText from "../../../../utils/UpperCaseText";
import {
  StyledTableRow,
  StyledBigTableHeader,
} from "../../../../shared/Tables/TableRow";
import {
  StyledExpansionPanel,
  StyledExpansionPanelSummary,
} from "../../../../shared/ExpansionPanels/ExpansionPanel";
import { formatPriceValue } from "../../../../utils/FormatPrice";
import crStyles from "../../crStyles";
import {
  IS_FORD_BRAND,
  NOTNULL_OR_NOTUNDEFINED,
} from "../../../../utils/EmpUtil";
import EMPEmptyState from "../../../../shared/components/EMPEmptyState/EMPEmptyState";

class Options extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: true,
    };
  }

  expandCollapseAccordion = () =>
    this.setState((prevState) => ({
      expand: !prevState.expand,
    }));

  getValue(item, value) {
    return (
      NOTNULL_OR_NOTUNDEFINED(item) && (value ? formatPriceValue(item) : item)
    );
  }

  render() {
    const { name, total, options, overallTotal } = this.props;

    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    const sharedStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordSharedStyles
        : lincolnSharedStyles;

    return (
      <div>
        <StyledExpansionPanel
          defaultExpanded={true}
          onChange={this.expandCollapseAccordion}
        >
          <StyledExpansionPanelSummary style={crStyles.headerBarNeutral}>
            <div className={sharedStyles.headerBar}>
              <div className={sharedStyles.header}>
                {UpperCaseText(
                  this.props.t(`PurchaseDetails.purchaseAccessories.${name}`)
                )}
              </div>
              <div className={styles.acceptRejectDiv}>
                {options && options.length > 0 && this.getValue(total, true)}
                {this.state.expand ? (
                  <ExpandLessIcon data-testid="less" />
                ) : (
                  <ExpandMoreIcon data-testid="more" />
                )}
              </div>
            </div>
          </StyledExpansionPanelSummary>

          {options && options.length > 0 ? (
            options.map((option, index) => {
              return (
                <StyledTableRow
                  isLincolnBrand={
                    this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                  }
                  text={this.getValue(
                    option.mappedPartId
                      ? `${option.description} (${option.mappedPartId})`
                      : option.description
                  )}
                  data={
                    option.price
                      ? this.getValue(option.price, true)
                      : this.props.t(
                          "PurchaseDetails.purchaseAccessories.included"
                        )
                  }
                  indent={true}
                  index={index}
                  key={index}
                />
              );
            })
          ) : (
            <StyledTableRow
              isLincolnBrand={
                this.props.user && !IS_FORD_BRAND(this.props.user.brand)
              }
              text={this.props.t(
                `PurchaseDetails.purchaseAccessories.none${name}`
              )}
              indent={true}
              index={0}
            />
          )}
        </StyledExpansionPanel>
        {name === "customer" && (
          <StyledBigTableHeader
            isLincolnBrand={
              this.props.user && !IS_FORD_BRAND(this.props.user.brand)
            }
            text={UpperCaseText(
              this.props.t(
                "PurchaseDetails.purchaseAccessories.accessoriesTotal"
              )
            )}
            data={
              overallTotal !== null ? (
                this.getValue(overallTotal, true)
              ) : (
                <EMPEmptyState />
              )
            }
            indent={true}
          />
        )}
      </div>
    );
  }
}

export default withTranslation("emp")(Options);
