import React, { Component } from "react";
import { StyledBasicExpansionPanel } from "../../../../shared/ExpansionPanels/ExpansionPanel";
import fordStyles from "./DealerNotes.module.scss";
import lincolnStyles from "./DealerNotes-Lincoln.module.scss";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { withTranslation } from "react-i18next";
import {
  AccordionSummary,
  Button,
  Popper,
  withStyles,
  Grid,
} from "@material-ui/core";
import DealerNotesDialogEdit from "./DealerNotesDialogEdit";
import DealerNotesDialogDelete from "./DealerNotesDialogDelete";
import { IS_FORD_BRAND } from "../../../../utils/EmpUtil";
import StyledChevronRightIcon from "../../../../shared/Icons/StyledChevronRightIcon";
import StyledClearIcon from "../../../../shared/Icons/StyledClearIcon";
import DealerNotesClient from "../../../../clients/DealerNotesClient";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import ConfirmDialog from "../../../../shared/confirmDialog/ConfirmDialog";
import DealerNote from "./DealerNote";
import { generateNoteText } from "./DealerNoteUtil";
import DealerNotesViewAllDialog from "./DealerNotesViewAllDialog";
import EMPButton from "../../../../shared/components/EMPButton/EMPButton";
import MotionChevronRight from "../../../../shared/Icons/MotionChevronRight";
import EMPTextAreaField from "../../../../shared/components/EMPTextAreaField/EMPTextAreaField";

let isFordBrand = true;
let dealerNotesStyles = fordStyles;

const StyledAccordionSummary = withStyles({
  root: {
    fontFamily: "FordAntennaRegular",
    fontSize: 24,
    paddingLeft: 24,
    paddingRight: 24,
    color: "#1C1C1E",
  },
  content: {
    marginTop: 16,
    marginBottom: 16,
  },
})(AccordionSummary);

class DealerNotes extends Component {
  constructor(props) {
    super(props);
    this.MAX_CHARACTERS = 250;
    this.PAGE_SIZE = 8;
    this.state = {
      expandDealerNotes: false,
      openEditDialog: false,
      openDeleteDialog: false,
      openViewAllDialog: false,
      newNoteContent: "",
      activeNote: null,
      notes: [],
      notesForViewAll: [],
      pagination: { currentPage: 0, pageSize: 0, totalPages: 0, totalNotes: 0 },
      paginationForViewAll: {
        currentPage: 0,
        pageSize: 0,
        totalPages: 0,
        totalNotes: 0,
      },
      popperAnchorEl: null,
      popperOpen: false,
      dealerNotesRetrieveError: false,
      dealerNotesSaveError: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ expandDealerNotes: this.props.expandAccordian });
    this.getDealerNotes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordion !== this.props.expandAccordion) {
      this.setState({ expandDealerNotes: this.props.expandAccordion });
    }
  }

  handleChange = (event) => {
    this.setState({ newNoteContent: event.target.value });
  };

  handleUpdate = (content, id) => {
    this.updateDealerNotes(content, id);
    this.toggleEditDialog();
  };

  handleOverflowClick = (note, event) => {
    const { currentTarget } = event;
    this.setState({
      activeNote: note,
      popperAnchorEl: currentTarget,
      popperOpen: !this.state.popperOpen,
    });
  };

  handleSetDealerNotes = (notes, pagination) => {
    this.setState({ notes, pagination });
  };

  handleSetDealerNotesForViewAll = (notesForViewAll, paginationForViewAll) => {
    this.setState({ notesForViewAll, paginationForViewAll });
  };

  handleSetErrorStatus = (bool) => {
    this.setState({ dealerNotesRetrieveError: bool });
  };

  toggleDeleteDialog = () => {
    if (this.state.openDeleteDialog) this.setState({ activeNote: null });
    this.setState((prevState) => ({
      popperOpen: false,
      openDeleteDialog: !prevState.openDeleteDialog,
    }));
  };

  toggleEditDialog = () => {
    if (this.state.openEditDialog) this.setState({ activeNote: null });
    this.setState((prevState) => ({
      popperOpen: false,
      openEditDialog: !prevState.openEditDialog,
    }));
  };

  toggleViewAllDialog = () => {
    if (!this.state.openViewAllDialog) {
      const pageNumber = 0;
      this.getDealerNotes(pageNumber, this.PAGE_SIZE);
    }
    this.setState((prevState) => ({
      openViewAllDialog: !prevState.openViewAllDialog,
    }));
  };

  expandDealerNotes = () => {
    this.setState({ expandDealerNotes: !this.state.expandDealerNotes });
  };

  updateStyles = (isFordBrand = true) => {
    dealerNotesStyles = isFordBrand ? fordStyles : lincolnStyles;
  };

  addDealerNotes = () => {
    if (!this.state.newNoteContent) return;
    const requestBody = {
      dealerNotes: this.state.newNoteContent,
      orderId: this.props.orderId,
      userId: this.props.user.userId,
    };
    DealerNotesClient.saveDealerNotes(
      this.props.user.token,
      this.props.user.getEncodedCommonId(),
      requestBody
    )
      .then((result) => {
        const { dealerNotes } = result;
        this.setState({ newNoteContent: "", notes: dealerNotes });
      })
      .catch((error) => {
        this.setState({ dealerNotesSaveError: true });
        return Promise.reject(error);
      });
  };

  updateDealerNotes = (dealerNotes, id) => {
    this.setState({ loading: true });
    const requestBody = {
      id: id,
      dealerNotes: dealerNotes,
    };

    DealerNotesClient.updateDealerNotes(this.props.user.token, requestBody)
      .then((result) => {
        const notesCopy = JSON.parse(JSON.stringify(this.state.notes));
        const notesForViewAllCopy = JSON.parse(
          JSON.stringify(this.state.notesForViewAll)
        );

        this.updateNotesList(notesCopy, result);
        this.updateNotesList(notesForViewAllCopy, result);

        this.setState({
          notesForViewAll: notesForViewAllCopy,
          notes: notesCopy,
          newNoteContent: "",
        });
      })
      .catch();

    this.setState({ loading: false });
  };

  updateNotesList = (notesCopy, result) => {
    notesCopy.forEach((note, index) => {
      if (note.id === result.id) notesCopy[index] = result;

      if (result.replyTo === note.id) {
        note.replies.forEach((reply, idx) => {
          if (reply.id === result.id) note.replies[idx] = result;
        });
      }
    });
  };

  getDealerNotes = (pageNumber, pageSize) => {
    DealerNotesClient.getDealerNotes(
      this.props.user.token,
      this.props.orderId,
      pageNumber,
      pageSize
    )
      .then((result) => {
        if (result) {
          if (pageSize) {
            const {
              dealerNotes: notesForViewAll,
              pagination: paginationForViewAll,
            } = result;
            this.setState({ notesForViewAll, paginationForViewAll });
          } else {
            const { dealerNotes: notes, pagination } = result;
            this.setState({ notes, pagination });
          }
        }
      })
      .catch((error) => {
        this.setState({ dealerNotesRetrieveError: true });
        return Promise.reject(error);
      });
  };

  isLastNoteOnPage = (dealerNoteId, noteList) => {
    return (
      noteList[noteList.length - 1].id === dealerNoteId &&
      noteList.length % this.PAGE_SIZE === 1
    );
  };

  deleteDealerNotes = (dealerNotesId) => {
    this.setState({ loading: true });
    DealerNotesClient.deleteDealerNotes(this.props.user.token, dealerNotesId)
      .then(() => {
        this.toggleDeleteDialog();
        this.getDealerNotes();
        if (this.state.openViewAllDialog) {
          const pageNumber = this.state.paginationForViewAll.currentPage;
          const isLastNote = this.isLastNoteOnPage(
            dealerNotesId,
            this.state.notesForViewAll
          );
          if (this.state.paginationForViewAll.totalNotes === 1)
            this.setState({ openViewAllDialog: false });
          else if (isLastNote)
            this.getDealerNotes(pageNumber - 1, this.PAGE_SIZE);
          else this.getDealerNotes(pageNumber, this.PAGE_SIZE);
        }
        this.setState({ loading: false });
      })
      .catch();
  };

  render() {
    const styles = IS_FORD_BRAND(this.props.user.brand)
      ? fordStyles
      : lincolnStyles;

    if (this.props.user && this.props.user.brand) {
      isFordBrand = IS_FORD_BRAND(this.props.user.brand);
      this.updateStyles(isFordBrand);
    }

    const confirmDialogProps = {
      title: this.props.t("DealerNotes.saveDealerNotesErrorTitle"),
      bodyText: this.props.t("DealerNotes.saveDealerNotesErrorText"),
    };

    const popperId = this.state.popperOpen ? "simple-popper" : undefined;

    return (
      <StyledBasicExpansionPanel
        onChange={() => this.expandDealerNotes()}
        expanded={this.state.expandDealerNotes}
      >
        <StyledAccordionSummary
          id="exp"
          expandIcon={<ExpandLessIcon />}
          IconButtonProps={{ disableRipple: true }}
        >
          {this.props.t("DealerNotes.dealerNotes")}
        </StyledAccordionSummary>
        <div className={dealerNotesStyles.contentWrapper}>
          {!this.state.dealerNotesRetrieveError ? (
            <div className={dealerNotesStyles.dealerNotesContainer}>
              <HasPermissionTo
                perform={["dealerNotesSubmit"]}
                permissions={this.props.user.permissions.rolePermissions}
                render={() => {
                  return (
                    <Grid container>
                      <Grid item xs={12} sm={10} md={8}>
                        <EMPTextAreaField
                          value={this.state.newNoteContent}
                          hasCharacterCount
                          onChange={this.handleChange}
                          minRows={4}
                          placeholder="Add note"
                          maxCharacters={this.MAX_CHARACTERS}
                          testId="add-note-field"
                        ></EMPTextAreaField>
                      </Grid>
                      <Grid item xs={12} sm={10} md={8}>
                        <div className={dealerNotesStyles.saveButtonRow}>
                          <EMPButton
                            onClick={this.addDealerNotes}
                            buttonText={this.props.t("DealerNotes.saveNote")}
                            buttonType="contained"
                            disabled={this.state.newNoteContent.length === 0}
                          ></EMPButton>
                        </div>
                      </Grid>
                    </Grid>
                  );
                }}
              />
              <p className={dealerNotesStyles.noteCountText}>
                {this.state.notes &&
                  generateNoteText(
                    this.state.pagination.totalNotes,
                    this.props
                  )}
              </p>
              {this.state.notes.map((note) => (
                <DealerNote
                  key={note.id}
                  note={note}
                  replies={note.replies}
                  isFordBrand={isFordBrand}
                  popperId={popperId}
                  toggleOverflowMenu={this.handleOverflowClick}
                  setDealerNotes={this.handleSetDealerNotes}
                  setErrorStatus={this.handleSetErrorStatus}
                  {...this.props}
                />
              ))}
              {this.state.activeNote && (
                <>
                  <div
                    className={dealerNotesStyles.overflowOverlay}
                    style={{
                      display: this.state.popperOpen ? "block" : "none",
                      zIndex: 2000,
                    }}
                    onClick={() =>
                      this.setState({
                        popperOpen: !this.state.popperOpen,
                      })
                    }
                  />
                  <Popper
                    id={popperId}
                    open={this.state.popperOpen}
                    anchorEl={this.state.popperAnchorEl}
                    placement="left"
                    style={{ zIndex: 2001 }}
                  >
                    <div className={dealerNotesStyles.overflowContainer}>
                      <Button
                        className={dealerNotesStyles.noteEditButton}
                        onClick={this.toggleEditDialog}
                        data-testid="dealerNoteEdit"
                      >
                        {this.props.t("DealerNotes.edit")}
                        {!isFordBrand && (
                          <span className={dealerNotesStyles.chevronOffset}>
                            <StyledChevronRightIcon
                              size="23px"
                              color="#324047"
                            />
                          </span>
                        )}
                      </Button>
                      <Button
                        className={dealerNotesStyles.noteDeleteButton}
                        onClick={this.toggleDeleteDialog}
                        data-testid="dealerNoteDelete"
                      >
                        {this.props.t("DealerNotes.delete")}
                        {!isFordBrand && (
                          <span className={dealerNotesStyles.clearIconOffset}>
                            <StyledClearIcon size="20px" color="#324047" />
                          </span>
                        )}
                      </Button>
                      <div className={dealerNotesStyles.overflowArrow} />
                    </div>
                  </Popper>
                  <DealerNotesDialogEdit
                    props={this.props}
                    content={this.state.activeNote.dealerNotes}
                    id={this.state.activeNote.id}
                    open={this.state.openEditDialog}
                    closeDialog={this.toggleEditDialog}
                    handleUpdate={this.handleUpdate}
                    loading={this.state.loading}
                    maxCharacters={this.MAX_CHARACTERS}
                  />
                  <DealerNotesDialogDelete
                    props={this.props}
                    note={this.state.activeNote}
                    open={this.state.openDeleteDialog}
                    closeDialog={this.toggleDeleteDialog}
                    deleteDealerNotes={this.deleteDealerNotes}
                    loading={this.state.loading}
                  />
                </>
              )}
              <ConfirmDialog
                open={this.state.dealerNotesSaveError}
                showSettings={false}
                showWarningIcon={true}
                hideCancelAndConfirm={true}
                dialogProps={confirmDialogProps}
                onCancel={() => this.setState({ dealerNotesSaveError: false })}
                boldTitle={true}
                {...this.props}
              />
              {this.state.openViewAllDialog ? (
                <DealerNotesViewAllDialog
                  openViewAllDialog={this.state.openViewAllDialog}
                  closeDialog={this.toggleViewAllDialog}
                  isFordBrand={isFordBrand}
                  toggleOverflowMenu={this.handleOverflowClick}
                  setDealerNotes={this.handleSetDealerNotes}
                  setDealerNotesForViewAll={this.handleSetDealerNotesForViewAll}
                  setErrorStatus={this.handleSetErrorStatus}
                  popperOpen={this.state.popperOpen}
                  dealerNotesRetrieveError={this.state.dealerNotesRetrieveError}
                  notes={this.state.notesForViewAll}
                  pagination={this.state.paginationForViewAll}
                  getDealerNotes={this.getDealerNotes}
                  pageSize={this.PAGE_SIZE}
                  {...this.props}
                />
              ) : null}
            </div>
          ) : (
            <p className={styles.labelError}>
              {" "}
              {this.props.t("DealerNotes.retrieveDealerNotesErrorText")}
            </p>
          )}
        </div>
        {this.state.pagination.totalNotes > 3 && (
          <div className={dealerNotesStyles.viewAllButtonWrapper}>
            <EMPButton
              onClick={this.toggleViewAllDialog}
              buttonText={this.props.t("DealerNotes.viewAll")}
              buttonType="text"
              rightIcon={<MotionChevronRight />}
            ></EMPButton>
          </div>
        )}
      </StyledBasicExpansionPanel>
    );
  }
}

export default withTranslation("emp")(DealerNotes);
