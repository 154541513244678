import React from "react";
import { IconButton, Tooltip, makeStyles } from "@material-ui/core";
import MotionDash from "../../Icons/MotionDash";

// no props

const useStyles = makeStyles({
  buttonRoot: {
    display: "inline",
    padding: 0,
    "&:hover": {
      background: "none",
    },
  },
  label: {
    fontSize: 0,
  },
  popper: {
    opacity: 1,
    pointerEvents: "auto",
  },
  tooltip: {
    backgroundColor: "#ffffff",
    color: "#000000",
    boxShadow: "1px 1px 9px 0 rgba(0, 0, 0, 0.27)",
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
    top: "10px",
  },
  arrow: {
    color: "#ffffff",
  },
});

const EMPEmptyState = () => {
  const classes = useStyles();
  return (
    <div style={{ display: "inline-block" }}>
      <Tooltip
        arrow
        placement="top"
        title="No data available"
        enterTouchDelay={0}
        leaveTouchDelay={3000}
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      >
        <IconButton
          disableRipple
          classes={{ root: classes.buttonRoot, label: classes.label }}
          color="inherit"
          data-testid="empty-state-icon"
        >
          <MotionDash />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default EMPEmptyState;
