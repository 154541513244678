import React from "react";
import "../../../../../styles/sharedStyles.scss";
import styles from "./AcceptAndSubmitPickUpConfirmDialog.module.scss";
import { withTranslation } from "react-i18next";
import {
  ACCEPT_AND_SUBMIT,
  ACCEPT_AND_SUBMIT_PICK_UP_CONFIRM,
  DEALER_ACTIONS,
} from "../../../../../common/Constants";
import CustomerReservationsClient from "../../../../../clients/CustomerReservationsClient";
import UiDateUtils from "../../../../../utils/UiDateUtils";
import ErrorProcessingDialog from "../../../../../errors/ErrorProcessingDialog";
import { IS_CANADA_MARKET } from "../../../../../utils/EmpUtil";
import EMPDialog from "../../../../../shared/components/EMPDialog/EMPDialog";

class AcceptAndSubmitPickUpConfirmDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openErrorDialog: false,
    };
  }

  handleClose = () => {
    this.props.onClose(ACCEPT_AND_SUBMIT_PICK_UP_CONFIRM);
  };

  handleConfirm = () => {
    this.submitToUVIS()
      .then(() => {
        this.handleClose();
      })
      .catch();
  };

  toggleErrorDialog = () => {
    this.setState((prevState) => ({
      openErrorDialog: !prevState.openErrorDialog,
    }));
  };

  formatPhoneNumber = (phone) => {
    return phone.replace(/\D/g, "");
  };

  async submitToUVIS() {
    const acceptAndSubmitRequest = {
      reservationId: this.props.tradeInDetails.orderId,
      dealerAction: DEALER_ACTIONS.TRADE_IN_ACCEPT_AUCTION,
      acceptedDate: this.props.data.dateVehicleSeen,
      additionalVIN: this.props.data.vinBeingPurchased,
      additionalProperties: {
        TRADEIN_VALUE_STATUS: this.props.tradeInDetails.tradeInValueStatus,
        AUCTION_DROP_OFF_DATE: this.props.tradeInDetails.auctionDropOffDate,
        TRADEIN_APPRAISAL_ID: this.props.tradeInDetails.appraisalId,
      },
    };

    this.props.hideOrShow(true);
    CustomerReservationsClient.sendToUVIS({
      token: this.props.user.token,
      vin: this.props.tradeInDetails.vin,
      customerCode: this.props.tradeInDetails.customerCode,
      categoryCode: this.props.tradeInDetails.categoryCode,
      contactName: this.props.data.contactName,
      contactEmail: this.props.data.contactEmail,
      contactPhoneNumber: this.formatPhoneNumber(this.props.data.contactPhone),
      leadIdentifier: this.props.tradeInDetails.appraisalId,
      gdiaMarketPrice: this.props.tradeInDetails.modelMarketPrice,
      offerRedemptionMileage: this.props.data.redemptionMileage,
      modelYear: this.props.tradeInDetails.modelYear,
      make: this.props.tradeInDetails.vehicleMake,
      model: this.props.tradeInDetails.vehicleModel,
      tradeInAppraisal: this.props.tradeInDetails.appraisalAmount,
      dropOffPickupInd: "N",
      pickupLocationName: "Ford ARS DTP Trade-In",
      pickupContactName: this.props.data.name,
      pickupEmail: this.props.data.email,
      pickupPhone: this.formatPhoneNumber(this.props.data.phone),
      pickupAddress: this.props.data.address,
      pickupCity: this.props.data.city,
      pickupState: this.props.data.state,
      pickupZip: this.props.data.zip,
      pickupCountry: "USA",
      tradeInConditionRating: this.props.tradeInDetails.conditionRating,
      requiredByArrivalDate: this.props.tradeInDetails.arriveByDate,
      dropOffPickUpLocation: this.props.data.auctionLocationPickup,
    })
      .then(() => {
        this.props.onSubmit(acceptAndSubmitRequest);
      })
      .catch(() => {
        this.toggleErrorDialog();
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  }

  render() {
    return (
      <>
        <EMPDialog
          open={this.props.open}
          onClose={this.handleClose}
          dialogTitle={this.props.t(
            "TradeIn.US.dialogs.acceptAndSubmitConfirm.title"
          )}
          hasCloseIcon
          dialogActionsDataTestId="pickUpConfirmDialogActions"
          hasActionButton
          actionButtonText={this.props.t(
            "TradeIn.US.dialogs.acceptAndSubmitConfirm.confirm"
          )}
          onConfirm={this.handleConfirm}
          disableActionButton={
            !this.props.user.permissions.rolePermissions.hasOwnProperty(
              "tradeInSubmit"
            )
          }
          hasSecondaryActionButton
          secondaryActionButtonText={this.props.t(
            "TradeIn.US.dialogs.acceptAndSubmitConfirm.edit"
          )}
          onSecondaryAction={() =>
            this.props.onDialogChange(
              ACCEPT_AND_SUBMIT,
              ACCEPT_AND_SUBMIT_PICK_UP_CONFIRM,
              {}
            )
          }
        >
          <div data-testid="acceptAndSubmitPickupConfirmDialog">
            <div className={styles.subTitle}>
              {this.props.t(
                "TradeIn.US.dialogs.acceptAndSubmitConfirm.pickUp.subTitle"
              )}
            </div>

            <div className={styles.splitContainer}>
              <div className={styles.leftContainer}>
                <span className={styles.inputLabel}>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndSubmit.contactName"
                  )}
                </span>
                <div>
                  <span className={styles.value}>
                    {this.props.data.contactName}
                  </span>
                </div>
              </div>
              <div className={styles.rightContainer}>
                <span className={styles.inputLabel}>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndSubmit.contactEmail"
                  )}
                </span>
                <div>
                  <span className={styles.value}>
                    {this.props.data.contactEmail}
                  </span>
                </div>
              </div>
            </div>

            <div className={styles.splitContainer}>
              <div className={styles.leftContainer}>
                <div className={styles.textBoxSplitLeft}>
                  <span className={styles.inputLabel}>
                    {this.props.t(
                      "TradeIn.US.dialogs.acceptAndSubmit.contactPhone"
                    )}
                  </span>
                  <div>
                    <span className={styles.value}>
                      {this.props.data.contactPhone}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.rightContainer}>
                <span className={styles.inputLabel}>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndSubmit.redemptionMileage"
                  )}
                </span>
                <div>
                  <span className={styles.value}>
                    {this.props.data.redemptionMileage}
                  </span>
                </div>
              </div>
            </div>

            <div className={styles.splitContainer}>
              <div className={styles.leftContainer}>
                <span className={styles.inputLabel}>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndSubmit.dateVehicleSeen"
                  )}
                </span>
                <div>
                  <span className={styles.value}>
                    {UiDateUtils.formatDateMmDdYyyy(
                      this.props.data.dateVehicleSeen
                    )}
                  </span>
                </div>
              </div>
              <div className={styles.rightContainer}>
                <span className={styles.inputLabel}>
                  {this.props.t("TradeIn.US.dialogs.acceptAndSubmit.vin")}
                </span>
                <div>
                  <span className={styles.value}>
                    {this.props.data.vinBeingPurchased}
                  </span>
                </div>
              </div>
            </div>

            <div className={styles.decision}>
              {this.props.t(
                "TradeIn.US.dialogs.acceptAndSubmit.handoverMethod"
              )}
              : {this.props.t("TradeIn.US.dialogs.acceptAndSubmit.pickUp")}
            </div>

            <div className={styles.information}>
              <div>
                {this.props.t(
                  "TradeIn.US.dialogs.acceptAndSubmitConfirm.pickUp.body"
                )}
              </div>
              <ul>
                <li>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndSubmitConfirm.pickUp.bullet1"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndSubmitConfirm.pickUp.bullet2"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndSubmitConfirm.pickUp.bullet3"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndSubmitConfirm.pickUp.bullet4"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndSubmitConfirm.pickUp.bullet5"
                  )}
                </li>
              </ul>
            </div>

            <div className={styles.splitContainer}>
              <div className={styles.leftContainer}>
                <span className={styles.inputLabel}>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndSubmit.pickUpInfo.auctionHouse"
                  )}
                </span>
                <div>
                  <span className={styles.value}>
                    {this.props.data.auctionLocationPickup}
                  </span>
                </div>
              </div>
              <div className={styles.rightContainer}>
                <span className={styles.inputLabel}>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndSubmit.pickUpInfo.email"
                  )}
                </span>
                <div>
                  <span className={styles.value}>{this.props.data.email}</span>
                </div>
              </div>
            </div>

            <div className={styles.splitContainer}>
              <div className={styles.leftContainer}>
                <span className={styles.inputLabel}>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndSubmit.pickUpInfo.name"
                  )}
                </span>
                <div>
                  <span className={styles.value}>{this.props.data.name}</span>
                </div>
              </div>
              <div className={styles.rightContainer}>
                <div className={styles.textBoxSplitLeft}>
                  <span className={styles.inputLabel}>
                    {this.props.t(
                      "TradeIn.US.dialogs.acceptAndSubmit.pickUpInfo.phone"
                    )}
                  </span>
                  <div>
                    <span className={styles.value}>
                      {this.props.data.phone}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.addressInfo}>
              <div className={styles.splitContainer}>
                <div className={styles.leftContainer}>
                  <span className={styles.inputLabel}>
                    {this.props.t(
                      "TradeIn.US.dialogs.acceptAndSubmit.pickUpInfo.address"
                    )}
                  </span>
                  <div>
                    <span className={styles.value}>
                      {this.props.data.address}
                    </span>
                  </div>
                </div>
                <div className={styles.rightContainer}>
                  <span className={styles.inputLabel}>
                    {this.props.t(
                      "TradeIn.US.dialogs.acceptAndSubmit.pickUpInfo.city"
                    )}
                  </span>
                  <div>
                    <span className={styles.value}>{this.props.data.city}</span>
                  </div>
                </div>
              </div>

              <div className={styles.splitContainer}>
                <div className={styles.leftContainer}>
                  <span className={styles.inputLabel}>
                    {this.props.t(
                      "TradeIn.US.dialogs.acceptAndSubmit.pickUpInfo.state"
                    )}
                  </span>
                  <div>
                    <span className={styles.value}>
                      {this.props.data.state}
                    </span>
                  </div>
                </div>
                <div className={styles.rightContainer}>
                  <span className={styles.inputLabel}>
                    {this.props.t(
                      "TradeIn.US.dialogs.acceptAndSubmit.pickUpInfo.zip"
                    )}
                  </span>
                  <div>
                    <span className={styles.value}>{this.props.data.zip}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.splitContainer}>
              <div className={styles.leftContainer}>
                <span className={styles.inputLabel}>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndSubmit.dropOffInfo.arriveByDate"
                  )}
                </span>
                <div>
                  <span className={styles.value}>
                    {this.props.tradeInDetails &&
                      this.props.tradeInDetails.arriveByDate}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </EMPDialog>
        {this.state.openErrorDialog && (
          <ErrorProcessingDialog
            open={this.state.openErrorDialog}
            close={this.toggleErrorDialog}
            title={this.props.t("Error.processingErrorTitle")}
            body={
              IS_CANADA_MARKET(this.props.user.market)
                ? this.props.t("Error.processingErrorMessageCAN")
                : this.props.t("Error.processingErrorMessage")
            }
          />
        )}
      </>
    );
  }
}

export default withTranslation("emp")(AcceptAndSubmitPickUpConfirmDialog);
