import React from "react";
import { SvgIcon } from "@material-ui/core";

const MotionChevronRight = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16.8 12L9.6 4.79999L8.76 5.63999L15.12 12L8.76 18.36L9.6 19.2L16.8 12Z" />
      </svg>
    </SvgIcon>
  );
};

export default MotionChevronRight;
