import React, { Component } from "react";
import fordStyles from "./NAOrderDetailsNewVersion.module.scss";
import lincolnStyles from "./NAOrderDetailsNewVersionLincoln.module.scss";
import { withTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {
  DEFAULT_FEATURE,
  INCLUDED_FEATURE,
  ORDER_CHANNEL_INSTORE,
  SELECTED_FEATURE,
} from "../../../common/Constants";
import { StyledTableRow } from "../../../shared/Tables/TableRow";
import {
  StyledBasicExpansionPanel,
  StyledBasicExpansionPanelSummary,
} from "../../../shared/ExpansionPanels/ExpansionPanel";
import Grid from "@material-ui/core/Grid";
import { InfoDetail } from "./InfoDetail";
import CustomerReservationsClient from "../../../clients/CustomerReservationsClient";
import { logMsg } from "../../../common/Logger";
import { base64OpenInNewTab } from "../../../utils/FileDownloadUtil";
import { IS_FIELD_VIEW_USER, IS_FORD_BRAND } from "../../../utils/EmpUtil";
import crStylesFord from "../crStyles";
import crStylesLincoln from "../crStylesLincoln";
import UpperCaseText from "../../../utils/UpperCaseText";
import { AccordionSummary, withStyles } from "@material-ui/core";

const StyledAccordionSummary = withStyles({
  root: {
    fontFamily: "FordAntennaRegular",
    fontSize: 24,
    paddingLeft: 24,
    paddingRight: 24,
    color: "#1C1C1E",
  },
  content: {
    marginTop: 16,
    marginBottom: 16,
  },
})(AccordionSummary);

class VehicleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandVehicleDetails: false,
      expandStandardIncludedFeatures: false,
      expandSpecSummary: false,
      expandCustomerAddedFeatures: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({
        expandVehicleDetails: this.props.expandAccordian,
        expandStandardIncludedFeatures: this.props.expandAccordian,
        expandCustomerAddedFeatures: this.props.expandAccordian,
        expandSpecSummary: this.props.expandAccordian,
      });
    }
  }

  expandVehicleDetails = () => {
    this.setState((prevState) => ({
      expandVehicleDetails: !prevState.expandVehicleDetails,
    }));
  };

  expandStandardIncludedFeatures = () => {
    this.setState({
      expandStandardIncludedFeatures:
        !this.state.expandStandardIncludedFeatures,
    });
  };

  expandSpecSummary = () => {
    this.setState({
      expandSpecSummary: !this.state.expandSpecSummary,
    });
  };

  expandCustomerAddedFeatures = () => {
    this.setState({
      expandCustomerAddedFeatures: !this.state.expandCustomerAddedFeatures,
    });
  };

  getTitle = () => {
    const { modelYear, nameplate } = this.props.customerReservation;

    return modelYear && nameplate
      ? `${this.props.t(
          "OrderDetails.vehicleDetailsTitle"
        )} (${modelYear} ${nameplate})`
      : this.props.t("OrderDetails.vehicleDetailsTitle");
  };

  getSpecSummaryTitle = () => {
    const customerReservation = this.props.customerReservation;
    return customerReservation &&
      customerReservation.modelYear &&
      customerReservation.nameplate
      ? `${customerReservation.modelYear} ${customerReservation.nameplate}`
      : this.props.t("OrderDetails.vehicleDetailsTitle");
  };

  getInventoryWindowSticker = () => {
    const { customerReservation, dealerInfo } = this.props;
    if (customerReservation && dealerInfo) {
      const splitDealerId =
        dealerInfo.commonId && dealerInfo.commonId.split("|");
      const paCode = splitDealerId.length > 1 && splitDealerId[1];
      const vin =
        customerReservation.purchaseInfo && customerReservation.purchaseInfo.vin
          ? customerReservation.purchaseInfo.vin
          : customerReservation.vinNumber;
      if (vin && paCode) {
        const tab = window.open("about:blank", "_blank");
        CustomerReservationsClient.getInventoryWindowSticker(
          this.props.user.token,
          paCode,
          vin
        )
          .then((response) => {
            base64OpenInNewTab(tab, response, "application/pdf");
          })
          .catch((reason) => {
            tab.close();
            logMsg("reason", reason);
          });
      }
    }
  };

  render() {
    const styles = IS_FORD_BRAND(this.props.user.brand)
      ? fordStyles
      : lincolnStyles;

    const crStyles = IS_FORD_BRAND(this.props.user.brand)
      ? crStylesFord
      : crStylesLincoln;

    const isFordBrand = IS_FORD_BRAND(this.props.user.brand);
    const customerReservation = this.props.customerReservation;
    const specSummary = customerReservation.specSummary;
    const vehicleFeatures = this.props.vehicleFeatures;
    const expandStandardIncludedFeatures =
      this.state.expandStandardIncludedFeatures;
    const expandSpecSummary = this.state.expandSpecSummary;
    const expandCustomerAddedFeatures = this.state.expandCustomerAddedFeatures;
    const isWindowStickerVisible =
      (customerReservation.purchaseInfo &&
        customerReservation.purchaseInfo.vin) ||
      customerReservation.vinNumber;
    const orderChannel = customerReservation.orderChannel;
    const isOffline =
      customerReservation &&
      customerReservation.orderChannel &&
      customerReservation.orderChannel === "InStore" &&
      !IS_FIELD_VIEW_USER(this.props.user.userType) ? (
        <p className={fordStyles.statusMessage}>
          {this.props.t(
            "OrderDetailsPricingSummary.offlineOrderVehicleMessage"
          )}
        </p>
      ) : null;

    return (
      <StyledBasicExpansionPanel
        onChange={() => this.expandVehicleDetails()}
        expanded={this.state.expandVehicleDetails}
      >
        <StyledAccordionSummary
          id="vehicle_details_expand"
          expandIcon={<ExpandLessIcon />}
          IconButtonProps={{ disableRipple: true }}
        >
          {this.getTitle()}
        </StyledAccordionSummary>

        <div className={styles.customerInfoGrid}>
          <Grid item xs={12}>
            {isOffline}
          </Grid>

          {isWindowStickerVisible ? (
            <Grid item xs={4}>
              <div className={styles.subHeaderText}>
                {this.props.t("OrderDetails.vehicleDetailsTitle")}
              </div>

              {!(
                customerReservation.purchaseInfo &&
                customerReservation.purchaseInfo.stockId
              ) && (
                <div className={styles.customerInfoContainer}>
                  <span
                    className={styles.windowStickerLink}
                    onClick={() => this.getInventoryWindowSticker()}
                  >
                    {this.props.t("OrderDetails.viewWindowSticker")}
                  </span>
                </div>
              )}

              <InfoDetail
                isFordBrand={isFordBrand}
                header={this.props.t("OrderDetails.year")}
                value={customerReservation.modelYear}
                upperCase={false}
              />

              <InfoDetail
                isFordBrand={isFordBrand}
                header={this.props.t("OrderDetails.model")}
                value={customerReservation.nameplate}
                upperCase={false}
              />
              <InfoDetail
                isFordBrand={isFordBrand}
                header={this.props.t("OrderDetails.trim")}
                value={customerReservation.trim}
                upperCase={false}
              />
              <InfoDetail
                isFordBrand={isFordBrand}
                header={this.props.t("OrderDetails.color")}
                value={customerReservation.color}
                upperCase={false}
              />
            </Grid>
          ) : (
            <>
              {(UpperCaseText(orderChannel) !== ORDER_CHANNEL_INSTORE ||
                IS_FIELD_VIEW_USER(this.props.user.userType)) && (
                <StyledBasicExpansionPanel
                  onChange={() => this.expandStandardIncludedFeatures()}
                  expanded={this.state.expandStandardIncludedFeatures}
                >
                  <StyledBasicExpansionPanelSummary
                    style={crStyles.orderDetailsExpansion}
                  >
                    <div className={styles.headerBar}>
                      <div
                        className={styles.featuresExpansionNoIndent}
                        style={crStyles.summaryTitle}
                      >
                        {this.props.t("OrderDetails.vehicleDetailsFeatures")}
                      </div>
                      {expandStandardIncludedFeatures ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </div>
                  </StyledBasicExpansionPanelSummary>

                  {specSummary && specSummary.length > 0 && (
                    <StyledBasicExpansionPanel
                      onChange={() => this.expandSpecSummary()}
                      expanded={this.state.expandSpecSummary}
                    >
                      <StyledBasicExpansionPanelSummary
                        style={crStyles.orderDetailsExpansion}
                      >
                        <div className={styles.headerBarNoIdent}>
                          <StyledTableRow
                            text={this.getSpecSummaryTitle()}
                            data={null}
                            index={1}
                            indent={true}
                          />
                          {expandSpecSummary ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </div>
                      </StyledBasicExpansionPanelSummary>
                      {specSummary.map((description, index) => {
                        return (
                          <div key={index} className={styles.mediumIndent}>
                            <StyledTableRow
                              text={description}
                              data={null}
                              index={1}
                              indent={false}
                            />
                          </div>
                        );
                      })}
                    </StyledBasicExpansionPanel>
                  )}
                  {vehicleFeatures &&
                    vehicleFeatures.hasOwnProperty(DEFAULT_FEATURE) &&
                    vehicleFeatures[DEFAULT_FEATURE].map(
                      (description, index) => {
                        return (
                          <StyledTableRow
                            text={description}
                            data={null}
                            index={index}
                            indent={true}
                            key={index}
                          />
                        );
                      }
                    )}

                  {vehicleFeatures &&
                    vehicleFeatures.hasOwnProperty(INCLUDED_FEATURE) &&
                    vehicleFeatures[INCLUDED_FEATURE].map(
                      (description, index) => {
                        return (
                          <StyledTableRow
                            text={description}
                            data={null}
                            index={index}
                            indent={true}
                            key={index}
                          />
                        );
                      }
                    )}
                </StyledBasicExpansionPanel>
              )}
              {(UpperCaseText(orderChannel) !== ORDER_CHANNEL_INSTORE ||
                IS_FIELD_VIEW_USER(this.props.user.userType)) && (
                <StyledBasicExpansionPanel
                  onChange={() => this.expandCustomerAddedFeatures()}
                  expanded={this.state.expandCustomerAddedFeatures}
                >
                  <StyledBasicExpansionPanelSummary
                    style={crStyles.orderDetailsExpansion}
                  >
                    <div className={styles.headerBar}>
                      <div
                        className={styles.featuresExpansionNoIndent}
                        style={crStyles.summaryTitle}
                      >
                        {this.props.t(
                          "OrderDetails.vehicleDetailsCustomerAddedFeatures"
                        )}
                      </div>
                      {expandCustomerAddedFeatures ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </div>
                  </StyledBasicExpansionPanelSummary>

                  {vehicleFeatures &&
                    vehicleFeatures.hasOwnProperty(SELECTED_FEATURE) &&
                    vehicleFeatures[SELECTED_FEATURE].map(
                      (description, index) => (
                        <StyledTableRow
                          text={description}
                          data={null}
                          index={index}
                          indent={true}
                          key={index}
                        />
                      )
                    )}
                </StyledBasicExpansionPanel>
              )}
            </>
          )}
        </div>
      </StyledBasicExpansionPanel>
    );
  }
}

export default withTranslation("emp")(VehicleDetails);
