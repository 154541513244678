import React from "react";
import { SvgIcon } from "@material-ui/core";

const MotionChevronLeft = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.2 12L14.4 19.2L15.24 18.36L8.88 12L15.24 5.63999L14.4 4.79999L7.2 12Z" />
      </svg>
    </SvgIcon>
  );
};

export default MotionChevronLeft;
