import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import UpperCaseText from "../../../utils/UpperCaseText";

// available props
// type: string (text, email)
// placeholder: string
// validationState: string (error, success, default)
// value: string
// testId: string
// disabled: bool
// readOnly: bool
// error: bool
// onChange: function (to access or validate internal text value)
// onClick: function (to activate some form behavior like a radio toggle)

const useStyles = makeStyles({
  root: {
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
    fontSize: 14,
    fontWeight: "400",
    lineHeight: "20px",
    borderRadius: 8,
    outline: "1px solid #6B7786",
    color: "#00142E",

    "&.MuiInputBase-adornedStart": {
      padding: "8px 16px",
    },
  },
  disabled: {
    borderRadius: 8,
    backgroundColor: "#E6E8EA",
  },
  focused: {
    outline: "2px solid #066FEF",
  },
  colorSecondary: {
    outlineColor: "#008200",
    "&:focused": {
      outlineColor: "#008200",
    },
  },
  error: {
    outline: "2px solid #BF152C",
  },
  input: {
    padding: "8px 16px",
    height: "auto",
    "&::placeholder": {
      fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
      fontSize: 14,
      lineHeight: "20px",
    },
    "&:read-only": {
      background: "#E6E8EA",
    },
    "&.MuiInputBase-inputAdornedStart": {
      padding: 0,
    },
  },
});

const useHelperTextStyles = makeStyles({
  root: {
    fontSize: "12px",
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
    lineHeight: "16px",
    letterSpacing: "0px",
    paddingTop: "4px",
    "&.Mui-error": {
      color: "#BF152C",
    },
  },
});

const EMPInputField = React.forwardRef((props, ref) => {
  const {
    type,
    placeholder,
    validationState,
    value,
    testId,
    disabled,
    readOnly,
    error,
    onChange,
    onClick,
    name,
    multiline,
    helperText,
    startAdornment,
  } = props;
  const classes = useStyles();
  const helperTextClasses = useHelperTextStyles();

  return (
    <TextField
      InputProps={{
        readOnly,
        disableUnderline: true,
        classes: {
          root: classes.root,
          disabled: classes.disabled,
          focused: classes.focused,
          colorSecondary: classes.colorSecondary,
          error: classes.error,
          input: classes.input,
        },
        startAdornment,
      }}
      inputProps={{
        "data-testid": testId,
      }}
      FormHelperTextProps={{
        classes: {
          ...helperTextClasses,
        },
      }}
      type={type}
      value={value}
      error={UpperCaseText(validationState) === "ERROR" || error}
      color={
        UpperCaseText(validationState) === "SUCCESS" ? "secondary" : "primary"
      }
      placeholder={placeholder}
      fullWidth
      disabled={disabled}
      onChange={onChange}
      onClick={onClick}
      ref={ref}
      name={name}
      multiline={multiline}
      helperText={helperText}
    ></TextField>
  );
});
export default EMPInputField;
