const fieldViewSearchTableStyles = {
  sort: {
    height: "12.5px",
    fill: "#102b4e",
  },
  expansionPanel: {
    height: "40px",
    minHeight: "40px",
  },
  headerBar: {
    color: "#102b4e",
    backgroundColor: "#d8d8d8",
    height: "40px",
    minHeight: "40px",
    padding: 0,
    margin: 0,
  },
  headerBarGrey: {
    backgroundColor: "#f3f3f3",
    maxHeight: "40px",
    minHeight: "40px",
    padding: 0,
    margin: 0,
  },
  headerBarBg: {
    backgroundColor: "#e5e5e5",
    maxHeight: "40px",
    minHeight: "40px",
    padding: 0,
    margin: 0,
  },
  barBg: {
    backgroundColor: "#ffffff",
    maxHeight: "40px",
    minHeight: "40px",
    padding: 0,
  },
  headerBarNeutral: {
    maxHeight: "50px",
    minHeight: "50px",
    padding: 0,
  },
  accessoriesHeaderBarBg: {
    marginTop: "5px",
    backgroundColor: "#e5e5e5",
    maxHeight: "40px",
    minHeight: "40px",
    padding: 0,
  },
  accessoriesHeaderBarBgLincoln: {
    marginTop: "5px",
    backgroundColor: "#ffffff",
    maxHeight: "40px",
    minHeight: "40px",
    padding: 0,
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
  },
  gridItems: {
    display: "block",
    alignItems: "center",
    marginLeft: "-15px",
  },
  dropdown: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  priceDropdown: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  orderTypeHeader: {
    verticalAlign: "bottom",
    fontFamily: "'Ford Antenna Bold', 'FordAntennaBold', sans-serif",
    paddingBottom: 15,
    paddingLeft: "3%",
    fontSize: 14,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "left",
    width: "11%",
  },
  idHeader: {
    verticalAlign: "bottom",
    fontFamily: "'Ford Antenna Bold', 'FordAntennaBold', sans-serif",
    paddingBottom: 15,
    paddingLeft: "5%",
    fontSize: 14,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "left",
    width: "17%",
  },
  dateHeader: {
    verticalAlign: "bottom",
    fontFamily: "'Ford Antenna Bold', 'FordAntennaBold', sans-serif",
    paddingBottom: 15,
    paddingLeft: "5%",
    fontSize: 14,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "left",
    width: "10%",
  },
  specsHeader: {
    verticalAlign: "bottom",
    fontFamily: "'Ford Antenna Bold', 'FordAntennaBold', sans-serif",
    paddingBottom: 15,
    paddingLeft: "2%",
    fontSize: 14,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "left",
    width: "12%",
  },
  statusHeader: {
    verticalAlign: "bottom",
    fontFamily: "'Ford Antenna Bold', 'FordAntennaBold', sans-serif",
    paddingBottom: 15,
    paddingLeft: "5%",
    fontSize: 14,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "left",
    width: "9%",
  },
  feeHeader: {
    verticalAlign: "bottom",
    fontFamily: "'Ford Antenna Bold', 'FordAntennaBold', sans-serif",
    paddingBottom: 15,
    paddingLeft: "5%",
    fontSize: 14,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "left",
    width: "22%",
  },
  emptyHeader: {
    paddingLeft: 15,
    paddingRight: "6%",
    paddingTop: 0,
    paddingBottom: 0,
    width: "20%",
  },
  orderType: {
    padding: "0px 0px 0px 3%",
    overflowWrap: "break-word",
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
    textAlign: "left",
    letterSpacing: "1px",
    fontSize: "12px",
    lineHeight: "normal",
    width: "12%",
  },
  orderTypeText: {
    color: "orange",
  },
  reservationIdText: {
    color: "#102b4e",
    textDecoration: "underline",
  },
  dealerNameText: {
    color: "#102b4e",
  },
  addressText: {
    lineHeight: "1",
    textAlign: "left",
  },
  cellContent: {
    width: "12%",
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
    textAlign: "left",
    letterSpacing: "1px",
    padding: "0px 0px 0px 2%",
    fontSize: "12px",
    lineHeight: "normal",
  },
  codeContent: {
    width: "10%",
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
    textAlign: "left",
    letterSpacing: "1px",
    padding: "0px 0px 0px 2%",
    fontSize: "12px",
    lineHeight: "normal",
  },
  pendingActionType: {
    paddingLeft: "6%",
    paddingRight: 15,
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  orderId: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "center",
  },
  date: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "center",
  },
  specs: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "left",
    textOverflow: "ellipsis",
  },
  commonStatusStyle: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "center",
    fontFamily: "FordAntennaCondRegular, sans-serif",
    fontSize: "12px",
    lineHeight: "normal",
    letterSpacing: "1px",
  },
  confirmedStatus: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "center",
    fontFamily: "FordAntennaCondRegular, sans-serif",
    fontSize: "12px",
    lineHeight: "normal",
    letterSpacing: "1px",
    color: "#00ad46",
    backgroundColor: "rgba(0, 173, 70, 0.03)",
  },
  cancelledStatus: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "center",
    fontFamily: "FordAntennaCondRegular, sans-serif",
    fontSize: "12px",
    lineHeight: "normal",
    letterSpacing: "1px",
    color: "#ff0000",
    backgroundColor: "rgba(255, 0, 0, 0.03)",
  },
  amendedStatus: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "center",
    fontFamily: "FordAntennaCondRegular, sans-serif",
    fontSize: "12px",
    lineHeight: "normal",
    letterSpacing: "1px",
    color: "#4a4a4a",
    backgroundColor: "rgba(0, 0, 0, 0.03)",
  },
  statusRed: {
    color: "#ff0000",
  },
  statusGreen: {
    color: "#00ad46",
  },
  statusGray: {
    paddingLeft: 15,
    paddingRight: 15,
    color: "#4a4a4a",
    textAlign: "center",
  },
  fee: {
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: "center",
  },
  view: {
    fontFamily: "FordAntennaCondBold, sans-serif",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "center",
    letterSpacing: "1.88px",
    textDecoration: "underline",
    width: "15%",
  },
  viewButton: {
    fontFamily: "FordAntennaCondBold, sans-serif",
    fontSize: "12px",
    color: "#102b4e",
    padding: 0,
    textAlign: "right",
    letterSpacing: "0.5px",
  },
  viewIcon: {
    height: "17.5px",
  },
  arrowEnabled: {
    backgroundColor: "#102b4e",
    color: "white",
    height: "40px",
    width: "40px",
    borderRadius: "3px",
  },
  arrowDisabled: {
    backgroundColor: "#8c8c8c",
    color: "white",
    height: "40px",
    width: "40px",
    borderRadius: "3px",
  },
  orderDetailsExpansion: {
    backgroundColor: "#ffffff",
    maxHeight: "60px",
    minHeight: "60px",
    padding: 0,
  },
  summaryTitle: {
    height: "100%",
    fontSize: "14px",
  },
  cellHeader: {
    verticalAlign: "bottom",
    fontFamily: "'Ford Antenna Bold', 'FordAntennaBold', sans-serif",
    paddingBottom: 15,
    paddingLeft: "2%",
    fontSize: 14,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "left",
  },
  customerNameHeader: {
    verticalAlign: "bottom",
    fontFamily: "'Ford Antenna Bold', 'FordAntennaBold', sans-serif",
    paddingBottom: 15,
    paddingLeft: "2%",
    fontSize: 14,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "left",
    width: "15%",
  },
  viewLinks: {
    fontFamily: "FordAntennaCondBold, sans-serif",
    fontSize: "12px",
    color: "#102b4e",
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: "center",
    letterSpacing: "0.5px",
    textDecoration: "none",
    cursor: "pointer",
    borderRight: "1px solid gray",
  },
  viewLinksRight: {
    fontFamily: "FordAntennaCondBold, sans-serif",
    fontSize: "12px",
    color: "#102b4e",
    paddingLeft: 10,
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: "center",
    letterSpacing: "0.5px",
    textDecoration: "none",
    cursor: "pointer",
  },
  specsORA: {
    fontFamily: "FordAntennaRegular, sans-serif",
    textAlign: "left",
    letterSpacing: "1px",
    padding: "0px 0px 0px 2%",
    lineHeight: "normal",
  },
  specsWrapperORA: {
    lineHeight: 1.4,
  },
};

export default fieldViewSearchTableStyles;
