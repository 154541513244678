import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import EMPChip from "../EMPChip/EMPChip";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";

// Available props:
// headingText: string
// subItems: array
// hasLabel: Boolean
// labelText: string

const useStyles = makeStyles(
  {
    root: {
      fontFamily: "'FordAntennaRegular', sans-serif",
      marginRight: 12,
    },
    h1: {
      color: (props) => (IS_FORD_BRAND(props.brand) ? "#00095b" : "#324047"),
      fontSize: 24,
    },
    body1: {
      color: "#000000",
      fontSize: 16,
    },
  },
  {
    name: "EMPGroupedHeading",
  }
);

const EMPGroupedHeading = (props) => {
  const { headingText, subItems, label, hideSubItems } = props;
  const classes = useStyles(props);

  return (
    <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
      <Grid item>
        <Typography
          variant="h1"
          align="left"
          classes={{ root: classes.root, h1: classes.h1 }}
        >
          {headingText}
        </Typography>
      </Grid>
      {label && (
        <Grid item>
          <EMPChip
            label={label.text}
            labelColor={label.color}
            testId="order-label"
          />
        </Grid>
      )}
      {!hideSubItems && (
        <Grid item container spacing={0} alignItems="center">
          {subItems.map(
            (dataItem) =>
              dataItem && (
                <Grid item key={dataItem.toString()}>
                  <Typography
                    variant="body1"
                    align="left"
                    classes={{ root: classes.root, body1: classes.body1 }}
                    data-testid={dataItem}
                  >
                    {dataItem}
                  </Typography>
                </Grid>
              )
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default EMPGroupedHeading;
