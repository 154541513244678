import React, { Component } from "react";
import fordStyles from "./SubNav.module.scss";
import lincolnStyles from "./SubNav-Lincoln.module.scss";
import SubNavDropdown from "./SubNavDropdown";
import HasPermissionTo from "../../common/HasPermissionTo";
import SubNavTabs from "./SubNavTabs";
import { withTranslation } from "react-i18next";
import CustomDropdown from "./CustomDropdown";
import { IS_FORD_BRAND } from "../../utils/EmpUtil";
import UpperCaseText from "../../utils/UpperCaseText";

class SubNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleTabsCount: 100,
      windowWidth: window.innerWidth,
    };
    this.tabletWindowWidth = 1024;
    this.onboardedDealers =
      UpperCaseText(this.props.user.userType) === "DEALER" &&
      this.props.user.getOnboardedDealerGroup();
  }

  componentDidMount() {
    if (this.props.tabs) {
      window.addEventListener("resize", this.handleWindowResize);
      this.getVisibleTabsCount();
    }
  }

  componentWillUnmount() {
    if (this.props.tabs) {
      window.removeEventListener("resize", this.handleWindowResize);
    }
  }

  handleWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth, visibleTabsCount: 100 });
    this.getVisibleTabsCount();
  };

  inViewPort = (boundary, divPaddingRight, windowWidth) => {
    return boundary.left >= 0 && boundary.right + divPaddingRight < windowWidth;
  };

  getVisibleTabsCount = () => {
    if (this.state.windowWidth > this.tabletWindowWidth) {
      let visibleTabs = 0;
      let notVisibleTabsFlag = false;
      let elemRightBoundary = 0;
      const windowWidth = this.state.windowWidth;
      const divPaddingRightPercent = 6;
      const divPaddingRight = windowWidth * (divPaddingRightPercent / 100);
      const moreTabWidth = 172;
      this.props.tabs.forEach((item) => {
        if (!notVisibleTabsFlag) {
          const elem = document.getElementById("tab" + item.id);
          const boundary = elem.getBoundingClientRect();
          if (this.inViewPort(boundary, divPaddingRight, windowWidth)) {
            visibleTabs = visibleTabs + 1;
          } else {
            // checking if there is enough space for More Tab. Otherwise, reduce one Visible Tab to make space.
            if (
              elemRightBoundary + divPaddingRight + moreTabWidth >=
              windowWidth
            ) {
              visibleTabs = visibleTabs - 1;
            }
            notVisibleTabsFlag = true;
          }
          elemRightBoundary = boundary.right;
        }
      });

      this.setState({ visibleTabsCount: visibleTabs });
    }
  };

  render() {
    const isFordBrand = IS_FORD_BRAND(this.props.user.brand);
    const styles = isFordBrand ? fordStyles : lincolnStyles;
    return (
      <div className={styles.subNav}>
        {!this.props.restrictDropdown ? (
          this.props.showCustomDropdown ? (
            <div className={styles.container}>
              <CustomDropdown {...this.props} isFordBrand={isFordBrand} />
            </div>
          ) : (
            <div className={styles.container}>
              <HasPermissionTo
                perform={["AllCMA:view"]}
                permissions={this.props.user.permissions.rolePermissions}
                condition={this.onboardedDealers.length > 1}
                render={() => <SubNavDropdown {...this.props} />}
              />
            </div>
          )
        ) : null}

        <SubNavTabs
          visibleTabsCount={this.state.visibleTabsCount}
          isFordBrand={isFordBrand}
          {...this.props}
        />
      </div>
    );
  }
}

export default withTranslation("emp")(SubNav);
