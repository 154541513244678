import React from "react";
import "../../../../styles/sharedStyles.scss";
import Grid from "@material-ui/core/Grid";
import styles from "./AcceptAndKeepConfirmDialog.module.scss";
import { withTranslation } from "react-i18next";
import {
  ACCEPT_AND_KEEP,
  ACCEPT_AND_KEEP_CONFIRM,
  DEALER_ACTIONS,
} from "../../../../common/Constants";
import UiDateUtils from "../../../../utils/UiDateUtils";
import EMPDialog from "../../../../shared/components/EMPDialog/EMPDialog";

class AcceptAndKeepConfirmDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClose = () => {
    this.props.onClose(ACCEPT_AND_KEEP_CONFIRM);
  };

  handleConfirm = () => {
    const acceptAndKeepRequest = {
      reservationId: this.props.tradeInDetails.orderId,
      dealerAction: DEALER_ACTIONS.TRADE_IN_ACCEPT_KEEP,
      acceptedDate: this.props.data.dateVehicleSeen,
    };

    this.props.onSubmit(acceptAndKeepRequest);
    this.handleClose();
  };

  edit = () => {
    this.props.onDialogChange(ACCEPT_AND_KEEP, ACCEPT_AND_KEEP_CONFIRM);
  };

  render() {
    return this.props.open ? (
      <EMPDialog
        open={this.props.open}
        handleClose={this.handleClose}
        dialogTitle={this.props.t(
          "TradeIn.US.dialogs.acceptAndKeepConfirm.title"
        )}
        hasCloseIcon
        dialogActionsDataTestId="acceptKeepConfirmDialogActions"
        hasActionButton
        actionButtonText={this.props.t(
          "TradeIn.US.dialogs.acceptAndKeepConfirm.confirm"
        )}
        onConfirm={this.handleConfirm}
        disableActionButton={
          !this.props.user.permissions.rolePermissions.hasOwnProperty(
            "tradeInSubmit"
          )
        }
        hasSecondaryActionButton
        secondaryActionButtonText={this.props.t(
          "TradeIn.US.dialogs.acceptAndKeepConfirm.edit"
        )}
        onSecondaryAction={this.edit}
      >
        <Grid
          container
          data-testid="acceptKeepConfirmDialog"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <div className={styles.body}>
              <div>
                {this.props.t(
                  "TradeIn.US.dialogs.acceptAndKeepConfirm.body.title"
                )}
              </div>
              <ul>
                <li>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndKeepConfirm.body.bullet1"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndKeepConfirm.body.bullet2"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndKeepConfirm.body.bullet3"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndKeepConfirm.body.bullet4"
                  )}
                </li>
                <li>
                  {this.props.t(
                    "TradeIn.US.dialogs.acceptAndKeepConfirm.body.bullet5"
                  )}
                </li>
              </ul>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={styles.title}>
              {this.props.t(
                "TradeIn.US.dialogs.acceptAndKeepConfirm.redemptionMileage"
              )}
            </div>

            <div className={styles.text}>
              {this.props.data.redemptionMileage}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={styles.title}>
              {this.props.t(
                "TradeIn.US.dialogs.acceptAndKeepConfirm.dateVehicleSeen"
              )}
            </div>

            <div className={styles.text}>
              {UiDateUtils.formatDateMmDdYyyy(this.props.data.dateVehicleSeen)}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={styles.body}>
              {this.props.t(
                "TradeIn.US.dialogs.acceptAndKeepConfirm.needChanges"
              )}
            </div>
          </Grid>
        </Grid>
      </EMPDialog>
    ) : (
      ""
    );
  }
}

export default withTranslation("emp")(AcceptAndKeepConfirmDialog);
