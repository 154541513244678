import axios from "axios";
import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";
import { getHeaders } from "../utils/AuthorizationUtil";
import { BASE_URL } from "../utils/EmpUtil";
import { is401, is404 } from "../errors/ErrorConstants";

class TermsAndConditionsClient {
  static baseUrl = BASE_URL;

  static retrieveFile(user) {
    const url = `${
      this.baseUrl
    }/${user.generateResourcePath()}/terms-and-conditions`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data.file)
      .catch((error) => {
        if (is401(error) || is404(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static retrieveTermsAndConditionsHistory(user) {
    return this.getTermsAndConditions(user, "all-terms-and-conditions")
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error) || is404(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static uploadTermsAndConditions(user, data) {
    const url = `${
      this.baseUrl
    }/${user.generateResourcePath()}/terms-and-conditions`;

    return axios
      .post(url, data, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static deleteTermsAndConditions(user, version) {
    const url = `${
      this.baseUrl
    }/${user.generateResourcePath()}/delete-terms-and-conditions?version=${version}`;

    return axios.delete(url, getHeaders(user.token)).catch((error) => {
      if (is401(error)) {
        AuthenticationFailureHandler.execute();
      }
      return Promise.reject(error);
    });
  }

  static getTermsAndConditions(user, path) {
    let url;
    if (
      (user.userType === "dealer" || user.userType === "na_dealer") &&
      user.isMainDealer(user.dealerType) &&
      user.showAll
    ) {
      const commonIds = user.getCommonIdsWithoutSDLR();
      url = `${
        this.baseUrl
      }/${user.generateResourcePath()}/${path}?commonDealerIds=${commonIds}`;
    } else {
      url = `${this.baseUrl}/${user.generateResourcePath()}/${path}`;
    }
    return axios.get(url, getHeaders(user.token));
  }

  static saveDealerTermsAndConditionsAcceptance(
    user,
    termsAndConditions,
    isAccepted,
    commonIds
  ) {
    const url = `${
      this.baseUrl
    }/${user.generateResourcePath()}/terms-and-conditions-acceptance/?dealerCommonIds=${commonIds}`;

    const data = {
      dealerUserId: user.userId,
      dealerId: user.dealerId,
      version: termsAndConditions.version,
      isAccepted: isAccepted,
    };

    return axios
      .post(url, data, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}

export default TermsAndConditionsClient;
