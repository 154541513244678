export const messages = {
  // Universal
  ECOMM_AllPermissions_Message: ["DealerDashboard.allPermissions"],
  FIELD_User_Message: ["DealerDashboard.fieldViewUserNavBarText"],
  // View
  US_FordBrand_ViewMessage: [
    "DealerDashboard.navBarTextIntro",
    "DealerDashboard.navBarText_reservationOrders_viewonly",
    "DealerDashboard.navBarText_newVehiclePurchaseRequests_viewonly",
    "DealerDashboard.navBarText_tradeIns_viewonly",
    "DealerDashboard.navBarText_additionalPermissions",
  ],
  US_LincolnBrand_ViewMessage: [
    "DealerDashboard.navBarTextIntro",
    "DealerDashboard.navBarText_newVehiclePurchaseRequests_viewonly",
    "DealerDashboard.navBarText_tradeIns_viewonly",
    "DealerDashboard.navBarText_additionalPermissions",
  ],
  CA_FordBrand_ViewMessage: [
    "DealerDashboard.navBarTextIntro",
    "DealerDashboard.reservationsModuleViewOnly",
    "DealerDashboard.ordersViewOnly",
    "DealerDashboard.businessSettings",
    "DealerDashboard.navBarText_additionalPermissions",
  ],
  // Submit
  US_FordBrand_SubmitMessage: [
    "DealerDashboard.navBarTextIntro",
    "DealerDashboard.navBarText_reservationOrders_editable",
    "DealerDashboard.navBarText_newVehiclePurchaseRequests_viewonly",
    "DealerDashboard.navBarText_tradeIns_ifApplicable_viewonly",
    "DealerDashboard.navBarText_additionalPermissions",
  ],
  US_LincolnBrand_SubmitMessage: [
    "DealerDashboard.navBarTextIntro",
    "DealerDashboard.navBarText_newVehiclePurchaseRequests_viewonly",
    "DealerDashboard.navBarText_tradeIns_ifApplicable_viewonly",
    "DealerDashboard.navBarText_additionalPermissions",
  ],
  CA_FordBrand_SubmitMessage: [
    "DealerDashboard.navBarTextIntro",
    "DealerDashboard.reservationsModule",
    "DealerDashboard.ordersViewOnly",
    "DealerDashboard.businessSettings",
    "DealerDashboard.navBarText_additionalPermissions",
  ],
  CA_LincolnBrand_SubmitMessage: [
    "DealerDashboard.navBarTextIntro",
    "DealerDashboard.reservationsModule",
    "DealerDashboard.ordersViewOnly",
    "DealerDashboard.businessSettings",
    "DealerDashboard.navBarText_additionalPermissions",
  ],
  // Customer Handling
  US_FordBrand_CustomerHandlingMessage: [
    "DealerDashboard.navBarTextIntro",
    "DealerDashboard.navBarText_customerHandling",
    "DealerDashboard.navBarText_reservationOrders_viewonly",
    "DealerDashboard.navBarText_newVehiclePurchaseRequests",
    "DealerDashboard.navBarText_tradeIns_ifApplicable",
    "DealerDashboard.navBarText_additionalPermissions",
  ],
  US_LincolnBrand_CustomerHandlingMessage: [
    "DealerDashboard.navBarTextIntro",
    "DealerDashboard.navBarText_customerHandling",
    "DealerDashboard.navBarText_newVehiclePurchaseRequests",
    "DealerDashboard.navBarText_tradeIns_ifApplicable",
    "DealerDashboard.navBarText_additionalPermissions",
  ],
  // Customer Handling and Accessory
  US_FordBrand_CustomerHandlingAndAccessoryMessage: [
    "DealerDashboard.navBarTextIntro",
    "DealerDashboard.navBarText_customerHandling",
    "DealerDashboard.navBarText_reservationOrders_viewonly",
    "DealerDashboard.navBarText_newVehiclePurchaseRequests",
    "DealerDashboard.navBarText_tradeIns_ifApplicable",
    "DealerDashboard.navBarText_accessoriesModule",
    "DealerDashboard.navBarText_additionalPermissions",
  ],
  US_LincolnBrand_CustomerHandlingAndAccessoryMessage: [
    "DealerDashboard.navBarTextIntro",
    "DealerDashboard.navBarText_customerHandling",
    "DealerDashboard.navBarText_newVehiclePurchaseRequests",
    "DealerDashboard.navBarText_tradeIns_ifApplicable",
    "DealerDashboard.navBarText_accessoriesModule",
    "DealerDashboard.navBarText_additionalPermissions",
  ],
  // Accessory
  US_FordBrand_AccessoriesMessage: [
    "DealerDashboard.eComm_Accessory_navBarText",
    "DealerDashboard.navBarText_additionalPermissions",
  ],
  CA_FordBrand_AccessoriesMessage: [
    "DealerDashboard.navBarTextIntro",
    "DealerDashboard.accessoriesModule",
    "DealerDashboard.businessSettings",
    "DealerDashboard.navBarText_additionalPermissions",
  ],
  // Dealer Principal
  ECOMM_CA_DealerPrincipal_Message: [
    "DealerDashboard.navBarTextIntro",
    "DealerDashboard.reservationsModule",
    "DealerDashboard.ordersViewOnly",
    "DealerDashboard.accessoriesModule",
    "DealerDashboard.businessSettings",
    "DealerDashboard.navBarText_additionalPermissions",
  ],
  ECOMM_MODELE_Message: ["DealerDashboard.modelEPilot"],
  ECOMM_Admin_Message: ["DealerDashboard.empAdminMessage"],
  ECOMM_CRC_Message: ["DealerDashboard.crcPermissions"],
};
