import { DECIMAL_PLACES, NA_DEFAULT_CURRENCY } from "../common/Constants";
import { NOTNULL_OR_NOTUNDEFINED } from "./EmpUtil";

export function formatPriceValue(value, fallback = null) {
  const defaultCurrency = NA_DEFAULT_CURRENCY;

  if (NOTNULL_OR_NOTUNDEFINED(value)) {
    value = Number(parseFloat(value).toFixed(DECIMAL_PLACES)).toLocaleString(
      "en",
      { minimumFractionDigits: DECIMAL_PLACES }
    );

    value = value.toString();
    value =
      value.charAt(0) === "+" || value.charAt(0) === "-"
        ? value.charAt(0).concat(defaultCurrency, value.substr(1))
        : defaultCurrency.concat(value);
  } else {
    value = fallback;
  }
  return value;
}
