import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import fordStyles from "./FieldReporting-Ford.module.scss";
import fordStylesOverride from "./FieldReportingFordStylesOverride";
import lincolnStylesOverride from "./FieldReportingLincolnStylesOverride";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox, withStyles } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import DatePicker from "react-datepicker";
import * as DateConstants from "../../shared/constants/DateConstants";
import checkboxStyles from "../../shared/checkBox/CheckBox.module.scss";
import Reset from "@material-ui/icons/Replay";
import DatePickerIcon from "../../imgs/DatePickerIcon";
import ReservationsPreview from "./reportPreview/ReservationsPreview";
import PurchaseRequestPreview from "./reportPreview/PurchaseRequestPreview";
import OrdersPreview from "./reportPreview/OrdersPreview";
import UpperCaseText from "../../utils/UpperCaseText";
import UiDateUtils from "../../utils/UiDateUtils";
import { base64FileDownload } from "../../utils/FileDownloadUtil";
import { logMsg } from "../../common/Logger";
import { IS_FORD_BRAND, IS_USA_MARKET } from "../../utils/EmpUtil";
import lincolnStyles from "./FieldReporting-Lincoln.module.scss";
import { publish } from "../../common/PubSub";
import VehicleLinesClient from "../../clients/VehicleLinesClient";
import DealerReportingClient from "../../clients/DealerReportingClient";
import DealerGroupingClient from "../../clients/DealerGroupingClient";
import LocationDataClient from "../../clients/LocationDataClient";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import EMPSelectField from "../../shared/components/EMPSelectField/EMPSelectField";

const EMPTextValidator = withStyles({
  root: {
    "& .MuiFormHelperText-root": {
      fontFamily: "'FordAntennaRegular', sans-serif",
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "20px",
      color: "#BF152C",
      marginBottom: 0,
    },
    "& .MuiInputBase-root": {
      borderRadius: 8,
      outline: "1px solid #6B7786",
      padding: "8px 16px",
      backgroundColor: "#FFFFFF",
      fontFamily: "'FordAntennaRegular', sans-serif",
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "20px",
      color: "#00142E",
    },
    "& .Mui-error.MuiInputBase-root": {
      borderRadius: 8,
      outline: "1px solid #BF152C",
    },
    "& .MuiInputBase-input": {
      padding: 0,
    },
    "& .Mui-focused.MuiInputBase-root": {
      outline: "2px solid #066FEF",
    },
    "& .Mui-error.Mui-focused.MuiInputBase-root": {
      outline: "2px solid #BF152C",
    },
  },
})(TextValidator);

let styles = fordStyles;
let isFordBrand = true;
let stylesOverride = fordStylesOverride;

const StyledRadioGroup = withStyles({
  root: {
    "flex-direction": "row",
  },
})(RadioGroup);

const StyledCheckBox = withStyles({
  root: {
    padding: "5px",
  },
})(Checkbox);

class FieldReporting extends Component {
  constructor(props) {
    super(props);
    this.salesCodeInput = React.createRef();
    this.state = {
      reportData: "",
      base64String: "",
      vehicleLines: {},
      brand:
        this.props.brand === "F"
          ? this.props.t("fieldReporting.dropDowns.fordBrand")
          : this.props.t("fieldReporting.dropDowns.lincolnBrand"),
      brands: [
        this.props.t("fieldReporting.dropDowns.fordBrand"),
        this.props.t("fieldReporting.dropDowns.lincolnBrand"),
      ],
      reportType: this.props.t("fieldReporting.dropDowns.purchaseRequest"),
      reportTypes: [
        this.props.t("fieldReporting.dropDowns.reservationsConversions"),
        this.props.t("fieldReporting.dropDowns.ordersConversions"),
        this.props.t("fieldReporting.dropDowns.purchaseRequest"),
      ],
      model: this.props.t("fieldReporting.dropDowns.all"),
      modelYears: [],
      modelYear: this.props.t("fieldReporting.dropDowns.all"),
      marketArea: this.props.t("fieldReporting.dropDowns.select"),
      marketAreas: [],
      region: this.props.t("fieldReporting.dropDowns.select"),
      regions: [],
      zone: this.props.t("fieldReporting.dropDowns.select"),
      zones: [],
      dealership: this.props.t("fieldReporting.dropDowns.select"),
      dealerships: [],
      allRegions: null,
      regionsByMarketArea: null,
      zonesByMarketArea: null,
      zonesByRegion: null,
      fordDealershipsByZone: null,
      lincolnDealershipsByZone: null,
      salesCodeMap: null,
      dateRange: this.props.t("fieldReporting.dropDowns.last30Days"),
      dateRanges: [
        this.props.t("fieldReporting.dropDowns.last30Days"),
        this.props.t("fieldReporting.dropDowns.last90Days"),
        this.props.t("fieldReporting.dropDowns.yearToDate"),
      ],
      previouslySelectedReportType: null,
      searchBySalesCode: false,
      salesCode: null,
      invalidSalesCodeError: false,
      fromDate: new Date(),
      toDate: new Date(),
      transactionDataChecked: false,
      tradeInDataChecked: false,
      dateRangeMethod: "auto",
      renderPreview: false,
      renderDealerDetailsSection: false,
    };
  }

  componentDidMount() {
    this.props.hideOrShow(true);
    LocationDataClient.getLocationData(this.props.user)
      .then((res) => {
        this.setState({
          marketAreas: this.state.marketAreas.concat(res.allMarketAreas),
          regions: this.state.regions.concat(res.allRegions),
          allRegions: this.state.regions.concat(res.allRegions),
          allZones: res.allZones,
          regionsByMarketArea: res.regionsByMarketArea,
          zonesByMarketArea: res.zonesByMarketArea,
          zonesByRegion: res.zonesByRegion,
          fordDealershipsByZone: res.fordDealershipsByZone,
          lincolnDealershipsByZone: res.lincolnDealershipsByZone,
          salesCodeMap: res.salesCodeCommonIdMap,
          dealerInfo: null,
        });
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
    this.props.hideOrShow(true);
    VehicleLinesClient.getVehicleLines(
      this.props.user,
      this.state.brand === "Ford Brand" ? "FORD" : "LINCOLN"
    )
      .then((res) => {
        this.setState({ vehicleLines: res });
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  }

  handleBrandChange = (e) => {
    let dealerships = [];
    const vehicleLines = {};
    const brand = e.target.value === "Ford Brand" ? "FORD" : "LINCOLN";

    if (this.state.zone !== this.props.t("fieldReporting.dropDowns.select")) {
      if (
        e.target.value === "Ford Brand" &&
        this.state.fordDealershipsByZone[this.state.zone] &&
        this.state.fordDealershipsByZone[this.state.zone].length > 0
      ) {
        dealerships = Object.values(
          this.state.fordDealershipsByZone[this.state.zone]
        );
      } else {
        if (
          this.state.lincolnDealershipsByZone[this.state.zone] &&
          this.state.lincolnDealershipsByZone[this.state.zone].length > 0
        )
          dealerships = Object.values(
            this.state.lincolnDealershipsByZone[this.state.zone]
          );
      }

      if (dealerships.length > 0) {
        dealerships = dealerships
          .filter((dealer) => dealer.regionDesc === this.state.region)
          .map((dealer) => dealer.dealerName);
      }
    }

    this.props.hideOrShow(true);
    VehicleLinesClient.getVehicleLines(this.props.user, brand)
      .then((res) => {
        this.setState({ vehicleLines: res });
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });

    this.setState({
      brand: e.target.value,
      dealerships,
      vehicleLines,
      model: this.props.t("fieldReporting.dropDowns.all"),
      modelYear: this.props.t("fieldReporting.dropDowns.all"),
      marketArea: this.props.t("fieldReporting.dropDowns.select"),
      region: this.props.t("fieldReporting.dropDowns.select"),
      zone: this.props.t("fieldReporting.dropDowns.select"),
      dealership: this.props.t("fieldReporting.dropDowns.select"),
    });
  };

  handleTypeChange = (e) => {
    this.setState({
      reportType: e.target.value,
      transactionDataChecked: false,
      tradeInDataChecked: false,
    });
  };

  handleModelChange = (e) => {
    const model = e.target.value;
    const modelYear = this.props.t("fieldReporting.dropDowns.all");
    if (model === "All") {
      this.setState({
        model,
        modelYear,
        modelYears: [],
      });
    } else {
      const modelYears = this.state.vehicleLines[model].map(
        (vehicle) => vehicle.year
      );
      this.setState({
        model,
        modelYear,
        modelYears,
      });
    }
  };

  handleModelYearChange = (e) => {
    this.setState({ modelYear: e.target.value });
  };

  handleMarketAreaChange = (e) => {
    if (
      e.target.value === this.props.t("fieldReporting.dropDowns.all") &&
      (this.state.region !== this.props.t("fieldReporting.dropDowns.all") ||
        this.state.brand === "Lincoln Brand")
    ) {
      this.setState({
        marketArea: e.target.value,
        zone: this.props.t("fieldReporting.dropDowns.select"),
        zones: this.state.brand === "Lincoln Brand" ? this.state.allZones : [],
        regions:
          this.state.brand === "Lincoln Brand"
            ? []
            : Object.values(this.state.allRegions),
        region: this.props.t("fieldReporting.dropDowns.select"),
        dealership: this.props.t("fieldReporting.dropDowns.select"),
      });
    } else if (
      e.target.value === this.props.t("fieldReporting.dropDowns.all") &&
      (this.state.region === this.props.t("fieldReporting.dropDowns.all") ||
        this.state.brand === "Lincoln Brand")
    ) {
      this.setState({
        marketArea: e.target.value,
        zone: this.props.t("fieldReporting.dropDowns.select"),
        zones: this.state.brand === "Lincoln Brand" ? this.state.allZones : [],
        dealership: this.props.t("fieldReporting.dropDowns.select"),
      });
    } else if (
      e.target.value !== this.props.t("fieldReporting.dropDowns.select") &&
      e.target.value !== this.props.t("fieldReporting.dropDowns.all")
    ) {
      this.setState({
        marketArea: e.target.value,
        regions: Object.values(this.state.regionsByMarketArea[e.target.value]),
        region: this.props.t("fieldReporting.dropDowns.select"),
        zone: this.props.t("fieldReporting.dropDowns.select"),
        zones:
          this.state.brand === "Lincoln Brand"
            ? Object.values(this.state.zonesByMarketArea[e.target.value])
            : [],
        dealership: this.props.t("fieldReporting.dropDowns.select"),
      });
    } else {
      this.setState({
        marketArea: e.target.value,
        regions: Object.values(this.state.allRegions),
        region: this.props.t("fieldReporting.dropDowns.select"),
        zone: this.props.t("fieldReporting.dropDowns.select"),
        zones:
          this.state.brand === "Lincoln Brand"
            ? Object.values(this.state.allZones)
            : [],
        dealership: this.props.t("fieldReporting.dropDowns.select"),
      });
    }
  };

  handleRegionChange = (e) => {
    if (e.target.value === this.props.t("fieldReporting.dropDowns.all")) {
      this.setState({
        region: e.target.value,
        zone: this.props.t("fieldReporting.dropDowns.select"),
        dealership: this.props.t("fieldReporting.dropDowns.select"),
      });
    } else {
      this.setState({
        region: e.target.value,
        zone: this.props.t("fieldReporting.dropDowns.select"),
        dealership: this.props.t("fieldReporting.dropDowns.select"),
        zones:
          e.target.value !== this.props.t("fieldReporting.dropDowns.select")
            ? Object.values(this.state.zonesByRegion[e.target.value])
            : [],
      });
    }
  };

  handleZoneChange = (e) => {
    if (e.target.value === this.props.t("fieldReporting.dropDowns.all")) {
      this.setState({
        zone: e.target.value,
        dealership: this.props.t("fieldReporting.dropDowns.select"),
      });
    } else {
      const zone = e.target.value;
      let dealerships = [];
      if (this.state.brand === "Ford Brand") {
        if (
          this.state.fordDealershipsByZone[zone] &&
          this.state.fordDealershipsByZone[zone].length > 0
        ) {
          dealerships = Object.values(this.state.fordDealershipsByZone[zone]);
        }
      } else {
        if (
          this.state.lincolnDealershipsByZone[zone] &&
          this.state.lincolnDealershipsByZone[zone].length > 0
        ) {
          dealerships = Object.values(
            this.state.lincolnDealershipsByZone[zone]
          );
        }
      }

      if (dealerships.length > 0) {
        if (this.state.brand === "Ford Brand") {
          dealerships = dealerships.filter(
            (dealer) => dealer.regionDesc === this.state.region
          );
        }
        dealerships = dealerships.map((dealer) => dealer.dealerName);
      }
      this.setState({
        zone,
        dealerships,
        dealership: this.props.t("fieldReporting.dropDowns.select"),
      });
    }
  };

  handleDealershipChange = (e) => {
    this.setState({ dealership: e.target.value });
  };

  handleDateRangeChange = (e) => {
    this.setState({ dateRange: e.target.value });
  };

  handleSalesCodeChange = (e) => {
    this.setState({
      salesCode: e.target.value,
    });
  };

  handleFromDateChange = (date) => {
    if (date !== null) {
      this.setState({
        fromDate: date,
      });
    }
  };

  handleToDateChange = (date) => {
    if (date !== null) {
      this.setState({
        toDate: date,
      });
    }
  };

  getTodayDate() {
    const today = new Date();
    return `${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}`;
  }

  downloadPurchaseRequestReport = (reportName, commonId) => {
    this.props.hideOrShow(true);
    const reqData = {
      reportType: this.state.reportType,
      marketArea: this.state.searchBySalesCode ? "" : this.state.marketArea,
      salesCode: this.state.searchBySalesCode ? this.state.salesCode : "",
      region: this.state.region,
      zone: this.state.zone,
      dealership: this.state.dealership,
      daterange:
        this.state.dateRangeMethod !== "manual" ? this.state.dateRange : "",
      dateFrom:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.fromDate)
          : "",
      dateTo:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.toDate)
          : "",
    };
    DealerReportingClient.downloadPurchaseRequestReportInCsv(
      this.props.user,
      this.props.lang,
      commonId.split("|").join("%7C"),
      this.state.transactionDataChecked,
      this.state.tradeInDataChecked,
      reqData
    )
      .then((res) => {
        const filename = reportName + " - " + this.getTodayDate() + ".xlsx";
        const BOM = "\uFEFF";
        const response = BOM + res;
        base64FileDownload(
          decodeURIComponent(response),
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          filename
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  downloadDealerReservationReport = (reportName, commonId) => {
    this.props.hideOrShow(true);
    const reqData = {
      reportType: this.state.reportType,
      marketArea: this.state.searchBySalesCode ? "" : this.state.marketArea,
      salesCode: this.state.searchBySalesCode ? this.state.salesCode : "",
      region: this.state.region,
      zone: this.state.zone,
      dealership: this.state.dealership,
      daterange:
        this.state.dateRangeMethod !== "manual" ? this.state.dateRange : "",
      dateFrom:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.fromDate)
          : "",
      dateTo:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.toDate)
          : "",
    };
    DealerReportingClient.downloadDealerReservationReportInCsv(
      this.props.user,
      this.props.lang,
      commonId.split("|").join("%7C"),
      this.state.transactionDataChecked,
      reqData
    )
      .then((res) => {
        const filename = reportName + " - " + this.getTodayDate() + ".xlsx";
        const BOM = "\uFEFF";
        const response = BOM + res;
        base64FileDownload(
          decodeURIComponent(response),
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          filename
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  downloadOrderConversionReport = (reportName, commonId) => {
    this.props.hideOrShow(true);
    const reqData = {
      reportType: this.state.reportType,
      marketArea: this.state.searchBySalesCode ? "" : this.state.marketArea,
      salesCode: this.state.searchBySalesCode ? this.state.salesCode : "",
      region: this.state.region,
      zone: this.state.zone,
      dealership: this.state.dealership,
      daterange:
        this.state.dateRangeMethod !== "manual" ? this.state.dateRange : "",
      dateFrom:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.fromDate)
          : "",
      dateTo:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.toDate)
          : "",
    };
    DealerReportingClient.downloadOrderConversionReportInCsv(
      this.props.user,
      this.props.lang,
      commonId.split("|").join("%7C"),
      this.state.transactionDataChecked,
      reqData
    )
      .then((res) => {
        const filename = reportName + " - " + this.getTodayDate() + ".xlsx";
        const BOM = "\uFEFF";
        const response = BOM + res;
        base64FileDownload(
          decodeURIComponent(response),
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          filename
        );
      })
      .catch((reason) => {
        logMsg("reason", reason);
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  resetForm = () => {
    if (this.salesCodeInput && this.salesCodeInput.current) {
      this.salesCodeInput.current.value = "";
    }

    this.setState({
      brand: this.props.t("fieldReporting.dropDowns.fordBrand"),
      reportType: this.props.t("fieldReporting.dropDowns.purchaseRequest"),
      marketArea: this.props.t("fieldReporting.dropDowns.select"),
      region: this.props.t("fieldReporting.dropDowns.select"),
      zone: this.props.t("fieldReporting.dropDowns.select"),
      dealership: this.props.t("fieldReporting.dropDowns.select"),
      dateRange: this.props.t("fieldReporting.dropDowns.last30Days"),
      model: this.props.t("fieldReporting.dropDowns.all"),
      modelYear: this.props.t("fieldReporting.dropDowns.all"),
      modelYears: [],
      salesCode: null,
      fromDate: new Date(),
      toDate: new Date(),
      dateRangeMethod: "auto",
    });
  };

  toggleTradeInData = () => {
    this.setState((prevState) => ({
      tradeInDataChecked: !prevState.tradeInDataChecked,
    }));
  };

  toggleTransactionData = () => {
    this.setState((prevState) => ({
      transactionDataChecked: !prevState.transactionDataChecked,
    }));
  };

  // I'm so sorry
  updateSelectionTypes = () => {
    let totalType;
    let filterType;
    let date;
    const all = this.props.t("fieldReporting.dropDowns.all");
    const select = this.props.t("fieldReporting.dropDowns.select");

    if (this.state.searchBySalesCode) {
      filterType = `${this.state.salesCode} - `;
    } else {
      if (this.state.brand === "Ford Brand") {
        // add logic for searching by sales code. Show Dealership name only
        if (this.state.marketArea === all && this.state.region === select) {
          totalType = this.props.t("fieldReporting.nationalTotal");
          filterType = "All Market Areas - ";
        } else if (
          this.state.marketArea !== all &&
          this.state.marketArea !== select &&
          this.state.region === select
        ) {
          totalType = this.props.t("fieldReporting.nationalTotal");
          filterType = this.state.marketArea + " - ";
        } else if (
          this.state.marketArea === all &&
          this.state.region === all &&
          this.state.zone === select
        ) {
          totalType = this.props.t("fieldReporting.nationalTotal");
          filterType = "All Market Areas - All Regions - ";
        } else if (
          this.state.marketArea === all &&
          this.state.region !== all &&
          this.state.region !== select &&
          this.state.zone === select
        ) {
          totalType = this.props.t("fieldReporting.regionalTotal");
          filterType =
            "All Market Areas - " +
            this.state.region +
            " - " +
            this.state.dateRange;
        } else if (
          this.state.marketArea === all &&
          this.state.region !== all &&
          this.state.region !== select &&
          this.state.zone === all &&
          this.state.dealership === select
        ) {
          totalType = this.props.t("fieldReporting.regionalTotal");
          filterType =
            "All Market Areas - " + this.state.region + " - All Zones - ";
        } else if (
          this.state.marketArea === all &&
          this.state.region !== all &&
          this.state.region !== select &&
          this.state.zone !== all &&
          this.state.zone !== select &&
          this.state.dealership === select
        ) {
          totalType = this.props.t("fieldReporting.regionalTotal");
          filterType =
            "All Market Areas - " +
            this.state.region +
            " - " +
            this.state.zone +
            " - ";
        } else if (
          this.state.marketArea === all &&
          this.state.region !== all &&
          this.state.region !== select &&
          this.state.zone !== all &&
          this.state.zone !== select &&
          this.state.dealership === all
        ) {
          totalType = this.props.t("fieldReporting.zoneTotal");
          filterType =
            "All Market Areas - " +
            this.state.region +
            " - " +
            this.state.zone +
            " - All Dealerships - ";
        } else if (
          this.state.marketArea === all &&
          this.state.region !== all &&
          this.state.region !== select &&
          this.state.zone !== all &&
          this.state.zone !== select &&
          this.state.dealership !== all &&
          this.state.dealership !== select
        ) {
          totalType = this.props.t("fieldReporting.dealershipTotal");
          filterType =
            "All Market Areas - " +
            this.state.region +
            " - " +
            this.state.zone +
            " - " +
            this.state.dealership +
            " - ";
        } else if (
          this.state.marketArea !== select &&
          this.state.marketArea !== all &&
          this.state.region === all &&
          this.state.zone === select
        ) {
          totalType = this.props.t("fieldReporting.nationalTotal");
          filterType = this.state.marketArea + " - All Regions - ";
        } else if (
          this.state.marketArea !== select &&
          this.state.marketArea !== all &&
          this.state.region !== select &&
          this.state.region !== all &&
          this.state.zone === all &&
          this.state.dealership === select
        ) {
          totalType = this.props.t("fieldReporting.regionalTotal");
          filterType =
            this.state.marketArea +
            " - " +
            this.state.region +
            " - All Zones - ";
        } else if (
          this.state.marketArea !== select &&
          this.state.marketArea !== all &&
          this.state.region !== select &&
          this.state.region !== all &&
          this.state.zone === select
        ) {
          totalType = this.props.t("fieldReporting.regionalTotal");
          filterType =
            this.state.marketArea + " - Region " + this.state.region + " - ";
        } else if (
          this.state.marketArea !== select &&
          this.state.marketArea !== all &&
          this.state.region !== select &&
          this.state.region !== all &&
          this.state.zone !== all &&
          this.state.zone !== select &&
          this.state.dealership === select
        ) {
          totalType = this.props.t("fieldReporting.zoneTotal");
          filterType =
            this.state.marketArea +
            " - " +
            this.state.region +
            " - " +
            this.state.zone +
            " - ";
        } else if (
          this.state.marketArea !== all &&
          this.state.marketArea !== select &&
          this.state.region !== select &&
          this.state.region !== all &&
          this.state.zone !== all &&
          this.state.zone !== select &&
          this.state.dealership === all
        ) {
          totalType = this.props.t("fieldReporting.zoneTotal");
          filterType =
            this.state.marketArea +
            " - " +
            this.state.region +
            " - " +
            this.state.zone +
            " - All Dealerships - ";
        } else if (
          this.state.marketArea !== all &&
          this.state.marketArea !== select &&
          this.state.region !== select &&
          this.state.region !== all &&
          this.state.zone !== all &&
          this.state.zone !== select &&
          this.state.dealership !== select &&
          this.state.dealership !== all
        ) {
          totalType = this.props.t("fieldReporting.dealershipTotal");
          filterType =
            this.state.marketArea +
            " - " +
            this.state.region +
            " - " +
            this.state.zone +
            " - " +
            this.state.dealership +
            " - ";
        }
      } else {
        if (this.state.marketArea === all && this.state.zone === select) {
          totalType = this.props.t("fieldReporting.nationalTotal");
          filterType = "All Market Areas - ";
        } else if (
          this.state.marketArea !== all &&
          this.state.marketArea !== select &&
          this.state.zone === select
        ) {
          totalType = this.props.t("fieldReporting.nationalTotal");
          filterType = this.state.marketArea + " - ";
        } else if (
          this.state.marketArea === all &&
          this.state.zone === all &&
          this.state.dealership === select
        ) {
          totalType = this.props.t("fieldReporting.nationalTotal");
          filterType = "All Market Areas - All Zones - ";
        } else if (
          this.state.marketArea !== all &&
          this.state.marketArea !== select &&
          this.state.zone === all
        ) {
          totalType = this.props.t("fieldReporting.nationalTotal");
          filterType = this.state.marketArea + " - All Zones - ";
        } else if (
          this.state.marketArea !== all &&
          this.state.marketArea !== select &&
          this.state.zone !== all &&
          this.state.zone !== select &&
          this.state.dealership === select
        ) {
          totalType = this.props.t("fieldReporting.nationalTotal");
          filterType = this.state.marketArea + " - " + this.state.zone + " - ";
        } else if (
          this.state.marketArea === all &&
          this.state.zone !== all &&
          this.state.zone !== select &&
          this.state.dealership === select
        ) {
          totalType = this.props.t("fieldReporting.nationalTotal");
          filterType = "All Market Areas - " + this.state.zone + " - ";
        } else if (
          this.state.marketArea === all &&
          this.state.zone !== all &&
          this.state.zone !== select &&
          this.state.dealership !== all &&
          this.state.dealership !== select
        ) {
          totalType = this.props.t("fieldReporting.nationalTotal");
          filterType =
            "All Market Areas - " +
            this.state.zone +
            " - " +
            this.state.dealership +
            " - ";
        } else if (
          this.state.marketArea === all &&
          this.state.zone !== all &&
          this.state.zone !== select &&
          this.state.dealership === all
        ) {
          totalType = this.props.t("fieldReporting.nationalTotal");
          filterType =
            "All Market Areas - " + this.state.zone + " - All Dealerships - ";
        } else if (
          this.state.marketArea !== all &&
          this.state.marketArea !== select &&
          this.state.zone !== all &&
          this.state.zone !== select &&
          this.state.dealership !== all &&
          this.state.dealership !== select
        ) {
          totalType = this.props.t("fieldReporting.nationalTotal");
          filterType =
            this.state.marketArea +
            " - " +
            this.state.zone +
            " - " +
            this.state.dealership +
            " - ";
        } else if (
          this.state.marketArea !== all &&
          this.state.marketArea !== select &&
          this.state.zone !== all &&
          this.state.zone !== select &&
          this.state.dealership === all
        ) {
          totalType = this.props.t("fieldReporting.nationalTotal");
          filterType =
            this.state.marketArea +
            " - " +
            this.state.zone +
            " - All Dealerships - ";
        }
      }
    }
    if (this.state.dateRangeMethod === "auto") {
      date = isFordBrand
        ? UpperCaseText(this.state.dateRange)
        : this.state.dateRange;
    } else {
      date =
        "FROM " +
        UiDateUtils.formatDateMmDdYyyy(this.state.fromDate) +
        " TO " +
        UiDateUtils.formatDateMmDdYyyy(this.state.toDate);
    }
    this.setState({
      selectionTypes: {
        totalType: totalType,
        filterType: filterType,
        dateRange: date,
        model:
          this.state.model === "All"
            ? this.props.t("fieldReporting.allModels")
            : this.state.model,
        modelYear:
          this.state.modelYear === "All"
            ? this.props.t("fieldReporting.allModelYears")
            : this.state.modelYear,
      },
    });
  };

  updateSearchType = () => {
    this.setState((prevState) => ({
      searchBySalesCode: !prevState.searchBySalesCode,
      marketArea: this.props.t("fieldReporting.dropDowns.select"),
      region: this.props.t("fieldReporting.dropDowns.select"),
      zone: this.props.t("fieldReporting.dropDowns.select"),
      dealership: this.props.t("fieldReporting.dropDowns.select"),
      salesCode: null,
      invalidSalesCodeError: false,
    }));
  };

  toggleRadio = (e) => {
    this.setState({ dateRangeMethod: e.target.value });
  };

  downloadFieldReport = () => {
    this.props.hideOrShow(true);
    let reportName = "FieldReport";
    const today = new Date();
    if (this.state.reportType === "Orders & conversions") {
      reportName =
        "Report-Orders-" + UiDateUtils.formatDateMmDdYyyy(today) + ".xlsx";
    } else if (this.state.reportType === "Reservations & conversions") {
      reportName =
        "Report-Reservations-" +
        UiDateUtils.formatDateMmDdYyyy(today) +
        ".xlsx";
    } else if (this.state.reportType === "Purchase Request") {
      reportName =
        "Report-PurchaseRequest-" +
        UiDateUtils.formatDateMmDdYyyy(today) +
        ".xlsx";
    }
    const BOM = "\uFEFF";
    const response = BOM + this.state.base64String;
    base64FileDownload(
      decodeURIComponent(response),
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      reportName
    );
    this.props.hideOrShow(false);
  };

  downloadDealerReport = () => {
    let commonId;
    if (this.state.searchBySalesCode) {
      commonId = IS_USA_MARKET
        ? this.state.salesCodeMap[UpperCaseText(this.state.salesCode)]
        : IS_FORD_BRAND
        ? this.state.salesCodeMap["F" + UpperCaseText(this.state.salesCode)]
        : this.state.salesCodeMap["L" + UpperCaseText(this.state.salesCode)];
    } else {
      const dealerships =
        this.state.brand === "Ford Brand"
          ? Object.values(this.state.fordDealershipsByZone[this.state.zone])
          : Object.values(this.state.lincolnDealershipsByZone[this.state.zone]);

      commonId = dealerships.filter(
        (dealership) => dealership.dealerName === this.state.dealership
      )[0].commonId;
    }

    switch (this.state.reportType) {
      case this.props.t("fieldReporting.dropDowns.reservationsConversions"):
        this.downloadDealerReservationReport(
          this.props.t("fieldReporting.dropDowns.reservationsConversions"),
          commonId
        );
        break;
      case this.props.t("fieldReporting.dropDowns.ordersConversions"):
        this.downloadOrderConversionReport(
          this.props.t("fieldReporting.dropDowns.ordersConversions"),
          commonId
        );
        break;
      case this.props.t("fieldReporting.dropDowns.purchaseRequest"):
        this.downloadPurchaseRequestReport(
          this.props.t("fieldReporting.dropDowns.purchaseRequest"),
          commonId
        );
        break;
      default:
        break;
    }
  };

  getFieldReport = () => {
    this.props.hideOrShow(true);
    let invalidSalesCodeError = false;
    let renderPreview = true;

    if (this.state.searchBySalesCode) {
      const validBrand =
        (this.state.salesCode &&
          UpperCaseText(this.state.salesCode.charAt(0)) === "F") ||
        UpperCaseText(this.state.salesCode.charAt(0)) === "L";
      const code = this.state.salesCode && this.state.salesCode.substring(1);

      if (!validBrand || !code.match("\\b\\d{5}\\b")) {
        invalidSalesCodeError = true;
        renderPreview = false;
      } else {
        DealerGroupingClient.getDealerGroup(
          this.props.user,
          this.state.salesCode,
          null,
          true
        )
          .then((response) => {
            if (response[0] === null) {
              invalidSalesCodeError = true;
              renderPreview = false;
            } else {
              this.setState({ dealerInfo: response });
            }
          })
          .catch(() => {
            invalidSalesCodeError = true;
            renderPreview = false;
          });
      }
    }
    let filteredReportType = this.state.reportType.replace(/ /g, "");
    filteredReportType = filteredReportType.replace("&", "-");
    this.updateSelectionTypes();
    const reqBody = {
      reportType: filteredReportType,
      model:
        this.state.model !== this.props.t("fieldReporting.dropDowns.all")
          ? this.state.model
          : null,
      modelYear:
        this.state.modelYear !== this.props.t("fieldReporting.dropDowns.all")
          ? this.state.modelYear
          : null,
      marketArea: this.state.marketArea,
      salesCode: this.state.salesCode,
      region: this.state.region,
      zone: this.state.zone,
      dealership: this.state.dealership,
      dateRange:
        this.state.dateRangeMethod === "auto" ? this.state.dateRange : "",
      dateFrom:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.fromDate)
          : "",
      dateTo:
        this.state.dateRangeMethod === "manual"
          ? UiDateUtils.formatDateMmDdYyyy(this.state.toDate)
          : "",
      brand: this.state.brand === "Ford Brand" ? "ford" : "lincoln",
    };
    DealerReportingClient.getFieldReport(this.props.user, reqBody)
      .then((res) => {
        this.setState({
          reportData: res && res.fieldReport,
          base64String: res && res.base64String,
          renderPreview: renderPreview,
          renderDealerDetailsSection:
            renderPreview &&
            ((this.state.dealership &&
              this.state.dealership !==
                this.props.t("fieldReporting.dropDowns.select") &&
              this.state.dealership !==
                this.props.t("fieldReporting.dropDowns.all")) ||
              this.state.salesCode),
          previouslySelectedReportType: this.state.reportType,
          invalidSalesCodeError: invalidSalesCodeError,
        });
      })
      .catch((reason) => {
        logMsg("reason", reason);
        this.setState({
          renderPreview: false,
          renderDealerDetailsSection: false,
        });
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  updateStyles = () => {
    isFordBrand = IS_FORD_BRAND(this.props.brand);
    stylesOverride = isFordBrand ? fordStylesOverride : lincolnStylesOverride;
    document.body.style.backgroundColor = isFordBrand ? "#ffffff" : "#f6f7f7";
    styles = isFordBrand ? fordStyles : lincolnStyles;
  };

  render() {
    this.props.user.updateBrand(this.props.user.dealerBrand);
    publish("brandChanged", this.props.user.dealerBrand);
    this.updateStyles();
    const StyledRadio = withStyles({
      root: {
        color: isFordBrand ? "#102b4e" : "#324047",
        "&$checked": {
          color: isFordBrand ? "#102b4e" : "#f26147",
        },
      },
      checked: {},
    })(Radio);
    return (
      <div
        key={
          "brand_reporting_" +
          this.props.user.dealerBrand +
          "_" +
          this.state.brand
        }
      >
        <section className="pageWrapper">
          <div className={styles.blueBar} />

          <div className={styles.pagePadding}>
            <div className={styles.header}>
              <div className={styles.title}>
                {this.props.t("fieldReporting.reporting")}
              </div>

              <div className={styles.dropDownsWrapper}>
                <div className={styles.dropdownDiv}>
                  <DropDown
                    {...this.props}
                    id="brand"
                    hideDefaultItems={true}
                    onDropdownChange={this.handleBrandChange}
                    items={this.state.brands}
                    selectedValue={this.state.brand}
                  />
                </div>
              </div>
            </div>

            <div className={styles.subTitle}>
              {this.props.t("fieldReporting.generateReport")}
            </div>
          </div>

          <div className={styles.topDivider} />

          <div className={styles.pagePadding}>
            <Grid container>
              <Grid item xs={3} style={stylesOverride.gridItem}>
                {this.props.t("fieldReporting.reportType")}
              </Grid>
            </Grid>

            <div className={styles.typeAndRegionWrapper}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <div className={styles.dropDown}>
                    <div className={styles.dropDownLabel}>
                      {this.props.t("fieldReporting.type")}
                    </div>
                    <div>
                      <DropDown
                        {...this.props}
                        id="type"
                        onDropdownChange={this.handleTypeChange}
                        items={this.state.reportTypes}
                        selectedValue={this.state.reportType}
                        hideDefaultItems={true}
                        fullWidth={true}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className={styles.dropDown}>
                    <div className={styles.dropDownLabel}>
                      {this.props.t("fieldReporting.model")}
                    </div>
                    <div>
                      <DropDown
                        {...this.props}
                        id="model"
                        onDropdownChange={this.handleModelChange}
                        items={Object.keys(this.state.vehicleLines)}
                        selectedValue={this.state.model}
                        showAggregate={true}
                        fullWidth={true}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className={styles.dropDown}>
                    <div className={styles.dropDownLabel}>
                      {this.props.t("fieldReporting.modelYear")}
                    </div>
                    <div>
                      <DropDown
                        {...this.props}
                        id="modelYear"
                        onDropdownChange={this.handleModelYearChange}
                        items={this.state.modelYears}
                        selectedValue={this.state.modelYear}
                        modelYear={true}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <Grid item xs={12}>
              <span className={styles.locationLabel}>
                {this.props.t("fieldReporting.reportLocation")}
              </span>
              <span className={styles.locationLabelSub}>
                {this.props.t("fieldReporting.reportLocationSub")}
              </span>
            </Grid>

            <div className={styles.typeAndRegionWrapper}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  {this.state.searchBySalesCode ? (
                    <ValidatorForm onSubmit={this.handleSave}>
                      <div className={styles.dropDownLabel}>
                        {this.props.t("fieldReporting.enterSalesCode")}
                      </div>
                      <EMPTextValidator
                        name="searchBySalesCode"
                        id="searchBySalesCode"
                        value={this.state.salesCode}
                        inputRef={this.salesCodeInput}
                        onChange={this.handleSalesCodeChange}
                        className={styles.searchInput}
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </ValidatorForm>
                  ) : (
                    <div className={styles.dropDown}>
                      <div className={styles.dropDownLabel}>
                        {this.props.t("fieldReporting.marketArea")}
                      </div>
                      <div>
                        <DropDown
                          {...this.props}
                          id="marketArea"
                          onDropdownChange={this.handleMarketAreaChange}
                          items={this.state.marketAreas}
                          selectedValue={this.state.marketArea}
                        />
                      </div>
                    </div>
                  )}
                </Grid>

                {!this.state.searchBySalesCode &&
                  this.state.brand === "Ford Brand" && (
                    <Grid item xs={12} sm={3}>
                      <div>
                        <div
                          className={
                            this.state.marketArea ===
                            this.props.t("fieldReporting.dropDowns.select")
                              ? styles.dropDownLabelDisabled
                              : styles.dropDownLabel
                          }
                        >
                          {this.props.t("fieldReporting.region")}
                        </div>
                        <div>
                          <DropDown
                            {...this.props}
                            id="region"
                            onDropdownChange={this.handleRegionChange}
                            items={this.state.regions}
                            selectedValue={this.state.region}
                            disabled={
                              this.state.marketArea ===
                              this.props.t("fieldReporting.dropDowns.select")
                            }
                          />
                        </div>
                      </div>
                    </Grid>
                  )}

                {!this.state.searchBySalesCode && (
                  <Grid item xs={12} sm={3}>
                    <div className={styles.dropDown}>
                      <div
                        className={
                          (this.state.brand === "Lincoln Brand" &&
                            this.state.marketArea ===
                              this.props.t(
                                "fieldReporting.dropDowns.select"
                              )) ||
                          (this.state.brand === "Ford Brand" &&
                            this.state.region ===
                              this.props.t(
                                "fieldReporting.dropDowns.select"
                              )) ||
                          (this.state.brand === "Ford Brand" &&
                            this.state.marketArea ===
                              this.props.t("fieldReporting.dropDowns.all") &&
                            this.state.region ===
                              this.props.t("fieldReporting.dropDowns.all"))
                            ? styles.dropDownLabelDisabled
                            : styles.dropDownLabel
                        }
                      >
                        {this.props.t("fieldReporting.zone")}
                      </div>
                      <div>
                        <DropDown
                          {...this.props}
                          id="zone"
                          onDropdownChange={this.handleZoneChange}
                          items={this.state.zones}
                          selectedValue={this.state.zone}
                          disabled={
                            this.state.brand === "Ford Brand"
                              ? this.state.region ===
                                  this.props.t(
                                    "fieldReporting.dropDowns.select"
                                  ) ||
                                this.state.region ===
                                  this.props.t("fieldReporting.dropDowns.all")
                              : this.state.marketArea ===
                                this.props.t("fieldReporting.dropDowns.select")
                          }
                        />
                      </div>
                    </div>
                  </Grid>
                )}

                {!this.state.searchBySalesCode && (
                  <Grid item xs={12} sm={3}>
                    <div className={styles.dropDown}>
                      <div
                        className={
                          this.state.zone ===
                            this.props.t("fieldReporting.dropDowns.select") ||
                          this.state.zone ===
                            this.props.t("fieldReporting.dropDowns.all")
                            ? styles.dropDownLabelDisabled
                            : styles.dropDownLabel
                        }
                      >
                        {this.props.t("fieldReporting.dealership")}
                      </div>
                      <div>
                        <DropDown
                          {...this.props}
                          id="dealership"
                          onDropdownChange={this.handleDealershipChange}
                          items={this.state.dealerships}
                          selectedValue={this.state.dealership}
                          disabled={
                            this.state.zone ===
                              this.props.t("fieldReporting.dropDowns.select") ||
                            this.state.zone ===
                              this.props.t("fieldReporting.dropDowns.all")
                          }
                        />
                      </div>
                    </div>
                  </Grid>
                )}
              </Grid>

              <Grid container>
                <Grid item xs={12} style={stylesOverride.gridItem}>
                  <div className={styles.dropDown}>
                    {this.state.searchBySalesCode &&
                      this.state.invalidSalesCodeError && (
                        <div className={styles.error}>
                          {this.props.t("fieldView.salesCodeError")}
                        </div>
                      )}
                    <div
                      className={styles.searchByLink}
                      onClick={this.updateSearchType}
                    >
                      {this.state.searchBySalesCode
                        ? this.props.t("fieldReporting.searchByDropdown")
                        : this.props.t("fieldReporting.searchBySalesCode")}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <Grid container>
              <Grid item xs={7} style={stylesOverride.gridItem}>
                <span className={styles.dateRange}>
                  {this.props.t("fieldReporting.dateRange")}
                </span>
                <span className={styles.dateRangeSub}>
                  {this.props.t("fieldReporting.dateRangeSub")}
                </span>
              </Grid>
              <Grid item xs={5} />
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={styles.dateRangeWrapper}>
                  <StyledRadioGroup
                    value={this.state.dateRangeMethod}
                    onChange={this.toggleRadio}
                  >
                    <div className={styles.dropDownDateWrapper}>
                      <FormControlLabel
                        value="auto"
                        control={<StyledRadio />}
                        data-testid="auto"
                        checked={this.state.dateRangeMethod === "auto"}
                        label={
                          <div className={styles.dropDownDateWrapper}>
                            <div className={styles.dropdownAutoDate}>
                              <DropDown
                                {...this.props}
                                id="dateRange"
                                onDropdownChange={this.handleDateRangeChange}
                                items={this.state.dateRanges}
                                selectedValue={this.state.dateRange}
                              />
                            </div>
                            <div className={styles.orPadding}>
                              {this.props.t("fieldReporting.or")}
                            </div>
                          </div>
                        }
                      />
                    </div>
                    <FormControlLabel
                      value="manual"
                      control={<StyledRadio />}
                      data-testid="manual"
                      checked={this.state.dateRangeMethod === "manual"}
                      label={
                        <div className={styles.datePickerWrapper}>
                          <div className={styles.toFromDatePickerWrapper}>
                            <div className={styles.fromLabel}>
                              {this.props.t("fieldReporting.from")}
                            </div>
                            <div className={styles.datePickerInputIcon}>
                              <DatePicker
                                selected={this.state.fromDate}
                                onChange={this.handleFromDateChange}
                                popperPlacement="top-end"
                                id="fromDate"
                                maxDate={new Date()}
                                dateFormat={DateConstants.MMddyyyy}
                              />
                              <DatePickerIcon />
                            </div>
                          </div>
                          <div className={styles.dateDivider} />
                          <div className={styles.toFromDatePickerWrapper}>
                            <div className={styles.toLabel}>
                              {this.props.t("fieldReporting.to")}
                            </div>
                            <div className={styles.datePickerInputIcon}>
                              <DatePicker
                                selected={this.state.toDate}
                                onChange={this.handleToDateChange}
                                popperPlacement="top-end"
                                id="toDate"
                                maxDate={new Date()}
                                minDate={this.state.fromDate}
                                dateFormat={DateConstants.MMddyyyy}
                              />
                              <DatePickerIcon />
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </StyledRadioGroup>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.generateReport}>
                  <Reset className={styles.reset} onClick={this.resetForm} />
                  <div onClick={this.resetForm} className={styles.resetLabel}>
                    {this.props.t("fieldReporting.reset")}
                  </div>
                  <div className={styles.buttonContainer}>
                    <EMPButton
                      disabled={
                        this.state.marketArea ===
                          this.props.t("fieldReporting.dropDowns.select") &&
                        !this.state.salesCode
                      }
                      buttonType="contained"
                      submitButton
                      testId="runReport"
                      onClick={this.getFieldReport}
                      buttonText={this.props.t("fieldReporting.run")}
                    ></EMPButton>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={styles.pagePadding}>
            <div className={styles.bottomDivider} />

            <div className={styles.subTitleBottom}>
              {this.props.t("fieldReporting.reportPreview")}
            </div>
            {this.state.renderPreview ? (
              this.state.previouslySelectedReportType ===
              this.props.t(
                "fieldReporting.dropDowns.reservationsConversions"
              ) ? (
                <ReservationsPreview
                  {...this.props}
                  data={
                    this.state.reportData &&
                    this.state.reportData.reservationsAndConversions
                  }
                  renderPreview={this.state.renderPreview}
                  downloadFieldReport={this.downloadFieldReport}
                  selectionTypes={this.state.selectionTypes}
                  dealerInfo={this.state.dealerInfo}
                />
              ) : this.state.previouslySelectedReportType ===
                this.props.t("fieldReporting.dropDowns.ordersConversions") ? (
                <OrdersPreview
                  {...this.props}
                  data={
                    this.state.reportData &&
                    this.state.reportData.ordersAndConversions
                  }
                  renderPreview={this.state.renderPreview}
                  downloadFieldReport={this.downloadFieldReport}
                  selectionTypes={this.state.selectionTypes}
                  dealerInfo={this.state.dealerInfo}
                />
              ) : (
                this.state.previouslySelectedReportType ===
                  this.props.t("fieldReporting.dropDowns.purchaseRequest") && (
                  <PurchaseRequestPreview
                    {...this.props}
                    data={
                      this.state.reportData &&
                      this.state.reportData.purchaseRequest
                    }
                    renderPreview={this.state.renderPreview}
                    downloadFieldReport={this.downloadFieldReport}
                    selectionTypes={this.state.selectionTypes}
                    dealerInfo={this.state.dealerInfo}
                  />
                )
              )
            ) : (
              <div className={styles.reportErrorLabel}>
                {this.props.t("fieldReporting.reportPreviewError")}
              </div>
            )}
          </div>
          {this.state.renderDealerDetailsSection && (
            <div className={styles.dealerDetails}>
              <div className={styles.pagePadding}>
                <div className={styles.dealerName}>
                  {`${this.props.t(
                    "fieldReporting.table.dealerDetailReport"
                  )} - ${UpperCaseText(this.state.reportType)} - ${
                    this.state.selectionTypes.model
                  }, ${this.state.selectionTypes.modelYear}`}
                </div>
                <div className={styles.dealerInfo}>
                  {isFordBrand
                    ? UpperCaseText(this.state.selectionTypes.filterType) +
                      this.state.selectionTypes.dateRange
                    : this.state.selectionTypes.filterType +
                      this.state.selectionTypes.dateRange}
                </div>
                <div className={styles.info}>
                  {this.props.t("fieldReporting.table.dealerDetailReportInfo")}
                </div>
                <div className={styles.reportToIncludeWrapper}>
                  <div className={styles.checkBoxDiv}>
                    <div className={styles.reportToIncludeLabel}>
                      {this.props.t("fieldReporting.table.reportToInclude")}
                    </div>
                    {this.state.reportType ===
                      this.props.t(
                        "fieldReporting.dropDowns.purchaseRequest"
                      ) && (
                      <FormControlLabel
                        control={
                          <StyledCheckBox
                            color="blue"
                            boldCheck={true}
                            textClass={checkboxStyles.cancelLabel}
                            checked={this.state.tradeInDataChecked}
                            name="tradeInData"
                            id="tradeInData"
                            onChange={this.toggleTradeInData}
                          />
                        }
                        label={this.props.t("fieldReporting.tradeInData")}
                      />
                    )}
                    <FormControlLabel
                      control={
                        <StyledCheckBox
                          color="blue"
                          boldCheck={true}
                          textClass={checkboxStyles.cancelLabel}
                          checked={this.state.transactionDataChecked}
                          name="transactionData"
                          id="transactionData"
                          onChange={this.toggleTransactionData}
                        />
                      }
                      label={this.props.t("fieldReporting.transactionData")}
                    />
                  </div>
                  <EMPButton
                    testId="downloadDealerReport"
                    onClick={this.downloadDealerReport}
                    buttonType="text"
                    leftIcon={<CloudDownloadIcon />}
                    buttonText={this.props.t("fieldReporting.download")}
                    submitButton
                  ></EMPButton>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
    );
  }
}

const DropDown = (props) => {
  const menuItems = [];

  if (!props.hideDefaultItems && !props.modelYear && !props.showAggregate) {
    menuItems.push({
      text: "Select",
      value: "Select",
    });
  }
  if (!props.hideDefaultItems) {
    menuItems.push({
      value: "All",
      text: props.showAggregate ? "All (Aggregate)" : "All",
    });
  }
  if (props.items) {
    props.items.forEach((item) => {
      menuItems.push({
        text: item,
        value: item,
      });
    });
  }
  return (
    <FormControl
      id={props.id}
      className={styles.modelFormControl}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
    >
      <EMPSelectField
        testId={props.id}
        value={props.selectedValue}
        onChange={(e) => props.onDropdownChange(e)}
        menuItems={menuItems}
      />
    </FormControl>
  );
};

export default withTranslation("emp")(FieldReporting);
