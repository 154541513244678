import axios from "axios";
import { getHeaders } from "../utils/AuthorizationUtil";
import { BASE_URL } from "../utils/EmpUtil";

export default class DeliveryTimesClient {
  static baseUrl = BASE_URL;

  static saveTimeSlots(token, dealerId, requestBody) {
    const url = `${this.baseUrl}/dealer/time-slots/save?dealerPACode=${dealerId}`;

    return axios.post(url, requestBody, getHeaders(token));
  }

  static getTimeSlots(token, dealerId) {
    const url = `${this.baseUrl}/dealer/time-slots?dealerPACode=${dealerId}`;

    return axios.get(url, getHeaders(token));
  }
}
