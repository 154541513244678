export default class Crc {
  constructor(token, userDetails, history) {
    if (userDetails) {
      this.userId = userDetails.userId;
      this.commonName = userDetails.commonName;
      this.market = userDetails.market;
      this.fullName = userDetails.fullName;
      this.userType = userDetails.userType;
      this.permissions = userDetails.permissions;
      this.brand = userDetails.brand;
    }
    this.token = token;
    this.history = history;
  }
}
