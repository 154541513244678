import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Grid,
  InputLabel,
  Box,
  FormControl,
  makeStyles,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import CustomerReservationsClient from "../../clients/CustomerReservationsClient";
import EMPSelectField from "../../shared/components/EMPSelectField/EMPSelectField";
import EMPDialog from "../../shared/components/EMPDialog/EMPDialog";
import { DEALER_ACTIONS } from "../../common/Constants";

const useErrorStyles = makeStyles({
  root: {
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
  },
  body1: {
    fontSize: "16px",
    color: "#000000",
  },
  colorError: {
    fontSize: "14px",
    backgroundColor: "rgba(246, 229, 231, 1.00)",
    color: "#000000",
    padding: "8px 16px",
    lineHeight: "20px",
  },
});

const useLabelStyles = makeStyles({
  root: {
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
    fontSize: 14,
    color: "#000000",
    fontWeight: "400",
    lineHeight: "20px",
    marginBottom: 8,
  },
});

const CancelReasons = ({ token, user, t, updateDropdown, value }) => {
  const labelClasses = useLabelStyles();
  const errorClasses = useErrorStyles();

  function setMenuItems(data) {
    const menuItems = data?.cancellationReasons.map((item) => ({
      text: item.displayText,
      value: item.code,
    }));

    menuItems.unshift({
      text: t("CustomerOrderDetails.pleaseSelect"),
      value: "",
      disabled: true,
    });

    return menuItems;
  }

  const {
    isFetching,
    isError,
    data: cancellationReasons,
    error,
  } = useQuery({
    queryKey: ["cancelReasons", "consentReasons"],
    queryFn: async () => {
      const { data } = await CustomerReservationsClient.getCancelOrderReasons(
        token,
        user.lang,
        "consentReasons",
        user.commonId
      );

      return data;
    },
    select: setMenuItems,
  });

  if (isFetching) {
    return (
      <Grid container spacing={2}>
        <Grid item>
          <CircularProgress size={24} />
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle1"
            classes={{
              root: labelClasses.root,
            }}
            align="center"
          >
            {t("common.loading")}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (isError) {
    return (
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          color="error"
          classes={{
            root: errorClasses.root,
            subtitle1: errorClasses.subtitle1,
            colorError: errorClasses.colorError,
          }}
        >
          {`${t("common.error")}: ${error.message}`}
          <br />
          {t("common.errorMessage1")}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <InputLabel disableAnimation shrink={false} classes={labelClasses}>
        {t("OrderDetails.resubmitReason")}
      </InputLabel>
      <FormControl id="resubmitOfflineDropDown" fullWidth={true}>
        <EMPSelectField
          testId="dropdown"
          value={value}
          onChange={(event) => updateDropdown(event)}
          menuItems={cancellationReasons}
        />
      </FormControl>
    </Grid>
  );
};

const ResubmitOfflineDialog = ({
  t,
  user,
  open,
  onClose,
  orderId,
  token,
  commonDealerId,
  updateReservation,
}) => {
  const [dropDownValue, setDropDownValue] = useState("");

  const updateDropdown = (event) => setDropDownValue(event.target.value);

  const disableCondition = () => {
    const { rolePermissions } = user.permissions;
    const lacksPermission = rolePermissions.hasOwnProperty(
      "processOfflineResubmitSubmit"
    );
    const isDropDownValueEmpty =
      dropDownValue === undefined || dropDownValue === "";

    return lacksPermission && isDropDownValueEmpty;
  };

  return (
    <EMPDialog
      hasCloseIcon
      open={open}
      handleClose={onClose}
      dialogTitle={t("OrderDetails.resubmitProcessOffline")}
      titleType="warning"
      hasActionButton
      actionButtonText={t("OrderDetails.confirmResubmit")}
      disableActionButton={disableCondition()}
      hasSecondaryActionButton
      secondaryActionButtonText={t("OrderDetails.cancelResubmit")}
      onSecondaryAction={onClose}
      onConfirm={() =>
        updateReservation(
          orderId,
          DEALER_ACTIONS.INSTORE,
          "reservations_submitted",
          commonDealerId,
          dropDownValue
        ).then(() => {
          onClose(commonDealerId);
        })
      }
    >
      <Grid
        container
        data-testid="resubmitOfflineDialog"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Box mb={2}>{t("OrderDetails.customerDeclinedRequest")}</Box>
        </Grid>

        <CancelReasons
          user={user}
          t={t}
          token={token}
          updateDropdown={updateDropdown}
          value={dropDownValue}
        />
      </Grid>
    </EMPDialog>
  );
};

export default ResubmitOfflineDialog;
