import React, { Component } from "react";
import styles from "./Help.module.scss";
import { withTranslation } from "react-i18next";
import { helpContactInfo } from "./HelpRegionData.js";

class Help extends Component {
  render() {
    const market = this.props.market.toUpperCase();
    const contactInfo = helpContactInfo[market];
    return contactInfo ? (
      <div className="pageWrapper">
        <div className="pagePadding">
          <div className={styles.headerWrapper}>
            <span
              id="helpHeader"
              data-testid="helpHeader"
              className={styles.helpHeader}
            >
              {this.props.t("Help.header")}
            </span>
            <span
              data-testid="contactSubHeader"
              className={styles.helpSubheader}
            >
              {this.props.t(contactInfo.contactSubHeader)}
            </span>
            {contactInfo.displayLink && (
              <span
                id="subHeader"
                data-testid="contactLink"
                className={styles.helpSubheader}
              >
                <a
                  href={contactInfo.contactLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {contactInfo.contactLink}
                </a>
              </span>
            )}
          </div>
          {contactInfo.displayContact && (
            <div className={styles.contactWrapper}>
              <span
                id="contactHeader"
                data-testid="contactHeader"
                className={styles.contactHeader}
              >
                {this.props.t("Help.contactHeader")}
              </span>
              <hr className={styles.line} />

              {contactInfo.displayPhone && (
                <div
                  className={styles.contactInfo}
                  data-testid="phoneNumber"
                  id="phoneNumber"
                >
                  <span>{this.props.t(contactInfo.addressTitle)}</span>

                  {contactInfo.additionalAddress ? (
                    <span>{this.props.t(contactInfo.address1)}</span>
                  ) : null}
                  {contactInfo.additionalAddress ? (
                    <span>{this.props.t(contactInfo.address2)}</span>
                  ) : null}
                  <span>{contactInfo.phoneNumber}</span>
                </div>
              )}
              {contactInfo.contactEmail && (
                <span className={styles.contactInfo} data-testid="contactEmail">
                  {contactInfo.contactEmail}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    ) : null;
  }
}

export default withTranslation("emp")(Help);
