import {
  CANADA_ENGLISH,
  CANADA_FRENCH,
  TERMS_AND_CONDITIONS,
  USA_ENGLISH,
  USA_MARKET_CODE,
} from "../../common/Constants";
import UpperCaseText from "../../utils/UpperCaseText";

export default class NADealer {
  constructor(token, userDetails, history) {
    if (userDetails) {
      this.market = userDetails.market;
      this.userId = userDetails.userId;
      this.loggedInDealerId = userDetails.dealerId;
      this.userType = userDetails.userType;
      this.commonName = userDetails.commonName;
      this.dealerId = userDetails.dealerId;
      this.fullName = userDetails.fullName;
      this.permissions = userDetails.permissions;
      this.enrolledSalesCodes = userDetails.enrolledSalesCodes;
      this.brand = userDetails.brand;
      this.dealerType = userDetails.dealerType;
      this.dealerGroupId =
        userDetails.market.toUpperCase() + userDetails.dealerId;
      this.dealerBrand = userDetails.dealerBrand;
      this.dealersNotFullyOnboarded = null;
    }
    this.showAll = true;
    this.token = token;
    this.history = history;
  }

  isCanadaDealer() {
    return UpperCaseText(this.market) === "CAN";
  }

  updatePendingActions(pendingActions) {
    this.pendingActions = pendingActions;
  }

  updateBusinessSettingsTab(tab) {
    this.businessSettingsPage = tab;
  }

  isMainDealer(dealerType) {
    return dealerType === "MDLR" || dealerType === "HUB";
  }

  reformatCommonId(commonId) {
    return commonId.split("%7C").join("|");
  }

  setLanguageCode(currentLanguage) {
    this.lang =
      this.market.toUpperCase() === USA_MARKET_CODE
        ? USA_ENGLISH
        : currentLanguage === CANADA_ENGLISH
        ? CANADA_ENGLISH
        : CANADA_FRENCH;
  }

  updateBrand(brand) {
    this.brand = brand;
  }

  showWarning(value) {
    this.displayWarning = value;
  }

  getEncodedCommonId() {
    return this.commonId ? this.commonId.split("|").join("%7C") : "";
  }

  updateCurrentDealer(currentDealer, showAll) {
    if (currentDealer) {
      this.dealerId = currentDealer.paCode;
      this.commonId = currentDealer.commonId;
      this.commonDealerId = currentDealer.commonId;
      this.dealerType = currentDealer.dealerType;
      this.name = currentDealer.name;
      this.dealerName = currentDealer.name
        ? currentDealer.name
        : currentDealer.dealerName;
      //this.lang = currentDealer.lang;
      this.salesCode = currentDealer.salesCode;
    }
    this.showAll = showAll;
    return this;
  }

  setDealerGroup(dealerGroup) {
    this.dealerGroup = dealerGroup;
    this.isLoggedInUserMDLR =
      dealerGroup.filter(
        (d) => d.dealerType === "HUB" || "MDLR" === d.dealerType
      ).length >= 1;

    const dealerGroupingsMap = {};
    const dealerGroupingsMapByDealerId = {};
    this.dealerGroup.forEach((dealer) => {
      dealerGroupingsMap[dealer.commonId] = dealer;
      dealerGroupingsMapByDealerId[dealer.completeDealerId] = dealer;
      dealer.regionalId = dealer.paCode;
      dealer.isOnboarded = true;
    });

    this.dealerGroupingsMap = dealerGroupingsMap;
    this.dealerGroupingsMapByDealerId = dealerGroupingsMapByDealerId;
  }

  getCommonIds() {
    return this.getDealerIdsOrCommonIds(true);
  }

  getDealerIdsWithSDLR() {
    return this.getDealerIdsOrCommonIds(false, false);
  }

  getDealerIdsWithoutSDLR() {
    return this.getDealerIdsOrCommonIds(false, true);
  }

  getCommonIdsWithoutSDLR() {
    return this.getDealerIdsOrCommonIds(true, true);
  }

  getDealerIdsOrCommonIds(isCommonId, filterSDLR = false) {
    const ids = [];

    function getDealerOrCommonId(dealer, isCommonId) {
      let id;
      if (isCommonId) {
        id = dealer.commonId.split("|").join("%7C");
      } else {
        id = dealer.completeDealerId;
      }
      return id;
    }

    if (this.dealerGroup && this.dealerGroup.length > 0) {
      let dealerGroup = this.dealerGroup;
      dealerGroup = dealerGroup.filter((dealer) =>
        dealer.commonId.endsWith(this.brand)
      );
      const currentDealer = dealerGroup.filter(
        (dealer) => dealer.paCode === this.dealerId
      )[0];
      if (
        (currentDealer.dealerType === "HUB" ||
          "MDLR" === currentDealer.dealerType) &&
        this.showAll
      ) {
        //If logged in as Main and viewing as ALL
        dealerGroup.forEach((dealer) => {
          if (filterSDLR && dealer.dealerType === "SDLR") return;
          ids.push(getDealerOrCommonId(dealer, isCommonId));
        });
      } else if (
        this.loggedInDealerId === this.dealerId ||
        "SDLR" !== currentDealer.dealerType ||
        !filterSDLR
      ) {
        //If Main is viewing as Individual or SDLR/BRAN login individually or If Main not selects SDLR
        ids.push(getDealerOrCommonId(currentDealer, isCommonId));
      }
    } else {
      ids.push(this.commonDealerId.split("|").join("%7C"));
    }
    return ids.toString();
  }

  getOnboardedDealerGroup(filterSDLR = false) {
    if (this.dealerGroup) {
      const onboardedDealers = this.dealerGroup.filter(
        (dealer) => dealer.isOnboarded
      );
      return filterSDLR
        ? onboardedDealers.filter((dealer) => dealer.dealerType !== "SDLR")
        : onboardedDealers;
    } else {
      return [];
    }
  }

  updateUnOnboardedDealers(dealersToRemove, action) {
    if (this.dealersNotFullyOnboarded) {
      if (action === TERMS_AND_CONDITIONS) {
        const TCDealers = this.dealersNotFullyOnboarded[TERMS_AND_CONDITIONS];
        this.dealersNotFullyOnboarded[TERMS_AND_CONDITIONS] = TCDealers.filter(
          (dealer) => !dealersToRemove.includes(dealer)
        );
      }
    }
  }

  generateResourcePath() {
    return this.isCanadaDealer()
      ? `dealer/${this.dealerGroupId}`
      : `dealer/${this.dealerId}`;
  }
}
