import React from "react";
import "../../../../../styles/sharedStyles.scss";
import Grid from "@material-ui/core/Grid";
import fordStyles from "./RefundHoldDepositDialog.module.scss";
import lincolnStyles from "./RefundHoldDepositDialogLincoln.module.scss";
import { withTranslation } from "react-i18next";
import { IS_FORD_BRAND } from "../../../../../utils/EmpUtil";
import EMPDialog from "../../../../../shared/components/EMPDialog/EMPDialog";
import EMPCheckbox from "../../../../../shared/components/EMPCheckbox/EMPCheckbox";

class RefundHoldDepositDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirm: false,
    };
  }

  handleConfirm = (e) => {
    this.props.handleConfirm();
    this.handleClose(e);
  };

  handleClose = (e) => {
    this.setState({ confirm: false });
    this.props.onClose(e);
  };

  toggleCheckbox = () => {
    this.setState((prevState) => ({
      confirm: !prevState.confirm,
    }));
  };

  render() {
    const styles = IS_FORD_BRAND(this.props.user.brand)
      ? fordStyles
      : lincolnStyles;

    return (
      <EMPDialog
        open={this.props.open}
        handleClose={this.handleClose}
        hasCloseIcon
        titleType="warning"
        dialogTitle={this.props.t(
          "OrderDetailsConsumerDeposit.refundHoldDepositTitle"
        )}
        hasActionButton
        actionButtonText={this.props.t(
          "OrderDetailsConsumerDeposit.holdDepositConfirm"
        )}
        onConfirm={this.handleConfirm}
        disableActionButton={
          !this.props.user.permissions.rolePermissions.hasOwnProperty(
            "depositSubmit"
          ) || !this.state.confirm
        }
        hasSecondaryActionButton
        secondaryActionButtonText={this.props.t(
          "OrderDetailsConsumerDeposit.holdDepositCancel"
        )}
        onSecondaryAction={this.handleClose}
      >
        <Grid
          container
          data-testid="refundHoldDepositDialog"
          className={styles.dialogContainer}
        >
          <Grid item xs={12}>
            <p className={styles.subTitle}>
              {this.props.t(
                "OrderDetailsConsumerDeposit.refundHoldDepositSubTitle"
              )}
            </p>
          </Grid>

          <Grid item xs={12}>
            <EMPCheckbox
              checked={this.state.confirm}
              name="chkBxConfirm"
              id="chkBxConfirm"
              label={this.props.t(
                "OrderDetailsConsumerDeposit.refundHoldDepositConfirmText"
              )}
              handleChange={this.toggleCheckbox}
            ></EMPCheckbox>
          </Grid>

          <Grid item xs={12}>
            <ul className={styles.bulletPoints}>
              <li>
                {this.props.t(
                  "OrderDetailsConsumerDeposit.refundHoldDepositBullet1"
                )}
              </li>
              <li>
                {this.props.t(
                  "OrderDetailsConsumerDeposit.refundHoldDepositBullet2"
                )}
              </li>
              <li>
                {this.props.t(
                  "OrderDetailsConsumerDeposit.refundHoldDepositBullet3"
                )}
              </li>
              <li>
                {this.props.t(
                  "OrderDetailsConsumerDeposit.refundHoldDepositBullet4"
                )}
              </li>
              <li>
                {this.props.t(
                  "OrderDetailsConsumerDeposit.refundHoldDepositBullet5"
                )}
              </li>
            </ul>
          </Grid>
        </Grid>
      </EMPDialog>
    );
  }
}

export default withTranslation("emp")(RefundHoldDepositDialog);
