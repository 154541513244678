import React, { Component } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import termsHistoryStyles from "./termsHistoryStyles";
import styles from "./TermsAndConditionsHistory.module.scss";
import ClearIcon from "@material-ui/icons/Clear";
import { withTranslation } from "react-i18next";
import ConfirmDialog from "../../../shared/confirmDialog/ConfirmDialog";
import HasPermissionTo from "../../../common/HasPermissionTo";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import MotionChevronRight from "../../../shared/Icons/MotionChevronRight";
import EMPEmptyState from "../../../shared/components/EMPEmptyState/EMPEmptyState";

class TermsAndConditionsHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
    };

    this.deleteTermsDialogProps = {
      title: this.props.t("TermsandConditionsHistory.deleteTitle"),
      bodyText: this.props.t("TermsandConditionsHistory.deleteBody"),
      confirm: this.props.t("TermsandConditionsHistory.confirm"),
      cancel: this.props.t("TermsandConditionsHistory.cancel"),
    };
  }

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleConfirm = () => {
    this.props.deleteTerms(this.version);
    this.handleClose();
  };

  deleteTermsDialog = (version) => {
    this.version = version;
    this.setState({ openDialog: true });
  };

  loadTableCellHeader = (style, divStyle, column, displayColumn) => {
    return (
      <TableCell style={style}>
        {displayColumn && (
          <div className={divStyle} id="header">
            {this.props.t("TermsandConditionsHistory." + column)}
          </div>
        )}
      </TableCell>
    );
  };

  showDeleteIcon = (index) => {
    return (
      this.props.termsAndConditionsHistory.length > 1 &&
      index === 0 &&
      this.props.termsAndConditionsHistory[0].canBeDeleted
    );
  };

  render() {
    return (
      <div className={styles.title}>
        <h2 className="pagePaddingLeft" id="termsHeader">
          {this.props.t("TermsandConditionsHistory.title")}
        </h2>
        <Table id="termsHistory">
          <TableHead>
            <TableRow
              style={termsHistoryStyles.tableRow}
              className={styles.tableHeader}
            >
              {this.loadTableCellHeader(
                termsHistoryStyles.documentHeader,
                styles.alignStart,
                "document",
                true
              )}
              {this.props.isDealer &&
                this.loadTableCellHeader(
                  termsHistoryStyles.effectiveDateHeader,
                  styles.alignCenter,
                  "acceptedOn",
                  true
                )}
              {this.loadTableCellHeader(
                termsHistoryStyles.effectiveDateHeader,
                styles.alignCenter,
                "effectiveFrom",
                true
              )}
              {this.loadTableCellHeader(
                termsHistoryStyles.downloadHeader,
                null,
                "download",
                false
              )}
              {!this.props.isDealer &&
                this.loadTableCellHeader(null, null, null, false)}
            </TableRow>
          </TableHead>

          <ConfirmDialog
            open={this.state.openDialog}
            dialogProps={this.deleteTermsDialogProps}
            onConfirm={this.handleConfirm}
            onCancel={this.handleClose}
            onClose={this.handleClose}
            boldTitle={false}
            {...this.props}
          />

          <TableBody className={styles.tableBody}>
            {this.props.termsAndConditionsHistory.map(
              (termsAndConditions, index) => (
                <TableRow
                  className="pagePaddingLeft pagePaddingRight"
                  id={`row_${index}`}
                  key={index}
                >
                  <TableCell style={termsHistoryStyles.document}>
                    {this.props.user.market.toUpperCase() +
                      " " +
                      this.props.t("TermsandConditionsHistory.documentName") +
                      termsAndConditions.version}
                  </TableCell>
                  {this.props.isDealer && (
                    <TableCell style={termsHistoryStyles.effectiveDate}>
                      {termsAndConditions.accepted ? (
                        termsAndConditions.dealerApprovedDate
                      ) : (
                        <EMPEmptyState />
                      )}
                    </TableCell>
                  )}
                  <TableCell style={termsHistoryStyles.effectiveDate}>
                    {termsAndConditions.effectiveDate}
                  </TableCell>
                  <TableCell style={termsHistoryStyles.download}>
                    <div>
                      <span>
                        {termsAndConditions.accepted ? (
                          <EMPButton
                            testId={`acceptLink_${index}`}
                            onClick={() =>
                              termsAndConditions.accepted &&
                              this.props.handleTermsAndConditionsDownload(
                                termsAndConditions
                              )
                            }
                            buttonType="text"
                            leftIcon={<CloudDownloadIcon />}
                            buttonText={this.props.t(
                              "TermsandConditionsHistory.download"
                            )}
                          ></EMPButton>
                        ) : (
                          this.props.isDealer && (
                            <EMPButton
                              testId={`acceptLink_${index}`}
                              onClick={() =>
                                this.props.alterComponentView(
                                  termsAndConditions
                                )
                              }
                              buttonType="text"
                              rightIcon={<MotionChevronRight />}
                              buttonText={this.props.t(
                                "TermsandConditionsHistory.review"
                              )}
                            ></EMPButton>
                          )
                        )}
                      </span>
                    </div>
                  </TableCell>
                  {!this.props.isDealer && (
                    <TableCell style={termsHistoryStyles.rightCell}>
                      <div className={styles.deleteWrapper}>
                        <EMPButton
                          testId={`acceptLink_${index}`}
                          onClick={() =>
                            this.props.handleTermsAndConditionsDownload(
                              termsAndConditions
                            )
                          }
                          buttonType="text"
                          leftIcon={<CloudDownloadIcon />}
                          buttonText={this.props.t(
                            "TermsandConditionsHistory.download"
                          )}
                        ></EMPButton>
                        <HasPermissionTo
                          permissions={
                            this.props.user.permissions.rolePermissions
                          }
                          perform={["deleteTermsConditions"]}
                          condition={this.showDeleteIcon(index)}
                          render={() => (
                            <Button
                              style={termsHistoryStyles.button}
                              data-testid={"delete_" + index}
                              onClick={() =>
                                this.deleteTermsDialog(
                                  termsAndConditions.version
                                )
                              }
                            >
                              <ClearIcon />
                            </Button>
                          )}
                        />
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withTranslation("emp")(TermsAndConditionsHistory);
