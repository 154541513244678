import Grid from "@material-ui/core/Grid";
import React from "react";
import fordStyles from "./DashboardCards-Ford.module.scss";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";
import lincolnStyles from "./DashboardCards-Lincoln.module.scss";

let isFordBrand = true;
let styles = fordStyles;

export const SubTitle = (props) => {
  const { data, type } = props;

  isFordBrand = IS_FORD_BRAND(props.user.brand);
  styles = isFordBrand ? fordStyles : lincolnStyles;

  return (
    <Grid container className={styles.subTitle}>
      {type !== "accessories" && data && data.hasOwnProperty("addedToday") && (
        <Grid item>
          <p>
            <span>{data.addedToday}</span>
            {data.addedToday !== null
              ? props.t("DealerDashboard.addedToday")
              : props.t("DealerDashboard.pleaseRefresh")}
          </p>
        </Grid>
      )}

      {type !== "accessories" && data && data.hasOwnProperty("active") && (
        <Grid item>
          <p>
            <span>{data.active}</span>
            {props.t("DealerDashboard.active")}
          </p>
        </Grid>
      )}

      {type !== "accessories" &&
        data &&
        data.hasOwnProperty("vehicleHolds") && (
          <Grid item>
            <p>
              <span>{data.vehicleHolds} </span>
              {data.vehicleHolds === 1
                ? props.t("DealerDashboard.vehicleHold")
                : props.t("DealerDashboard.vehicleHolds")}
            </p>
          </Grid>
        )}
      {type !== "accessories" && data && data.hasOwnProperty("total") && (
        <Grid item>
          <p>
            <span>{data.total}</span>
            {props.t("DealerDashboard.total")}
          </p>
        </Grid>
      )}
      {type === "accessories" && data && data.hasOwnProperty("total") && (
        <Grid item>
          <p>
            <span>{data.total}</span>
            {data.total === 1
              ? props.t("DealerDashboard.accessoriesSubTitleSingular")
              : props.t("DealerDashboard.accessoriesSubTitlePlural")}
          </p>
        </Grid>
      )}
    </Grid>
  );
};
