import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import stylesOverride from "../stylesOverride";
import styles from "../NACustomerOrders.module.scss";
import { withTranslation } from "react-i18next";
import UpperCaseText from "../../../utils/UpperCaseText";
import { Link } from "react-router-dom";
import HasPermissionTo from "../../../common/HasPermissionTo";
import UiDateUtils from "../../../utils/UiDateUtils";
import MotionChevronRight from "../../../shared/Icons/MotionChevronRight";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import EMPEmptyState from "../../../shared/components/EMPEmptyState/EMPEmptyState";

class ModelESearchOrders extends Component {
  render() {
    const ordersData =
      this.props.orders &&
      this.props.orders.filter(
        (order) =>
          order.orderType &&
          UpperCaseText(order.orderType) === "ORDER" &&
          order.journeyType === "Model-E"
      );
    const { appointments } = this.props;

    return ordersData && ordersData.length !== 0 ? (
      <Table>
        <TableHead className={styles.tableHead}>
          <TableRow style={stylesOverride.resultsHeader}>
            <TableCell style={stylesOverride.orderTypeHeader}>
              <div>{this.props.t("ModelEOrders.orderIdSearchHeader")}</div>
            </TableCell>
            <HasPermissionTo
              perform={["customerNameColumnInCHGrid"]}
              permissions={this.props.user.permissions.rolePermissions}
              render={() => {
                return (
                  <TableCell style={stylesOverride.customerNameHeader}>
                    <div className={`${styles.alignStart}`}>
                      {this.props.t("ModelEOrders.customerNameSearchHeader")}
                    </div>
                  </TableCell>
                );
              }}
            />
            <TableCell style={stylesOverride.specsHeader}>
              <div className={` ${styles.alignStart}`}>
                {this.props.t("ModelEOrders.vehicleSpecsSearchHeader")}
              </div>
            </TableCell>
            <TableCell style={stylesOverride.scheduledDeliveryHeader}>
              <div>
                {UpperCaseText(
                  this.props.t("ModelEOrders.DeliverySearchHeader")
                )}
              </div>
            </TableCell>
            <TableCell style={stylesOverride.purchaseDetailsHeader}>
              <div>
                {UpperCaseText(
                  this.props.t("ModelEOrders.viewDetailsSearchHeader")
                )}
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={styles.tableBody}>
          {ordersData &&
            ordersData.map((order) => (
              <TableRow key={order.id}>
                <TableCell style={stylesOverride.purchaseRequestType}>
                  <div
                    className={styles.orderTypeDiv}
                    data-testid="orderTypeDiv"
                  >
                    <Link
                      to={{
                        pathname: `/dealer/${order.dealerId}/customer-reservations/model-e-details/${order.id}`,
                      }}
                      className={styles.tableLink}
                    >
                      {order.id}
                    </Link>
                  </div>
                </TableCell>
                <HasPermissionTo
                  perform={["customerNameColumnInCHGrid"]}
                  permissions={this.props.user.permissions.rolePermissions}
                  render={() => {
                    return (
                      <TableCell style={stylesOverride.customerName}>
                        {order.customerName || <EMPEmptyState />}
                      </TableCell>
                    );
                  }}
                />

                <TableCell style={stylesOverride.specs}>
                  <div className={styles.specsWrapper}>
                    <div className={styles.nameplate}>
                      {order.nameplate}{" "}
                      {order.vinNumber ? ` (${order.vinNumber})` : ""}
                    </div>
                    <span className={styles.specs}>
                      {order.specSummary &&
                        order.specSummary.filter(Boolean).join(" / ")}
                    </span>
                  </div>
                </TableCell>

                <TableCell style={stylesOverride.specs}>
                  {appointments &&
                  appointments[order.id] &&
                  appointments[order.id].deliveryAppointment.date ? (
                    <div className={styles.specsWrapper}>
                      <span
                        style={{
                          color: "#4d4d4d",
                        }}
                      >
                        {UiDateUtils.formatToDayDateTime(
                          appointments[order.id].deliveryAppointment.date
                        )}
                      </span>
                      <br />
                      {appointments[order.id].deliveryAppointment
                        .dealershipEmployee ? (
                        <span
                          style={{
                            color: "green",
                          }}
                        >
                          {`${this.props.t("ModelEOrders.assignedTo")} ${
                            appointments[order.id].deliveryAppointment
                              .dealershipEmployeeName
                          }`}
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "#D62D0A",
                          }}
                        >
                          {this.props.t("ModelEOrders.needsAssignment")}
                        </span>
                      )}
                    </div>
                  ) : (
                    <div className={styles.specsWrapper}>
                      <span
                        style={{
                          color: "#4d4d4d",
                        }}
                      >
                        {this.props.t("ModelEOrders.deliveryNotScheduled")}
                      </span>{" "}
                    </div>
                  )}
                </TableCell>

                <TableCell style={stylesOverride.details}>
                  <EMPButton
                    testId="orderDetailLink"
                    buttonElement={Link}
                    buttonType="text"
                    rightIcon={<MotionChevronRight />}
                    buttonText={this.props.t("ModelEOrders.viewDetails")}
                    link={`/dealer/${order.dealerId}/customer-reservations/model-e-details/${order.id}`}
                  ></EMPButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    ) : (
      this.props.noContentText &&
        this.props.getNoContent(this.props.noContentText)
    );
  }
}

export default withTranslation("emp")(ModelESearchOrders);
