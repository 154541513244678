import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import fordStyles from "./BrandSettings-Ford.module.scss";
import lincolnStyles from "./BrandSettings-Lincoln.module.scss";
import Grid from "@material-ui/core/Grid";
import Item from "../../../shared/item/Item";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import ValidationRules from "../../../common/ValidationRules";
import { Dialog, makeStyles, withStyles } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import { DNP, SRP } from "../PriceType";
import ConfirmDialog from "../../../shared/confirmDialog/ConfirmDialog";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";
import BrandSettingsClient from "../../../clients/BrandSettingsClient";
import { logMsg } from "../../../common/Logger";
import HasPermissionTo from "../../../common/HasPermissionTo";
import {
  displayError,
  ERROR_PROCESSING,
  is404,
  isProcessingError,
  PAGE_ERROR,
} from "../../../errors/ErrorConstants";
import StyledChevronRightIcon from "../../../shared/Icons/StyledChevronRightIcon";
import { BRAND_NAMES } from "../../../common/Constants";
import TitleCase from "../../../utils/TitleCase";
import CapitalizeEachWord from "../../../utils/CapitalizeEachWord";
import StyledCheckIcon from "../../../shared/Icons/StyledCheckIcon";
import StyledCloseIcon from "../../../shared/Icons/StyledCloseIcon";
import * as AnalyticsService from "../../../shared/services/analytics/AnalyticsService";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";

const BrandSettingsWhiteButton = withStyles({
  root: {
    fontFamily: "FordAntennaCondMedium, sans-serif",
    fontWeight: 500,
    color: "#102b4e",
    fontSize: "1rem",
    letterSpacing: "0.5px",
  },
})(Button);

const BrandSettingsBlueButton = withStyles({
  root: {
    fontFamily: "FordAntennaCondMedium, sans-serif",
    fontWeight: 500,
    backgroundColor: "#102b4e",
    color: "#ffffff",
    fontSize: "1rem",
    letterSpacing: "0.5px",
  },
})(Button);

const EMPInputStyles = makeStyles({
  root: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 14,
    fontWeight: "400",
    lineHeight: "20px",
    borderRadius: 8,
    outline: "1px solid #6B7786",
    color: "#00142E",
    padding: "8px 16px",
  },

  disabled: {
    borderRadius: 8,
    backgroundColor: "#E6E8EA",
  },
  focused: {
    outline: "2px solid #066FEF",
  },
  colorSecondary: {
    outlineColor: "#008200",
    "&:focused": {
      outlineColor: "#008200",
    },
  },
  error: {
    outlineColor: "#BF152C",
    "&:focused": {
      outlineColor: "#BF152C",
    },
  },
  input: {
    padding: 0,
    height: "auto",
    "&::placeholder": {
      fontFamily: "'FordAntennaRegular', sans-serif",
      fontSize: 14,
      lineHeight: "20px",
    },
  },
});

const useHelperTextStyles = makeStyles({
  root: {
    fontSize: "12px",
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
    lineHeight: "16px",
    letterSpacing: "0px",
    paddingTop: "4px",
    "&.Mui-error": {
      color: "#BF152C",
    },
  },
});

let isFordBrand = true;
let styles = null;

class BrandSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brandSettings: null,
      buttonEnabled: false,
      overridesApplied: false,
      confirmDialog: false,
      isDataLoaded: false,
      hasError: [],
    };
  }

  componentDidMount() {
    AnalyticsService.trackPageLoadEvent(
      "fv:emp:accessories pricing:brand",
      this.props.user
    );
    ValidationRules();
    this.getDealerBrandAdjustments();
  }

  handleOptionChange = (value) => {
    const copy = JSON.parse(JSON.stringify(this.state.brandSettings));
    copy.priceType = value;

    this.setState({ brandSettings: copy }, () => {
      this.enableOrDisableButton();
    });
  };

  handleClose = () => {
    this.setState({ confirmDialog: false });
  };

  handleConfirm = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ confirmDialog: true });
  };

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.hideOrShow(true);
    const data = JSON.parse(JSON.stringify(this.state.brandSettings));
    data.markup = parseFloat(data.markup);
    data.laborRate = parseFloat(data.laborRate);
    data.salesCode = this.props.user.salesCode;

    BrandSettingsClient.saveDealerBrandAdjustments(
      this.props.user,
      data,
      this.props.user.market === "CAN"
        ? "CAN" + this.props.user.dealerId
        : this.props.user.dealerId,
      this.props.user.brand
    )
      .then(() => {
        this.setState(
          {
            buttonEnabled: false,
            confirmDialog: false,
            overridesApplied: true,
          },
          () => {
            this.props.enableOtherTabs(true);
            this.previousData = data;
          }
        );
      })
      .catch((error) => {
        logMsg("the error is: ", error);
        if (is404(error)) {
          this.setState({ hasError: [PAGE_ERROR] });
        } else if (isProcessingError(error)) {
          this.setState({
            hasError: [ERROR_PROCESSING],
          });
        }
      })
      .finally(() => this.props.hideOrShow(false));
  };

  getDealerBrandAdjustments() {
    isFordBrand = IS_FORD_BRAND(this.props.user.brand);
    styles = isFordBrand ? fordStyles : lincolnStyles;
    this.props.hideOrShow(true);
    let brandSettings, enableTabs;
    BrandSettingsClient.getDealerBrandAdjustments(this.props.user)
      .then((data) => {
        enableTabs = !!data.priceType;
        if (!enableTabs) {
          data.priceType = SRP;
        }
        brandSettings = data;

        this.confirmDialogProps = {
          title: this.props.t("BrandSettings.dialog.confirm.title"),
          bodyText: this.props.t("BrandSettings.dialog.confirm.body"),
          settings: this.props.t("BrandSettings.dialog.confirm.settings", {
            markup: brandSettings.markup,
            labourRate: brandSettings.laborRate,
          }),
          markup: this.props.t("BrandSettings.dialog.confirm.markup"),
          laborRate: this.props.t("BrandSettings.dialog.confirm.laborRate"),
          checkbox: this.props.t("BrandSettings.dialog.confirm.checkbox"),
          confirm: isFordBrand
            ? this.props.t("BrandSettings.dialog.confirm.F.confirmPublish")
            : this.props.t("BrandSettings.dialog.confirm.L.confirm"),
          cancel: isFordBrand
            ? this.props.t("BrandSettings.dialog.confirm.F.goBack")
            : this.props.t("BrandSettings.dialog.confirm.L.cancel"),
        };

        this.previousData = JSON.parse(JSON.stringify(brandSettings));
      })
      .catch((error) => {
        logMsg("reason", error);
        if (is404(error)) {
          this.setState({ hasError: [PAGE_ERROR] });
        } else if (isProcessingError(error)) {
          this.setState({
            hasError: [ERROR_PROCESSING],
          });
        }
      })
      .finally(() => {
        this.setState({
          isDataLoaded: true,
          brandSettings: brandSettings,
          buttonEnabled: false,
        });
        this.props.enableOtherTabs(enableTabs);
        this.props.hideOrShow(false);
      });
  }

  updateModel = (event, model, field) => {
    const copy = JSON.parse(JSON.stringify(this.state.brandSettings));

    if ("markup" === field) {
      copy.markup = event.target.value;
    } else {
      copy.laborRate = event.target.value;
    }

    this.setState({ brandSettings: copy }, () => {
      this.enableOrDisableButton();
    });
    this.confirmDialogProps.settings = this.props.t(
      "BrandSettings.dialog.confirm.settings",
      {
        markup: copy.markup,
        labourRate: copy.laborRate,
      }
    );
  };

  enableOrDisableButton = () => {
    const isPriceTypeChanged =
      this.previousData.priceType !== this.state.brandSettings.priceType;
    const isMarkupChanged =
      this.state.brandSettings.markup &&
      this.previousData.markup !== parseFloat(this.state.brandSettings.markup);
    const isLaborRateChanged =
      this.state.brandSettings.laborRate &&
      this.previousData.laborRate !==
        parseFloat(this.state.brandSettings.laborRate);

    this.setState({
      buttonEnabled:
        isPriceTypeChanged || isMarkupChanged || isLaborRateChanged,
    });
  };

  render() {
    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    this.props.user.showWarning(this.state.buttonEnabled);
    return (
      this.state.isDataLoaded && (
        <div className="pagePadding">
          <ValidatorForm onSubmit={(event) => this.handleConfirm(event)}>
            <div className={styles.header}>
              {this.props.t("BrandSettings.header.pricing")}
            </div>
            <div className={styles.title}>
              {this.props.t("BrandSettings.title.pricing")}
            </div>

            <PriceType
              {...this.props}
              onClick={this.handleOptionChange}
              selectedOption={
                this.state.brandSettings && this.state.brandSettings.priceType
              }
            />

            <BrandMarkup
              {...this.props}
              updateModel={this.updateModel}
              brandSettings={this.state.brandSettings}
            />
            <SaveButton
              {...this.props}
              enabled={this.state.buttonEnabled}
              isFordBrand={isFordBrand}
            />
          </ValidatorForm>

          <OverridesDialog
            {...this.props}
            overridesApplied={this.state.overridesApplied}
            handleTabChange={this.props.handleTabChange}
            onClose={() => this.setState({ overridesApplied: false })}
          />

          <ConfirmDialog
            open={this.state.confirmDialog}
            showSettings={true}
            showWarningIcon={true}
            dialogProps={this.confirmDialogProps}
            markupValue={
              this.state.brandSettings ? this.state.brandSettings.markup : null
            }
            laborRateValue={
              this.state.brandSettings
                ? this.state.brandSettings.laborRate
                : null
            }
            onConfirm={this.handleSubmit}
            onCancel={this.handleClose}
            boldTitle={true}
            onClose={this.handleClose}
            disableConfirm={
              !this.props.user.permissions.rolePermissions.hasOwnProperty(
                "accessoriesSubmit"
              )
            }
            {...this.props}
          />
        </div>
      )
    );
  }
}

const SaveButton = (props) => {
  return (
    <EMPButton
      disabled={!props.enabled}
      testId="applyOverrides"
      buttonType="contained"
      submitButton
      buttonText={props.t("BrandSettings.save")}
    ></EMPButton>
  );
};

const PriceType = (props) => {
  const StyledRadio = withStyles({
    root: {
      color: isFordBrand ? "#102b4e" : "#324047",
      "&$checked": {
        color: isFordBrand ? "#102b4e" : "#f26147",
      },
    },
    checked: {},
  })(Radio);

  function getRadio(radio) {
    const isLincolnChecked = !isFordBrand && props.selectedOption === radio;
    return (
      <div
        className={isLincolnChecked ? styles.radioChecked : styles.radio}
        onClick={() => props.onClick(radio)}
      >
        <div>
          <StyledRadio
            value={radio}
            name={radio}
            data-testid={radio}
            checked={props.selectedOption === radio}
            onChange={() => {}}
          />
        </div>
        <div className={styles.text}>
          <div className={styles.radioTitle}>
            {" "}
            {props.t("BrandSettings." + radio)}
          </div>
          <div className={styles.radioText}>
            {props.t("BrandSettings.text." + radio, {
              brand: isFordBrand
                ? TitleCase(BRAND_NAMES.FORD)
                : TitleCase(BRAND_NAMES.LINCOLN),
            })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.priceType}>
      {getRadio(SRP)}
      {getRadio(DNP)}
    </div>
  );
};

const BrandMarkup = (props) => {
  const classes = EMPInputStyles();
  const helperTextClasses = useHelperTextStyles();
  const model = props.brandSettings;
  return (
    <>
      <div className={styles.header}>
        {props.t("BrandSettings.header.markup")}
      </div>
      <div className={styles.title}>
        {props.t("BrandSettings.title.markup")}
      </div>

      <div className={`${styles.brandHeader}`}>
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <div className={styles.alignLeft}>
              <Item value={props.t("BrandSettings.brand")} />
            </div>
          </Grid>
          <Grid item xs={2}>
            <Item value={props.t("BrandSettings.markup")} />
          </Grid>
          <Grid item xs={2}>
            <Item value={props.t("BrandSettings.laborRate")} />
          </Grid>
        </Grid>
      </div>

      <div className={`${styles.brandText}`}>
        <Grid container spacing={0}>
          <Grid item xs={5} className={styles.brand}>
            <div className={styles.brandName}>
              {props.t("BrandSettings.model." + props.user.brand)}
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={styles.laborRateRow}>
              <div className={styles.percentage}>%</div>
              <div className={styles.textBox}>
                <TextValidator
                  value={model.markup || model.markup === 0 ? model.markup : ""}
                  data-testid="markup"
                  name="markup"
                  validators={[
                    "required",
                    "matchRegexp:^^[+-d\\b.]+$",
                    "isSignedFloatWithTwoDecimalPlaces",
                  ]}
                  errorMessages={[
                    props.t("Error.requiredField"),
                    props.t("Error.invalidInput"),
                    props.t("Error.invalidInput"),
                    props.t("Error.invalidInput"),
                  ]}
                  onChange={(e) => props.updateModel(e, model, "markup")}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: classes.root,
                      disabled: classes.disabled,
                      focused: classes.focused,
                      colorSecondary: classes.colorSecondary,
                      error: classes.error,
                      input: classes.input,
                    },
                  }}
                  FormHelperTextProps={{
                    classes: {
                      ...helperTextClasses,
                    },
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={styles.laborRateRow}>
              <div className={styles.currency}>$</div>
              <div className={styles.textBox}>
                <TextValidator
                  value={
                    model.laborRate || model.laborRate === 0
                      ? model.laborRate
                      : ""
                  }
                  data-testid="laborRate"
                  name="laborRate"
                  validators={[
                    "required",
                    "matchRegexp:^[0-9\\b.]+$",
                    "isNumber",
                    "isNumericWithTwoDecimalPlaces",
                  ]}
                  errorMessages={[
                    props.t("Error.requiredField"),
                    props.t("Error.invalidInput"),
                    props.t("Error.invalidInput"),
                    props.t("Error.invalidInput"),
                  ]}
                  onChange={(e) => props.updateModel(e, model, "laborRate")}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: classes.root,
                      disabled: classes.disabled,
                      focused: classes.focused,
                      colorSecondary: classes.colorSecondary,
                      error: classes.error,
                      input: classes.input,
                    },
                  }}
                  FormHelperTextProps={{
                    classes: {
                      ...helperTextClasses,
                    },
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const OverridesDialog = (props) => {
  const StyledDialog = withStyles({
    paper: {
      maxWidth: isFordBrand ? 600 : 495,
      width: isFordBrand ? 600 : 495,
      borderRadius: 0,
    },
  })(Dialog);
  const checkColor = isFordBrand ? "#3b822e" : "#219653";
  const closeButtonColor = isFordBrand ? "#102b4e" : "#324047";

  return (
    <StyledDialog open={props.overridesApplied} onClose={props.onClose}>
      <div className={styles.overridesDialog}>
        <Grid id="overridesDialog">
          <Grid item md={12}>
            <div className={styles.cancelButton}>
              <button
                data-testid="closeDialogBtn"
                className={styles.closeDialogBtn}
                onClick={props.onClose}
                type="submit"
              >
                <StyledCloseIcon
                  isFordBrand={isFordBrand}
                  color={closeButtonColor}
                />
              </button>
            </div>
          </Grid>

          <Grid item md={12}>
            <div className={styles.savedContainer}>
              <span className={styles.checkMark}>
                <StyledCheckIcon color={checkColor} isFordBrand={isFordBrand} />
              </span>
              <div className={styles.dialogTitle}>
                {props.t("BrandSettings.dialog.overrides.title")}
              </div>
            </div>
          </Grid>
          <Grid item md={12}>
            <div className={styles.dialogDescription}>
              {props.t("BrandSettings.dialog.overrides.description")}
            </div>
          </Grid>

          <Grid item md={12}>
            <div className={styles.buttons}>
              <HasPermissionTo
                perform={["namePlateSettingsTabUS"]}
                permissions={props.user.permissions.rolePermissions}
                render={() => {
                  return (
                    <BrandSettingsWhiteButton
                      className={styles.customiseToBtn}
                      onClick={(event) => props.handleTabChange(event, 1)}
                    >
                      <div
                        className={props.isFordBrand ? "" : styles.lincolnBtn}
                      >
                        {isFordBrand ? (
                          props.t(
                            "BrandSettings.dialog.overrides.nameplatesBtn"
                          )
                        ) : (
                          <>
                            {CapitalizeEachWord(
                              props.t(
                                "BrandSettings.dialog.overrides.nameplatesBtn"
                              )
                            )}
                            <span className={styles.chevronOffset}>
                              <StyledChevronRightIcon
                                size="23px"
                                color="#324047"
                              />
                            </span>
                          </>
                        )}
                      </div>
                    </BrandSettingsWhiteButton>
                  );
                }}
              />
              <HasPermissionTo
                perform={["categorySettingsTabUS"]}
                permissions={props.user.permissions.rolePermissions}
                render={() => {
                  return (
                    <BrandSettingsWhiteButton
                      className={styles.customiseToBtn}
                      onClick={(event) => props.handleTabChange(event, 2)}
                    >
                      <div
                        className={props.isFordBrand ? "" : styles.lincolnBtn}
                      >
                        {isFordBrand ? (
                          props.t(
                            "BrandSettings.dialog.overrides.categoriesBtn"
                          )
                        ) : (
                          <>
                            {CapitalizeEachWord(
                              props.t(
                                "BrandSettings.dialog.overrides.categoriesBtn"
                              )
                            )}
                            <span className={styles.chevronOffset}>
                              <StyledChevronRightIcon
                                size="23px"
                                color="#324047"
                              />
                            </span>
                          </>
                        )}
                      </div>
                    </BrandSettingsWhiteButton>
                  );
                }}
              />

              <BrandSettingsBlueButton
                className={styles.brandConfirm}
                onClick={props.onClose}
              >
                <span>{props.t("BrandSettings.dialog.overrides.done")}</span>
                {!isFordBrand && (
                  <span className={styles.chevronOffset}>
                    <StyledChevronRightIcon size="23px" />
                  </span>
                )}
              </BrandSettingsBlueButton>
            </div>
          </Grid>
        </Grid>
      </div>
    </StyledDialog>
  );
};

export default withTranslation("emp")(BrandSettings);
