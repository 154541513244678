import { withStyles } from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Item from "../../../shared/item/Item";
import SortingIcon from "../../../shared/sortingIcon/SortingIcon";
import RectangleEdit from "../../../shared/rectangleEdit/RectangleEdit";
import { TextValidator } from "react-material-ui-form-validator";
import React from "react";
import { withTranslation } from "react-i18next";

const StyledClear = withStyles({
  root: {
    width: 15,
    height: 15,
    fill: "#e81607",
    paddingTop: 0,
  },
})(Clear);
const StyledButton = withStyles({
  root: {
    minWidth: 20,
    paddingTop: 0,
  },
})(Button);

const EMPTextValidator = withStyles({
  root: {
    "& .MuiFormHelperText-root": {
      fontFamily: "'FordAntennaRegular', sans-serif",
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "20px",
      color: "#BF152C",
      marginBottom: 0,
    },
    "& .MuiInputBase-root": {
      borderRadius: 8,
      outline: "1px solid #6B7786",
      padding: "8px 16px",
      backgroundColor: "#FFFFFF",
      fontFamily: "'FordAntennaRegular', sans-serif",
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "20px",
      color: "#00142E",
    },
    "& .Mui-error.MuiInputBase-root": {
      borderRadius: 8,
      outline: "1px solid #BF152C",
    },
    "& .MuiInputBase-input": {
      padding: 0,
    },
    "& .Mui-focused.MuiInputBase-root": {
      outline: "2px solid #066FEF",
    },
    "& .Mui-error.Mui-focused.MuiInputBase-root": {
      outline: "2px solid #BF152C",
    },
  },
})(TextValidator);

class CategorySettingsRowHeader extends React.Component {
  render() {
    const props = this.props;

    return (
      <>
        <div className={props.styles.categoryHeader}>
          <Grid container spacing={0}>
            <Grid item xs={4} onClick={() => props.sortColumns("namePlate")}>
              <div className={props.styles.typeHeader}>
                <Item value={props.t("CategorySettings.namePlate")} />
                <span className={props.styles.sortingIcon}>
                  <SortingIcon
                    currentKey="namePlate"
                    sortKey={props.sortKey}
                    sortDirection={props.sortDirection}
                  />
                </span>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={props.styles.markupTypeLabel}>
                <Item value={props.t("BrandSettings.markup")} />
                {!props.markupEditEnabled ? (
                  <RectangleEdit
                    dataTestId="markupEditBtn"
                    onEditClick={props.updateMarkupEditEnabled}
                  />
                ) : (
                  <StyledButton
                    size="small"
                    name="Cancel"
                    data-testid="cancelMarkupRate"
                    onClick={props.updateMarkupEditEnabled}
                  >
                    <StyledClear />
                  </StyledButton>
                )}
              </div>
            </Grid>
            <Grid item xs={2} onClick={() => props.sortColumns("markupType")}>
              <div className={props.styles.markupTypeLabel}>
                <Item value={props.t("CategorySettings.markUpType")} />
                <span className={props.styles.sortingIcon}>
                  <SortingIcon
                    currentKey="markupType"
                    sortKey={props.sortKey}
                    sortDirection={props.sortDirection}
                  />
                </span>
              </div>
            </Grid>
            <Grid item xs={2} onClick={() => props.sortColumns("laborRate")}>
              <div className={props.styles.markupTypeLabel}>
                <Item value={props.t("BrandSettings.laborRate")} />
                {!props.laborRateEditEnabled ? (
                  <RectangleEdit
                    dataTestId="laborRateEditBtn"
                    onEditClick={props.updateLaborRateEditEnabled}
                  />
                ) : (
                  <StyledButton
                    size="small"
                    name="Cancel"
                    data-testid="cancelLaborRateBtn"
                    onClick={props.updateLaborRateEditEnabled}
                  >
                    <StyledClear />
                  </StyledButton>
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={2}
              onClick={() => props.sortColumns("laborRateType")}
            >
              <div className={props.styles.markupTypeLabel}>
                <Item value={props.t("CategorySettings.laborRateType")} />
                <span className={props.styles.sortingIcon}>
                  <SortingIcon
                    currentKey="laborRateType"
                    sortKey={props.sortKey}
                    sortDirection={props.sortDirection}
                  />
                </span>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={props.styles.namePlateTextDiv}>
          <Grid container spacing={0}>
            <Grid item xs={4} />
            <Grid item xs={2}>
              {this.props.markupEditEnabled && (
                <div className={props.styles.laborRateRow}>
                  <div className={props.styles.headerPercentage}>&#37;</div>
                  <div className={props.styles.textBox}>
                    <EMPTextValidator
                      value={props.overAllMarkup}
                      data-testid="markup"
                      name="markup"
                      placeholder="--"
                      validators={[
                        "required",
                        "matchRegexp:^^[+-d\\b.]+$",
                        "isSignedFloatWithTwoDecimalPlaces",
                      ]}
                      errorMessages={[
                        props.t("Error.requiredField"),
                        props.t("Error.invalidInput"),
                        props.t("Error.invalidInput"),
                        props.t("Error.invalidInput"),
                      ]}
                      onChange={(e) => props.updateModel(e)}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2}>
              {props.laborRateEditEnabled && (
                <div className={props.styles.laborRateRow}>
                  <div className={props.styles.headerCurrency}>&#36;</div>
                  <div className={props.styles.textBox}>
                    <EMPTextValidator
                      value={props.overAllLaborRate}
                      data-testid="laborRate"
                      name="laborRate"
                      placeholder="--"
                      validators={[
                        "required",
                        "matchRegexp:^[0-9\\b.]+$",
                        "isNumber",
                        "isNumericWithTwoDecimalPlaces",
                      ]}
                      errorMessages={[
                        props.t("Error.requiredField"),
                        props.t("Error.invalidInput"),
                        props.t("Error.invalidInput"),
                        props.t("Error.invalidInput"),
                      ]}
                      onChange={(e) => props.updateModel(e)}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default withTranslation("emp")(CategorySettingsRowHeader);
