import React, { Component } from "react";
import fordStyles from "./NAOrderDetailsNewVersion.module.scss";
import lincolnStyles from "./NAOrderDetailsNewVersionLincoln.module.scss";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { StyledBasicExpansionPanel } from "../../../shared/ExpansionPanels/ExpansionPanel";
import {
  IS_CANADA_MARKET,
  IS_FORD_BRAND,
  IS_MODEL_E_ORDER,
} from "../../../utils/EmpUtil";
import GetVehiclePermissions from "../../../utils/VehiclePermissionsUtil";
import HasPermissionTo from "../../../common/HasPermissionTo";
import UpperCaseText from "../../../utils/UpperCaseText";
import * as constants from "../../../common/Constants";
import DataCard from "../../../shared/components/DataCard/DataCard";
import { AXZ_PIN_VALID, PURCHASE_TYPES } from "../../../common/Constants";
import { AccordionSummary, withStyles } from "@material-ui/core";
import EMPEmptyState from "../../../shared/components/EMPEmptyState/EMPEmptyState";

const StyledAccordionSummary = withStyles({
  root: {
    fontFamily: "FordAntennaRegular",
    fontSize: 24,
    paddingLeft: 24,
    paddingRight: 24,
    color: "#1C1C1E",
  },
  content: {
    marginTop: 16,
    marginBottom: 16,
  },
})(AccordionSummary);

class CustomerInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandCustomerInfo: false,
    };
  }
  componentDidMount() {
    this.setState({ expandCustomerInfo: true });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({ expandCustomerInfo: this.props.expandAccordian });
    }
  }
  expandCustomerInfo = () => {
    this.setState({ expandCustomerInfo: !this.state.expandCustomerInfo });
  };

  CustomerInfo = ({ header, value }, styles) => {
    if (header === "name") {
      return this.renderCustomerInfo(
        this.props.getFullName(value),
        styles.customerInfoText,
        header
      );
    } else if (header === "email") {
      return this.renderCustomerInfo(
        value.email,
        styles.customerInfoText,
        header
      );
    } else if (header === "phoneNumber") {
      return this.renderCustomerInfo(
        this.props.formatPhoneNumber(value.phoneNumber),
        styles.customerInfoText,
        header
      );
    } else if (header === "address") {
      const fullAddress = value
        ? this.concatenateAddress(
            value.address1,
            value.address2,
            value.address3,
            value.address4,
            value.city,
            value.state,
            value.country,
            value.zip
          )
        : "";
      return this.renderCustomerInfo(
        fullAddress,
        styles.customerInfoText,
        header
      );
    } else if (header === "fordCreditPreApproval") {
      return this.renderCustomerInfo(
        value
          ? this.props.t("GeneralEMP.statusYes")
          : this.props.t("GeneralEMP.statusNo"),
        styles.customerInfoText,
        header
      );
    } else if (header === "licensePlateDecision") {
      return this.renderCustomerInfo(
        value
          ? this.props.t("CustomerOrderDetails.keepPlatesYes")
          : value === false
          ? this.props.t("CustomerOrderDetails.keepPlatesNo")
          : null,
        styles.customerInfoText,
        header
      );
    } else if (header === "registrationMonth") {
      return this.renderCustomerInfo(
        value !== 0 ? this.props.t("GeneralEMP.month." + value) : null,
        styles.customerInfoText,
        header
      );
    }
  };

  concatenateAddress(line1, line2, line3, line4, city, state, country, zip) {
    let address1 = null;
    if (line1) address1 = line1;
    if (line2) address1 = address1 ? address1 + " " + line2 : line2;
    if (line3) address1 = address1 ? address1 + " " + line3 : line3;
    if (line4) address1 = address1 ? address1 + " " + line4 : line4;

    address1 = address1 ? address1 + "," : address1;

    let address2 = null;
    if (city) address2 = city + ",";

    let address3 = null;
    if (state) address3 = state;
    if (zip) address3 = address3 ? address3 + " " + zip : zip;

    let address4 = null;
    if (country) address4 = country;

    return [address1, address2, address3, address4];
  }

  renderCustomerInfo = (fieldData, className, testId) => {
    return (
      <div className={className} data-testid={testId}>
        {fieldData ? (
          Array.isArray(fieldData) ? (
            fieldData.map(
              (data, index) => data && <span key={index}>{data}</span>
            )
          ) : (
            fieldData
          )
        ) : (
          <EMPEmptyState />
        )}
      </div>
    );
  };

  determineAffinityGroup(
    affinityList,
    affinityName,
    customerReservation,
    styles
  ) {
    if (affinityName === "Affinity - Military") {
      return customerReservation && customerReservation.activeMilitaryFlag
        ? this.renderCustomerInfo("Yes", styles.customerInfoText)
        : this.renderCustomerInfo("No", styles.customerInfoText);
    }

    if (affinityList.includes(affinityName)) {
      return this.props.t("CustomerOrderDetails.yes");
    } else {
      return this.props.t("CustomerOrderDetails.no");
    }
  }

  render() {
    const { customerReservation, customerInfo } = this.props;

    const styles = IS_FORD_BRAND(this.props.user.brand)
      ? fordStyles
      : lincolnStyles;

    const isCANFieldViewUser =
      UpperCaseText(this.props.user.userType) === "FIELD_VIEW" &&
      UpperCaseText(this.props.user.market) === "CAN";

    const purchaseTypeIsValid =
      this.props.customerReservation &&
      this.props.customerReservation.purchaseInfo &&
      this.props.customerReservation.purchaseInfo.purchaseType;

    const purchaseTypeIsSignAtDealer =
      purchaseTypeIsValid &&
      this.props.customerReservation.purchaseInfo.purchaseType ===
        PURCHASE_TYPES.SIGN_AT_DEALER;

    const purchaseTypeIsOnlineOrder =
      purchaseTypeIsValid &&
      this.props.customerReservation.purchaseInfo.purchaseType ===
        PURCHASE_TYPES.SIGN_ONLINE;

    const hasJourneyType =
      this.props.customerReservation &&
      this.props.customerReservation.journeyType;

    const showFordCreditPreApproval = !(
      hasJourneyType &&
      this.props.customerReservation.journeyType === "Model-E" &&
      (purchaseTypeIsSignAtDealer || purchaseTypeIsOnlineOrder)
    );

    return (
      <StyledBasicExpansionPanel
        onChange={() => this.expandCustomerInfo()}
        expanded={this.state.expandCustomerInfo}
      >
        <StyledAccordionSummary
          id="exp"
          expandIcon={<ExpandLessIcon />}
          IconButtonProps={{ disableRipple: true }}
        >
          {this.props.t("OrderDetails.customerInformation")}
        </StyledAccordionSummary>
        <div className={styles.customerInfoGrid}>
          {customerInfo ? (
            <Grid container spacing={2}>
              <Grid item className={styles.cardGridItemWrapper}>
                <DataCard
                  cardHeader={this.props.t(
                    "CustomerOrderDetails.contactDetails"
                  )}
                >
                  <div className={styles.customerInfoContainer}>
                    {this.renderCustomerInfo(
                      this.props.t("OrderDetails.lowerCaseName"),
                      styles.itemHeaderText
                    )}
                    {this.CustomerInfo(
                      { value: customerInfo, header: "name" },
                      styles
                    )}
                  </div>

                  {!isCANFieldViewUser && (
                    <div className={styles.customerInfoContainer}>
                      {this.renderCustomerInfo(
                        this.props.t("OrderDetails.lowerCaseEmail"),
                        styles.itemHeaderText
                      )}
                      {this.CustomerInfo(
                        { value: customerInfo, header: "email" },
                        styles
                      )}
                    </div>
                  )}

                  <div className={styles.customerInfoContainer}>
                    {this.renderCustomerInfo(
                      this.props.t("OrderDetails.preferredContactMethod"),
                      styles.itemHeaderText,
                      "contactMethodHeader"
                    )}
                    {this.renderCustomerInfo(
                      customerReservation &&
                        customerReservation.purchaseInfo &&
                        customerReservation.purchaseInfo
                          .preferredContactMethod &&
                        this.props.t(
                          "GeneralEMP." +
                            customerReservation.purchaseInfo
                              .preferredContactMethod
                        ),
                      styles.customerInfoText
                    )}
                  </div>

                  {!isCANFieldViewUser && customerInfo && (
                    <>
                      <div className={styles.customerInfoContainer}>
                        {this.renderCustomerInfo(
                          this.props.t("OrderDetails.mobilePhone"),
                          styles.itemHeaderText,
                          "contactMethodHeader"
                        )}
                        {this.renderCustomerInfo(
                          this.props.formatPhoneNumber(
                            customerInfo.mobilePhoneNumber
                          ),
                          styles.customerInfoText
                        )}
                      </div>
                      <div className={styles.customerInfoContainer}>
                        {this.renderCustomerInfo(
                          this.props.t("OrderDetails.secondaryPhone"),
                          styles.itemHeaderText,
                          "contactMethodHeader"
                        )}
                        {this.renderCustomerInfo(
                          this.props.formatPhoneNumber(
                            customerInfo.phoneNumber
                          ),
                          styles.customerInfoText
                        )}
                      </div>
                    </>
                  )}
                </DataCard>
              </Grid>
              {!isCANFieldViewUser && (
                <Grid item className={styles.cardGridItemWrapper}>
                  <DataCard
                    cardHeader={this.props.t("CustomerOrderDetails.address")}
                  >
                    <div className={styles.customerInfoContainer}>
                      {this.CustomerInfo(
                        {
                          value: customerInfo,
                          header: "address",
                        },
                        styles
                      )}
                    </div>
                    <HasPermissionTo
                      perform={["modelEDetailsPage"]}
                      permissions={this.props.user.permissions.rolePermissions}
                      condition={
                        IS_MODEL_E_ORDER(customerReservation.journeyType) &&
                        IS_FORD_BRAND(this.props.user.brand) &&
                        this.props.fromPurchaseDetails &&
                        (customerReservation.qualifiedIncentiveTypes ||
                          customerReservation.activeMilitaryFlag)
                      }
                      render={() => (
                        <div>
                          <div className={styles.subHeaderText}>
                            {this.props.t(
                              "PurchaseDetails.affinityProgramsHeader"
                            )}
                          </div>
                          <div className={styles.customerInfoContainer}>
                            <ul>
                              <li>
                                <p>
                                  {this.props.t("PurchaseDetails.axzHeader")}:
                                  {customerReservation.purchaseInfo &&
                                  customerReservation.purchaseInfo
                                    .axzPlanPinValidity === AXZ_PIN_VALID ? (
                                    <span
                                      className={styles.customerInfoListValue}
                                    >
                                      {this.props.t("CustomerOrderDetails.yes")}
                                    </span>
                                  ) : (
                                    <span
                                      className={styles.customerInfoListValue}
                                    >
                                      {this.props.t("CustomerOrderDetails.no")}
                                    </span>
                                  )}
                                </p>
                              </li>
                              {constants.possibleQualifiedIncentiveTypes.map(
                                (item, i) => {
                                  return (
                                    <li key={i}>
                                      <p>
                                        {this.props.t(
                                          `PurchaseDetails.${item
                                            .replace(/\s/g, "")
                                            .toString()}`
                                        )}
                                        :
                                        <span
                                          className={
                                            styles.customerInfoListValue
                                          }
                                        >
                                          {this.determineAffinityGroup(
                                            customerReservation.qualifiedIncentiveTypes,
                                            item,
                                            customerReservation,
                                            styles
                                          )}
                                        </span>
                                      </p>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </div>
                      )}
                    />
                  </DataCard>
                </Grid>
              )}
              {!IS_CANADA_MARKET(this.props.user.market) && (
                <Grid item className={styles.cardGridItemWrapper}>
                  <DataCard
                    cardHeader={this.props.t("OrderDetails.additionalDetails")}
                  >
                    {!this.props.fromPurchaseDetails &&
                      showFordCreditPreApproval && (
                        <div className={styles.customerInfoContainer}>
                          {this.renderCustomerInfo(
                            this.props.t("OrderDetails.preApproval"),
                            styles.itemHeaderText,
                            "preApprovalHeader"
                          )}

                          <HasPermissionTo
                            perform={["fordCreditPreApproval:view"]}
                            permissions={GetVehiclePermissions(
                              customerReservation.catalogId,
                              this.props.user.permissions.vehiclePermissions
                            )}
                            render={() => {
                              return this.renderCustomerInfo(
                                customerReservation &&
                                  customerReservation.fordCreditPreApproval
                                  ? this.props.t("GeneralEMP.statusYes")
                                  : this.props.t("GeneralEMP.statusNo"),
                                styles.customerInfoText,
                                "fordCreditPreApproval"
                              );
                            }}
                            noRender={() => {
                              return this.renderCustomerInfo(
                                false,
                                styles.customerInfoText,
                                null
                              );
                            }}
                          />
                        </div>
                      )}
                    {this.props.fromPurchaseDetails && (
                      <div className={styles.customerInfoContainer}>
                        {this.renderCustomerInfo(
                          this.props.t("OrderDetails.registrationMonth"),
                          styles.itemHeaderText,
                          "registrationMonthHeader"
                        )}
                        {this.CustomerInfo(
                          {
                            header: "registrationMonth",
                            value:
                              customerReservation &&
                              customerReservation.registrationMonth,
                          },
                          styles
                        )}
                      </div>
                    )}
                    {this.props.fromPurchaseDetails && (
                      <div className={styles.customerInfoContainer}>
                        {this.renderCustomerInfo(
                          this.props.t("OrderDetails.licensePlateDecision"),
                          styles.itemHeaderText,
                          "plateDecisionHeader"
                        )}
                        {this.CustomerInfo(
                          {
                            value:
                              customerReservation &&
                              customerReservation.keepLicensePlateFlag,
                            header: "licensePlateDecision",
                          },
                          styles
                        )}
                      </div>
                    )}
                  </DataCard>
                </Grid>
              )}
              <HasPermissionTo
                perform={["modelEDetailsPage"]}
                permissions={this.props.user.permissions.rolePermissions}
                condition={
                  !IS_CANADA_MARKET(this.props.user.market) &&
                  IS_MODEL_E_ORDER(customerReservation.journeyType)
                }
                render={() => (
                  <Grid item className={styles.cardGridItemWrapper}>
                    <DataCard
                      cardHeader={UpperCaseText(
                        this.props.t("OrderDetails.customerOptIn")
                      )}
                    >
                      <div className={styles.customerInfoContainer}>
                        {this.renderCustomerInfo(
                          this.props.t("OrderDetails.smsUpdate"),
                          styles.itemHeaderText,
                          "smsUpdateHeader"
                        )}

                        {this.renderCustomerInfo(
                          null,
                          styles.customerInfoText,
                          "smsUpdate"
                        )}
                      </div>

                      <div className={styles.customerInfoContainer}>
                        {this.renderCustomerInfo(
                          this.props.t("OrderDetails.yourFordAmbassador"),
                          styles.itemHeaderText,
                          "yourFordAmbassadorHeader"
                        )}

                        {this.renderCustomerInfo(
                          null,
                          styles.customerInfoText,
                          "yourFordAmbassador"
                        )}
                      </div>
                    </DataCard>
                  </Grid>
                )}
              />
            </Grid>
          ) : (
            <span className={styles.labelError}>
              {" "}
              {this.props.t("CustomerOrderDetails.errorLoadingCustomerInfo")}
            </span>
          )}
        </div>
      </StyledBasicExpansionPanel>
    );
  }
}

export default withTranslation("emp")(CustomerInformation);
