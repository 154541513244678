import { Accordion, AccordionSummary, withStyles } from "@material-ui/core";

export const StyledExpansionPanel = withStyles({
  root: {
    boxShadow: "none",
    marginBottom: 0,
    "&::before": {
      backgroundColor: "transparent",
    },
    "&:last-child": {
      paddingRight: 0,
    },
  },
  expanded: {
    "&$expanded": {
      margin: "0",
    },
  },
})(Accordion);

export const StyledExpansionPanelSummary = withStyles({
  root: {
    "&$expanded": {
      margin: "0",
    },
  },
  content: {
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {
    margin: 0,
  },
})(AccordionSummary);

export const IndentedStyledExpansionPanel = withStyles({
  root: {
    boxShadow: "none",
    marginBottom: 0,
    marginTop: 0,
    paddingLeft: 30,
    "&::before": {
      backgroundColor: "transparent",
    },
  },
})(Accordion);

export const StyledBasicExpansionPanel = withStyles({
  root: {
    boxShadow: "none",
    margin: 0,
    "&::before": {
      backgroundColor: "transparent",
    },
  },
})(Accordion);

export const StyledBasicExpansionPanelSummary = withStyles({
  content: {
    margin: 0,
  },
  root: {
    "&$disabled": {
      opacity: 1,
      background: "white",
    },
  },
  disabled: {
    opacity: 1,
    background: "white",
  },
})(AccordionSummary);
