import { withStyles } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React from "react";

const StyledChevronRightIcon = (props) => {
  const StyledChevron = withStyles({
    root: {
      height: props.size ? props.size : 20,
      width: props.size ? props.size : 20,
      color: props.color ? props.color : "white",
    },
  })(ChevronRightIcon);

  return <StyledChevron />;
};

export default StyledChevronRightIcon;
