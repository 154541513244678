import React from "react";
import styles from "./NACustomerOrders.module.scss";
import { withTranslation } from "react-i18next";
import { US_MACHE_CATALOG_ID } from "../../common/Constants";
import EMPDialog from "../../shared/components/EMPDialog/EMPDialog";
import { Typography, makeStyles } from "@material-ui/core";

const useListStyles = makeStyles({
  root: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    color: "#000000",
    fontSize: 16,
    lineHeight: "24px",
    marginBottom: "4px",
  },
});

const CustomerOrdersDialog = (props) => {
  const classes = useListStyles();

  function getUSADialog() {
    return props.catalogId === US_MACHE_CATALOG_ID ? (
      <div>
        <ul>
          <li>{props.t("ReservationsSubmitted.bodyText1USMache")}</li>
          <li>{props.t("ReservationsSubmitted.bodyText2USMache")}</li>
          <li>{props.t("ReservationsSubmitted.bodyText3USMache")}</li>
          <li>{props.t("ReservationsSubmitted.bodyText4USMache")}</li>
          <li>{props.t("ReservationsSubmitted.bodyText5USMache")}</li>
          <li>{props.t("ReservationsSubmitted.bodyText6USMache")}</li>
        </ul>
        <div className={styles.redBox}>
          <span className={styles.consent}>
            {props.t("ReservationsSubmitted.bodyTextConsent")}
          </span>
        </div>
      </div>
    ) : (
      <div>
        <ul>
          <li>{props.t("ReservationsSubmitted.bodyText0")}</li>
          <li>{props.t("ReservationsSubmitted.bodyText1")}</li>
          <li>
            {props.t("ReservationsSubmitted.bodyText2")}
            <strong>
              <i>{props.t("ReservationsSubmitted.bodyText2a")}</i>
            </strong>
            {props.t("ReservationsSubmitted.bodyText2b")}
          </li>
          <li>{props.t("ReservationsSubmitted.bodyText3")}</li>
          <li>{props.t("ReservationsSubmitted.bodyText4")}</li>
          <li>{props.t("ReservationsSubmitted.bodyText5")}</li>
        </ul>
        <div className={styles.redBox}>
          <span className={styles.consent}>
            {props.t("ReservationsSubmitted.bodyTextConsent")}
          </span>
        </div>
      </div>
    );
  }
  return (
    <EMPDialog
      open={props.open}
      handleClose={props.onClose}
      dialogTitle={props.dialogTitle}
      hasSecondaryActionButton
      secondaryActionButtonText={props.cancelText}
      onSecondaryAction={props.onGoBack}
      disableActionButton={props.disableConfirm}
      hasActionButton
      actionButtonText={props.confirmText}
      onConfirm={props.confirmAction}
      titleType="warning"
      hasCloseIcon
    >
      {props.dialogBody.length > 0 ? (
        <ul>
          {props.dialogBody.map((message, index) => {
            return (
              <li key={index}>
                <Typography variant="body1" classes={{ ...classes }}>
                  {message}
                </Typography>
              </li>
            );
          })}
        </ul>
      ) : (
        getUSADialog()
      )}
    </EMPDialog>
  );
};

export default withTranslation("emp")(CustomerOrdersDialog);
