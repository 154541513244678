import React from "react";
import FordLogo from "../../../common/FordLogo";
import { withTranslation } from "react-i18next";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";
import LincolnLogo from "../../../common/LincolnLogo";
import { Box, Grid, Typography, withStyles } from "@material-ui/core";

const StyledTypography = withStyles({
  root: {
    margin: 0,
    fontSize: "13px",
    color: "#4d4d4d",
    lineHeight: "normal",
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
  },
})(Typography);

const formatBranding = (str, showLogoProp) => {
  let text;

  if (showLogoProp) {
    const words = str.split(" ");
    text = (
      <>
        {words[0]} <br /> {words[1]} {words[2]}
      </>
    );
  } else {
    text = str;
  }

  return <StyledTypography component="p">{text}</StyledTypography>;
};

const EMPBranding = (props) => {
  return (
    <Grid container alignItems="center">
      {props.showLogo && (
        <div style={{ fontSize: "0px", marginRight: 10 }}>
          {IS_FORD_BRAND(props.brand) ? (
            <FordLogo width={props.width} height={props.height} />
          ) : (
            <LincolnLogo width={props.width} />
          )}
        </div>
      )}
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        {formatBranding(props.t("GeneralEMP.branding"), props.showLogo)}
      </Box>
    </Grid>
  );
};

export default withTranslation("emp")(EMPBranding);
