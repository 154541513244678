import * as AnalyticsService from "../../../shared/services/analytics/AnalyticsService";
import getDeliveryStatus from "../../../utils/DeliveryStatusUtils/GetDeliveryStatus";
import { useQuery } from "@tanstack/react-query";

export function useAnalytics(renderedOrderId, user) {
  return useQuery({
    queryKey: ["deliveryModule", renderedOrderId],
    enabled: false,
    onSettled: (result) => {
      if (result !== undefined) {
        const { message } = getDeliveryStatus(result?.deliveryAppointment);
        AnalyticsService.trackModelEPageLoadDetailEvent(
          "fv:emp:model e details",
          renderedOrderId,
          message.toLowerCase(),
          user
        );
      } else {
        AnalyticsService.trackModelEPageLoadDetailEvent(
          "fv:emp:model e details",
          renderedOrderId,
          "no info available",
          user
        );
      }
    },
  });
}
