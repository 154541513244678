import React, { Component } from "react";
import LoadTermsAndConditions from "./termsAndConditions/LoadTermsAndConditions";
import HasPermissionTo from "../../common/HasPermissionTo";
import { withTranslation } from "react-i18next";
import SubNav from "../../shared/subnav/SubNav";
import TranslationDetails from "../translations/TranslationDetails";

class NscBusinessSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: this.props.startingTab,
    };
  }

  handleTabChange = (event, selectedTab) => {
    this.setState({ selectedTab });
  };

  getTabs() {
    return [
      {
        menuName: this.props.t("ProgressBar.termsConditions"),
        enabled: true,
        id: "empMembership",
      },
      {
        menuName: this.props.t("ProgressBar.translations"),
        enabled: true,
        id: "translations",
        hide: !this.props.user.permissions.rolePermissions.hasOwnProperty(
          "translationsTab"
        ),
      },
    ];
  }

  render() {
    return (
      <div>
        <SubNav
          dashboard={false}
          selectedTab={this.state.selectedTab}
          title={this.props.t("GlobalNavBar.settings")}
          action={this.handleTabChange}
          tabs={this.getTabs()}
          restrictDropdown={this.state.restrictDropdown}
          {...this.props}
        />
        <div className="pageWrapper">
          {this.state.selectedTab === 0 && (
            <LoadTermsAndConditions {...this.props} />
          )}

          <HasPermissionTo
            perform={["translationsTab"]}
            permissions={this.props.user.permissions.rolePermissions}
            condition={this.state.selectedTab === 1}
            render={() => <TranslationDetails {...this.props} />}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(NscBusinessSettings);
