import React from "react";
import { Dialog, withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Clear from "@material-ui/icons/Clear";
import fordStyles from "./VehicleHoldReleaseDialog.module.scss";
import lincolnStyles from "./VehicleHoldReleaseDialogLincoln.module.scss";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import { IS_FORD_BRAND } from "../../../../../../utils/EmpUtil";
import StyledWarningIcon from "../../../../../../shared/Icons/StyledWarningIcon";
import StyledChevronRightIcon from "../../../../../../shared/Icons/StyledChevronRightIcon";

const StyledClear = withStyles({
  root: {
    width: 27,
    height: 27,
    fill: "#102b4e",
    align: "right",
  },
})(Clear);

const StyledDialog = withStyles({
  paper: {
    width: 650,
    maxWidth: 650,
  },
})(Dialog);

const StyledGrid = withStyles({
  root: {
    width: 650,
    maxWidth: 650,
  },
  container: {
    width: 650,
    maxWidth: 650,
  },
})(Grid);

class VehicleHoldReleaseDialog extends React.Component {
  render() {
    const styles = IS_FORD_BRAND(this.props.user) ? fordStyles : lincolnStyles;

    return (
      <StyledDialog
        open={this.props.open}
        className={styles.dialog}
        onClose={this.props.onClose}
      >
        <StyledGrid
          container
          data-testid="vehileHoldReleaseDialog"
          className={styles.dialogContainer}
          justifyContent="center"
        >
          <Grid item md={12} className={styles.cancel}>
            <div>
              <button data-testid="closeDialog" onClick={this.props.onClose}>
                <StyledClear />
              </button>
            </div>
          </Grid>

          <Grid item md={9}>
            <div className={styles.dialogTitle}>
              {IS_FORD_BRAND(this.props.user) ? (
                <span className={styles.warning} />
              ) : (
                <StyledWarningIcon />
              )}
              <div className={styles.dialogTitle}>
                {this.props.t("OrderDetails.vehicleHold.dialog.title")}
              </div>
            </div>
          </Grid>

          <Grid item md={9}>
            <div className={styles.dialogDescription}>
              {this.props.t("OrderDetails.vehicleHold.dialog.body")}
            </div>
          </Grid>

          <Grid item md={9}>
            <div className={styles.dialogActions}>
              <Button
                className={styles.goBack}
                name="cancel"
                data-testid="cancel"
                onClick={this.props.onClose}
              >
                {this.props.t("OrderDetails.vehicleHold.dialog.cancel")}
              </Button>
              {this.props.user.permissions.rolePermissions.hasOwnProperty(
                "vehicleHoldSubmit"
              ) ? (
                <Button
                  className={styles.confirmShared}
                  name="confirm"
                  data-testid="confirm"
                  onClick={() => {
                    this.props.onReleaseHold();
                  }}
                >
                  {this.props.t("OrderDetails.vehicleHold.dialog.confirm")}
                  {!IS_FORD_BRAND(this.props.user.brand) && (
                    <StyledChevronRightIcon size="23px" />
                  )}
                </Button>
              ) : (
                <Button
                  className={styles.confirmShared}
                  name="confirm"
                  data-testid="confirm"
                  disabled={true}
                >
                  {this.props.t("OrderDetails.vehicleHold.dialog.confirm")}
                  {!IS_FORD_BRAND(this.props.user.brand) && (
                    <StyledChevronRightIcon size="23px" />
                  )}
                </Button>
              )}
            </div>
          </Grid>
        </StyledGrid>
      </StyledDialog>
    );
  }
}

export default withTranslation("emp")(VehicleHoldReleaseDialog);
