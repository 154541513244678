import React, { Component } from "react";
import styles from "./NAOrderDetailsNewVersion.module.scss";
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import HasPermissionTo from "../../../common/HasPermissionTo";
import UiDateUtils from "../../../utils/UiDateUtils";
import {
  CANCELLED_STATUSES,
  FORMAT_COMMON_ID,
  IS_CANADA_MARKET,
  ORA_STATUSES,
} from "../../../utils/EmpUtil";
import CustomerReservationsClient from "../../../clients/CustomerReservationsClient";
import UpperCaseText from "../../../utils/UpperCaseText";
import {
  DEALER_ACTIONS,
  US_MACHE_CATALOG_ID,
  WBDO_CANCELLATION_REASON,
} from "../../../common/Constants";
import { StyledBasicExpansionPanel } from "../../../shared/ExpansionPanels/ExpansionPanel";
import {
  displayError,
  ERROR_PROCESSING,
  isProcessingError,
} from "../../../errors/ErrorConstants";
import { InfoDetail } from "./InfoDetail";
import CancelOrderDialog from "../CancelOrderDialog";
import CustomerOrdersDialog from "../CustomerOrdersDialog";
import ProcessOfflineDialog from "../ProcessOfflineDialog";
import ResubmitOfflineDialog from "../ResubmitOfflineDialog";
import DataCard from "../../../shared/components/DataCard/DataCard";
import { AccordionSummary, withStyles } from "@material-ui/core";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import * as AnalyticsService from "../../../shared/services/analytics/AnalyticsService";
import EMPEmptyState from "../../../shared/components/EMPEmptyState/EMPEmptyState";
import SharedToolTip from "../../../shared/sharedToolTip/SharedToolTip";

const StyledAccordionSummary = withStyles({
  root: {
    fontFamily: "FordAntennaRegular",
    fontSize: 24,
    paddingLeft: 24,
    paddingRight: 24,
    color: "#1C1C1E",
  },
  content: {
    marginTop: 16,
    marginBottom: 16,
  },
})(AccordionSummary);

class OrderInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCancel: false,
      openRejectOrderDialog: false,
      openProcessOfflineDialog: false,
      openProcessOfflineResubmitDialog: false,
      openCAN: false,
      expandOrderInfo: true,
      hasError: [],
      cancelError: false,
    };
  }

  componentDidMount() {
    this.setState({ expandOrderInfo: this.props.expandAccordian });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({ expandOrderInfo: this.props.expandAccordian });
    }
  }
  onClose = (commonDealerId = null) => {
    if (
      this.props.user.isCanadaDealer() &&
      this.props.user.dealerGroup.length > 1
    ) {
      const currentDealer = JSON.parse(JSON.stringify(this.state.dealerData));

      currentDealer[commonDealerId] = {
        open: false,
        openCAN: false,
      };

      this.setState({ dealerData: currentDealer });
    } else {
      this.setState({
        openRejectOrderDialog: false,
        openCAN: false,
        openProcessOfflineDialog: false,
        openProcessOfflineResubmitDialog: false,
      });
    }
  };

  handleAcceptOrder = (orderId, selectedTab, tab, dealerCommonId) => {
    AnalyticsService.trackOnClickDetailEvent(
      "fv:emp:customer handling:order details",
      "emp:order details:order id",
      `emp:order details:order id:${orderId}`,
      orderId,
      "emp:customer handling:order details:content:accept",
      "fv:emp:customer handling:order details:content action",
      this.props.user
    );
    this.updateOrder(orderId, selectedTab, tab, dealerCommonId);
  };

  handleRejectOrder = (orderId, user) => {
    this.setState({ openRejectOrderDialog: true });
    AnalyticsService.trackPageLoadDetailEvent(
      "fv:emp:customer handling:orders details:reject popup",
      "emp:order details:order id",
      orderId,
      user
    );
  };

  handleRejectOrderConfirm = (orderId, selectedTab, tab, dealerCommonId) => {
    AnalyticsService.trackOnClickDetailEvent(
      "fv:emp:customer handling:orders details:reject popup",
      "emp:order details:order id",
      `emp:order details:order id:${orderId}`,
      orderId,
      "emp:customer handling:orders details:reject popup:content:yes reject order",
      "fv:emp:customer handling:orders details:reject popup:content action",
      this.props.user
    );
    this.updateOrder(orderId, selectedTab, tab, dealerCommonId).then(() => {
      this.onClose();
    });
  };

  handleRejectOrderCancel = () => {
    AnalyticsService.trackOnClickDetailEvent(
      "fv:emp:customer handling:orders details:reject popup",
      "emp:order details:order id",
      `emp:order details:order id:${this.props.customerReservation.id}`,
      this.props.customerReservation.id,
      "emp:customer handling:orders details:reject popup:content:no back",
      "fv:emp:customer handling:orders details:reject popup:content action",
      this.props.user
    );
    this.onClose();
  };

  getStatus(customerReservation) {
    if (CANCELLED_STATUSES.includes(customerReservation.status)) {
      return customerReservation.status === "RESERVATION_CANCELLED"
        ? this.props.t("OrderDetails.businessStatusCustomer")
        : this.props.t("OrderDetails.businessStatusDealer");
    } else {
      return customerReservation.businessStatus;
    }
  }

  isCancelled = (order) => {
    const cancelledStatuses = [
      "RESERVATION_CANCELLED",
      "DEALER_CANCELLED",
      "ORDER_CANCELLED",
    ];
    return cancelledStatuses.includes(UpperCaseText(order.status));
  };

  isWaitlisted = (order) => {
    return order.isWaitlisted;
  };

  isWaveDateExpired = (order) => {
    return order.isWaveDateExpired;
  };

  getReservationStatus = (order) => {
    return !this.isCancelled(order) && this.isWaitlisted(order)
      ? this.props.t("OrderDetails.extended")
      : order.reservationStatus
      ? order.reservationStatus
      : this.getStatus(order);
  };

  getConversionStatus(customerReservation) {
    const conversionStatus =
      customerReservation.customerConsentInfo &&
      UpperCaseText(customerReservation.customerConsentInfo.status);

    switch (conversionStatus) {
      case "INITIATED":
        return this.props.t("OrderDetails.pendingConsent");
      case "DECLINED":
        return this.props.t("OrderDetails.consentDeclined");
      case "ACCEPTED":
        return this.props.t("OrderDetails.consentAccepted");
      case "REMINDED":
        return this.props.t("OrderDetails.pendingConsent");
      default:
        return null;
    }
  }

  getConsentReminderDate(customerReservation) {
    return (
      customerReservation.customerConsentInfo &&
      customerReservation.customerConsentInfo.emailReminderDate
    );
  }

  getResubmissionReason(customerReservation) {
    return (
      customerReservation.customerConsentInfo &&
      customerReservation.customerConsentInfo.dealerActionDetails &&
      customerReservation.customerConsentInfo.dealerActionDetails
        .dealerRejectedReason
    );
  }

  toggleCancel = () => {
    if (
      UpperCaseText(this.props.customerReservation.orderType) === "RESERVATION"
    ) {
      if (this.state.openCancel) {
        AnalyticsService.trackOnClickDetailEvent(
          "fv:emp:customer handling:reservation submitted:cancel popup",
          `emp:reservation:order id:${this.props.customerReservation.id}`,
          "emp:reservation:close button selected",
          this.props.customerReservation.id,
          "emp:customer handling:reservation submitted:cancel popup:content:close",
          "fv:emp:customer handling:reservation submitted:cancel popup:content action",
          this.props.user
        );
      }
    }
    this.setState({ openCancel: !this.state.openCancel, cancelError: false });
  };

  trackOpen = () => {
    if (
      UpperCaseText(this.props.customerReservation.orderType) === "RESERVATION"
    ) {
      AnalyticsService.trackPageLoadDetailEvent(
        "fv:emp:customer handling:reservation submitted:cancel popup",
        "emp:reservation:order id",
        this.props.customerReservation.id,
        this.props.user
      );
    }
  };
  submitCancel = (e, reason) => {
    if (
      UpperCaseText(this.props.customerReservation.orderType) === "RESERVATION"
    ) {
      AnalyticsService.trackOnClickDetailEvent(
        "fv:emp:customer handling:reservation submitted:cancel popup",
        `emp:reservation:order id:${this.props.customerReservation.id}`,
        `emp:reservation:cancel reason:${reason}`,
        this.props.customerReservation.id,
        "emp:customer handling:reservation submitted:cancel popup:content:cancel",
        "fv:emp:customer handling:reservation submitted:cancel popup:content action",
        this.props.user
      );
    }
    const cancelOrderActionInfo = {
      reservationId: this.props.customerReservation.id,
      dealerAction: DEALER_ACTIONS.NA_CANCEL,
      dealerRejectedReason: reason,
    };
    this.props.hideOrShow(true);
    CustomerReservationsClient.updateOrder(
      this.props.token,
      this.props.customerReservation.dealerId,
      cancelOrderActionInfo,
      this.props.user.userId
    )
      .then(() => {
        this.setState({ openCancel: false, cancelError: false });
        this.props.refreshReservationDetailsWithNotifications();
      })
      .catch((error) => {
        this.setState({ cancelError: true });
        if (isProcessingError(error)) {
          this.setState({ hasError: [ERROR_PROCESSING] });
        }
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  getCancelDialogProps = () => {
    if (
      UpperCaseText(this.props.customerReservation.orderType) === "RESERVATION"
    ) {
      if (
        this.props.customerReservation.catalogId.toUpperCase() ===
        US_MACHE_CATALOG_ID
      ) {
        return {
          title: this.props.t("OrderDetails.cancelReservationMacheTitle"),
          body: this.props.t("OrderDetails.cancelReservationMacheBodyText"),
          checkbox: this.props.t("OrderDetails.cancelReservationMacheCheckbox"),
          checkboxList: [
            this.props.t("OrderDetails.cancelReservationMacheCheckboxList1"),
            this.props.t("OrderDetails.cancelReservationMacheCheckboxList2"),
            this.props.t("OrderDetails.cancelReservationMacheCheckboxList3"),
          ],
          confirm: this.props.t("OrderDetails.cancelReservationMacheConfirm"),
          cancel: this.props.t("OrderDetails.cancelReservationMacheCancel"),
        };
      } else {
        if (IS_CANADA_MARKET(this.props.user.market)) {
          return {
            title: this.props.t("OrderDetails.cancelReservationTitle"),
            body: this.props.t("OrderDetails.cancelCANReservationBodyText"),
            checkbox: this.props.t("OrderDetails.cancelReservationCheckbox"),
            checkboxList: [
              this.props.t("OrderDetails.canadaCancelReservationCheckboxList1"),
              this.props.t("OrderDetails.canadaCancelReservationCheckboxList2"),
              this.props.t("OrderDetails.canadaCancelReservationCheckboxList3"),
              this.props.t("OrderDetails.canadaCancelReservationCheckboxList4"),
            ],
            confirm: this.props.t("OrderDetails.cancelReservationConfirm"),
            cancel: this.props.t("OrderDetails.cancelReservationCancel"),
          };
        } else {
          return {
            title: this.props.t("OrderDetails.cancelReservationTitle"),
            body: this.props.t("OrderDetails.cancelReservationBodyText"),
            checkbox: this.props.t("OrderDetails.cancelReservationCheckbox"),
            checkboxList: [
              this.props.t("OrderDetails.cancelReservationCheckboxList1"),
              this.props.t("OrderDetails.cancelReservationCheckboxList2"),
              this.props.t("OrderDetails.cancelReservationCheckboxList3"),
              this.props.t("OrderDetails.cancelReservationCheckboxList4"),
              this.props.t("OrderDetails.cancelReservationCheckboxList5"),
            ],
            confirm: this.props.t("OrderDetails.cancelReservationConfirm"),
            cancel: this.props.t("OrderDetails.cancelReservationCancel"),
          };
        }
      }
    } else {
      return {
        title: this.props.t("OrderDetails.cancelOrderTitle"),
        body: this.props.t("OrderDetails.cancelOrderBodyText"),
        checkbox: this.props.t("OrderDetails.cancelOrderCheckbox"),
        checkboxList: [
          this.props.t("OrderDetails.cancelOrderCheckboxList1"),
          this.props.t("OrderDetails.cancelOrderCheckboxList2"),
          this.props.t("OrderDetails.cancelOrderCheckboxList3"),
          this.props.t("OrderDetails.cancelOrderCheckboxList4"),
        ],
        confirm: this.props.t("OrderDetails.cancelOrderConfirm"),
        cancel: this.props.t("OrderDetails.cancelOrderCancel"),
      };
    }
  };

  displayOrderDetailsInformation = (orderType) => {
    return this.props.t("OrderDetails.orderInformation", {
      orderType: orderType,
    });
  };

  displayCANOrderDetailsInformation = (orderType) => {
    if (orderType === "RESERVATION") {
      return this.props.t("OrderDetails.reservationInformationCAN");
    }
    return this.props.t("OrderDetails.orderInformationCAN");
  };

  isReservation = (orderType) => {
    return orderType === "RESERVATION";
  };

  isConsentPending = (customerReservation) => {
    return (
      customerReservation.customerConsentInfo &&
      (UpperCaseText(customerReservation.customerConsentInfo.status) ===
        "INITIATED" ||
        UpperCaseText(customerReservation.customerConsentInfo.status) ===
          "REMINDED")
    );
  };

  expandOrderInfo = () => {
    this.setState({ expandOrderInfo: !this.state.expandOrderInfo });
  };

  isInStoreOrOfflineOrder = (orderChannel) => {
    return (
      UpperCaseText(orderChannel) === "INSTORE" ||
      UpperCaseText(orderChannel) === "OFFLINE"
    );
  };

  updateOrder = async (
    orderId,
    selectedTab,
    tab,
    dealerCommonId,
    rejectReason
  ) => {
    const naDealerActionInfo = {
      reservationId: orderId,
      dealerAction: selectedTab,
    };

    if (rejectReason) {
      naDealerActionInfo.dealerRejectedReason = rejectReason;
    }

    this.props.hideOrShow(true);
    CustomerReservationsClient.updateOrder(
      this.props.token,
      dealerCommonId,
      naDealerActionInfo,
      this.props.user.userId
    )
      .then(() => {
        window.location.reload(true);
      })
      .catch((error) => {
        if (isProcessingError(error)) {
          this.setState({ hasError: [ERROR_PROCESSING] });
        }
      })
      .finally(() => {
        this.props.hideOrShow(false);
      });
  };

  convertOrder = () => {
    if (this.props.user.isCanadaDealer()) {
      this.setState({
        openCAN: true,
      });
    } else {
      this.setState({
        openProcessOfflineDialog: !this.isConsentDeclined(),
        openProcessOfflineResubmitDialog: this.isConsentDeclined(),
      });
    }
  };

  isConsentDeclined = () => {
    return (
      this.props.customerReservation.customerConsentInfo &&
      UpperCaseText(
        this.props.customerReservation.customerConsentInfo.status
      ) === "DECLINED"
    );
  };

  getProcessOfflineBtn = (disabled) => {
    return (
      <EMPButton
        onClick={(e) => {
          e.stopPropagation();
          this.convertOrder();
        }}
        disabled={disabled}
        testId="process-offline-button"
        buttonType="contained"
        buttonText={this.props.t(
          "ReservationsSubmitted.processOfflineNormalCase"
        )}
      />
    );
  };

  getProcessOfflineDialogTitle = () => {
    if (
      this.props.user.market &&
      this.props.user.market.toUpperCase() === "USA" &&
      this.state.catalogId === US_MACHE_CATALOG_ID
    ) {
      return this.props.t("ReservationsSubmitted.areYouSure");
    } else {
      return this.props.t("ReservationsSubmitted.warning");
    }
  };

  getProcessOfflineDialogBodyCanadaUsers() {
    return this.props.user.market &&
      this.props.user.market.toUpperCase() === "CAN"
      ? [
          this.props.t("ReservationsSubmitted.bodyText1CA"),
          this.props.t("ReservationsSubmitted.bodyText2CA"),
          this.props.t("ReservationsSubmitted.bodyText3CA"),
          this.props.t("ReservationsSubmitted.bodyText4CA"),
          this.props.t("ReservationsSubmitted.bodyText5CA"),
        ]
      : [];
  }

  render() {
    const orderType = this.props.customerReservation.orderType;
    const formattedOrderType =
      orderType.charAt(0).toUpperCase() + orderType.slice(1).toLowerCase();
    const isReservation = this.isReservation(orderType);
    const isCancelled = CANCELLED_STATUSES.includes(
      this.props.customerReservation.status
    );
    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    const { customerReservation } = this.props;
    let submissionDate = "--";
    if (customerReservation.orderConfirmedDate) {
      submissionDate = customerReservation.orderConfirmedDate;
    } else if (customerReservation.date) {
      submissionDate = customerReservation.date;
    }

    const consentDeclinedDate =
      customerReservation.customerConsentInfo &&
      customerReservation.customerConsentInfo.consentDeclinedDate;

    const orderChannel = customerReservation.orderChannel;
    const reservationChannel = customerReservation.reservationChannel;

    const renderConversionStatusDataCard =
      ((isReservation || UpperCaseText(orderChannel) === "INSTORE") &&
        !IS_CANADA_MARKET(this.props.user.market)) ||
      customerReservation.reservationDealerAction;

    const isWbdoCancelled =
      customerReservation.cancellation &&
      customerReservation.cancellation.reason === WBDO_CANCELLATION_REASON;

    return (
      <>
        <StyledBasicExpansionPanel
          defaultExpanded={true}
          onChange={() => this.expandOrderInfo()}
          expanded={this.state.expandOrderInfo}
        >
          <StyledAccordionSummary
            id="exp"
            expandIcon={<ExpandLessIcon />}
            IconButtonProps={{ disableRipple: true }}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={6}>
                {IS_CANADA_MARKET(this.props.user.market)
                  ? this.displayCANOrderDetailsInformation(formattedOrderType)
                  : this.displayOrderDetailsInformation(formattedOrderType)}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {/* Render process offline for reservations */}
                  {UpperCaseText(customerReservation.orderType) ===
                    "RESERVATION" && (
                    <Grid item>
                      <HasPermissionTo
                        perform={["wbdoConvert:write"]}
                        permissions={
                          this.props.user.permissions.vehiclePermissions[
                            customerReservation.catalogId
                          ]
                        }
                        condition={
                          !this.isConsentPending(customerReservation) &&
                          !this.isWaitlisted(customerReservation) &&
                          !this.isWaveDateExpired(customerReservation)
                        }
                        render={() => {
                          return CANCELLED_STATUSES.includes(
                            customerReservation.status
                          ) || !customerReservation.readyToOrder
                            ? this.getProcessOfflineBtn(true)
                            : this.getProcessOfflineBtn(false);
                        }}
                        noRender={() => {
                          return this.getProcessOfflineBtn(true);
                        }}
                      />
                    </Grid>
                  )}
                  {/* Render accept and reject CTAs for orders requiring attention */}
                  {ORA_STATUSES.includes(customerReservation.status) ? (
                    customerReservation.orderType &&
                    UpperCaseText(customerReservation.orderType) === "ORDER" &&
                    (customerReservation.acceptRejectStatus === null ||
                      customerReservation.acceptRejectStatus === undefined) && (
                      <>
                        <Grid item>
                          <HasPermissionTo
                            perform={["acceptOrders:write"]}
                            permissions={
                              this.props.user.permissions.vehiclePermissions[
                                customerReservation.catalogId
                              ]
                            }
                            render={() => (
                              <EMPButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.handleAcceptOrder(
                                    customerReservation.id,
                                    DEALER_ACTIONS.ACCEPTED,
                                    "orders_requiring_attention",
                                    this.props.user.commonDealerId
                                      .split("|")
                                      .join("%7C")
                                  );
                                }}
                                testId="acceptOrderBtn"
                                buttonText={this.props.t(
                                  "OrdersRequiringAttention.acceptNormalCase"
                                )}
                                buttonElement="button"
                                buttonType="contained"
                              ></EMPButton>
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <HasPermissionTo
                            perform={["rejectOrders:write"]}
                            permissions={
                              this.props.user.permissions.vehiclePermissions[
                                customerReservation.catalogId
                              ]
                            }
                            render={() => (
                              <EMPButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.handleRejectOrder(
                                    customerReservation.id,
                                    this.props.user
                                  );
                                }}
                                testId="rejectOrderBtn"
                                buttonType="text"
                                buttonElement="button"
                                buttonText={this.props.t(
                                  "OrdersRequiringAttention.rejectNormalCase"
                                )}
                              ></EMPButton>
                            )}
                          />
                        </Grid>
                      </>
                    )
                  ) : (
                    <Grid item>
                      <HasPermissionTo
                        perform={["cancelOrderNA:write"]}
                        permissions={
                          this.props.user.permissions.rolePermissions
                        }
                        condition={!isCancelled}
                        render={() => {
                          return (
                            <EMPButton
                              onClick={(e) => {
                                e.stopPropagation();
                                this.toggleCancel();
                              }}
                              buttonText={this.props.t(
                                "OrderDetails.cancelOrderBtn"
                              )}
                              disabled={!customerReservation.isCancelEligible}
                              testId="cancelOrderBtn"
                              buttonType="text"
                              buttonElement="button"
                            />
                          );
                        }}
                        noRender={() => null}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </StyledAccordionSummary>
          <div className={styles.customerInfoGrid}>
            {customerReservation ? (
              <Grid container spacing={2}>
                {!isReservation && (
                  <>
                    <Grid item className={styles.cardGridItemWrapper}>
                      <DataCard
                        item
                        cardHeader={this.props.t("OrderDetails.orderDetails")}
                      >
                        <InfoDetail
                          header={this.props.t("CustomerReservations.id")}
                          value={customerReservation.id}
                        />

                        {customerReservation.vinNumber && (
                          <InfoDetail
                            header={this.props.t("OrderInfo.vin")}
                            value={customerReservation.vinNumber}
                          />
                        )}

                        <InfoDetail
                          header={this.props.t(
                            "OrderDetails.customerSubmissionDate"
                          )}
                          value={new Date(submissionDate)
                            .toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })
                            .replace(/\//g, "-")}
                        />

                        <InfoDetail
                          header={this.props.t("OrderDetails.status")}
                          value={
                            customerReservation.orderStatus
                              ? customerReservation.orderStatus
                              : this.getStatus(customerReservation)
                          }
                        />

                        {customerReservation.dealerAcceptedDate && (
                          <InfoDetail
                            header={this.props.t("OrderDetails.acceptedDate")}
                            value={customerReservation.dealerAcceptedDate}
                          />
                        )}
                        {customerReservation.dealerRejectedDate && (
                          <InfoDetail
                            header={this.props.t("OrderDetails.rejectedDate")}
                            value={customerReservation.dealerRejectedDate}
                          />
                        )}

                        {this.isInStoreOrOfflineOrder(orderChannel) && (
                          <InfoDetail
                            header={this.props.t(
                              "OrderDetails.consentAcceptanceDate"
                            )}
                            value={new Date(submissionDate)
                              .toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              })
                              .replace(/\//g, "-")}
                          />
                        )}
                      </DataCard>
                    </Grid>
                    <Grid item className={styles.cardGridItemWrapper}>
                      <DataCard item>
                        <InfoDetail
                          header={this.props.t("OrderDetails.webdoId")}
                          value={customerReservation.webdoId}
                        />

                        <InfoDetail
                          header={this.props.t("OrderDetails.amendedWBDO")}
                          value={
                            customerReservation.isOrderAmended
                              ? this.props.t("OrderDetails.yes")
                              : this.props.t("OrderDetails.no")
                          }
                        />
                        <InfoDetail
                          header={this.props.t("OrderDetails.channel")}
                          value={
                            orderChannel ? (
                              this.props.t(
                                `OrderDetails.${orderChannel.toLowerCase()}`
                              )
                            ) : (
                              <EMPEmptyState />
                            )
                          }
                        />

                        <InfoDetail
                          header={this.props.t("OrderDetails.dealerUserId")}
                          value={
                            customerReservation.orderDetailsDealerAction &&
                            customerReservation.orderDetailsDealerAction
                              .dealerActionUserId
                          }
                        />

                        <InfoDetail
                          header={this.props.t(
                            "OrderDetails.cancellationReason"
                          )}
                          value={
                            isWbdoCancelled
                              ? this.props.t(
                                  "OrderDetails.wbdoCancellationReason"
                                )
                              : customerReservation.cancellation &&
                                customerReservation.cancellation.reason
                          }
                          tooltip={
                            isWbdoCancelled && (
                              <SharedToolTip
                                {...this.props}
                                openTestid="cancellationReasonTooltipId"
                                closeTestid="closeTooltipId"
                                title={this.props.t(
                                  "OrderDetails.wbdoCancelToolTipTitle"
                                )}
                                body={
                                  <Grid container>
                                    <Grid item xs={12}>
                                      {this.props.t(
                                        "OrderDetails.wbdoCancelToolTipBody1"
                                      )}
                                    </Grid>
                                    <Grid item xs={12}>
                                      {this.props.t(
                                        "OrderDetails.wbdoCancelToolTipBody2"
                                      )}
                                    </Grid>
                                    <Grid item xs={12}>
                                      {this.props.t(
                                        "OrderDetails.wbdoCancelToolTipBody3"
                                      )}
                                    </Grid>
                                  </Grid>
                                }
                              />
                            )
                          }
                        />
                      </DataCard>
                    </Grid>
                  </>
                )}

                {(customerReservation.conversionDate || isReservation) && (
                  <>
                    <Grid item className={styles.cardGridItemWrapper}>
                      <DataCard
                        cardHeader={this.props.t(
                          "OrderDetails.reservationDetails"
                        )}
                      >
                        {isReservation && (
                          <InfoDetail
                            header={this.props.t(
                              "CustomerReservations.reservationID"
                            )}
                            value={customerReservation.id}
                          />
                        )}
                        <InfoDetail
                          header={
                            isReservation
                              ? this.props.t(
                                  "OrderDetails.customerSubmissionDate"
                                )
                              : this.props.t("OrderDetails.reservationDate")
                          }
                          value={new Date(customerReservation.date)
                            .toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })
                            .replace(/\//g, "-")}
                        />
                        {isReservation &&
                          this.props.customerReservation.isWaitlisted && (
                            <InfoDetail
                              header={this.props.t("OrderDetails.extendedDate")}
                              value={UiDateUtils.formattedDate(
                                customerReservation.waitListedDate
                              )}
                            />
                          )}
                        <InfoDetail
                          header={this.props.t("OrderDetails.status")}
                          value={this.getReservationStatus(customerReservation)}
                        />

                        <InfoDetail
                          header={this.props.t("OrderDetails.channel")}
                          value={
                            reservationChannel ? (
                              this.props.t(
                                `OrderDetails.${reservationChannel.toLowerCase()}`
                              )
                            ) : (
                              <EMPEmptyState />
                            )
                          }
                        />

                        <InfoDetail
                          header={this.props.t("OrderDetails.dealerUserId")}
                          value={
                            (customerReservation.reservationDealerAction &&
                              customerReservation.reservationDealerAction
                                .dealerActionUserId) ||
                            (customerReservation.customerConsentInfo &&
                              customerReservation.customerConsentInfo
                                .dealerActionDetails &&
                              customerReservation.customerConsentInfo
                                .dealerActionDetails.dealerActionUserId)
                          }
                        />
                        {isReservation && (
                          <InfoDetail
                            header={this.props.t(
                              "OrderDetails.reservationCancellationDate"
                            )}
                            value={
                              customerReservation.cancellation &&
                              customerReservation.cancellation.cancellationDate
                            }
                          />
                        )}
                        {isReservation && (
                          <InfoDetail
                            header={this.props.t(
                              "OrderDetails.cancellationReason"
                            )}
                            value={
                              isWbdoCancelled
                                ? this.props.t(
                                    "OrderDetails.wbdoCancellationReason"
                                  )
                                : customerReservation.cancellation &&
                                  customerReservation.cancellation.reason
                            }
                            tooltip={
                              isWbdoCancelled && (
                                <SharedToolTip
                                  {...this.props}
                                  openTestid="cancellationReasonTooltipId"
                                  closeTestid="closeTooltipId"
                                  title={this.props.t(
                                    "OrderDetails.wbdoCancelToolTipTitle"
                                  )}
                                  body={
                                    <Grid container>
                                      <Grid item xs={12}>
                                        {this.props.t(
                                          "OrderDetails.wbdoCancelToolTipBody1"
                                        )}
                                      </Grid>
                                      <Grid item xs={12}>
                                        {this.props.t(
                                          "OrderDetails.wbdoCancelToolTipBody2"
                                        )}
                                      </Grid>
                                      <Grid item xs={12}>
                                        {this.props.t(
                                          "OrderDetails.wbdoCancelToolTipBody3"
                                        )}
                                      </Grid>
                                    </Grid>
                                  }
                                />
                              )
                            }
                          />
                        )}
                      </DataCard>
                    </Grid>
                    {renderConversionStatusDataCard && (
                      <Grid item className={styles.cardGridItemWrapper}>
                        <DataCard>
                          {(isReservation ||
                            UpperCaseText(customerReservation.orderChannel) ===
                              "INSTORE") &&
                            !IS_CANADA_MARKET(this.props.user.market) && (
                              <>
                                <InfoDetail
                                  header={this.props.t(
                                    "OrderDetails.conversionStatus"
                                  )}
                                  value={this.getConversionStatus(
                                    customerReservation
                                  )}
                                />
                                <InfoDetail
                                  header={this.props.t(
                                    "OrderDetails.consentReminderDate"
                                  )}
                                  value={this.getConsentReminderDate(
                                    customerReservation
                                  )}
                                />
                                <InfoDetail
                                  header={this.props.t(
                                    "OrderDetails.resubmissionReason"
                                  )}
                                  value={this.getResubmissionReason(
                                    customerReservation
                                  )}
                                />
                              </>
                            )}
                          {customerReservation.reservationDealerAction && (
                            <InfoDetail
                              header={this.props.t("OrderDetails.dealerUserId")}
                              value={
                                customerReservation.reservationDealerAction
                                  .dealerActionUserId ||
                                (customerReservation.customerConsentInfo &&
                                  customerReservation.customerConsentInfo
                                    .dealerActionDetails &&
                                  customerReservation.customerConsentInfo
                                    .dealerActionDetails.dealerActionUserId)
                              }
                            />
                          )}
                          {consentDeclinedDate && (
                            <InfoDetail
                              header={this.props.t(
                                "OrderDetails.consentDeclinedDate"
                              )}
                              value={consentDeclinedDate}
                            />
                          )}
                        </DataCard>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            ) : (
              <span className={styles.labelError}>
                {" "}
                {this.props.t("CustomerOrderDetails.errorLoadingCustomerInfo")}
              </span>
            )}
          </div>
        </StyledBasicExpansionPanel>

        {/*Customer Order Dialog to show reject order dialog*/}
        {this.state.openRejectOrderDialog && (
          <CustomerOrdersDialog
            open={this.state.openRejectOrderDialog}
            onClose={this.onClose}
            onGoBack={this.handleRejectOrderCancel}
            dialogTitle={this.props.t("OrdersRequiringAttention.warning")}
            dialogBody={[
              this.props.t("OrdersRequiringAttention.bodyText1"),
              this.props.t("OrdersRequiringAttention.bodyText2"),
              this.props.t("OrdersRequiringAttention.bodyText3"),
              this.props.t("OrdersRequiringAttention.bodyText4"),
            ]}
            confirmText={this.props.t("OrdersRequiringAttention.confirmText")}
            cancelText={this.props.t("OrdersRequiringAttention.cancelText")}
            disableConfirm={
              !this.props.user.permissions.rolePermissions.hasOwnProperty(
                "rejectOrdersSubmit"
              )
            }
            confirmAction={() =>
              this.handleRejectOrderConfirm(
                customerReservation.id,
                DEALER_ACTIONS.REJECTED,
                "orders_requiring_attention",
                this.props.user.commonDealerId.split("|").join("%7C")
              )
            }
          />
        )}

        {/*Open process offline dialog for reservations submitted*/}
        {this.state.openProcessOfflineDialog && (
          <ProcessOfflineDialog
            {...this.props}
            open={this.state.openProcessOfflineDialog}
            onClose={this.onClose}
            confirmAction={() =>
              this.updateOrder(
                customerReservation.id,
                DEALER_ACTIONS.INSTORE,
                "reservations_submitted",
                FORMAT_COMMON_ID(this.props.user.commonDealerId)
              )
                .then(() => {
                  this.onClose();
                })
                .catch(() => {
                  this.setState({ hasError: [ERROR_PROCESSING] });
                })
            }
          />
        )}

        {/*Open process offline resubmit dialog for reservations submitted*/}
        {this.state.openProcessOfflineResubmitDialog && (
          <ResubmitOfflineDialog
            {...this.props}
            open={this.state.openProcessOfflineResubmitDialog}
            onClose={() => this.onClose()}
            orderId={customerReservation.id}
            commonDealerId={FORMAT_COMMON_ID(this.props.user.commonDealerId)}
            updateReservation={this.updateOrder}
          />
        )}

        {/*Open process offline dialog for Canada dealers*/}
        {this.state.openCAN && (
          <CustomerOrdersDialog
            open={this.state.openCAN}
            onClose={() => this.onClose()}
            onGoBack={() => this.onClose()}
            catalogId={customerReservation.catalogId}
            dialogTitle={this.getProcessOfflineDialogTitle()}
            dialogBody={this.getProcessOfflineDialogBodyCanadaUsers()}
            confirmText={this.props.t("ReservationsSubmitted.confirmText")}
            cancelText={this.props.t("ReservationsSubmitted.cancelText")}
            confirmAction={() =>
              this.updateOrder(
                customerReservation.id,
                DEALER_ACTIONS.INSTORE,
                "reservations_submitted",
                FORMAT_COMMON_ID(this.props.user.commonDealerId)
              ).then(() => {
                this.onClose();
              })
            }
          />
        )}

        <HasPermissionTo
          perform={["cancelOrderNA:write"]}
          permissions={this.props.user.permissions.rolePermissions}
          render={() => {
            return (
              <CancelOrderDialog
                {...this.props}
                isRefund={false}
                dialogProps={this.getCancelDialogProps()}
                open={this.state.openCancel}
                onClose={this.toggleCancel}
                handleConfirm={this.submitCancel}
                showDropdown
                cancelType="reservationReasons"
                onEnter={this.trackOpen}
                orderType={
                  this.props.customerReservation &&
                  this.props.customerReservation.orderType
                }
                transportError={this.state.cancelError}
              />
            );
          }}
          noRender={() => null}
        />
      </>
    );
  }
}

export default withTranslation("emp")(OrderInformation);
