import React from "react";
import EMPDialog from "../shared/components/EMPDialog/EMPDialog";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 14,
  },
  body1: {
    color: "black",
  },
});

const ErrorProcessingDialog = (props) => {
  const { open, close, title, body, buttonText } = props;
  const classes = useStyles();

  return (
    <EMPDialog
      dialogTitle={title}
      open={open}
      hasCloseIcon
      handleClose={close}
      hasActionButton
      actionButtonText={buttonText}
      onConfirm={close}
    >
      <Typography
        variant="body1"
        classes={{ root: classes.root, body1: classes.body1 }}
      >
        {body}
      </Typography>
    </EMPDialog>
  );
};

export default ErrorProcessingDialog;
