import { React } from "react";
import { SvgIcon } from "@material-ui/core";

const MotionPlus = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 48 48"
      htmlColor="#066FEF"
      shapeRendering="crispEdges"
    >
      <path d="M37.2 22.8H25.2V10.8H22.8V22.8H10.8V25.2H22.8V37.2H25.2V25.2H37.2V22.8Z" />
    </SvgIcon>
  );
};

export default MotionPlus;
