import React, { useEffect, useState } from "react";
import styles from "./EMPStatusCard.module.scss";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import EMPButton from "../EMPButton/EMPButton";
import HasPermissionTo from "../../../common/HasPermissionTo";

// Available Props
// cardTitle: string
// status: string (default, warning, success)
// hasActionButton: Boolean
// actionButtonText: string
// onActionClick: func
// icon: Component
// user: object
// actionButtonPermission: Array

const useStyles = makeStyles(
  {
    root: {
      fontFamily: "'FordAntennaRegular', sans-serif",
    },
    h2: {
      color: "#00095b",
      fontSize: 24,
      fontWeight: 400,
      fontFamily: "'FordAntennaBold', sans-serif",
    },
    body1: {
      color: "#4d4d4d",
      fontSize: 16,
    },
  },
  {
    name: "EMPStatusCard",
  }
);

const defaultColor = {
  container: {
    border: "1px solid #00095b",
  },
  headline: {
    color: "#00095b",
  },
};

const warningColor = {
  container: {
    border: "1px solid #D62D0A",
  },
  headline: {
    color: "#D62D0A",
  },
};

const successColor = {
  container: {
    border: "1px solid #008200",
  },
  headline: {
    color: "#008200",
  },
};

const EMPStatusHeadline = (props) => {
  const classes = useStyles();
  const { cardTitle, color, icon } = props;
  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      {icon && (
        <Grid item style={color ? color.headline : null}>
          <Box mr={1} style={{ fontSize: 0 }}>
            {icon}
          </Box>
        </Grid>
      )}
      <Grid item>
        <Typography
          variant="h2"
          classes={{ root: classes.root, h2: classes.h2 }}
          style={color ? color.headline : null}
          data-testid="status-headline"
        >
          {cardTitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

const EMPStatusCard = (props) => {
  const [color, setColor] = useState(defaultColor);
  const {
    cardTitle,
    status,
    hasActionButton,
    children,
    onActionClick,
    actionButtonText,
    user,
    icon,
    actionButtonPermission,
  } = props;

  useEffect(() => {
    updateColor(status);
  }, [status]);

  const updateColor = (statusColorProp) => {
    const colorMap = {
      warning: warningColor,
      default: defaultColor,
      success: successColor,
    };

    setColor(colorMap[statusColorProp]);
  };

  return (
    <Grid
      container
      spacing={0}
      justifyContent="space-between"
      alignItems="center"
      className={styles.statusCardContainer}
      style={color ? color.container : null}
    >
      <Grid item xs={hasActionButton ? 5 : 12} sm="auto">
        <EMPStatusHeadline cardTitle={cardTitle} color={color} icon={icon} />
      </Grid>
      {hasActionButton && actionButtonPermission && (
        <HasPermissionTo
          perform={actionButtonPermission}
          permissions={user.permissions.rolePermissions}
          render={() => {
            return (
              <Grid xs="auto" item>
                <EMPButton
                  buttonText={actionButtonText}
                  buttonType="link"
                  onClick={onActionClick}
                  testId="action-button"
                ></EMPButton>
              </Grid>
            );
          }}
        ></HasPermissionTo>
      )}
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Box mt={4}>{children}</Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EMPStatusCard;
