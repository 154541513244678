import React from "react";
import EMPDialog from "../../../shared/components/EMPDialog/EMPDialog";
import { makeStyles, Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 14,
  },
  body1: {
    color: "black",
    fontSize: 14,
  },
});
const EVTaxCreditLinksDialog = (props) => {
  const { open, handleClose, t } = props;
  const classes = useStyles(props);
  return (
    <EMPDialog
      open={open}
      hasCloseIcon
      handleClose={handleClose}
      dialogTitle={t("ModelEOrders.evTaxCreditLinkDialogText.title")}
    >
      <ol>
        <li>
          <Typography
            variant="body1"
            classes={{ root: classes.root, body1: classes.body1 }}
          >
            {t("ModelEOrders.evTaxCreditLinkDialogText.info1")}{" "}
            <a
              href={t("ModelEOrders.evTaxCreditLinkDialogText.info1LinkUrl")}
              target="_blank"
              rel="noreferrer"
            >
              {t("ModelEOrders.evTaxCreditLinkDialogText.info1LinkText")}
            </a>
          </Typography>
        </li>
        <li>
          <Typography
            variant="body1"
            classes={{ root: classes.root, body1: classes.body1 }}
          >
            {t("ModelEOrders.evTaxCreditLinkDialogText.info2")}{" "}
            <a
              href={t("ModelEOrders.evTaxCreditLinkDialogText.info2LinkUrl")}
              target="_blank"
              rel="noreferrer"
            >
              {t("ModelEOrders.evTaxCreditLinkDialogText.info2LinkText")}
            </a>
          </Typography>
        </li>
        <li>
          <Typography
            variant="body1"
            classes={{ root: classes.root, body1: classes.body1 }}
          >
            {t("ModelEOrders.evTaxCreditLinkDialogText.info3")}{" "}
            <a
              href={t("ModelEOrders.evTaxCreditLinkDialogText.info3LinkUrl")}
              target="_blank"
              rel="noreferrer"
            >
              {t("ModelEOrders.evTaxCreditLinkDialogText.info3LinkText")}
            </a>
          </Typography>
        </li>
      </ol>
    </EMPDialog>
  );
};

export default withTranslation("emp")(EVTaxCreditLinksDialog);
