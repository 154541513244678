import React from "react";
import { withTranslation } from "react-i18next";
import fordStyles from "../dashboardCards/DashboardCards-Ford.module.scss";
import lincolnStyles from "../dashboardCards/DashboardCards-Lincoln.module.scss";
import { IS_FORD_BRAND, IS_CANADA_MARKET } from "../../../utils/EmpUtil";
import { messages } from "./messages";
import { Box } from "@material-ui/core";

const DashboardMessage = (props) => {
  const styles = IS_FORD_BRAND(props.user.brand) ? fordStyles : lincolnStyles;
  const brand = IS_FORD_BRAND(props.user.brand) ? "FordBrand" : "LincolnBrand";
  const market = IS_CANADA_MARKET(props.user.market) ? "CA" : "US";
  const permissionsMap = new Map();

  // universal messages
  permissionsMap.set("FIELD_VIEW", messages.FIELD_User_Message);
  permissionsMap.set(
    "EMP_ECOMM_ACCESSORIES",
    messages[`${market}_${brand}_AccessoriesMessage`]
  );
  permissionsMap.set(
    "EMP_ECOMM_ACCESSORIES,EMP_ECOMM_CUSTOMERHANDLING,EMP_ECOMM_DEALER,SUBMIT",
    messages.ECOMM_AllPermissions_Message
  );
  permissionsMap.set(
    "EMP_ECOMM_ACCESSORIES,EMP_ECOMM_CUSTOMERHANDLING,EMP_ECOMM_DEALER,SUBMIT,VIEW",
    messages.ECOMM_AllPermissions_Message
  );

  permissionsMap.set(
    "EMP_ECOMM_ACCESSORIES,EMP_ECOMM_CUSTOMERHANDLING,EMP_ECOMM_DEALER,EMP_MODELE,SUBMIT",
    messages.ECOMM_AllPermissions_Message
  );

  permissionsMap.set(
    "EMP_ECOMM_ACCESSORIES,EMP_ECOMM_CUSTOMERHANDLING,EMP_ECOMM_DEALER,EMP_MODELE,SUBMIT,VIEW",
    messages.ECOMM_AllPermissions_Message
  );

  // brand and market specific messages
  permissionsMap.set("VIEW", messages[`${market}_${brand}_ViewMessage`]);
  permissionsMap.set("SUBMIT", messages[`${market}_${brand}_SubmitMessage`]);
  permissionsMap.set(
    "SUBMIT,VIEW",
    messages[`${market}_${brand}_SubmitMessage`]
  );
  permissionsMap.set(
    "EMP_ECOMM_ACCESSORIES,SUBMIT",
    messages[`${market}_${brand}_SubmitMessage`]
  );
  permissionsMap.set(
    "EMP_ECOMM_ACCESSORIES,SUBMIT,VIEW",
    messages[`${market}_${brand}_SubmitMessage`]
  );
  permissionsMap.set(
    "EMP_ECOMM_CUSTOMERHANDLING,MANAGE_ORDER_BANK,VIEW",
    messages[`${market}_${brand}_CustomerHandlingMessage`]
  );
  permissionsMap.set(
    "EMP_ECOMM_ACCESSORIES,EMP_ECOMM_CUSTOMERHANDLING",
    messages[`${market}_${brand}_CustomerHandlingAndAccessoryMessage`]
  );
  permissionsMap.set(
    "EMP_ECOMM_ACCESSORIES,EMP_ECOMM_CUSTOMERHANDLING,MANAGE_ORDER_BANK,VIEW",
    messages[`${market}_${brand}_CustomerHandlingAndAccessoryMessage`]
  );
  permissionsMap.set(
    "EMP_DLR_PRNCPL",
    messages.ECOMM_CA_DealerPrincipal_Message
  );
  permissionsMap.set(
    "EMP_DLR_PRNCPL,SUBMIT",
    messages.ECOMM_AllPermissions_Message
  );
  permissionsMap.set(
    "EMP_DLR_PRNCPL,VIEW",
    messages.ECOMM_AllPermissions_Message
  );
  permissionsMap.set(
    "EMP_DLR_PRNCPL,SUBMIT,VIEW",
    messages.ECOMM_AllPermissions_Message
  );
  permissionsMap.set("EMP_MODELE", messages.ECOMM_MODELE_Message);
  permissionsMap.set("EMP_ADMIN", messages.ECOMM_Admin_Message);
  permissionsMap.set("EMP_CRC,EMP_CRC_REFUND", messages.ECOMM_CRC_Message);
  permissionsMap.set("EMP_CRC", messages.ECOMM_CRC_Message);

  // check user roles, if all exist process translation strings
  const checkPermissions = (permissionsArr, translationArr) => {
    const arrayCheck = permissionsArr.every((value) => {
      if (props.userDetails.roles && props.userDetails.roles.length > -1) {
        return props.userDetails.roles.includes(value);
      } else {
        return null;
      }
    });

    if (translationArr !== undefined) {
      return arrayCheck ? processStrings(translationArr) : null;
    } else {
      return "";
    }
  };

  // convert translation strings into returned HTML with criteria
  const processStrings = (translationArr) => {
    return translationArr.map((string) =>
      string === "DealerDashboard.navBarTextIntro" ||
      string === "DealerDashboard.navBarText_additionalPermissions" ||
      string === "DealerDashboard.eComm_Accessory_navBarText" ||
      string === "DealerDashboard.allPermissions" ||
      string === "DealerDashboard.fieldViewUserNavBarText" ||
      string === "DealerDashboard.modelEPilot" ? (
        <li className={styles.unstyledListItem} key={string}>
          {props.t(string)}
        </li>
      ) : (
        <li key={string}>{props.t(string)}</li>
      )
    );
  };

  return (
    <Box mb={0} component="ul" className={styles.styledList}>
      {props.userDetails &&
        checkPermissions(
          props.userDetails.roles,
          permissionsMap.get(props.userDetails.roles.sort().join(","))
        )}
    </Box>
  );
};

export default withTranslation("emp")(DashboardMessage);
