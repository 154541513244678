import styles from "./Search.module.scss";
import React from "react";
import { withTranslation } from "react-i18next";

const CustomerEmailOrOrderIdSearch = (props) => {
  return (
    <div>
      <p className={styles.subheader}>
        To search for a consumer order, enter a valid customer email address or
        order ID number.
      </p>
      <div className={styles.inputSearchWrapper}>
        SEARCH BY:
        <form className={styles.form} onSubmit={props.searchOrders}>
          <input
            value={props.searchQuery}
            data-testid="crcInput"
            name="search"
            type="text"
            className={styles.inputField}
            onChange={(e) => props.updateSearchQuery("searchQuery", e)}
            placeholder="Customer email or order ID"
            required
          />
          <button
            className={styles.searchButtonIcon}
            type="submit"
            id="searchBtn"
            data-testid="searchBtn"
          >
            <div className={styles.searchIcon}></div>
          </button>
        </form>
      </div>
    </div>
  );
};
export default withTranslation("emp")(CustomerEmailOrOrderIdSearch);
