import React, { useEffect, useState } from "react";
import EMPStatusCard from "../../../shared/components/EMPStatusCard/EMPStatusCard";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  withStyles,
} from "@material-ui/core";
import EMPDialog from "../../../shared/components/EMPDialog/EMPDialog";
import EMPTypeAheadField from "../../../shared/components/EMPTypeAheadField/EMPTypeAheadField";
import DeliveryClient from "../../../clients/DeliveryClient";
import { withTranslation } from "react-i18next";
import UiDateUtils from "../../../utils/UiDateUtils";
import getDeliveryStatus from "../../../utils/DeliveryStatusUtils/GetDeliveryStatus";
import EMPDatePicker from "../../../shared/components/EMPDatePicker/EMPDatePicker";
import UpperCaseText from "../../../utils/UpperCaseText";
import * as AnalyticsService from "../../../shared/services/analytics/AnalyticsService";
import { ENV, GET_SCHEDULER_URL } from "../../../utils/EmpUtil";
import Tooltip from "@material-ui/core/Tooltip";
import EMPInputField from "../../../shared/components/EMPInputField/EMPInputField";
import { CopyToClipboard } from "react-copy-to-clipboard";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import { MODELE_DEALER_INTERACTIONS } from "../../../common/Constants";
import EMPEmptyState from "../../../shared/components/EMPEmptyState/EMPEmptyState";
import DealerDeliveryToolClient from "../../../clients/DealerDeliveryToolClient";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Skeleton from "@material-ui/lab/Skeleton";

// Available Props:
// customerInfo: object
// vehicleInfo: object
// user: object
// transportError: Func

const useStyles = makeStyles({
  root: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 14,
  },
  h6: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 20,
    color: "#00095b",
    marginBottom: "16px",
  },
  subtitle1: {
    color: "#5c5c5c",
  },
  body1: {
    color: "black",
    fontSize: 14,
  },
  body2: {
    fontSize: 12,
    lineHeight: "20px",
    color: "#4d4d4d",
    marginBottom: 8,
  },
  bold: {
    fontFamily: "'FordAntennaBold', sans-serif",
    color: "black",
  },
  errorText: {
    color: "#D62D0A",
  },
  sizeSmall: {
    marginRight: 8,
    marginBottom: 8,
    backgroundColor: "#ffffff",
    borderRadius: 8,
    border: "1px solid #00095b",

    "&:last-of-type": {
      marginRight: 0,
    },
  },
  labelSmall: {
    fontSize: 12,
    padding: "4px 8px",
    color: "#00095b",
    textTransform: "capitalize",
    lineHeight: "24px",
  },
  input: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 14,
    color: "#000000",
    width: "100%",
    backgroundColor: "white",
    borderRadius: 8,
    padding: "8px 65px 8px 10px !important",
  },
});

const FormWrapper = withStyles({
  root: {
    border: "1px solid #cccccc",
    padding: "16px",
    borderRadius: "8px",
  },
})(Box);

const NoDeliveryCard = (props) => {
  const { classes, url, t, isCanceled, title } = props;
  const [value] = useState(url);
  const [copied, setCopied] = useState(false);

  const copyAndReset = (result) => {
    setCopied(result);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <EMPStatusCard cardTitle={title} status="default">
      <Grid container>
        <Grid item xs={12}>
          <Box mb={1}>
            <Typography
              variant="body1"
              classes={{ root: classes.root, subtitle1: classes.subtitle1 }}
            >
              {isCanceled
                ? t("ModelEOrders.deliveryCancelledExplanation")
                : t("ModelEOrders.deliveryNotScheduledExplanation")}
            </Typography>
          </Box>
        </Grid>

        {!isCanceled && (
          <Grid item xs={12}>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item xs={12} sm={8}>
                <Box mr={2}>
                  <EMPInputField
                    value={value}
                    readOnly
                    testId="scheduler-url"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  open={copied}
                  disableFocusListener
                  disableHoverListener
                  title={t("ModelEOrders.copied")}
                  arrow
                  placement="top"
                  data-testid="copy-tooltip"
                >
                  <CopyToClipboard
                    text={value}
                    onCopy={(text, result) => copyAndReset(result)}
                  >
                    <EMPButton
                      buttonType="outlined"
                      buttonText={t("ModelEOrders.copyToClipboard")}
                      testId="copy-button"
                    ></EMPButton>
                  </CopyToClipboard>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </EMPStatusCard>
  );
};

const DeliveryDialog = (props) => {
  const {
    open,
    status,
    handleClose,
    customerInfo,
    vehicleInfo,
    classes,
    t,
    appointment,
    specialist,
    onConfirm,
    actionLoading,
    user,
  } = props;

  const [formData, setFormData] = useState({
    newDate: appointment.date,
    specialist: {
      dealershipEmployeeName: appointment["dealershipEmployeeName"],
      dealershipEmployee: appointment["dealershipEmployee"],
    },
  });

  const [submissionDisabled, setSubmissionDisabled] = useState(true);

  const dealerId = sessionStorage.getItem("dealerId");
  const token = sessionStorage.getItem("adfs.token");

  const getEmployees = async () =>
    await DeliveryClient.getEmployees(token, dealerId);

  const validateAndSetFormData = (data) => {
    if (data !== null) {
      setFormData({ ...formData, ...data });
    }
  };

  const setModalHeader = (status) =>
    status === "requested"
      ? t("ModelEOrders.confirmAppointment")
      : t("ModelEOrders.deliveryDetails");

  const submitFormData = (token, dealerId, appointment, formData) => {
    setSubmissionDisabled(true);
    onConfirm(token, dealerId, appointment, formData);
  };

  const currentButtonText =
    status === "requested"
      ? t("ModelEOrders.confirmAppointment")
      : t("ModelEOrders.saveChanges");

  function trackModalOpen(modalHeader, orderId) {
    AnalyticsService.trackPageLoadDetailEvent(
      "fv:emp:model e details:appt popup",
      `emp:model e:appt form:${modalHeader}`,
      `emp:model e:order id:${orderId}`,
      user
    );
  }

  function trackConfirmAppointment(orderId) {
    AnalyticsService.trackOnClickDetailEvent(
      "fv:emp:model e details:appt popup",
      `emp:model e:appt form:${setModalHeader(status).toLowerCase()}`,
      `emp:model e:order id:${orderId}`,
      orderId,
      `emp:model e details:appt popup:content:${currentButtonText.toLowerCase()}`,
      "fv:emp:model e details:appt popup:content action",
      user
    );
  }

  useEffect(() => {
    const checkForNull = Object.values(formData).some(
      (value) => value === null || value === ""
    );

    return setSubmissionDisabled(checkForNull);
  }, [formData]);

  return (
    <EMPDialog
      open={open}
      hasCloseIcon
      handleClose={handleClose}
      onEnter={() => {
        trackModalOpen(setModalHeader(status), appointment.orderId);
        setSubmissionDisabled(true);
      }}
      onExit={() =>
        setFormData({
          newDate: appointment.date,
          specialist: {
            dealershipEmployeeName: appointment["dealershipEmployeeName"],
            dealershipEmployee: appointment["dealershipEmployee"],
          },
        })
      }
      dialogTitle={setModalHeader(status)}
      hasActionButton
      actionLoading={actionLoading}
      actionButtonText={currentButtonText}
      onConfirm={() => {
        trackConfirmAppointment(appointment.orderId);
        submitFormData(token, dealerId, appointment, formData);
      }}
      disableActionButton={submissionDisabled}
    >
      <FormWrapper mb={2}>
        <Typography
          variant="h6"
          classes={{ root: classes.root, h6: classes.h6 }}
        >
          {t("ModelEOrders.customer")}
        </Typography>

        <Typography
          variant="body1"
          classes={{ root: classes.root, body1: classes.body1 }}
        >{`${customerInfo.firstName} ${customerInfo.lastName}`}</Typography>
        <Typography
          variant="body1"
          classes={{ root: classes.root, body1: classes.body1 }}
        >{`${vehicleInfo.modelYear} ${vehicleInfo.namePlate}`}</Typography>
        <Typography
          variant="body1"
          classes={{ root: classes.root, body1: classes.body1 }}
        >
          {`VIN: ${vehicleInfo.vin}`}
        </Typography>
      </FormWrapper>
      <FormWrapper mb={2}>
        <Typography
          variant="h6"
          classes={{ root: classes.root, h6: classes.h6 }}
        >
          {status === "requested"
            ? t("ModelEOrders.requestedDateAndTime")
            : t("ModelEOrders.dateAndTime")}
        </Typography>

        <Typography
          variant="body2"
          classes={{ root: classes.root, body2: classes.body2 }}
        >
          {t("ModelEOrders.dateTimeHelperText")}
        </Typography>

        <EMPDatePicker
          incomingDate={new Date(formData.newDate)}
          startAtToday
          transportFormData={(newDate) => validateAndSetFormData({ newDate })}
        />
      </FormWrapper>
      <FormWrapper mb={2}>
        <Typography
          variant="h6"
          classes={{ root: classes.root, h6: classes.h6 }}
        >
          {t("ModelEOrders.deliverySpecialist")}
        </Typography>
        {specialist ? (
          <Box mb={2}>
            <Typography
              variant="body1"
              classes={{ root: classes.root, body1: classes.bold }}
            >
              Assigned to {specialist}
            </Typography>
            <Typography
              variant="body2"
              classes={{ root: classes.root, body2: classes.body2 }}
            >
              {t("ModelEOrders.deliveryAssigned2")}
            </Typography>
          </Box>
        ) : (
          <Typography
            variant="body2"
            classes={{ root: classes.root, body2: classes.body2 }}
          >
            {t("ModelEOrders.deliveryNotAssigned")}
          </Typography>
        )}

        <EMPTypeAheadField
          fieldName="employees"
          fieldLabel="Search for an employee"
          noOptionsText="No results found"
          onFetch={getEmployees}
          dataKey="dealerEmployees"
          transportFormData={(specialist) =>
            validateAndSetFormData({ specialist })
          }
          disabledItem={specialist}
        ></EMPTypeAheadField>
      </FormWrapper>
    </EMPDialog>
  );
};

const Delivery = (props) => {
  const classes = useStyles(props);
  const {
    orderId,
    customerInfo,
    vehicleInfo,
    t,
    user,
    setCustomerFollowupNotification,
    transportError,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDeliverySpecialist, setSelectedDeliverySpecialist] =
    useState(null);
  const [deliveryStatus, setDeliveryStatus] = useState(null);
  const { userType } = user;
  const isFieldUser = userType && userType === "field_view";

  const queryClient = useQueryClient();

  const {
    isFetching,
    data: deliveryModule,
    isError,
  } = useQuery({
    queryKey: ["deliveryModule", orderId],
    retry: 1,
    queryFn: () =>
      DealerDeliveryToolClient.getDeliveryModuleInfo(
        user.token,
        isFieldUser ? user?.dealerGroup[0].paCode : user.dealerId,
        orderId
      ),
    onSuccess: (deliveryModule) => {
      setDeliveryStatus(() =>
        getDeliveryStatus(deliveryModule.deliveryAppointment)
      );
      setSelectedDeliverySpecialist(
        deliveryModule.deliveryAppointment &&
          deliveryModule.deliveryAppointment.dealershipEmployeeName
          ? deliveryModule.deliveryAppointment.dealershipEmployeeName
          : null
      );
      setCustomerFollowupNotification(
        deliveryModule?.showCustomerFollowupNotification
      );
    },
  });

  const generateSchedulerUrl = (orderId) => {
    const url = GET_SCHEDULER_URL(ENV);
    return `${url}?orderId=${orderId}`;
  };

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => {
    setLoading(false);
    setDialogOpen(false);
  };

  const submitFormData = async (token, dealerId, appointment, formData) => {
    setLoading(true);
    try {
      await DeliveryClient.updateAppointment(
        token,
        dealerId,
        appointment,
        formData
      );

      queryClient.invalidateQueries({ queryKey: ["deliveryModule", orderId] });
      handleDialogClose();
    } catch (err) {
      transportError(err);
    }
  };

  const isCanceled =
    deliveryStatus &&
    deliveryStatus.status &&
    UpperCaseText(deliveryStatus.status) === "CANCELED";

  return isFetching ? (
    <Skeleton variant="rect" animation="wave" height={243} />
  ) : isError ? (
    <Typography
      variant="subtitle1"
      classes={{ root: classes.root, subtitle1: classes.subtitle1 }}
    >
      {t("common.errorMessage2", {
        element: "Delivery information",
      })}
    </Typography>
  ) : deliveryModule.deliveryAppointment === null ? (
    <NoDeliveryCard
      classes={classes}
      t={t}
      isCanceled={isCanceled}
      url={generateSchedulerUrl(orderId)}
      title={deliveryStatus && deliveryStatus.message}
    />
  ) : (
    deliveryStatus && (
      <>
        <EMPStatusCard
          cardTitle={deliveryStatus.message}
          status={deliveryStatus.statusColor}
          icon={deliveryStatus.icon}
          user={user}
          hasActionButton={deliveryStatus.status !== "completed"}
          actionButtonText={deliveryStatus.actionText}
          onActionClick={handleDialogOpen}
          actionButtonPermission={["modelEDeliveryAction"]}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={5} md={5}>
              <Typography
                variant="subtitle1"
                classes={{ root: classes.root, subtitle1: classes.subtitle1 }}
              >
                {t("ModelEOrders.deliveryAppointmentTime")}
              </Typography>
              <Typography
                variant="body1"
                classes={{ root: classes.root, body1: classes.body1 }}
              >
                {UiDateUtils.formatToDayDateTime(
                  deliveryModule.deliveryAppointment.date
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7} md={3}>
              <Typography
                variant="subtitle1"
                classes={{ root: classes.root, subtitle1: classes.subtitle1 }}
              >
                {t("ModelEOrders.deliverySpecialist")}
              </Typography>
              <Typography
                variant="body1"
                classes={{ root: classes.root, body1: classes.body1 }}
              >
                {selectedDeliverySpecialist
                  ? selectedDeliverySpecialist
                  : t("ModelEOrders.notAssigned")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5} md={4}>
              <Typography
                variant="subtitle1"
                classes={{ root: classes.root, subtitle1: classes.subtitle1 }}
              >
                {t("ModelEOrders.appointmentType")}
              </Typography>
              <Typography
                variant="body1"
                classes={{ root: classes.root, body1: classes.body1 }}
              >
                {deliveryModule.deliveryAppointment.dealerInteraction ? (
                  MODELE_DEALER_INTERACTIONS[
                    deliveryModule.deliveryAppointment.dealerInteraction
                  ]
                ) : (
                  <EMPEmptyState />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography
                variant="subtitle1"
                classes={{ root: classes.root, subtitle1: classes.subtitle1 }}
              >
                {t("ModelEOrders.topicsOfInterest")}
              </Typography>
              {deliveryModule.deliveryAppointment.orientationTopics.length ? (
                <Typography
                  variant="body1"
                  classes={{ root: classes.root, body1: classes.body1 }}
                >
                  {deliveryModule.deliveryAppointment.orientationTopics.join(
                    ", "
                  )}
                </Typography>
              ) : (
                <EMPEmptyState />
              )}
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography
                variant="subtitle1"
                classes={{ root: classes.root, subtitle1: classes.subtitle1 }}
              >
                {t("ModelEOrders.deliveryNotes")}
              </Typography>
              {deliveryModule.deliveryAppointment.additionalDetails ? (
                <Typography
                  variant="body1"
                  classes={{ root: classes.root, body1: classes.body1 }}
                >
                  {deliveryModule.deliveryAppointment.additionalDetails}
                </Typography>
              ) : (
                <EMPEmptyState />
              )}
            </Grid>
          </Grid>
        </EMPStatusCard>
        <DeliveryDialog
          user={user}
          open={dialogOpen}
          status={deliveryStatus.status}
          handleClose={handleDialogClose}
          customerInfo={customerInfo}
          vehicleInfo={vehicleInfo}
          appointment={deliveryModule.deliveryAppointment}
          specialist={selectedDeliverySpecialist}
          classes={classes}
          t={t}
          actionLoading={loading}
          onConfirm={(token, dealerId, appointment, formData) =>
            submitFormData(token, dealerId, appointment, formData)
          }
        />
      </>
    )
  );
};

export default withTranslation("emp")(Delivery);
