export const generateCharacterText = (count, props) => {
  if (count === 1)
    return ` ${props.t("DealerNotes.character")}  ${props.t(
      "DealerNotes.remaining"
    )}`;
  if (count > 1 || count === 0)
    return ` ${props.t("DealerNotes.characters")}  ${props.t(
      "DealerNotes.remaining"
    )}`;
  return "";
};

export const generateNoteText = (count, props) => {
  if (count === 1) return `1  ${props.t("DealerNotes.note")}`;
  if (count > 1) return `${count}  ${props.t("DealerNotes.notes")}`;
  return "";
};
