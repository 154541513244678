import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import HelpAndSupportClient from "../../clients/HelpAndSupportClient";
import { base64FileDownload } from "../../utils/FileDownloadUtil";
import EMPDialog from "../components/EMPDialog/EMPDialog";
import EMPButton from "../components/EMPButton/EMPButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Typography, withStyles, Grid, Box } from "@material-ui/core";
import * as AnalyticsService from "../services/analytics/AnalyticsService";

const StyledTypography = withStyles({
  root: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 14,
    color: "#000000",
  },
  h6: {
    fontFamily: "'FordAntennaBold', sans-serif",
    fontSize: 16,
    color: "#00095b",
    marginBottom: "4px",
  },
  body1: {
    marginBottom: 8,
  },
})(Typography);

class HelpAndSupportDialog extends Component {
  trackUserAction = (action) => {
    AnalyticsService.trackOnClickEvent(
      `fv:emp:help and support:popup`,
      `emp:help and support:popup:content:${action}`,
      `fv:emp:help and support:popup:content action`,
      this.props.user
    );
  };

  downloadFile = () => {
    HelpAndSupportClient.getFAQs(this.props.user)
      .then((response) => {
        base64FileDownload(response, "application/pdf", "USA-FAQs.pdf");
        this.trackUserAction("pdf download");
      })
      .catch();
  };

  render() {
    const mailToTechSupportEmail = `mailto:${encodeURIComponent(
      this.props.t("HelpAndSupport.techSupportEmail")
    )}`;

    const mailToSupportEmail = `mailto:${encodeURIComponent(
      this.props.t("HelpAndSupport.supportEmail")
    )}`;

    const supportWebsite = this.props.t("HelpAndSupport.supportWebsite");

    return (
      <EMPDialog
        open={this.props.open}
        dialogTitle={this.props.t("Footer.help")}
        hasCloseIcon
        handleClose={this.props.closeModal}
        onEnter={() => {
          AnalyticsService.trackPageLoadEvent(
            `fv:emp:help and support:popup`,
            this.props.user
          );
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box mb={2}>
              <StyledTypography variant="h6">
                {this.props.t("HelpAndSupport.resources")}
              </StyledTypography>
              <StyledTypography variant="body1">
                {this.props.t("HelpAndSupport.resourcesDescription")}
              </StyledTypography>
              <EMPButton
                buttonType="text"
                buttonText={this.props.t("HelpAndSupport.downloadFAQs")}
                buttonElement="button"
                onClick={this.downloadFile}
                leftIcon={<GetAppIcon />}
              ></EMPButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={3}>
              <StyledTypography variant="h6">
                {this.props.t("HelpAndSupport.supportContactHeader")}
              </StyledTypography>
              <div>
                <StyledTypography variant="body1">
                  {this.props.t("HelpAndSupport.emailTitle")}{" "}
                  <a
                    href={mailToSupportEmail}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#066FEF" }}
                    onClick={() => this.trackUserAction("click to email")}
                  >
                    {this.props.t("HelpAndSupport.supportEmail")}
                  </a>
                </StyledTypography>

                <StyledTypography variant="body1">
                  {this.props.t("HelpAndSupport.phoneTitle")}{" "}
                  {this.props.t("HelpAndSupport.supportPhone")}
                </StyledTypography>
                <StyledTypography variant="body1">
                  {this.props.t("HelpAndSupport.websiteTitle")}{" "}
                  <a
                    href={supportWebsite}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#066FEF" }}
                    onClick={() =>
                      this.trackUserAction("click to website resource center")
                    }
                  >
                    {this.props.t("HelpAndSupport.supportWebsiteLink")}
                  </a>
                </StyledTypography>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={3}>
              <StyledTypography variant="h6">
                {this.props.t("HelpAndSupport.technicalHelp")}
              </StyledTypography>
              <StyledTypography variant="body1">
                {this.props.t("HelpAndSupport.technicalHelpDescription")}
              </StyledTypography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={3}>
              <StyledTypography variant="h6">
                {this.props.t("HelpAndSupport.technicalSupportContactHeader")}
              </StyledTypography>
              <div>
                <StyledTypography variant="body1">
                  {this.props.t("HelpAndSupport.emailTitle")}{" "}
                  <a
                    href={mailToTechSupportEmail}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#066FEF" }}
                    onClick={() => this.trackUserAction("click to email")}
                  >
                    {this.props.t("HelpAndSupport.techSupportEmail")}
                  </a>
                </StyledTypography>
                <StyledTypography variant="body1">
                  {this.props.t("HelpAndSupport.phoneTitle")}{" "}
                  {this.props.t("HelpAndSupport.techSupportPhone")}
                </StyledTypography>
                <StyledTypography variant="body1">
                  {this.props.t("HelpAndSupport.hoursOfOperation")}{" "}
                  {this.props.t("HelpAndSupport.techSupportHours")}
                </StyledTypography>
              </div>
            </Box>
          </Grid>
        </Grid>
      </EMPDialog>
    );
  }
}

export default withTranslation("emp")(HelpAndSupportDialog);
