import React, { Component } from "react";
import NACustomerOrders from "../components/customerHandling/NACustomerOrders";
import { matchPath, Redirect, Route, Router, Switch } from "react-router-dom";
import withIdleTimer from "../common/WithIdleTimer";
import {
  CANADA_ENGLISH,
  ENGLISH,
  FORD_ENGLISH,
  LINCOLN_ENGLISH,
  SALESCODE_KEY,
} from "../common/Constants";
import NADealer from "./pure/NADealer";
import GlobalNavBar from "../shared/globalNavBar/GlobalNavBar";
import AccessoriesWrapper from "../components/accessories/AccessoriesWrapper";
import { publish } from "../common/PubSub";
import ErrorScreen from "../errors/ErrorScreen";
import DealerDashboard from "../components/dashboard/dealerDashBoard/DealerDashboard";
import ProtectedRoute from "../common/ProtectedRoute";
import Footer from "../shared/Footer/Footer";
import LocalizedInfoClient from "../clients/LocalizedInfoClient";
import { logMsg } from "../common/Logger";
import PageError from "../errors/PageError";
import "../styles/sharedStyles.scss";
import Help from "../components/help/Help";
import NADealerReporting from "../components/reporting/NADealerReporting";
import { withTranslation } from "react-i18next";
import DealerSearch from "../components/fieldView/DealerSearch";
import {
  IS_CANADA_MARKET,
  IS_FORD_BRAND,
  IS_USA_MARKET,
} from "../utils/EmpUtil";
import FieldReporting from "../components/fieldView/FieldReporting";
import Reporting from "../components/reporting/Reporting";
import BusinessSettings from "../components/businessSetting/BusinessSettings";
import DeliveryTools from "../components/deliveryTools/DeliveryTools";
import SearchResultsWrapper from "../components/customerHandling/search/SearchResultsWrapper";
import ReleaseNotes from "../components/releaseNotes/ReleaseNotes";
import ModelEOrderDetails from "../components/customerHandling/modelEDetails/ModelEOrderDetails";

class FieldViewApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderApp: false,
      user: new NADealer(
        this.props.token,
        this.props.userDetails,
        this.props.history
      ),
      shouldNavigationBeRestricted: false,
      brand: this.props.userDetails.brand,
    };
    this.salesCode = sessionStorage.getItem(SALESCODE_KEY);
    this.localizedInfo = {};
    this.dealerChanged = this.dealerChanged.bind(this);

    // Listening for route change
    this.props.history.listen(() => this.setNavigationRestriction());
  }

  componentDidMount() {
    // need this for error popups so that user isn't re-authorized as regular dealer
    this.props.history.push("/dealer-search");
    this.getLocalizedInfo();
  }

  getLocalizedInfo() {
    LocalizedInfoClient.getLocalizedInfo(this.state.user)
      .then((response) => {
        this.localizedInfo = Object.assign(
          {},
          { ...this.localizedInfo },
          { ...response }
        );
      })
      .catch((error) => logMsg("the error is: ", error))
      .finally(() => {
        this.lang = localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : ENGLISH;
        this.props.i18n.changeLanguage(this.lang);
        this.state.user.setLanguageCode(this.props.i18n.language);

        if (IS_USA_MARKET(this.state.user.market)) {
          if (!IS_FORD_BRAND(this.state.user.brand)) {
            this.props.i18n.changeLanguage(LINCOLN_ENGLISH);
          } else {
            this.props.i18n.changeLanguage(FORD_ENGLISH);
          }
        }

        if (IS_CANADA_MARKET(this.state.user.market)) {
          this.props.i18n.changeLanguage(CANADA_ENGLISH);
          this.state.user.setLanguageCode(this.props.i18n.language);
        }

        this.setNavigationRestriction();
        this.setState({ renderApp: true });
        this.props.hideOrShow(false);
      });
  }

  isMainViewingSDLR = (dealer) => {
    // Returns true if a main dealer is currently viewing an SDLR
    return this.state.user.isLoggedInUserMDLR && dealer.dealerType === "SDLR";
  };

  setNavigationRestriction = () => {
    const isAllowedPath = this.isCurrentPathAllowedToShowHeaderAndFooter(
      this.props.history.location.pathname
    );
    this.setState({ shouldNavigationBeRestricted: !isAllowedPath });
  };

  isCurrentPathAllowedToShowHeaderAndFooter = (currentPathName) => {
    const paths = [
      "/dashboard",
      "/pricing/accessories",
      "/",
      "/dealer-reporting",
      "/help",
      "/error",
      "/dealer-search",
      "/field-reporting",
      "/reporting",
      "/business-settings",
      "/delivery-tools",
      "/delivery-tools/download",
      "/delivery-tools/times",
      "/delivery-tools/tasks",
      "/ecommerce-releases",
    ];

    if (paths.includes(currentPathName)) {
      return true;
    } else {
      const allowedOrderDetails = matchPath(currentPathName, {
        path: `/dealer/:commonId/customer-reservations/order-details/:id`,
      });

      const allowedPurchaseDetails = matchPath(currentPathName, {
        path: `/dealer/:commonId/customer-reservations/purchase-details/:id`,
      });

      const allowedModelEOrderDetails = matchPath(currentPathName, {
        path: `/dealer/:commonId/customer-reservations/model-e-details/:id`,
      });

      const allowedCustomerHandlingTabs = matchPath(currentPathName, {
        path: `/customer-handling/:selectedTab`,
      });

      const allowedSearchResultsWrapper = matchPath(currentPathName, {
        path: `/search/:keyword`,
      });

      return (
        !!allowedOrderDetails ||
        !!allowedPurchaseDetails ||
        !!allowedModelEOrderDetails ||
        !!allowedCustomerHandlingTabs ||
        !!allowedSearchResultsWrapper
      );
    }
  };

  updateCurrentDealer(dealer, showAll = false) {
    this.setState(
      { user: this.state.user.updateCurrentDealer(dealer, showAll) },
      () => {
        this.state.user.setLanguageCode(this.props.i18n.language);
      }
    );
  }

  dealerChanged(dealer, showAll = false) {
    this.updateCurrentDealer(dealer, showAll);
  }

  changeBrand = (brandCode) => {
    this.state.user.updateBrand(brandCode);
    const dealer =
      this.state.user.dealerGroup &&
      this.state.user.dealerGroup.filter((dealer) =>
        dealer.salesCode.startsWith(brandCode)
      )[0];
    this.setState(
      {
        user: this.state.user.updateCurrentDealer(dealer, false),
        brand: brandCode,
      },
      () => {
        publish("brandChanged", brandCode);
      }
    );
  };

  changeDealer = () => {
    this.state.user.updateBrand(this.state.brand);
    this.setState(
      {
        user: new NADealer(
          this.props.token,
          this.props.userDetails,
          this.props.history
        ),
      },
      () => {
        publish("brandChanged", this.state.brand);
      }
    );
    if (IS_USA_MARKET(this.state.user.market)) {
      if (!IS_FORD_BRAND(this.state.user.brand)) {
        this.props.i18n.changeLanguage(LINCOLN_ENGLISH);
      } else {
        this.props.i18n.changeLanguage(FORD_ENGLISH);
      }
    }
    this.state.user.setLanguageCode(this.props.i18n.language);
  };

  render() {
    const helpSupportTitle = IS_FORD_BRAND(this.state.brand)
      ? this.props.t("Footer.help")
      : this.props.t("Footer.helpCap");

    return this.state.renderApp ? (
      <Router history={this.props.history}>
        <div className="appWrapper">
          {!this.state.dealerGroupError &&
            !this.state.shouldNavigationBeRestricted && (
              <GlobalNavBar
                {...this.props}
                urlPath={window.location.pathname}
                user={this.state.user}
                changeBrand={this.changeBrand}
                changeDealer={this.changeDealer}
                fromDealerSearch={
                  this.props.history.location.pathname === "/dealer-search" ||
                  this.props.history.location.pathname === "/field-reporting" ||
                  this.props.history.location.pathname === "/ecommerce-releases"
                }
              />
            )}
          <Switch>
            <ProtectedRoute
              exact
              path="/dashboard"
              component={withIdleTimer(DealerDashboard)}
              perform={["dashBoardPage"]}
              permissions={this.state.user.permissions.rolePermissions}
              customprops={{
                user: this.state.user,
                onDealerChanged: this.dealerChanged,
                lang: this.lang,
              }}
              parentProps={this.props}
              condition={true}
            />
            <ProtectedRoute
              exact
              path="/pricing/accessories"
              component={withIdleTimer(AccessoriesWrapper)}
              perform={["accessoriesPage"]}
              permissions={this.state.user.permissions.rolePermissions}
              customprops={{
                user: this.state.user,
                changeBrand: this.changeBrand,
                onDealerChanged: this.dealerChanged,
                localizedInfo: this.localizedInfo,
                lang: this.lang,
              }}
              parentProps={this.props}
              condition={true}
            />
            <ProtectedRoute
              exact
              path="/reporting"
              component={withIdleTimer(Reporting)}
              perform={["reportingPage"]}
              permissions={this.state.user.permissions.rolePermissions}
              customprops={{
                user: this.state.user,
                lang: this.lang,
              }}
              parentProps={this.props}
              condition={true}
            />
            <ProtectedRoute
              exact
              path="/business-settings"
              component={withIdleTimer(BusinessSettings)}
              perform={["businessSettingsPage"]}
              permissions={this.state.user.permissions.rolePermissions}
              condition={true}
              customprops={{
                ...this.props,
                isMainViewingSDLR: this.isMainViewingSDLR,
                user: this.state.user,
                lang: this.lang,
                dealerGroup: this.state.user.dealerGroup,
                localizedInfo: this.localizedInfo,
                permissions: this.state.user.permissions.rolePermissions,
              }}
            />
            <ProtectedRoute
              exact
              path="/customer-handling/:selectedTab"
              component={withIdleTimer(NACustomerOrders)}
              perform={["customerHandlingPage"]}
              permissions={this.state.user.permissions.rolePermissions}
              customprops={{
                user: this.state.user,
                localizedInfo: this.localizedInfo,
                onDealerChanged: this.dealerChanged,
                lang: this.lang,
              }}
              parentProps={this.props}
              condition={true}
            />

            <Redirect
              from="/dealer/:commonId/customer-reservations/order-details/:orderId"
              to="/customer-handling/model_e_orders"
            />
            <Redirect
              from="/dealer/:commonId/customer-reservations/purchase-details/:orderId"
              to="/customer-handling/model_e_orders"
            />

            <ProtectedRoute
              exact
              path="/dealer/:commonId/customer-reservations/model-e-details/:orderId"
              component={withIdleTimer(ModelEOrderDetails)}
              perform={["modelEDetailsPage"]}
              permissions={this.state.user.permissions.rolePermissions}
              customprops={{
                user: this.state.user,
                localizedInfo: this.localizedInfo,
                onDealerChanged: this.dealerChanged,
                lang: this.lang,
              }}
              parentProps={this.props}
              condition={true}
            />

            <ProtectedRoute
              exact
              path="/dealer-reporting"
              component={withIdleTimer(NADealerReporting)}
              perform={["dealerReporting:view"]}
              permissions={this.state.user.permissions.rolePermissions}
              customprops={{
                user: this.state.user,
                lang: this.lang,
              }}
              parentProps={this.props}
              condition={true}
            />
            <ProtectedRoute
              exact
              path="/dealer-search"
              component={withIdleTimer(DealerSearch)}
              perform={["dealerSearch"]}
              permissions={this.state.user.permissions.rolePermissions}
              customprops={{
                user: this.state.user,
                lang: this.lang,
                brand: this.state.brand,
              }}
              parentProps={this.props}
              condition={true}
            />
            <ProtectedRoute
              exact
              path="/field-reporting"
              component={withIdleTimer(FieldReporting)}
              perform={["fieldReporting"]}
              permissions={this.state.user.permissions.rolePermissions}
              customprops={{
                user: this.state.user,
                lang: this.lang,
                brand: this.state.brand,
              }}
              parentProps={this.props}
              condition={true}
            />
            <ProtectedRoute
              path="/delivery-tools"
              component={withIdleTimer(DeliveryTools)}
              perform={["modelEDeliveryToolsTab"]}
              permissions={this.state.user.permissions.rolePermissions}
              customprops={{ user: this.state.user, lang: this.lang }}
              parentProps={this.props}
              condition={true}
            />
            <Route
              path="/ecommerce-releases"
              component={withIdleTimer(() => (
                <ReleaseNotes {...this.props} user={this.state.user} />
              ))}
            />
            <ProtectedRoute
              exact
              path="/search/:keyword"
              component={withIdleTimer(SearchResultsWrapper)}
              perform={[]}
              permissions={this.state.user.permissions.rolePermissions}
              customprops={{
                user: this.state.user,
              }}
              parentProps={this.props}
              condition={true}
            />
            <Route
              exact
              path="/"
              render={() => (
                <Redirect
                  to={{
                    pathname: "/dealer-search",
                  }}
                />
              )}
            />
            <Route
              exact
              path="/help"
              component={withIdleTimer(() => (
                <Help {...this.props} market={this.state.user.market} />
              ))}
            />
            <Route
              exact
              path="/error"
              render={(props) => <ErrorScreen {...props} />}
            />
            <Route component={withIdleTimer(PageError)} />
          </Switch>

          {!this.state.shouldNavigationBeRestricted && (
            <span className="noPrint">
              <Footer
                user={this.state.user}
                footerTitle={this.props.t("Footer.baseTitle")}
                helpSupportTitle={helpSupportTitle}
                permissions={this.state.user.permissions}
              />
            </span>
          )}
        </div>
      </Router>
    ) : (
      ""
    );
  }
}

export default withTranslation("emp")(FieldViewApp);
