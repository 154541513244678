import React from "react";
import { FormControlLabel, Checkbox, makeStyles } from "@material-ui/core";
import MotionCheck from "../../Icons/MotionCheck";

const useLabelStyles = makeStyles({
  root: {
    margin: 0,
  },
  label: {
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
    marginLeft: "10px",
    color: "#333333",
    fontSize: "14px",
    lineHeight: "20px",
    "&.Mui-disabled": {
      color: "#808080",
    },
  },
});

const useStyles = makeStyles({
  root: {
    padding: 0,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 4,
    width: 24,
    height: 24,
    border: "1px solid #808080",
    boxSizing: "border-box",

    "$root.Mui-focusVisible &": {
      outlineStyle: "solid",
      outline: "1px auto #000000",
    },

    "input:disabled ~ &": {
      backgroundColor: "#E5E5E5",
      border: "1px solid #808080",
    },
  },
  checkedIcon: {
    border: "1px solid #066FEF",
    backgroundColor: "#066FEF",
    fontSize: "0px",
  },
});

const EMPCheckbox = ({
  disabled,
  required,
  name,
  label,
  ariaLabel,
  value,
  id,
  handleChange,
  checked,
}) => {
  const classes = useStyles();
  const labelClasses = useLabelStyles();

  function onChange(e) {
    handleChange(e.target.checked);
  }

  return (
    <FormControlLabel
      disabled={disabled}
      classes={{
        root: labelClasses.root,
        label: labelClasses.label,
      }}
      data-testid={id}
      control={
        <Checkbox
          className={classes.root}
          id={id}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          required={required}
          disableRipple
          name={name}
          checkedIcon={
            <span className={`${classes.icon} ${classes.checkedIcon}`}>
              <MotionCheck />
            </span>
          }
          icon={<span className={classes.icon} />}
          inputProps={{ "aria-label": ariaLabel }}
          color="default"
          value={value}
        />
      }
      label={label}
    />
  );
};

export default EMPCheckbox;
