import { Redirect, Route } from "react-router-dom";
import React from "react";
import HasPermissionTo from "./HasPermissionTo";

const ProtectedRoute = ({
  component: Component,
  perform,
  permissions,
  customprops,
  parentProps,
  condition,
  ...rest
}) => {
  return (
    <HasPermissionTo
      perform={perform}
      permissions={permissions}
      render={() => (
        <Route
          {...rest}
          render={(props) =>
            condition ? (
              <Component
                {...props.match.params}
                {...customprops}
                {...parentProps}
              />
            ) : (
              <Redirect to="/" />
            )
          }
        />
      )}
      noRender={() => <Redirect to="/" />}
    />
  );
};

export default ProtectedRoute;
