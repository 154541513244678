const reportPreviewStylesOverride = {
  cell: {
    fontFamily: "ProximaNova, sans-serif",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: "#324047",
  },
  cellGreyBackground: {
    fontFamily: "ProximaNovaSemibold, sans-serif",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: "#324047",
    backgroundColor: "#f5f5f5",
  },
  cellGreyBackgroundBold: {
    fontFamily: "ProximaNova, sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: "#324047",
    backgroundColor: "#f5f5f5",
  },
};

export default reportPreviewStylesOverride;
