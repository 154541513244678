import React from "react";
import fordStyles from "./NACustomerOrders.module.scss";
import lincolnStyles from "./NACustomerOrdersLincoln.module.scss";
import HasPermissionTo from "../../common/HasPermissionTo";
import { withTranslation } from "react-i18next";
import { IS_FORD_BRAND } from "../../utils/EmpUtil";
import ModelESearchOrders from "./tabs/ModelESearchOrders";
import EMPAnnouncementBanner from "../../shared/components/EMPAnnouncementBanner/EMPAnnouncementBanner";
import { Box } from "@material-ui/core";

const SearchResults = (props) => {
  const styles = IS_FORD_BRAND(props.user.brand) ? fordStyles : lincolnStyles;

  return (
    <HasPermissionTo
      perform={["modelETab"]}
      permissions={props.user.permissions.rolePermissions}
      render={() => {
        return (
          <>
            <Box px="50px" pb="50px">
              <EMPAnnouncementBanner
                text={props.t("AnnouncementBanner.announcement")}
                textAlign="center"
              ></EMPAnnouncementBanner>
            </Box>
            <div id="Modele" className={styles.resultsWrapper}>
              <ModelESearchOrders {...props} noContentText="noResults" />
            </div>
          </>
        );
      }}
      noRender={() => {
        return (
          <div className={`pagePaddingLeft ${styles.noResultsFound}`}>
            {props.t("CustomerOrders.noResultsFound")}
          </div>
        );
      }}
    />
  );
};

export default withTranslation("emp")(SearchResults);
