import React from "react";
import fordStyles from "./SortingIcon-Ford.module.scss";
import lincolnStyles from "./SortingIcon-Lincoln.module.scss";

const SortingIcon = (props) => {
  if (props.hide) return null;
  const prevSortKey = props.currentKey;
  const styles =
    props.isFordBrand || !props.hasOwnProperty("isFordBrand")
      ? fordStyles
      : lincolnStyles;
  const index =
    props.index === null || props.index === undefined ? "" : props.index;
  let displaySortIcon = (
    <span
      className={styles.sortDown}
      data-testid={"downIcon" + prevSortKey + index}
    />
  );
  if (props.sortKey === prevSortKey) {
    displaySortIcon =
      props.sortDirection === "ASC" ? (
        <span
          className={styles.sortUpFilled}
          data-testid={"upIconFilled" + prevSortKey + index}
        />
      ) : (
        <span
          className={styles.sortDownFilled}
          data-testid={"downIconFilled" + prevSortKey + index}
        />
      );
  }

  return displaySortIcon;
};

export default SortingIcon;
