import React, { forwardRef, useState } from "react";
import "../../../styles/DateTime.scss";
import DatePicker from "react-datepicker";
import { Button, makeStyles } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";

const useStyles = makeStyles({
  // input styles below
  // datepicker styles come from imported stylesheet
  wrapper: {
    width: "100%",
    display: "block",
  },
  input: {
    width: "100%",
    boxSizing: "border-box",
    border: "none",
    fontFamily: "'FordAntennaRegular', sans-serif",
    letterSpacing: 0,
    lineHeight: "24px",
    fontSize: 16,
    padding: 8,
  },
  buttonRoot: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 14,
    color: "#333333",
    letterSpacing: 0,
    border: "1px solid #4d4d4d",
    borderRadius: 8,
    padding: "8px 10px 8px 10px !important",
    justifyContent: "flex-start",
    textTransform: "none",

    fullWidth: {
      "&:hover": {
        borderColor: "#194BB5",
        backgroundColor: "none",
      },
    },
  },
});

const EMPDatePicker = (props) => {
  const classes = useStyles();
  const wrapperStyles = classes.wrapper;
  const inputStyles = classes.input;
  const { incomingDate, transportFormData, startAtToday } = props;

  const [date, setDate] = useState(incomingDate);

  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      classes={{ root: classes.buttonRoot, fullWidth: classes.fullWidth }}
      onClick={onClick}
      ref={ref}
      disableRipple
      fullWidth
      startIcon={<EventIcon />}
      data-testid="date-picker-button"
    >
      {value}
    </Button>
  ));

  return (
    <DatePicker
      {...props}
      wrapperClassName={wrapperStyles}
      className={inputStyles}
      selected={date}
      minDate={startAtToday && new Date()}
      showTimeSelect
      dateFormat="EEEE, MMMM do yyyy p"
      calendarClassName="emp-datepicker"
      onChange={(value) => {
        setDate(value);
        return transportFormData(
          value.toLocaleDateString("en-CA") +
            " " +
            value.toLocaleTimeString([], {
              hour12: false,
            })
        );
      }}
      customInput={<DateInput />}
    />
  );
};

export default EMPDatePicker;
