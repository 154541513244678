import axios from "axios";
import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";
import { ACCESSORIES_PRICING_URL } from "../utils/EmpUtil";
import { is401 } from "../errors/ErrorConstants";
import { getHeaders } from "../utils/AuthorizationUtil";
class CategorySettingsClient {
  static baseUrl = ACCESSORIES_PRICING_URL;

  static getDealerCategorySettings(user, brand) {
    const url = `${
      this.baseUrl
    }/accessories/category-settings?dealerCommonId=${user.getCommonIds()}&brand=${brand}`;

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data.result.categorySettingsGroup;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static saveDealerCategorySettings(
    user,
    data,
    modifiedLaborRate,
    modifiedMarkup
  ) {
    const url = `${
      this.baseUrl
    }/accessories/category-settings/save?dealerCommonId=${user.getCommonIds()}&modifiedLaborRate=${modifiedLaborRate}&modifiedMarkup=${modifiedMarkup}`;

    return axios
      .post(url, data, getHeaders(user.token))
      .then(() => {
        return Promise.resolve(true);
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}

export default CategorySettingsClient;
