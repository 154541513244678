import { withStyles } from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";
import React from "react";

const StyledClearIcon = (props) => {
  const StyledClear = withStyles({
    root: {
      height: props.size ? props.size : 20,
      width: props.size ? props.size : 20,
      color: props.color ? props.color : "white",
    },
  })(Clear);

  return <StyledClear />;
};

export default StyledClearIcon;
