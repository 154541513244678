import React, { Component } from "react";
import {
  Accordion,
  AccordionSummary,
  Box,
  withStyles,
} from "@material-ui/core";
import styles from "./DealerShipDetails.module.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Button from "@material-ui/core/Button";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Grid from "@material-ui/core/Grid";
import EditIcon from "../../../shared/Edit/EditIcon";
import { withTranslation } from "react-i18next";
import DealerEmployeeTable from "./DealerEmployeeTable";
import HasPermissionTo from "../../../common/HasPermissionTo";
import DealerDetailsClient from "../../../clients/DealerDetailsClient";
import crStyles from "../../customerHandling/crStyles";
import UpperCaseText from "../../../utils/UpperCaseText";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";

const StyledExpansionPanelSummary = withStyles({
  content: {
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {
    margin: 0,
  },
})(AccordionSummary);

const EMPTextValidator = withStyles({
  root: {
    "& .MuiFormHelperText-root": {
      fontFamily: "'FordAntennaRegular', sans-serif",
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "20px",
      color: "#BF152C",
      marginBottom: 0,
    },
    "& .MuiInputBase-root": {
      borderRadius: 8,
      outline: "1px solid #6B7786",
      padding: "8px 16px",
      backgroundColor: "#FFFFFF",
      fontFamily: "'FordAntennaRegular', sans-serif",
      fontSize: 14,
      fontWeight: "400",
      lineHeight: "20px",
      color: "#00142E",
    },
    "& .Mui-error.MuiInputBase-root": {
      borderRadius: 8,
      outline: "1px solid #BF152C",
    },
    "& .MuiInputBase-input": {
      padding: 0,
    },
    "& .Mui-focused.MuiInputBase-root": {
      outline: "2px solid #066FEF",
    },
    "& .Mui-error.Mui-focused.MuiInputBase-root": {
      outline: "2px solid #BF152C",
    },
  },
})(TextValidator);

class DealerContactInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: true,
      editMode: false,
      currentEmailText: this.props.dealers.emailAddress,
      currentPhoneText: this.props.dealers.phoneNumber,
      emailText: this.props.dealers.emailAddress,
      phoneText: this.props.dealers.phoneNumber,
    };
    this.dealer = this.props.dealers;
    this.showAddress =
      this.props.user.permissions.rolePermissions.hasOwnProperty(
        "businessAddress:view"
      );
  }

  flipIcon() {
    const current = this.state.expand;
    this.setState({ expand: !current });
  }

  cancelEdit = () => {
    this.setState({
      editMode: false,
      emailText: this.state.currentEmailText,
      phoneText: this.state.currentPhoneText,
    });
  };

  updateEmailAndPhone = (completeDealerId) => {
    const emailIdValue = document.getElementById(
      "emailInput_" + completeDealerId
    ).value;

    DealerDetailsClient.updateDealerEmailAddress(
      this.props.user,
      completeDealerId,
      emailIdValue
    )
      .then(() => {
        this.setState({
          emailText: emailIdValue,
          currentEmailText: emailIdValue,
        });
      })
      .then(() => {
        this.updatePhone(completeDealerId, emailIdValue);
      })
      .catch((error) => {
        // Throw an error
        throw new Error("Failed to update email and phone: " + error.message);
      });
  };

  updatePhone = (completeDealerId, emailIdValue) => {
    const phoneNumValue = document.getElementById(
      "phoneInput_" + completeDealerId
    ).value;
    DealerDetailsClient.updateDealerPhoneNumber(
      this.props.user,
      completeDealerId,
      phoneNumValue
    )
      .then((dealer) => {
        this.setState({
          phoneText: phoneNumValue,
          currentPhoneText: phoneNumValue,
          editMode: false,
        });
        this.props.updateDealerPhoneAndEmail(
          dealer,
          emailIdValue,
          phoneNumValue
        );
      })
      .catch(() => {});
  };

  editDealershipInfo = () => {
    this.setState({ editMode: true });
  };

  emailOnChange = (event) => {
    this.setState({ emailText: event.target.value });
  };

  phoneOnChange = (event) => {
    this.setState({ phoneText: event.target.value });
  };

  render() {
    return (
      <div className={styles.divPadding}>
        <Accordion
          classes={{ root: styles.expPanelNoBoxShadow }}
          style={{ marginBottom: "10px" }}
          defaultExpanded={true}
          onChange={() => this.flipIcon()}
        >
          <StyledExpansionPanelSummary style={crStyles.headerBarBg} id="exp">
            <div className={styles.headerBar}>
              <div>
                {this.dealer.name}
                <span>({this.dealer.regionalId})</span>
              </div>
              {this.state.expand ? (
                <ExpandLessIcon id="less" />
              ) : (
                <ExpandMoreIcon id="more" />
              )}
            </div>
            <br />
          </StyledExpansionPanelSummary>
          <div className={styles.businessDetailsContainer}>
            <div className={styles.sectionHeaderDealership}>
              {this.props.t("DealerShipDetails.dealershipHeader")}
            </div>
            <div className={styles.subHeader}>
              <HasPermissionTo
                perform={["businessAddress:view"]}
                permissions={this.props.user.permissions.rolePermissions}
                render={() => (
                  <span
                    id="businessDetails"
                    className={styles.businessDetailsInputGridModel}
                  >
                    {this.props.t("DealerShipDetails.businessDetails")}
                  </span>
                )}
              />
            </div>
            <ValidatorForm
              style={{ width: "100%" }}
              onSubmit={() =>
                this.updateEmailAndPhone(this.dealer.completeDealerId)
              }
            >
              <Grid
                container
                className={!this.showAddress ? styles.addressFlex : ""}
              >
                <HasPermissionTo
                  perform={["businessAddress:view"]}
                  permissions={this.props.user.permissions.rolePermissions}
                  render={() =>
                    this.props.user.market.toUpperCase() === "DEU" ? (
                      <Grid item md={3} className={styles.divAddress}>
                        <span>{this.dealer.address.street} </span>
                        <span>
                          {this.dealer.address.postalCode}&nbsp;
                          {this.dealer.address.city}
                        </span>
                        <span> {this.dealer.address.country}</span>
                      </Grid>
                    ) : (
                      <Grid item md={3} className={styles.divAddress}>
                        <span>{this.dealer.address.street} </span>
                        <span> {this.dealer.address.city}</span>
                        <span>
                          {" "}
                          {this.dealer.address.country},
                          {this.dealer.address.postalCode}
                        </span>
                      </Grid>
                    )
                  }
                />
                <>
                  {this.state.editMode ? (
                    <Grid item md={3}>
                      <Box pr={2}>
                        <EMPTextValidator
                          value={this.state.emailText}
                          onChange={this.emailOnChange}
                          name={"emailInput_" + this.dealer.completeDealerId}
                          id={"emailInput_" + this.dealer.completeDealerId}
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            this.props.t("Error.requiredField"),
                            this.props.t("Error.invalidInput"),
                          ]}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item md={3} className={styles.divEmail}>
                      {this.state.emailText ? (
                        <span className={styles.emailText}>
                          {this.state.emailText}
                        </span>
                      ) : (
                        this.props.t("DealerShipDetails.emailAddress")
                      )}
                    </Grid>
                  )}
                  {this.state.editMode ? (
                    <Grid item md={3}>
                      <Box pr={2}>
                        <EMPTextValidator
                          onChange={this.phoneOnChange}
                          value={this.state.phoneText}
                          name={"phoneInput_" + this.dealer.completeDealerId}
                          id={"phoneInput_" + this.dealer.completeDealerId}
                          validators={["required"]}
                          errorMessages={[this.props.t("Error.requiredField")]}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      md={3}
                      className={`${styles.divPhone} editPhoneOn`}
                    >
                      {this.state.phoneText ? (
                        <span className={styles.phoneText}>
                          {this.state.phoneText}
                        </span>
                      ) : (
                        this.props.t("DealerShipDetails.phoneNumber")
                      )}
                    </Grid>
                  )}
                  <Grid
                    item
                    md={3}
                    className={
                      this.showAddress
                        ? styles.contactEditDiv
                        : styles.contactEditDivNoAddress
                    }
                  >
                    {this.state.editMode ? (
                      <>
                        <EMPButton
                          testId={"confirmInfo_" + this.dealer.regionalId}
                          submitButton
                          disabled={
                            UpperCaseText(this.props.user.userType) ===
                            "FIELD_VIEW"
                          }
                          buttonType="text"
                          buttonText={this.props.t("BusinessSettings.save")}
                        ></EMPButton>

                        <EMPButton
                          testId={"cancelInfo_" + this.dealer.regionalId}
                          buttonText={this.props.t(
                            "BusinessSettings.cancelButton"
                          )}
                          buttonType="text"
                          onClick={this.cancelEdit}
                        ></EMPButton>
                      </>
                    ) : (
                      <HasPermissionTo
                        perform={["dealershipDetails:write"]}
                        permissions={
                          this.props.user.permissions.rolePermissions
                        }
                        condition={
                          !this.props.isMainViewingSDLR(this.props.dealers)
                        }
                        render={() => (
                          <Button
                            id={`Edit_${this.dealer.regionalId}`}
                            onClick={this.editDealershipInfo}
                          >
                            <EditIcon />
                          </Button>
                        )}
                      />
                    )}
                  </Grid>
                </>
              </Grid>
            </ValidatorForm>
          </div>
          <DealerEmployeeTable {...this.props} />
        </Accordion>
      </div>
    );
  }
}

export default withTranslation("emp")(DealerContactInfo);
