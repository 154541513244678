import { FormControlLabel, Grid, withStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { withTranslation } from "react-i18next";
import React from "react";
import DatePicker from "react-datepicker";
import * as DateConstants from "../../shared/constants/DateConstants";
import DatePickerIcon from "../../imgs/DatePickerIcon";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { IS_FORD_BRAND } from "../../utils/EmpUtil";
import fordStylesOverride from "../fieldView/FieldReportingFordStylesOverride";
import lincolnStylesOverride from "../fieldView/FieldReportingLincolnStylesOverride";
import fordStyles from "../fieldView/FieldReporting-Ford.module.scss";
import lincolnStyles from "../fieldView/FieldReporting-Lincoln.module.scss";
import EMPSelectField from "../../shared/components/EMPSelectField/EMPSelectField";

const StyledRadioGroup = withStyles({
  root: {
    "flex-direction": "row",
  },
})(RadioGroup);

const StyledRadio = withStyles({
  root: {
    color: "#102b4e",
    "&$checked": {
      color: "#102b4e",
    },
  },
  checked: {},
})(Radio);

const ReportFilter = (props) => {
  const isFordBrand = IS_FORD_BRAND(props.brand);
  const stylesOverride = isFordBrand
    ? fordStylesOverride
    : lincolnStylesOverride;
  const styles = isFordBrand ? fordStyles : lincolnStyles;
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12} style={stylesOverride.gridItem}>
          <span className={styles.dateRange}>
            {props.t("fieldReporting.dateRange")}
          </span>
          <span className={styles.dateRangeSub}>
            {props.t("fieldReporting.dateRangeSub")}
          </span>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={9} style={stylesOverride.gridItem}>
          <div className={styles.dateRangeWrapper}>
            <StyledRadioGroup
              value={props.dateRangeMethod}
              onChange={props.toggleRadio}
            >
              <div className={styles.dropDownDateWrapper}>
                <FormControlLabel
                  value="auto"
                  control={<StyledRadio />}
                  data-testid="auto"
                  checked={props.dateRangeMethod === "auto"}
                  label={
                    <div className={styles.dropDownDateWrapper}>
                      <div className={styles.dropdownAutoDate}>
                        <FilterDropdown
                          id="dateRange"
                          onDropdownChange={props.handleDateRangeChange}
                          items={props.dateRanges}
                          selectedValue={props.dateRange}
                          disabled={false}
                          t={props.t}
                          styles={styles}
                        />
                      </div>
                      <div className={styles.orPadding}>
                        {props.t("fieldReporting.or")}
                      </div>
                    </div>
                  }
                />
              </div>
              <FormControlLabel
                value="manual"
                control={<StyledRadio />}
                data-testid="manual"
                checked={props.dateRangeMethod === "manual"}
                label={
                  <div className={styles.datePickerWrapper}>
                    <div className={styles.toFromDatePickerWrapper}>
                      <div className={styles.fromLabel}>
                        {props.t("fieldReporting.from")}
                      </div>
                      <div className={styles.datePickerInputIcon}>
                        <DatePicker
                          selected={props.fromDate}
                          onChange={props.handleFromDateChange}
                          id="fromDate"
                          maxDate={new Date()}
                          dateFormat={DateConstants.MMddyyyy}
                        />
                        <DatePickerIcon />
                      </div>
                    </div>
                    <div className={styles.dateDivider} />
                    <div className={styles.toFromDatePickerWrapper}>
                      <div className={styles.toLabel}>
                        {props.t("fieldReporting.to")}
                      </div>
                      <div className={styles.datePickerInputIcon}>
                        <DatePicker
                          selected={props.toDate}
                          onChange={props.handleToDateChange}
                          id="toDate"
                          maxDate={new Date()}
                          minDate={props.fromDate}
                          dateFormat={DateConstants.MMddyyyy}
                        />
                        <DatePickerIcon />
                      </div>
                    </div>
                  </div>
                }
              />
            </StyledRadioGroup>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const FilterDropdown = (props) => {
  const menuItems = [
    {
      text: props.t("GeneralEMP.Select"),
      value: "Select",
    },
    {
      text: props.t("GeneralEMP.All"),
      value: "All",
    },
  ];
  if (props.items) {
    props.items.forEach((item) => {
      menuItems.push({
        text: item,
        value: item,
      });
    });
  }
  return (
    <FormControl
      id={props.id}
      className={props.styles.modelFormControl}
      disabled={props.disabled}
    >
      <EMPSelectField
        testId={props.id}
        value={props.selectedValue}
        onChange={(e) => props.onDropdownChange(e)}
        menuItems={menuItems}
      />
    </FormControl>
  );
};

export default withTranslation("emp")(ReportFilter);
