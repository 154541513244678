import React from "react";
import styles from "./DeliveryTools.module.scss";
import EMPTabs from "../../shared/components/EMPTabs/EMPTabs";
import { Route, useRouteMatch, Switch, Router } from "react-router-dom";
import DownloadTool from "./pages/downloadTool/DownloadTool";
import PreDeliveryTasks from "./pages/dealerTasks/PreDeliveryTasks.js";
import DeliveryTimes from "./pages/deliveryTimes/DeliveryTimes.js";
import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";

const usePageHeaderStyles = makeStyles({
  root: {
    fontFamily: "'FordAntennaRegular', sans-serif",
  },
  h1: {
    fontSize: "24px",
    fontFamily: "'FordAntennaBold', sans-serif",
    color: "#142742",
  },
  subtitle1: {
    fontSize: "16px",
    color: "#000000",
  },
});

// forcing footer to not be position:absolute for this page only
const addStyle = (() => {
  const style = document.createElement("style");
  document.body.append(style);
  return (styleString) => (style.textContent = styleString);
})();

addStyle(`
footer {
  position: relative !important;
}
`);

const PageHeader = (props) => {
  const { title, subTitle } = props;
  const classes = usePageHeaderStyles();
  return (
    <Box mt={2}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h1" classes={{ ...classes }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" classes={{ ...classes }}>
            {subTitle}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const DeliveryTools = (props) => {
  const { user, history, t } = props;
  const { path } = useRouteMatch();

  const tabItems = [
    {
      id: 1,
      route: `${path}`,
      title: t("DeliveryTools.tabTitle1"),
      value: "download",
      component: <DownloadTool user={user} />,
    },
    {
      id: 2,
      route: `${path}/times`,
      value: "times",
      title: t("DeliveryTools.tabTitle2"),
      component: <DeliveryTimes user={user} />,
    },
    {
      id: 3,
      route: `${path}/tasks`,
      value: "tasks",
      title: t("DeliveryTools.tabTitle3"),
      component: <PreDeliveryTasks user={user} />,
    },
  ];

  return (
    <section className={styles.pageContainer}>
      <Router history={history}>
        <div className={styles.tabsContainer}>
          <EMPTabs
            tabItems={tabItems}
            navigation
            history={history}
            user={user}
          />
        </div>
        <Switch>
          {tabItems.map((item) => (
            <Route exact path={item.route} key={item.id}>
              {item.component ? (
                <div className={styles.paddedSection}>{item.component}</div>
              ) : (
                <div className={styles.paddedSection}>
                  <PageHeader title={item.title} />
                </div>
              )}
            </Route>
          ))}
        </Switch>
      </Router>
    </section>
  );
};

export default withTranslation("emp")(DeliveryTools);
