import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from "@material-ui/core";
import MotionPlus from "../../Icons/MotionPlus";
import MotionMinus from "../../Icons/MotionMinus";

const useStyles = makeStyles({
  root: {
    borderTop: "1px solid #6B7786",
  },
  summaryRoot: {
    padding: "24px 16px",
    "&.Mui-expanded": {
      minHeight: "unset",
    },
  },
  content: {
    display: "block",
    margin: 0,
    color: "#1F3047",
    transition: "color 0.1s ease-in-out",
    "&.Mui-expanded": {
      margin: 0,
      color: "#066FEF",
    },
  },
  expandIcon: {
    "&.Mui-expanded": {
      transform: "unset",
    },
  },
  detailsRoot: {
    display: "block",
    padding: 16,
  },
  subtitle1: {
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
    fontSize: 24,
    lineHeight: "32px",
    marginBottom: 4,
    fontWeight: "400",
  },
  subtitle2: {
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
    fontSize: 20,
    lineHeight: "28px",
    fontWeight: "400",
  },
});

const EMPAccordion = ({
  children,
  heading,
  subHeading,
  onChange,
  expanded,
}) => {
  const classes = useStyles();

  return (
    <Accordion
      square
      elevation={0}
      classes={{ root: classes.root }}
      onChange={onChange}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={expanded ? <MotionMinus /> : <MotionPlus />}
        IconButtonProps={{ disableRipple: true }}
        classes={{
          root: classes.summaryRoot,
          content: classes.content,
          expandIcon: classes.expandIcon,
        }}
      >
        <Typography
          variant="subtitle1"
          classes={{ subtitle1: classes.subtitle1 }}
          color="inherit"
        >
          {heading}
        </Typography>
        <Typography
          variant="subtitle2"
          classes={{ subtitle2: classes.subtitle2 }}
        >
          {subHeading}
        </Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.detailsRoot }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default EMPAccordion;
