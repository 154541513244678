import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Slide,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import EMPSelectField from "../../../../shared/components/EMPSelectField/EMPSelectField";
import EMPButton from "../../../../shared/components/EMPButton/EMPButton";
import DeliveryTimesClient from "../../../../clients/DeliveryTimesClient";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { withTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import "./animation.css";
import * as AnalyticsService from "../../../../shared/services/analytics/AnalyticsService";

const usePageHeaderStyles = makeStyles({
  root: {
    fontFamily: "'FordAntennaRegular', sans-serif",
  },
  h1: {
    fontSize: "24px",
    fontFamily: "'FordAntennaBold', sans-serif",
    color: "#142742",
  },
  subtitle1: {
    fontSize: "16px",
    color: "#000000",
  },
  subtitle2: {
    fontSize: "16px",
    color: "#000000",
    fontFamily: "'FordAntennaBold', sans-serif",
  },
  colorError: {
    fontSize: "14px",
    backgroundColor: "rgba(246, 229, 231, 1.00)",
    color: "#000000",
    padding: "8px 16px",
    lineHeight: "20px",
  },
});

const useSnackbarStyles = makeStyles({
  containerRoot: {
    width: "100%",
    left: 0,
    bottom: 0,
  },
  root: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    backgroundColor: "inherit",
    color: "inherit",
    width: "100%",
    fontSize: 16,
    padding: 16,
    borderRadius: 0,
  },
  action: {
    marginRight: 0,
  },
});

const useTableCellStyles = makeStyles({
  root: {
    fontFamily: "'FordAntennaBold', sans-serif",
    borderBottom: "none",
    width: "96px",
    padding: 8,
    letterSpacing: "0em",
  },
});

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const hoursOfDay = Array.from({ length: 24 }, (_, i) => {
  if (i === 0) return `12 AM`;
  if (i < 12) return `${i} AM`;
  if (i === 12) return `12 PM`;
  return `${i - 12} PM`;
});

const Loader = ({ classes }) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <CircularProgress size={24} />
      </Grid>
      <Grid item>
        <Typography
          variant="subtitle1"
          classes={{
            root: classes.root,
            subtitle1: classes.subtitle1,
          }}
          align="center"
        >
          Loading delivery times...
        </Typography>
      </Grid>
    </Grid>
  );
};

const DeliveryTimes = ({ user, t }) => {
  const classes = usePageHeaderStyles();
  const tableCellClasses = useTableCellStyles();
  const snackBarClasses = useSnackbarStyles();
  const matches = useMediaQuery("(min-width: 1024px)");
  const snackBarRef = useRef(null);

  const queryClient = useQueryClient();

  const [openSave, setOpenSave] = useState(false);
  const [updatedTimeSlots, setUpdatedTimeSlots] = useState({});

  useEffect(() => {
    AnalyticsService.trackPageLoadEvent(
      "fv:emp:delivery tools:delivery times",
      user
    );
  }, [user]);

  const gatherAndSaveSlots = () => {
    const days = Object.keys(updatedTimeSlots);
    const formattedList = [];
    days.forEach((day) => {
      const newList = {};
      newList["day"] = day;
      const hours = Object.keys(updatedTimeSlots[day]);
      newList["slotsList"] = [];
      hours.forEach((hour) => {
        if (updatedTimeSlots[day][hour] > 0) {
          const slot = {};
          slot["time"] = hour;
          slot["slotsAvailable"] = updatedTimeSlots[day][hour];
          newList["slotsList"].push(slot);
        }
      });
      if (newList["slotsList"].length > 0) {
        formattedList.push(newList);
      }
    });
    saveTimeSlots.mutate(formattedList);
  };

  const saveTimeSlots = useMutation({
    mutationFn: (formattedList) => {
      return DeliveryTimesClient.saveTimeSlots(
        user.token,
        user.dealerId,
        formattedList
      );
    },
    onError: () => {
      // eslint-disable-next-line no-console
      console.log("error saving time slots");
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["deliveryTimes"], data);

      AnalyticsService.trackOnClickEvent(
        `fv:emp:delivery tools:delivery times`,
        `emp:delivery tools:delivery times:content:save`,
        `fv:emp:delivery tools:delivery times:content action`,
        user
      );

      setTimeout(() => {
        setOpenSave(false);
        setTimeout(() => {
          saveTimeSlots.reset();
        }, 1000);
      }, 3000);
    },
  });

  const updateTimeSlots = (day, hour, slotsAvailable) => {
    setUpdatedTimeSlots((prevState) => {
      const newState = { ...prevState };
      newState[day] = { ...newState[day], [hour]: Number(slotsAvailable) };
      return newState;
    });
    setOpenSave(true);
  };

  const formatTimeSlots = (response) => {
    const timeSlotsList = {};
    response.forEach((item) => {
      timeSlotsList[item.day] = {};
      item.slotsList.forEach((slot) => {
        timeSlotsList[item.day][slot.time] = slot.slotsAvailable;
      });
    });
    return timeSlotsList;
  };

  const checkToDisableSaveButton = () => {
    return (
      saveTimeSlots.isLoading ||
      saveTimeSlots.isError ||
      saveTimeSlots.isSuccess ||
      !user.permissions?.rolePermissions?.hasOwnProperty("deliveryTimesSubmit")
    );
  };

  const {
    isFetching,
    isError,
    data: timeSlots,
    error,
  } = useQuery({
    queryKey: ["deliveryTimes", user.dealerId],
    queryFn: async () => {
      const { data } = await DeliveryTimesClient.getTimeSlots(
        user.token,
        user.dealerId
      );
      return data;
    },
    select: formatTimeSlots,
    onSuccess: (data) => {
      setUpdatedTimeSlots(data);
    },
  });

  return (
    <section>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mb={5}>
            <Typography
              variant="subtitle1"
              classes={{ root: classes.root, subtitle1: classes.subtitle1 }}
            >
              Please set how many deliveries your dealership can complete per
              hour for each hour of business.
            </Typography>
            <Typography
              variant="subtitle2"
              classes={{ root: classes.root, subtitle2: classes.subtitle2 }}
            >
              If no selections are made, your dealership will follow the default
              delivery times schedule: 1 delivery appointment slot is available
              every 2 hours starting 1 hour after the dealership opens and
              ending 1 hour before the dealership closes.
            </Typography>
          </Box>
          <Box mb={5}>
            {isFetching ? (
              <Loader classes={classes} t={t} />
            ) : isError ? (
              <Typography
                variant="subtitle1"
                color="error"
                classes={{
                  root: classes.root,
                  subtitle1: classes.subtitle1,
                  colorError: classes.colorError,
                }}
              >
                {`${t("common.error")}: ${error.message}`}
                <br />
                Delivery times failed to load. Refresh the page to try again.
              </Typography>
            ) : (
              <TableContainer
                component={Paper}
                elevation={0}
                style={{ width: matches ? "max-content" : "100%" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        classes={{ root: tableCellClasses.root }}
                        style={{ width: "50px", whiteSpace: "nowrap" }}
                      ></TableCell>
                      {daysOfWeek.map((day) => (
                        <TableCell
                          classes={{ root: tableCellClasses.root }}
                          key={day}
                        >
                          {day}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hoursOfDay.map((hour) => (
                      <TableRow key={hour} hover>
                        <TableCell
                          classes={{ root: tableCellClasses.root }}
                          style={{
                            width: "50px",
                            whiteSpace: "nowrap",
                            textAlign: "right",
                          }}
                          component="th"
                          scope="row"
                        >
                          {hour}
                        </TableCell>
                        {daysOfWeek.map((day) => (
                          <TableCell
                            classes={{ root: tableCellClasses.root }}
                            key={day}
                          >
                            <EMPSelectField
                              menuItems={[
                                { id: 0, value: 0, text: "0" },
                                { id: 1, value: 1, text: "1" },
                                { id: 2, value: 2, text: "2" },
                                { id: 3, value: 3, text: "3" },
                                { id: 4, value: 4, text: "4" },
                                { id: 5, value: 5, text: "5" },
                              ]}
                              value={
                                updatedTimeSlots[day]?.[hour] ??
                                timeSlots[day]?.[hour] ??
                                0
                              }
                              onChange={(e) =>
                                updateTimeSlots(day, hour, e.target.value)
                              }
                              testId={`${day}_${hour.replace(" ", "")}`}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Grid>
      </Grid>
      <CSSTransition
        nodeRef={snackBarRef}
        in={saveTimeSlots.isSuccess || saveTimeSlots.isError}
        timeout={300}
        classNames={`timeslot-confirmation-${saveTimeSlots.status}`}
      >
        <Snackbar
          ref={snackBarRef}
          classes={{ root: snackBarClasses.containerRoot }}
          ContentProps={{
            classes: {
              root: snackBarClasses.root,
              action: snackBarClasses.action,
            },
          }}
          open={openSave}
          transitionDuration={300}
          message={
            saveTimeSlots.isError
              ? "Something went wrong.  Please reset the form and try again."
              : saveTimeSlots.isSuccess
              ? "Updates saved successfully."
              : "Do you want to save your changes to delivery times?"
          }
          action={
            <Grid container justifyContent="center">
              <Grid item>
                <EMPButton
                  buttonType="contained"
                  buttonText="Save changes"
                  onClick={() => gatherAndSaveSlots()}
                  testId="save-timeslot"
                  disabled={checkToDisableSaveButton()}
                />
              </Grid>
              <Grid item>
                <Box ml={1}>
                  <EMPButton
                    buttonType="outlined"
                    buttonText="Reset form"
                    onClick={() => {
                      setOpenSave(false);
                      saveTimeSlots.reset();
                      setUpdatedTimeSlots({});
                      queryClient.invalidateQueries(["deliveryTimes"]);
                    }}
                    testId="reset-timeslot"
                    disabled={
                      saveTimeSlots.isLoading || saveTimeSlots.isSuccess
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          }
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          TransitionComponent={Slide}
        />
      </CSSTransition>
    </section>
  );
};

export default withTranslation("emp")(DeliveryTimes);
