const VehicleHoldStyles = {
  headerBarBg: {
    backgroundColor: "#e5e5e5",
    maxHeight: "40px",
    minHeight: "40px",
    padding: 0,
    margin: 0,
  },
};

export default VehicleHoldStyles;
