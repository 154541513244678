import PNG from "../imgs/Lincoln_logo.png";
import React from "react";

const LincolnLogo = (props) => {
  return (
    <img
      data-testid="lincoln-logo"
      className={props.className}
      alt="LINCOLN"
      src={PNG}
      width={props.width}
    />
  );
};

export default LincolnLogo;
