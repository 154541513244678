import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { AccordionSummary, withStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { formatPriceValue } from "../../../../utils/FormatPrice";
import { StyledTableRow } from "../../../../shared/Tables/TableRow";
import { IS_FORD_BRAND } from "../../../../utils/EmpUtil";
import { StyledExpansionPanel } from "../../../../shared/ExpansionPanels/ExpansionPanel";

const StyledExpansionPanelSummary = withStyles({
  content: {
    paddingLeft: 50,
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {
    margin: 0,
  },
})(AccordionSummary);

class ProtectionPlanBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandBody: false,
    };
    this.distanceUnit =
      (this.props.localizedInfo &&
        this.props.localizedInfo.defaultDistanceUnit) ||
      this.props.t("ProtectionPlans.miles");
  }

  expandBody() {
    this.setState((prevState) => ({
      expandBody: !prevState.expandBody,
    }));
  }

  render() {
    const { styles, sharedStyles, crStyles } = this.props;

    return (
      this.props.protectionPlan && (
        <StyledExpansionPanel
          defaultExpanded={true}
          onChange={() => this.expandBody()}
        >
          <StyledExpansionPanelSummary style={crStyles.headerBarNeutral}>
            <div className={sharedStyles.headerBar}>
              <div
                className={sharedStyles.header}
                data-testid="customerOptOfflineTitle"
              >
                {this.props.protectionPlan.planName}
              </div>
              <div className={styles.acceptRejectDiv}>
                {formatPriceValue(
                  this.props.protectionPlan.dealerSellingPrice,
                  this.props.t("ProtectionPlans.notAvailable")
                )}
                {this.state.expandBody ? (
                  <ExpandMoreIcon id="more" />
                ) : (
                  <ExpandLessIcon id="less" />
                )}
              </div>
            </div>
          </StyledExpansionPanelSummary>

          <StyledTableRow
            isLincolnBrand={
              this.props.user && !IS_FORD_BRAND(this.props.user.brand)
            }
            text={this.props.t("ProtectionPlans.selectedTerm")}
            data={
              this.props.protectionPlan.term
                ? this.props.protectionPlan.term.concat(
                    this.props.t("ProtectionPlans.months")
                  )
                : this.props.t("ProtectionPlans.notAvailable")
            }
            index={0}
            indent={true}
            additionalTextPadding="0 0 0 50px"
          />
          <StyledTableRow
            isLincolnBrand={
              this.props.user && !IS_FORD_BRAND(this.props.user.brand)
            }
            text={this.props.t("ProtectionPlans.annualMileage")}
            data={
              this.props.protectionPlan.annualMileage
                ? this.props.protectionPlan.annualMileage.concat(
                    ` ${this.distanceUnit}`
                  )
                : this.props.t("ProtectionPlans.notAvailable")
            }
            index={1}
            indent={true}
            additionalTextPadding="0 0 0 50px"
          />
          <StyledTableRow
            isLincolnBrand={
              this.props.user && !IS_FORD_BRAND(this.props.user.brand)
            }
            text={this.props.t("ProtectionPlans.deductible")}
            data={
              this.props.protectionPlan.deductible !== null
                ? formatPriceValue(
                    this.props.protectionPlan.deductible,
                    this.props.t("ProtectionPlans.notAvailable")
                  )
                : this.props.protectionPlan.deductibleStr !== null
                ? this.props.protectionPlan.deductibleStr
                : this.props.t("ProtectionPlans.notAvailable")
            }
            index={2}
            indent={true}
            additionalTextPadding="0 0 0 50px"
          />
        </StyledExpansionPanel>
      )
    );
  }
}

export default withTranslation("emp")(ProtectionPlanBody);
