import React from "react";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import { Box } from "@material-ui/core";

const PublishButton = (props) => {
  return props.overridesApplied ? (
    <div className={props.styles.overridesApplied}>
      <span>{"✓ "}</span>
      {props.t("CategorySettings.overridesApplied")}
    </div>
  ) : (
    <Box textAlign="center" mt={2}>
      <EMPButton
        buttonType="contained"
        disabled={!props.enablePublishBtn}
        testId="publishAccessoriesSettingsFees"
        submitButton
        buttonText={props.t("NamePlate.publishOverrides")}
      ></EMPButton>
    </Box>
  );
};

export default PublishButton;
