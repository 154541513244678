const stylesOverride = {
  tableHeaderFord: {
    backgroundColor: "#ffffff",
  },
  tableHeaderLincoln: {
    backgroundColor: "#d2d2d2",
  },
  cellHeader: {
    verticalAlign: "bottom",
    fontFamily: "'Ford Antenna Bold', 'FordAntennaBold', sans-serif",
    paddingBottom: 15,
    paddingLeft: 16,
    fontSize: 14,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "left",
  },
  cellBody: {
    width: "15%",
    fontFamily: "FordAntennaRegular, sans-serif",
    textAlign: "left",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "1px",
    padding: "0px 0px 0px 16px",
    fontSize: "12px",
    lineHeight: "normal",
  },
  dealer: {
    fontFamily: "FordAntennaCondBold, sans-serif",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "center",
    letterSpacing: "1.88px",
    textDecoration: "underline",
    width: "15%",
  },
};

export default stylesOverride;
