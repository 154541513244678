import i18next from "i18next";
import XHR from "i18next-xhr-backend";
import emp_en from "../shared/translations/en";
import emp_fr from "../shared/translations/fr";
import emp_fr_ca from "../shared/translations/fr_CA";
import emp_en_na_lincoln from "../shared/translations/en_lincoln";
import emp_en_na_ford from "../shared/translations/en_ford";
import TranslationsClient from "../components/translations/TranslationsClient.js";
import { ADFS_TOKEN } from "./Constants";

const i18n = () => {
  const language = i18next.language || "fd_USA";

  const languageUrl = TranslationsClient.getDefaultLanguage();

  const backendOptions = {
    type: "backend",
    crossDomain: false,
    allowMultiLoading: false,
    loadPath: languageUrl + "{{lng}}",
    customHeaders: {
      authorization: `Bearer ${sessionStorage.getItem(ADFS_TOKEN)}`,
    },
  };

  const options = {
    initImmediate: false,
    debug: true,
    lng: language,
    fallbackLng: language,
    ns: ["emp"],
    defaultNS: "t",
    react: {
      wait: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
      defaultTransParent: "div",
    },
  };

  const resources = {
    en: {
      emp: emp_en,
    },
    fr: {
      emp: emp_fr,
    },
    fr_CA: {
      emp: emp_fr_ca,
    },
    lc: {
      emp: emp_en_na_lincoln,
    },
    fd: {
      emp: emp_en_na_ford,
    },
  };

  options["backend"] = backendOptions;

  i18next.use(XHR).init(options);

  // Loading translations from local shared folder if translation service fails
  i18next.on("failedLoading", function (lng, ns) {
    const resourceLng =
      lng === ("fr_CA" || "fr_BEL" || "nl_BEL") ? lng : lng.substr(0, 2);
    i18next.addResourceBundle(lng, ns, resources[resourceLng][ns], false, true);
  });

  return i18next;
};

export default i18n;
