import { ReactComponent as CheckComponent } from "../../imgs/check.svg";
import { ReactComponent as LincolnCheckComponent } from "../../imgs/lincoln-check.svg";
import React from "react";

const StyledCheckIcon = ({
  size = "33px",
  color = "#3B822E",
  isFordBrand = true,
}) => {
  const Check = isFordBrand ? CheckComponent : LincolnCheckComponent;
  return <Check style={{ color, height: size, width: size }} />;
};
export default StyledCheckIcon;
