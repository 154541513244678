import React from "react";
import { makeStyles, NativeSelect } from "@material-ui/core";
import MotionChevronDown from "../../Icons/MotionChevronDown.js";

// available props
// testId: string
// name: string
// menuItems: array of objects with shape { id: string, value: string, text: string, disabled: boolean }
// value: string
// disabled: boolean
// onChange: function

const useStyles = makeStyles(
  {
    select: {
      fontFamily: "'FordAntennaRegular', sans-serif",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "20px",
      color: "#00142E",
    },
    outlined: {
      borderRadius: 8,
      border: "solid 1px #6B7786",
      backgroundColor: "white",
      boxSizing: "border-box",
      padding: "8px 16px",
      height: "auto",
      "&:focus": {
        borderRadius: 8,
        backgroundColor: "white",
        outline: "2px solid #066FEF",
      },
    },
    disabled: {
      backgroundColor: "#E6E8EA",
    },
  },
  {
    name: "EMPSelect",
  }
);

const EMPSelectField = React.forwardRef((props, ref) => {
  const { testId, name, menuItems, value, disabled, onChange } = props;
  const classes = useStyles();
  return (
    <NativeSelect
      IconComponent={MotionChevronDown}
      data-testid={testId}
      name={name}
      value={value}
      disabled={disabled}
      onChange={onChange}
      ref={ref}
      variant="outlined"
      classes={{
        select: classes.select,
        outlined: classes.outlined,
        disabled: classes.disabled,
      }}
      disableUnderline
      style={{ width: "100%" }}
    >
      {menuItems.map((item, index) => (
        <option
          data-testid={item.id}
          value={item.value}
          key={index}
          disabled={item.disabled}
        >
          {item.text}
        </option>
      ))}
    </NativeSelect>
  );
});
export default EMPSelectField;
