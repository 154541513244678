import React, { Component } from "react";
import PreviewTermsConditions from "../../../shared/previewTermsConditions/PreviewTermsConditions";
import styles from "./AcceptTermsAndConditions.module.scss";
import { Grid, Box } from "@material-ui/core";
import { TERMS_AND_CONDITIONS } from "../../../common/Constants";
import PendingActionPrompt from "../../../shared/pendingActionPrompt/PendingActionPrompt";
import ConfirmDialog from "../../../shared/confirmDialog/ConfirmDialog";
import { withTranslation } from "react-i18next";
import HasPermissionTo from "../../../common/HasPermissionTo";
import TermsAndConditionsClient from "../../../clients/TermsAndConditionsClient";
import PendingActionClient from "../../../clients/PendingActionClient";
import { IS_CANADA_MARKET } from "../../../utils/EmpUtil";
import UpperCaseText from "../../../utils/UpperCaseText";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import MotionChevronLeft from "../../../shared/Icons/MotionChevronLeft";

class AcceptTermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDeclinePAPrompt: false,
      openConfirmPAPrompt: false,
      openDeclineDialog: false,
      openConfirmDialog: false,
      dealersActioned: [],
      dealersNotActioned: [],
    };
    this.customText = {
      actioned: this.props.t("PendingActionPrompt.actioned"),
      notActioned: this.props.t("PendingActionPrompt.notActioned"),
      applySelective: this.props.t("PendingActionPrompt.applySelectiveTerms"),
    };
    this.deactivateDialogProps = {
      title: this.props.t("BusinessSettings.titleDecline"),
      bodyText: this.props.user.isLoggedInUserMDLR
        ? this.props.t("BusinessSettings.bodyTextDeclineWarning")
        : this.props.t("BusinessSettings.bodyTextDecline"),
      confirm: this.props.t("BusinessSettings.confirm"),
      cancel: this.props.t("BusinessSettings.cancel"),
    };
    this.confirmDialogProps = {
      title: this.props.t("BusinessSettings.titleAccept"),
      bodyText: this.props.t("BusinessSettings.bodyTextAcceptWarning"),
      confirm: this.props.t("BusinessSettings.confirm"),
      cancel: this.props.t("BusinessSettings.cancel"),
    };
    this.dealerList = this.getDealerGroupNonSdlr();
    this.tnCStatus = {};
    this.onboardedDealerGroup = this.props.user.getOnboardedDealerGroup(true);
    this.termsAndConditions = this.props.termsAndConditions;
    if (!this.termsAndConditions) {
      this.termsAndConditions = this.props.termsAndConditionsHistory[0];
    }
  }

  componentDidMount() {
    this.tnCStatus = this.getTnCActionStatus();
  }

  handleDeclinePAClose = () => {
    this.setState({ openDeclinePAPrompt: false });
  };

  handleConfirmPAClose = () => {
    this.setState({ openConfirmPAPrompt: false });
  };

  handleDeclineClose = () => {
    this.setState({ openDeclineDialog: false });
  };

  handleConfirmClose = () => {
    this.setState({ openConfirmDialog: false });
  };

  handleConfirmation = (
    termsAndConditions,
    acceptance,
    commonIds,
    dealerIds
  ) => {
    this.props.hideOrShow(true);
    TermsAndConditionsClient.saveDealerTermsAndConditionsAcceptance(
      this.props.user,
      termsAndConditions,
      acceptance,
      commonIds.toString().split("|").join("%7C")
    )
      .then(() => {
        if (acceptance) {
          PendingActionClient.removeDealerPendingAction(
            this.props.user,
            dealerIds,
            TERMS_AND_CONDITIONS
          ).then(() => {
            this.props.getPendingActions().then(() => {
              this.props.alterComponentView(termsAndConditions);
            });
          });
        } else {
          this.props.alterComponentView(termsAndConditions);
        }
      })
      .catch(() => {})
      .finally(() => {
        this.props.user.dealerGroupingsMap &&
          this.props.user.updateUnOnboardedDealers(
            this.getDealerList(),
            TERMS_AND_CONDITIONS
          );
        this.props.hideOrShow(false);
      });
  };

  getDealerList() {
    const dealers = [];
    let dealerList = this.props.user.getCommonIdsWithoutSDLR();
    if (dealerList) {
      dealerList = dealerList.split(",");
      dealerList.forEach((dealer) => {
        const formattedDealer = this.props.user.reformatCommonId(dealer);
        dealers.push(
          this.props.user.dealerGroupingsMap[formattedDealer].completeDealerId
        );
      });
    }
    return dealers;
  }

  getCondition = () => {
    const isMainViewingSDLR = this.props.isMainViewingSDLR(this.props.user);

    if (isMainViewingSDLR) return false;

    // Returns true if the current view is the All CMAs view
    const isMainViewingAll = () => {
      return this.props.user.isLoggedInUserMDLR && this.props.user.showAll;
    };

    // Returns true if all of the remaining pending actions are for SDLR dealers
    const doesPAOnlyForSDLR = () => {
      let pendingAction;
      if (this.props.user && this.props.user.pendingActions) {
        pendingAction = this.props.user.pendingActions.filter(
          (pendingAction) => pendingAction.actionName === TERMS_AND_CONDITIONS
        )[0];
      }
      if (!pendingAction) return false;
      let allRemainingSDLR = true;
      const dealerIdList = pendingAction.attributes.dealerIds;
      dealerIdList.forEach((dealerId) => {
        if (
          this.props.user.dealerGroupingsMapByDealerId[dealerId].dealerType !==
          "SDLR"
        ) {
          allRemainingSDLR = false;
        }
      });
      return allRemainingSDLR;
    };

    // If the view is All CMAs and every pending action belongs to SDLR, the main cannot take an action for any of them
    // Therefore, the link should not show

    return !(isMainViewingAll() && doesPAOnlyForSDLR());
  };

  getDealerGroupNonSdlr() {
    const commonIds = this.props.user.getCommonIdsWithoutSDLR().split(",");
    commonIds.forEach((commonId, index) => {
      commonIds[index] = this.props.user.reformatCommonId(commonId);
    });
    return this.props.user.dealerGroup.filter((d) =>
      commonIds.includes(d.commonId)
    );
  }

  getTnCActionStatus = () => {
    const tnCStatus = {};
    const dealersActioned = [];
    const dealersNotActioned = [];
    this.dealerList.forEach((dealer) => {
      dealer.dealerName = dealer.name;
      if (
        this.props.termsAndConditionsHistory[0].acceptedDealers.includes(
          dealer.commonId
        )
      ) {
        dealer.termsStatus = this.props.t("PendingActionPrompt.accepted");
        dealersActioned.push(dealer);
        tnCStatus[dealer.commonId] = this.props.t("ConfirmDialog.accepted");
      } else if (
        this.props.termsAndConditionsHistory[0].declinedDealers.includes(
          dealer.commonId
        )
      ) {
        dealer.termsStatus = this.props.t("PendingActionPrompt.declined");
        dealersActioned.push(dealer);
        tnCStatus[dealer.commonId] = this.props.t("ConfirmDialog.rejected");
      } else {
        dealersNotActioned.push(dealer);
      }
    });

    this.setState({
      dealersActioned: dealersActioned,
      dealersNotActioned: dealersNotActioned,
    });
    return tnCStatus;
  };

  isMainViewingAll() {
    return (
      this.props.user.isLoggedInUserMDLR &&
      this.onboardedDealerGroup.length > 1 &&
      this.props.user.showAll
    );
  }

  isMainViewingBranch() {
    return (
      this.props.user.isLoggedInUserMDLR &&
      this.onboardedDealerGroup.length > 1 &&
      !this.props.user.showAll
    );
  }

  openDialog = (acceptance) => {
    if (this.isMainViewingAll()) {
      acceptance
        ? this.setState({ openConfirmPAPrompt: true })
        : this.setState({ openDeclinePAPrompt: true });
    } else if (this.isMainViewingBranch()) {
      acceptance
        ? this.setState({ openConfirmDialog: true })
        : this.setState({ openDeclineDialog: true });
    } else {
      acceptance
        ? this.handleConfirmation(
            this.termsAndConditions,
            true,
            this.props.user.getCommonIdsWithoutSDLR(),
            this.props.user.getDealerIdsWithoutSDLR()
          )
        : this.setState({ openDeclineDialog: true });
    }
  };

  applyAll = (termsAndConditions, acceptance) => {
    const commonIds = [];
    const dealerIds = [];
    this.dealerList.forEach((dealer) => {
      commonIds.push(dealer.commonId);
      dealerIds.push(dealer.completeDealerId);
    });
    this.handleConfirmation(
      termsAndConditions,
      acceptance,
      commonIds,
      dealerIds
    );
  };

  applySelective = (termsAndConditions, acceptance) => {
    const commonIds = [];
    const dealerIds = [];
    this.dealerList.forEach((dealer) => {
      if (!this.state.dealersActioned.includes(dealer)) {
        commonIds.push(dealer.commonId);
        dealerIds.push(dealer.completeDealerId);
      }
    });
    this.handleConfirmation(
      termsAndConditions,
      acceptance,
      commonIds,
      dealerIds
    );
  };

  render() {
    return (
      <div>
        <div className="pagePadding">
          <EMPButton
            buttonType="text"
            buttonElement="button"
            leftIcon={<MotionChevronLeft />}
            onClick={() =>
              this.props.alterComponentView(this.termsAndConditions)
            }
            buttonText={
              this.props.fromPending
                ? this.props.t("BusinessSettings.backToDashboard")
                : this.props.t("BusinessSettings.backToEmp")
            }
          ></EMPButton>

          <div className={styles.termsConditionsHeader}>
            {this.props.t("BusinessSettings.termsTitle")}
          </div>
          <div className={styles.previewPDFWrapper}>
            <div className={styles.previewPDFTitle}>
              {this.props.user.market.toUpperCase() +
                " " +
                this.props.t("BusinessSettings.termsHeader") +
                this.termsAndConditions.version +
                ".0"}
            </div>
            <div className={styles.effectiveFrom}>
              {" "}
              {this.props.t("BusinessSettings.termsEffectiveDate")}{" "}
              {this.termsAndConditions.effectiveDate})
            </div>
            <PreviewTermsConditions
              className={styles.previewPDF}
              file={this.termsAndConditions.file}
            />
          </div>
          <div className={styles.downloadNewVersion}>
            <span
              onClick={() =>
                this.props.handleTermsAndConditionsDownload(
                  this.termsAndConditions
                )
              }
            >
              {this.props.t("BusinessSettings.downloadTerms")}
            </span>
          </div>
        </div>

        <PendingActionPrompt
          {...this.props}
          disabled={false}
          customText={this.customText}
          showDeclineWarning={true}
          dealersActioned={this.state.dealersActioned}
          dealersNotActioned={this.state.dealersNotActioned}
          title={this.props.t("BusinessSettings.termsTitle")}
          buttonTitle={this.props.t("BusinessSettings.termsTitle")}
          onClose={this.handleDeclinePAClose}
          open={this.state.openDeclinePAPrompt}
          applyAll={() => this.applyAll(this.termsAndConditions, false)}
          applySome={() => this.applySelective(this.termsAndConditions, false)}
        />
        <PendingActionPrompt
          {...this.props}
          disabled={false}
          customText={this.customText}
          dealersActioned={this.state.dealersActioned}
          dealersNotActioned={this.state.dealersNotActioned}
          title={this.props.t("BusinessSettings.termsTitle")}
          buttonTitle={this.props.t("BusinessSettings.termsTitle")}
          onClose={this.handleConfirmPAClose}
          open={this.state.openConfirmPAPrompt}
          applyAll={() => this.applyAll(this.termsAndConditions, true)}
          applySome={() => this.applySelective(this.termsAndConditions, true)}
        />

        <ConfirmDialog
          {...this.props}
          dialogProps={this.deactivateDialogProps}
          showWarning={this.props.user.isLoggedInUserMDLR}
          dealerList={this.dealerList}
          open={this.state.openDeclineDialog}
          onConfirm={() =>
            this.handleConfirmation(
              this.termsAndConditions,
              false,
              this.props.user.getCommonIdsWithoutSDLR(),
              this.props.user.getDealerIdsWithoutSDLR()
            )
          }
          onCancel={this.handleDeclineClose}
          onClose={this.handleDeclineClose}
          attributes={this.tnCStatus}
          boldTitle={false}
        />
        <ConfirmDialog
          {...this.props}
          dialogProps={this.confirmDialogProps}
          showWarning={true}
          dealerList={this.dealerList}
          open={this.state.openConfirmDialog}
          onConfirm={() =>
            this.handleConfirmation(
              this.termsAndConditions,
              true,
              this.props.user.getCommonIdsWithoutSDLR(),
              this.props.user.getDealerIdsWithoutSDLR()
            )
          }
          onCancel={this.handleConfirmClose}
          onClose={this.handleConfirmClose}
          attributes={this.tnCStatus}
          boldTitle={false}
        />

        <HasPermissionTo
          perform={["acceptTermsAndConditions"]}
          permissions={this.props.user.permissions.rolePermissions}
          condition={this.getCondition()}
          render={() => (
            <>
              <div
                className={`${styles.acceptanceSelector} pagePaddingTop pagePaddingBottom primary-text-bold-color`}
              >
                {this.props.t("BusinessSettings.termsAccept")}
              </div>

              <Grid item xs={12} container justifyContent="center">
                <Box>
                  <EMPButton
                    buttonType="text"
                    buttonElement="button"
                    onClick={() =>
                      IS_CANADA_MARKET(this.props.user.market)
                        ? this.props.alterComponentView(this.termsAndConditions)
                        : this.openDialog(false)
                    }
                    disabled={
                      UpperCaseText(this.props.user.userType) === "FIELD_VIEW"
                    }
                    buttonText={this.props.t("BusinessSettings.decline")}
                  ></EMPButton>
                </Box>
                <Box ml={2}>
                  <EMPButton
                    onClick={() => this.openDialog(true)}
                    disabled={
                      UpperCaseText(this.props.user.userType) === "FIELD_VIEW"
                    }
                    buttonType="contained"
                    buttonElement="button"
                    buttonText={this.props.t("BusinessSettings.accept")}
                  ></EMPButton>
                </Box>
              </Grid>
            </>
          )}
        />
      </div>
    );
  }
}

export default withTranslation("emp")(AcceptTermsAndConditions);
