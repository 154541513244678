import axios from "axios";
import AuthenticationFailureHandler from "../../utils/AuthenticationFailureHandler";
import { TRANSLATION_BASE_URL } from "../../utils/EmpUtil";
import { getHeaders } from "../../utils/AuthorizationUtil";

export default class TranslationsClient {
  static translationUrl = TRANSLATION_BASE_URL;

  static getTranslationsLanguage(language, market, token) {
    const url = `${this.translationUrl}/translations/${language}/${market}`;
    return axios
      .get(url, getHeaders(token))
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error.response.status);
      });
  }

  static updateCurrentTranslations(
    language,
    moduleKey,
    key,
    updatedValue,
    market,
    token
  ) {
    const url = `${this.translationUrl}/translations/${language}/${market}`;
    const data = {
      moduleKey: moduleKey,
      key: key,
      changedValue: updatedValue,
    };
    return axios
      .post(url, data, getHeaders(token))
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error.response.status);
      });
  }

  static postAddTranslations(newTranslation, markets, token) {
    const url = `${this.translationUrl}/translations/add`;
    const data = {
      moduleKey: newTranslation.moduleKey,
      key: newTranslation.key,
      en: newTranslation.en,
      fr: newTranslation.fr,
      de: newTranslation.de,
      nl: newTranslation.nl,
      no: newTranslation.no,
      es: newTranslation.es,
      it: newTranslation.it,
      fi: newTranslation.fi,
      da: newTranslation.da,
      markets: markets,
    };
    return axios
      .post(url, data, getHeaders(token))
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error.response.status);
      });
  }

  static postAddComponentTranslations(newtranslation, markets, token) {
    const url = `${this.translationUrl}/translations/add/component`;
    const data = {
      moduleKey: newtranslation.moduleKey,
      key: newtranslation.key,
      en: newtranslation.en,
      fr: newtranslation.fr,
      de: newtranslation.de,
      nl: newtranslation.nl,
      no: newtranslation.no,
      es: newtranslation.es,
      it: newtranslation.it,
      fi: newtranslation.fi,
      da: newtranslation.da,
      markets: markets,
    };
    return axios
      .post(url, data, getHeaders(token))
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error.response.status);
      });
  }

  static getDefaultLanguage() {
    return `${this.translationUrl}/translations/language/`;
  }
}
