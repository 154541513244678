import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@material-ui/core";
import fordStyles from "./../orderDetails/NAOrderDetailsNewVersion.module.scss";
import lincolnStyles from "./../orderDetails/NAOrderDetailsNewVersionLincoln.module.scss";
import { withTranslation } from "react-i18next";
import DetailsHeader from "../common/DetailsHeader";
import CustomerInformation from "../orderDetails/CustomerInformation";
import PurchaseInformation from "../orderDetails/PurchaseInformation";
import ConsumerDeposit from "../orderDetails/ConsumerDeposit";
import HasPermissionTo from "../../../common/HasPermissionTo";
import TradeIn from "../../tradeIn/TradeInWrapper";
import Charging from "./addOns/Charging";
import { FINANCED, OWNED } from "../../../common/Constants";
import PurchaseAccessories from "./addOns/PurchaseAccessories";
import ProtectionPlan from "./protectionPlan/ProtectionPlan";
import PricingSummary from "./addOns/PricingSummary";
import VehicleDetails from "../orderDetails/VehicleDetails";
import PaymentMethod from "./paymentMethod/PaymentMethod";
import { ofType } from "../../../common/PubSub";
import CustomerReservationsClient from "../../../clients/CustomerReservationsClient";
import OffersAndIncentives from "./offersAndIncentives/OffersAndIncentives";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import UpperCaseText from "../../../utils/UpperCaseText";
import VehicleHold from "./dealerActions/vehicleHold/VehicleHold";
import DealerInformation from "../orderDetails/DealerInformation";
import { handleBack } from "../../../utils/GoBack";
import NADealerNotifications from "../notifications/NADealerNotifications";
import NADealerNotificationsClient from "../../../clients/NADealerNotificationsClient";
import { logMsg } from "../../../common/Logger";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";
import DealerNotes from "../orderDetails/dealerNotes/DealerNotes";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import MotionChevronLeft from "../../../shared/Icons/MotionChevronLeft";
import * as AnalyticsService from "../../../shared/services/analytics/AnalyticsService";
import SubNav from "../../../shared/subnav/SubNav";
import { displayError, LOAD_ERROR } from "../../../errors/ErrorConstants";

const PurchaseDetails = (props) => {
  const [orderDetail, setOrderDetail] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [pricingStack, setPricingStack] = useState({});
  const [expandAll, setExpandAll] = useState(true);
  const [hasError, setHasError] = useState([]);

  const { orderId, commonId, user, fromCrc, fromFieldView, hideOrShow } = props;

  const styles = IS_FORD_BRAND(user.brand) ? fordStyles : lincolnStyles;

  useEffect(() => {
    const subscription = ofType("language").subscribe((response) => {
      if (response) {
        props.user.setLanguageCode(response.data);
        getPurchaseDetails(orderId, commonId);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!orderDetail.customerReservation) {
      let num = 0;

      const eventInterval = setInterval(() => {
        num += 1;

        if (orderDetail.customerReservation) {
          AnalyticsService.trackPageLoadDetailEvent(
            "fv:emp:customer handling:purchase request details",
            "emp:purchase request:order id",
            orderId,
            user
          );
          clearInterval(eventInterval);
        }

        if (num === 10) {
          clearInterval(eventInterval);
        }
      }, 1000);
    } else {
      AnalyticsService.trackPageLoadDetailEvent(
        "fv:emp:customer handling:purchase request details",
        "emp:purchase request:order id",
        orderId,
        user
      );
    }
  }, []);

  useEffect(() => {
    if (fromCrc || fromFieldView) {
      const encodedCommonId = orderDetail?.dealerInfo?.commonId
        ?.split("|")
        .join("%7C");
      const renderedOrderId = orderDetail?.customerReservation?.id;

      if (encodedCommonId) {
        getNADealerNotifications(user, encodedCommonId, renderedOrderId);
      }
    } else {
      getPurchaseDetails(orderId, commonId);
      getNADealerNotifications(user, user?.getEncodedCommonId(), orderId);
    }

    getPriceStackData();
  }, [orderId]);

  const getNADealerNotifications = (user, encodedCommonId, orderId) => {
    NADealerNotificationsClient.getNADealerNotifications(
      user,
      encodedCommonId,
      orderId
    )
      .then((data) => setNotifications(data.ordersData))
      .catch((error) => logMsg("Failed to get notifications: ", error));
  };

  const getPurchaseDetails = (orderId, commonDealerId) => {
    hideOrShow(true);
    CustomerReservationsClient.getCustomerOrderDetails(
      { token: props.token },
      orderId,
      commonDealerId,
      false,
      user.lang,
      null
    )
      .then((response) => {
        setOrderDetail(response);
      })
      .catch(() => setHasError([LOAD_ERROR]))
      .finally(() => hideOrShow(false));
  };

  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  const getFullName = (value) => {
    let fullName = "";
    if (value) {
      if (value.firstName) fullName += value.firstName;
      if (value.lastName)
        fullName.length > 0
          ? (fullName += " " + value.lastName)
          : (fullName += value.lastName);
    }
    return fullName;
  };

  const openPrint = (id) => {
    AnalyticsService.trackOnClickDetailEvent(
      "fv:emp:customer handling:purchase request details",
      "emp:purchase request:order id",
      `emp:purchase request:order id:${id}`,
      id,
      "emp:customer handling:purchase request details:content:print this page",
      "fv:emp:customer handling:purchase request details:content action",
      props.user
    );

    setTimeout(() => {
      window.print();
    }, 100);
  };

  const deleteNotification = (id) => {
    NADealerNotificationsClient.deleteNotification(props.user, id).then(() => {
      setNotifications(
        notifications.filter((notification) => notification.uid !== id)
      );
    });
  };

  const getPriceStackData = () => {
    const commonId =
      user.commonId ||
      (props.orderDetail &&
        props.orderDetail.dealerInfo &&
        props.orderDetail.dealerInfo.commonId);

    hideOrShow(true);
    CustomerReservationsClient.getOrderPricingStack(
      props.user,
      props.orderId
        ? props.orderId
        : props.orderDetail &&
            props.orderDetail.customerReservation &&
            props.orderDetail.customerReservation.id,
      "",
      commonId,
      props.user.lang
    )
      .then((result) => {
        setPricingStack(result);
      })
      .finally(() => {
        hideOrShow(false);
      });
  };

  const refreshReservationDetailsWithNotifications = () => {
    getNADealerNotifications(
      props.user,
      props.user.getEncodedCommonId(),
      props.orderId
    );

    getPurchaseDetails(props.orderId, props.user.commonId, false);
  };

  const { tradeIn, customerReservation, customerInfo, dealerInfo } =
    orderDetail;

  const disableDealerActionsFlagForPurchaseRequests =
    customerReservation && customerReservation.vehicleSoldOrCancelledPast30Days;

  const purchaseRequestAccepted =
    customerReservation &&
    customerReservation.purchaseInfo &&
    (customerReservation.purchaseInfo.acceptRejectStatus === "accepted" ||
      (customerReservation.purchaseInfo.purchaseRequestDealerAction &&
        customerReservation.purchaseInfo.purchaseRequestDealerAction
          .dealerAction === "purchaserequestaccepted"));

  const vehicleSoldDateBefore30Days =
    !disableDealerActionsFlagForPurchaseRequests &&
    customerReservation &&
    customerReservation.vehicleStatusCode === "VPHD";

  const vehicleCancelDateBefore30Days =
    !disableDealerActionsFlagForPurchaseRequests &&
    customerReservation &&
    customerReservation.vehicleStatusCode === "VPHD" &&
    customerReservation.purchaseInfo &&
    customerReservation.purchaseInfo.acceptRejectStatus === "cancelled";

  const isTradeInLinksEnabled =
    purchaseRequestAccepted ||
    vehicleSoldDateBefore30Days ||
    vehicleCancelDateBefore30Days;

  const isDownPaymentEnabled =
    customerReservation &&
    customerReservation.purchaseInfo &&
    customerReservation.purchaseInfo.isDownPaymentEnabled;

  const orderDepositAmount =
    customerReservation &&
    customerReservation.orderPaymentInfo &&
    customerReservation.orderPaymentInfo.depositAmount;

  const holdDepositAmount =
    customerReservation &&
    customerReservation.vehicleHoldDepositInfo &&
    customerReservation.vehicleHoldDepositInfo.depositAmount;

  if (displayError(hasError)) throw new Error(hasError[0]);

  return customerReservation ? (
    <div className="pageWrapper">
      <SubNav
        id="subNav"
        dashboard={false}
        title={props.t("GlobalNavBar.dashboard")}
        {...props}
        isDealer
      />

      <HasPermissionTo
        perform={["USOrderDetailsNotifications:view"]}
        permissions={user.permissions.rolePermissions}
        condition={customerReservation.displayNotifications}
        render={() => (
          <NADealerNotifications
            {...props}
            customerReservation={customerReservation}
            notifications={notifications}
            deleteNotification={deleteNotification}
          />
        )}
      />

      <Grid
        container
        className={styles.componentContainer}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <EMPButton
            buttonText={props.t("PurchaseDetails.backToList")}
            testId="backButton"
            buttonStyle="text"
            leftIcon={<MotionChevronLeft />}
            onClick={() => {
              props.fromFieldView
                ? handleBack(false, props.history)
                : handleBack(fromCrc, props.history);
            }}
          ></EMPButton>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="Print page content"
            id="printButton"
            data-testid="printButton"
            onClick={() => openPrint(customerReservation.id)}
            edge="end"
            disableRipple
          >
            <PrintOutlinedIcon className={styles.printIcon} size="small" />
          </IconButton>
        </Grid>
      </Grid>

      <div className={`${styles.componentContainer} ${styles.customerHeading}`}>
        <DetailsHeader
          {...props}
          customerReservation={customerReservation}
          customerInfo={customerInfo}
        />
      </div>

      <div className={`customerOrderDetails ${styles.componentContainer}`}>
        <Grid
          container
          justifyContent="flex-end"
          className={styles.expandContainer}
        >
          <Grid item onClick={() => setExpandAll(!expandAll)}>
            {`${expandAll ? "Collapse" : "Expand"} all rows`}
          </Grid>
        </Grid>
        <div className={styles.expansionBox}>
          <PurchaseInformation
            {...props}
            customerReservation={customerReservation}
            refreshReservationDetailsWithNotifications={
              refreshReservationDetailsWithNotifications
            }
            expandAccordian={expandAll}
          />

          <CustomerInformation
            {...props}
            customerReservation={customerReservation}
            customerInfo={customerInfo}
            formatPhoneNumber={(phoneNumber) => formatPhoneNumber(phoneNumber)}
            fromPurchaseDetails
            getFullName={(value) => getFullName(value)}
            expandAccordian={expandAll}
          />

          {(props.fromCrc || props.fromFieldView) && (
            <DealerInformation
              {...props}
              dealerInfo={dealerInfo}
              expandAccordian={expandAll}
            />
          )}

          <VehicleDetails
            {...props}
            customerReservation={customerReservation}
            vehicleFeatures={
              customerReservation ? customerReservation.vehicleNAFeatures : {}
            }
            dealerInfo={dealerInfo}
            expandAccordian={expandAll}
          />
          <HasPermissionTo
            perform={["dealerNotesView"]}
            permissions={props.user.permissions.rolePermissions}
            render={() => (
              <DealerNotes
                {...props}
                orderId={customerReservation.id}
                expandAccordion={expandAll}
              />
            )}
          />
        </div>
      </div>

      <Grid container className={styles.newOrdersDiv}>
        <Grid item xs={12} className={styles.leftPadding}>
          <span>{props.t("PurchaseDetails.addOnsAndPricing")}</span>
        </Grid>
      </Grid>

      <div className={styles.componentContainer}>
        <div className={styles.expansionBox}>
          <Charging
            {...props}
            customerReservation={customerReservation}
            expandAccordian={expandAll}
          />
        </div>
      </div>

      <div className={styles.componentContainer}>
        <div className={styles.expansionBox}>
          <PurchaseAccessories
            {...props}
            accessories={customerReservation.allAccessories}
            expandAccordian={expandAll}
          />
        </div>
      </div>

      <div className={styles.componentContainer}>
        <ProtectionPlan
          {...props}
          protectionPlansData={customerReservation.protectionPlansData}
          expandAccordian={expandAll}
        />
      </div>

      <div className={styles.componentContainer}>
        <OffersAndIncentives
          {...props}
          fromPurchaseDetails
          pricingStack={pricingStack}
          expandAccordian={expandAll}
          customerReservation={customerReservation}
        />
      </div>

      <div className={styles.componentContainer}>
        <PricingSummary
          {...props}
          isDownPaymentEnabled={isDownPaymentEnabled}
          paymentType={customerReservation && customerReservation.paymentType}
          customerReservation={customerReservation}
          pricingStack={pricingStack}
          isOrder={UpperCaseText(customerReservation.orderType) === "ORDER"}
          isPurchaseRequest
          amountFinanced={
            customerReservation &&
            customerReservation.financeDataQuote &&
            (customerReservation.financeDataQuote.amountFinanced ||
              customerReservation.financeDataQuote.totalAmountFinancedStandard)
          }
          expandAccordian={expandAll}
        />
      </div>

      <div className={styles.componentContainer}>
        {customerReservation && (
          <PaymentMethod
            {...props}
            customerReservation={customerReservation}
            pricingStack={pricingStack}
            financeProductType={
              customerReservation && customerReservation.paymentType
            }
            authorizedBelow={isDownPaymentEnabled}
            isDownPaymentEnabled={
              isDownPaymentEnabled ||
              vehicleSoldDateBefore30Days ||
              vehicleCancelDateBefore30Days
            }
            expandAccordian={expandAll}
          />
        )}
      </div>

      <Grid container className={styles.newOrdersDiv}>
        <Grid item xs={12} className={styles.leftPadding}>
          <span>{props.t("PurchaseDetails.dealerActions")}</span>
        </Grid>
      </Grid>

      <HasPermissionTo
        perform={["vehicleHold"]}
        permissions={props.user.permissions.rolePermissions}
        condition={
          customerReservation.vehicleHoldData &&
          customerReservation.vehicleHoldData.holdStartDate
        }
        render={() => {
          return (
            <div className={styles.componentContainer}>
              <div className={styles.expansionBox}>
                <VehicleHold
                  {...props}
                  customerReservation={customerReservation}
                  refreshReservationDetailsWithNotifications={
                    refreshReservationDetailsWithNotifications
                  }
                  expandAccordian={expandAll}
                />
              </div>
            </div>
          );
        }}
      />

      <div className={styles.componentContainer}>
        <HasPermissionTo
          perform={["tradeIn:view"]}
          permissions={props.user.permissions.rolePermissions}
          condition={!!tradeIn}
          render={() => {
            return (
              <div className={styles.expansionBox}>
                <TradeIn
                  {...props}
                  refreshReservationDetailsWithNotifications={
                    refreshReservationDetailsWithNotifications
                  }
                  isOrderCancelled={() => {}}
                  status={customerReservation.status}
                  tradeInDetails={tradeIn}
                  linkEnabled={
                    tradeIn.ownershipType === FINANCED ||
                    tradeIn.ownershipType === OWNED
                      ? isTradeInLinksEnabled && tradeIn.appraisalAmount
                      : isTradeInLinksEnabled
                  }
                  disableDealerActionsFlagForPurchaseRequests={
                    disableDealerActionsFlagForPurchaseRequests
                  }
                  customerReservation={customerReservation}
                  expandAccordian={expandAll}
                />
              </div>
            );
          }}
        />
      </div>
      {(orderDepositAmount || holdDepositAmount || isDownPaymentEnabled) && (
        <div className={styles.componentContainer}>
          <div className={styles.expansionBox}>
            <ConsumerDeposit
              {...props}
              isPurchaseOrder
              customerReservation={customerReservation}
              refreshReservationDetailsWithNotifications={
                refreshReservationDetailsWithNotifications
              }
              isDownPaymentEnabled={isDownPaymentEnabled}
              disableDealerActionsFlagForPurchaseRequests={
                disableDealerActionsFlagForPurchaseRequests
              }
              expandAccordian={expandAll}
            />
          </div>
        </div>
      )}
    </div>
  ) : (
    ""
  );
};

export default withTranslation("emp")(PurchaseDetails);
