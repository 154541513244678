import axios from "axios";
import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";
import { getHeaders } from "../utils/AuthorizationUtil";
import { BASE_URL } from "../utils/EmpUtil";
import { is401 } from "../errors/ErrorConstants";

export default class DealerNotesClient {
  static baseUrl = BASE_URL;

  static saveDealerNotes(token, dealerId, requestBody) {
    const url = `${this.baseUrl}/dealer/dealernotes/${dealerId}/save-dealer-notes`;

    return axios
      .post(url, requestBody, getHeaders(token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static updateDealerNotes(token, requestBody) {
    const url = `${this.baseUrl}/dealer/dealernotes/update-dealer-notes`;

    return axios
      .put(url, requestBody, getHeaders(token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static getDealerNotes(token, orderId, pageNumber = 0, pageSize = 3) {
    const queryParams = `pageNumber=${pageNumber}&pageSize=${pageSize}`;
    const url = `${this.baseUrl}/dealer/dealernotes/${orderId}/get-dealer-notes?${queryParams}`;

    return axios
      .get(url, getHeaders(token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static deleteDealerNotes(token, id) {
    const url = `${this.baseUrl}/dealer/dealernotes/delete-dealer-notes/${id}`;
    return axios
      .delete(url, getHeaders(token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }
}
