import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { NOTNULL_OR_NOTUNDEFINED } from "../../../../utils/EmpUtil";
import { StyledTableRow } from "../../../../shared/Tables/TableRow";
import { formatPriceValue } from "../../../../utils/FormatPrice";

class FlexBuyPaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.index = 1;
  }

  getValue = (field) => {
    const { financeDataQuote } = this.props.customerReservation;

    return financeDataQuote && financeDataQuote[field];
  };

  render() {
    this.index = 0;
    const { financeType } = this.props.customerReservation;
    let estimatedMonthlyPaymentLessThan36Months;
    let estimatedMonthlyPaymentGreaterThan36Months;

    const flexBuyPrimary =
      this.props.pricingStack && this.props.pricingStack.flexBuyPrimary;
    const flexBuySecondary =
      this.props.pricingStack && this.props.pricingStack.flexBuySecondary;

    if (flexBuyPrimary && flexBuySecondary) {
      estimatedMonthlyPaymentLessThan36Months = flexBuyPrimary;
      estimatedMonthlyPaymentGreaterThan36Months = flexBuySecondary;
    } else if (
      this.props.customerReservation.pricingStack &&
      this.props.customerReservation.pricingStack.flexBuyPrimary &&
      this.props.customerReservation.pricingStack.flexBuySecondary
    ) {
      estimatedMonthlyPaymentLessThan36Months =
        this.props.customerReservation.pricingStack.flexBuyPrimary;
      estimatedMonthlyPaymentGreaterThan36Months =
        this.props.customerReservation.pricingStack.flexBuySecondary;
    } else {
      const flexBuyTerms = this.getValue("flexBuyTerms");
      estimatedMonthlyPaymentLessThan36Months =
        flexBuyTerms && flexBuyTerms[0] && flexBuyTerms[0].monthlyPayment;
      estimatedMonthlyPaymentGreaterThan36Months =
        flexBuyTerms && flexBuyTerms[1] && flexBuyTerms[1].monthlyPayment;
    }

    return (
      <div>
        <StyledTableRow
          text={this.props.t("PurchaseDetails.paymentMethod.type")}
          data={
            financeType
              ? this.props.t(`PurchaseDetails.paymentMethod.${financeType}`)
              : "--"
          }
          index={++this.index}
          testID="type"
        />

        <StyledTableRow
          text={this.props.t(
            "PurchaseDetails.paymentMethod.estimatedTotalSalesPrice"
          )}
          data={formatPriceValue(this.getValue("totalSalePrice"))}
          index={++this.index}
          testID="estimatedTotalSalesPrice"
        />
        <StyledTableRow
          text={this.props.downPayment}
          data={formatPriceValue(this.getValue("deposit"))}
          index={++this.index}
          testID="downPayment"
        />
        <StyledTableRow
          text={`${this.props.t(
            "PurchaseDetails.paymentMethod.estimatedMonthlyPayment"
          )} (1-36 months)`}
          data={
            NOTNULL_OR_NOTUNDEFINED(estimatedMonthlyPaymentLessThan36Months)
              ? formatPriceValue(
                  estimatedMonthlyPaymentLessThan36Months
                ).concat(this.props.t("PurchaseDetails.paymentMethod.perMonth"))
              : "--"
          }
          index={++this.index}
          testID="estimatedMonthlyPaymentLessThan36Months"
        />

        <StyledTableRow
          text={
            NOTNULL_OR_NOTUNDEFINED(this.getValue("term"))
              ? `${this.props.t(
                  "PurchaseDetails.paymentMethod.estimatedMonthlyPayment"
                )} (37-${this.getValue("term")} months)`
              : this.props.t(
                  "PurchaseDetails.paymentMethod.estimatedMonthlyPayment"
                )
          }
          data={
            NOTNULL_OR_NOTUNDEFINED(estimatedMonthlyPaymentGreaterThan36Months)
              ? formatPriceValue(
                  estimatedMonthlyPaymentGreaterThan36Months
                ).concat(this.props.t("PurchaseDetails.paymentMethod.perMonth"))
              : "--"
          }
          index={++this.index}
          testID="estimatedMonthlyPaymentGreaterThan36Months"
        />

        <StyledTableRow
          text={this.props.t("PurchaseDetails.paymentMethod.term")}
          data={
            NOTNULL_OR_NOTUNDEFINED(this.getValue("term"))
              ? this.getValue("term")
              : "--"
          }
          index={++this.index}
          testID="term"
        />

        <StyledTableRow
          text={this.props.t("PurchaseDetails.paymentMethod.apr")}
          data={
            NOTNULL_OR_NOTUNDEFINED(this.getValue("apr"))
              ? `${this.getValue("apr")}%`
              : "--"
          }
          index={++this.index}
          testID="apr"
        />
      </div>
    );
  }
}

export default withTranslation("emp")(FlexBuyPaymentMethod);
