import React, { Component } from "react";
import styles from "./accessDenied.module.scss";
import { withTranslation } from "react-i18next";
import FordLogo from "../../common/FordLogo";
import { invalidateSession } from "../../common/InvalidateSession";
import { IS_CANADA_MARKET, IS_USA_MARKET } from "../../utils/EmpUtil";
import { LANGUAGE_MARKET_MAP } from "../../common/Constants";

class AccessDeniedAsBevDisabled extends Component {
  componentDidMount() {
    if (
      !(
        IS_USA_MARKET(this.props.userDetails.market) ||
        IS_CANADA_MARKET(this.props.userDetails.market)
      )
    ) {
      const language = LANGUAGE_MARKET_MAP[this.props.userDetails.market];
      this.props.i18n.changeLanguage(
        language.substring(0, 2) + "_" + this.props.userDetails.market
      );
    }
    this.props.history.push("/denied");
    invalidateSession();
  }

  render() {
    return (
      <div className="pageWrapper">
        <div className={styles.brandDiv}>
          <FordLogo className={styles.brandLogo} />
        </div>
        <div className={styles.deniedTitle}>
          {IS_USA_MARKET(this.props.market) ||
          IS_CANADA_MARKET(this.props.market)
            ? this.props.t("AccessDeniedAsBevDisabled.header")
            : this.props.t("GeneralEMP.weHaveMoved")}
        </div>
        <div className={styles.deniedInfo}>
          {IS_USA_MARKET(this.props.market) ||
          IS_CANADA_MARKET(this.props.market) ? (
            <>
              <span>{this.props.t("AccessDeniedAsBevDisabled.subheader")}</span>{" "}
              <span>{this.props.t("AccessDeniedAsBevDisabled.help")}</span>
            </>
          ) : (
            <span
              className={styles.redirectUrl}
              onClick={() =>
                (window.location.href =
                  "https://www.eportal.dealerconnection.com")
              }
            >
              {this.props.t("GeneralEMP.clickHere")}
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation("emp")(AccessDeniedAsBevDisabled);
