/* eslint-disable react/jsx-key */
import React, { Component } from "react";
import FileBrowser from "../../../shared/fileBrowser/FileBrowser";
import Button from "@material-ui/core/Button";
import styles from "./UploadNewTermsAndConditions.module.scss";
import Grid from "@material-ui/core/Grid";
import pdfIcon from "../../../imgs/pdf-acrobat-icon.svg";
import ClearIcon from "@material-ui/icons/Clear";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FileEncoder from "../../../utils/FileEncoder";
import { withTranslation } from "react-i18next";
import { InfoIconWithMessage } from "../../../shared/infoIcon/InfoIcon";
import { TERMS_AND_CONDITIONS } from "../../../common/Constants";
import TermsAndConditionsClient from "../../../clients/TermsAndConditionsClient";
import PendingActionClient from "../../../clients/PendingActionClient";
import { IS_CANADA_MARKET } from "../../../utils/EmpUtil";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";

class UploadNewTermsAndConditions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      displayDetails: false,
      enablePubish: false,
      effectiveDate: "",
      displayError: false,
    };

    this.removeFile = this.removeFile.bind(this);
    this.selectEffectiveDate = this.selectEffectiveDate.bind(this);
  }

  onFileSelected = (file) => {
    if (file.size > 2000000 || file.type !== "application/pdf") {
      //Need to display an error message like file exceeds 70kb
      this.setState({ displayError: true, file: null, displayDetails: false });
    } else {
      this.setState({ file: file, displayDetails: true, displayError: false });
    }
  };

  uploadNewTermsAndConditions = () => {
    if (this.state.enablePubish) {
      this.props.hideOrShow(true);
      const date = this.state.effectiveDate;
      new FileEncoder()
        .encode(this.state.file)
        .then((data) => {
          const termsAndConditions = {
            file: data,
            nscId: this.props.user.userId,
            effectiveDate:
              date.getDate() +
              "/" +
              (date.getMonth() + 1) +
              "/" +
              date.getFullYear(),
          };
          this.props.hideOrShow(true);
          TermsAndConditionsClient.uploadTermsAndConditions(
            this.props.user,
            termsAndConditions
          )
            .then(() => {
              if (!IS_CANADA_MARKET(this.props.user.market)) {
                PendingActionClient.removeNscPendingAction(
                  this.props.user,
                  TERMS_AND_CONDITIONS
                );
              }
              this.props.loadTermsAndConditionsHistory();
              this.removeFile();
            })
            .catch(() => {})
            .finally(() => {
              this.props.hideOrShow(false);
            });
        })
        .catch(() => {})
        .finally(() => {
          this.props.hideOrShow(false);
        });
    }
  };

  selectEffectiveDate = (date) => {
    if (date !== null) {
      this.setState({ effectiveDate: date });
      this.setState({ enablePubish: true });
    }
  };

  removeFile = () => {
    document.getElementById("pdf-file").value = "";
    this.setState({
      file: null,
      displayDetails: false,
      enablePubish: false,
      effectiveDate: "",
    });
  };

  render() {
    return (
      this.props.enableUpload !== undefined && (
        <div className={styles.uploadTermsAndConditions}>
          {this.props.enableUpload ? (
            <>
              <div className="pagePadding">
                <InfoIconWithMessage
                  message={this.props.t(
                    "BusinessSettings.termsandConditionsHeader"
                  )}
                />
              </div>
              <div className="pagePaddingLeft">
                <FileBrowser
                  fileType=".pdf"
                  onFileSelected={this.onFileSelected}
                  id="pdf-file"
                >
                  <Button
                    className={styles.uploadBtn}
                    component="span"
                    id="upload-file-btn"
                  >
                    {this.props.t(
                      "BusinessSettings.uploadNewTermsAndConditions"
                    )}
                  </Button>
                </FileBrowser>
                <p className={styles.fileTypeText}>
                  {this.props.t("UploadTermsConditions.tipText")}
                </p>
                {this.state.displayError && (
                  <div className={styles.warningDiv}>
                    <div className={styles.warning}></div>
                    <div className={styles.errorText}>
                      {this.props.t("Error.fileError")}
                    </div>
                  </div>
                )}
              </div>
              {this.state.displayDetails && (
                <>
                  <Grid
                    container
                    className={`${styles.effectiveBox} pagePaddingLeft`}
                    id="effectiveRow"
                  >
                    <Grid item xs={4} className={styles.effectiveDateRow}>
                      <div className={styles.fileNameTextBox}>
                        <div>
                          <img src={pdfIcon} alt="PDF Icon" />
                          <p>{this.state.file.name}</p>
                        </div>
                        <div
                          className={styles.clearImage}
                          id="removeFile"
                          onClick={this.removeFile}
                        >
                          <ClearIcon />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={8} className={styles.effectiveDateRow}>
                      <span className={styles.effectiveText}>
                        {IS_CANADA_MARKET(this.props.user.market)
                          ? this.props.t("BusinessSettings.dueDate")
                          : this.props.t("BusinessSettings.effectiveFrom")}
                      </span>
                      <DatePicker
                        selected={this.state.effectiveDate}
                        onChange={this.selectEffectiveDate}
                        id="effectiveDate"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="dd/mm/yy"
                        minDate={new Date()}
                      />
                    </Grid>
                  </Grid>
                  <div className={styles.publishButton}>
                    <EMPButton
                      buttonText={this.props.t("BusinessSettings.publish")}
                      buttonElement="button"
                      buttonType="contained"
                      onClick={this.uploadNewTermsAndConditions}
                      testId="publish-action-btn"
                      disabled={!this.state.enablePubish}
                    ></EMPButton>
                  </div>
                </>
              )}
            </>
          ) : (
            <div id="termsHeaderWithWarning" className="pagePadding">
              <InfoIconWithMessage
                message={[
                  this.props.t("BusinessSettings.termsandConditionsHeader"),
                  " ",
                  <span className={styles.previewTitleWarning}>
                    {this.props.t("BusinessSettings.maximumUploadWarning")}
                  </span>,
                ]}
              />
            </div>
          )}
        </div>
      )
    );
  }
}

export default withTranslation("emp")(UploadNewTermsAndConditions);
