import { BASE_URL } from "../utils/EmpUtil";
import axios from "axios";
import { getHeaders } from "../utils/AuthorizationUtil";
import { is401 } from "../errors/ErrorConstants";
import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";

export default class LocationDataClient {
  static getLocationData(user) {
    const url = BASE_URL + `/location-data`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
