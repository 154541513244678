import axios from "axios";
import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";
import { getHeaders } from "../utils/AuthorizationUtil";
import {
  REPORTING_BASE_URL,
  RESERVATION_BASE_URL,
  IS_CANADA_MARKET,
} from "../utils/EmpUtil";
import { is401 } from "../errors/ErrorConstants";

export default class DealerReportingClient {
  static baseUrl = REPORTING_BASE_URL;

  static downloadOrderStatusReportInCsv(user, lang) {
    const commonIds = user.getCommonIdsWithSDLR
      ? user.getCommonIdsWithSDLR().split(",")
      : user.commonId.split("|").join("%7C");
    const url = `${RESERVATION_BASE_URL}/dealer/order-status-report?dealerCommonId=${commonIds}&lang=${lang}`;
    return axios
      .get(url, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static getCustomerNames(user, orderIds) {
    const url = `${this.baseUrl}/reporting/getCustomerNames?orderIds=${orderIds}`;
    return axios.get(url, getHeaders(user.token));
  }

  static getFieldReport(user, reqBody) {
    const url = `${this.baseUrl}/reporting/${user.market}/field-report`;

    if (reqBody.dateFrom === "") {
      reqBody.dateFrom = null;
    }
    if (reqBody.dateTo === "") {
      reqBody.dateTo = null;
    }
    if (reqBody.dateRange === "") {
      reqBody.dateRange = null;
    }

    return axios
      .post(url, reqBody, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static downloadPurchaseRequestReportInCsv(
    user,
    lang,
    commonId,
    withTransactionData = true,
    withTradeInData = true,
    reqData,
    fieldViewDealer = false
  ) {
    const userType = fieldViewDealer ? "na_dealer" : user.userType;
    const url = `${this.baseUrl}/reporting/${user.market}/purchase-requests-report`;
    reqData.userType = userType;
    reqData.marketId = user.market;
    reqData.commonIds = user.commonDealerId;
    reqData.withTransactionData = withTransactionData;
    reqData.withTradeInData = withTradeInData;

    if (reqData.dateFrom === "") {
      reqData.dateFrom = null;
    }
    if (reqData.dateTo === "") {
      reqData.dateTo = null;
    }
    if (reqData.dateRange === "") {
      reqData.dateRange = null;
    }

    return axios
      .post(url, reqData, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static downloadModelEReportInCsv(
    user,
    lang,
    commonId,
    withTransactionData = true,
    withTradeInData = true,
    reqData,
    fieldViewDealer = false
  ) {
    const userType = fieldViewDealer ? "na_dealer" : user.userType;
    const url = `${this.baseUrl}/reporting/${user.market}/modelE-report`;
    reqData.userType = userType;
    reqData.marketId = user.market;
    reqData.commonIds = user.commonDealerId;
    reqData.withTransactionData = withTransactionData;
    reqData.withTradeInData = withTradeInData;

    if (reqData.dateFrom === "") {
      reqData.dateFrom = null;
    }
    if (reqData.dateTo === "") {
      reqData.dateTo = null;
    }
    if (reqData.dateRange === "") {
      reqData.dateRange = null;
    }

    return axios
      .post(url, reqData, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static downloadDealerReservationReportInCsv(
    user,
    lang,
    commonId,
    withTransactionData = true,
    reqData,
    fieldViewDealer = false
  ) {
    const userType =
      fieldViewDealer && !IS_CANADA_MARKET(user.market)
        ? "na_dealer"
        : user.userType;
    const url = `${this.baseUrl}/reporting/${user.market}/reservations-report`;
    reqData.userType = userType;
    reqData.marketId = user.market;
    reqData.commonIds = user.commonDealerId;
    reqData.withTransactionData = withTransactionData;

    if (IS_CANADA_MARKET(user.market)) {
      reqData.orderType = "RESERVATION";
    }
    if (reqData.dateFrom === "") {
      reqData.dateFrom = null;
    }
    if (reqData.dateTo === "") {
      reqData.dateTo = null;
    }
    if (reqData.dateRange === "") {
      reqData.dateRange = null;
    }

    return axios
      .post(url, reqData, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static downloadOrderConversionReportInCsv(
    user,
    lang,
    commonId,
    withTransactionData = true,
    reqData,
    fieldViewDealer = false
  ) {
    const userType = fieldViewDealer ? "na_dealer" : user.userType;
    const url = `${this.baseUrl}/reporting/${user.market}/orders-report`;
    reqData.userType = userType;
    reqData.marketId = user.market;
    reqData.commonIds = user.commonDealerId;
    reqData.withTransactionData = withTransactionData;

    if (reqData.dateFrom === "") {
      reqData.dateFrom = null;
    }
    if (reqData.dateTo === "") {
      reqData.dateTo = null;
    }
    if (reqData.dateRange === "") {
      reqData.dateRange = null;
    }

    return axios
      .post(url, reqData, getHeaders(user.token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }
}
