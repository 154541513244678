const stylesOverride = {
  modeletable: {
    tableHeader: {
      verticalAlign: "middle",
      fontFamily: "FordAntennaCondBold",
      fontSize: 11,
      fontWeight: "bold",
      color: "#102b4e",
      boxSizing: "border-box",
      width: "12.5%",
    },
    dateHeader: {
      verticalAlign: "middle",
      fontFamily: "FordAntennaCondBold",
      fontSize: 11,
      fontWeight: "bold",
      color: "#102b4e",
      textAlign: "center",
      width: "10%",
    },
    date: {
      fontFamily: "FordAntennaCondRegular, sans-serif",
      fontSize: "12px",
    },
    actionNeededByHeader: {
      verticalAlign: "middle",
      fontFamily: "FordAntennaCondBold",
      fontSize: 11,
      fontWeight: "bold",
      color: "#102b4e",
      textAlign: "center",
      width: "10%",
    },
    specsHeader: {
      verticalAlign: "middle",
      fontFamily: "FordAntennaCondBold",
      fontSize: 11,
      fontWeight: "bold",
      color: "#102b4e",
      textAlign: "left",
      width: "19%",
    },
    status: {
      textAlign: "left",
    },
  },
  header: {
    height: 70,
  },
  resultsHeader: {
    height: 20,
  },
  orderTypeHeader: {
    verticalAlign: "bottom",
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    letterSpacing: "1.88px",
    padding: "0px 0px 20px 50px",
    boxSizing: "border-box",
    width: "12%",
  },
  dateHeader: {
    verticalAlign: "bottom",
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "center",
    letterSpacing: "1.88px",
    padding: "0px 0px 20px 9px",
    width: "10%",
  },
  customerNameHeader: {
    verticalAlign: "bottom",
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "left",
    letterSpacing: "1.88px",
    padding: "0px 0px 20px 15px",
    width: "12%",
  },
  actionNeededByHeader: {
    verticalAlign: "bottom",
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "center",
    letterSpacing: "1.70px",
    padding: "0px 0px 20px 9px",
    width: "10%",
  },
  statusHeader: {
    verticalAlign: "bottom",
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "center",
    letterSpacing: "1.88px",
    padding: "0px 0px 20px 9px",
    width: "10%",
  },
  convertHeader: {
    verticalAlign: "bottom",
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "center",
    letterSpacing: "1.88px",
    padding: "0px 0px 20px 0px",
    width: "10%",
  },
  specsHeader: {
    verticalAlign: "bottom",
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "left",
    letterSpacing: "1.88px",
    padding: "0 0 20px 15px",
    width: "19%",
  },
  scheduledDeliveryHeader: {
    verticalAlign: "bottom",
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "left",
    letterSpacing: "1.88px",
    padding: "0 0 20px 15px",
    width: "19%",
  },
  specsHeaderWide: {
    verticalAlign: "bottom",
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "left",
    letterSpacing: "1.88px",
    padding: "0 0 20px 15px",
    width: "32%",
  },
  specsHeaderORA: {
    verticalAlign: "bottom",
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "left",
    letterSpacing: "1.88px",
    padding: "0 0 20px 15px",
    width: "19%",
  },
  actionReqHeader: {
    verticalAlign: "bottom",
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "center",
    letterSpacing: "1.88px",
    padding: "0px 0px 20px 0px",
    width: "10%",
  },
  detailsHeader: {
    verticalAlign: "bottom",
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "center",
    letterSpacing: "1.88px",
    padding: "0px 0px 20px 0px",
    width: "14%",
  },
  feeHeader: {
    verticalAlign: "bottom",
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "center",
    letterSpacing: "1.88px",
    padding: "0px 0px 20px 0px",
    width: "13%",
  },
  orderType: {
    textAlign: "left",
  },
  archiveOrderType: {
    textAlign: "left",
    paddingLeft: 50,
    paddingRight: 0,
    color: "#ed8231",
    fontFamily: "FordAntennaCondRegular, sans-serif",
    fontSize: "12px",
    letterSpacing: "0.86px",
  },
  archiveReservationType: {
    textAlign: "left",
    paddingLeft: 50,
    paddingRight: 0,
    color: "#4a90e2",
    fontFamily: "FordAntennaCondRegular, sans-serif",
    fontSize: "12px",
    letterSpacing: "0.86px",
  },
  archivePurchaseRequestType: {
    textAlign: "left",
    paddingLeft: 50,
    paddingRight: 0,
    color: "#1700f4",
    fontFamily: "FordAntennaCondRegular, sans-serif",
    fontSize: "12px",
    letterSpacing: "0.86px",
  },
  purchaseRequestType: {
    textAlign: "left",
    paddingLeft: 50,
    paddingRight: 0,
  },
  deposit: {
    fontFamily: "FordAntennaCondRegular, sans-serif",
    fontSize: "12px",
    padding: 15,
    textAlign: "center",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1px",
    borderLeft: "1px solid #989898",
  },
  actionRequired: {
    fontFamily: "FordAntennaRegular, sans-serif",
    fontSize: "12px",
    padding: 15,
    textAlign: "center",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.86px",
  },
  date: {
    fontFamily: "FordAntennaCondRegular, sans-serif",
    fontSize: "12px",
    padding: 0,
    textAlign: "center",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1px",
  },
  customerName: {
    fontFamily: "FordAntennaCondRegular, sans-serif",
    fontSize: "12px",
    textAlign: "left",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1px",
    paddingLeft: 15,
    paddingRight: 15,
  },
  blankDash: {
    fontFamily: "FordAntennaCondRegular, sans-serif",
    fontSize: "12px",
    textAlign: "center",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1px",
    paddingLeft: 0,
    paddingRight: 56,
  },
  datePlaced: {
    fontFamily: "FordAntennaCondRegular, sans-serif",
    fontSize: "12px",
    padding: 15,
    textAlign: "center",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1px",
  },
  endDate: {
    fontFamily: "FordAntennaCondRegular, sans-serif",
    fontSize: "12px",
    padding: 15,
    textAlign: "center",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1px",
  },
  endDateElapsed: {
    fontFamily: "FordAntennaCondRegular, sans-serif",
    fontSize: "12px",
    padding: 15,
    textAlign: "center",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1px",
    color: "rgb(165, 20, 38)",
  },
  specs: {
    fontFamily: "FordAntennaCondRegular, sans-serif",
    textAlign: "left",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "1px",
    padding: 15,
  },
  actionsRequiredHeader: {
    verticalAlign: "bottom",
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "center",
    letterSpacing: "1.88px",
    padding: "0px 0px 20px 9px",
    width: "13%",
  },
  purchaseDetailsHeader: {
    verticalAlign: "bottom",
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "left",
    letterSpacing: "1.88px",
    padding: "0px 0px 20px 0px",
    width: "14%",
  },
  depositHeader: {
    verticalAlign: "bottom",
    fontFamily: "FordAntennaCondBold",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "center",
    letterSpacing: "1.88px",
    padding: "0px 0px 20px 0px",
    width: "10%",
  },

  specsSubmitted: {
    fontFamily: "FordAntennaCondRegular, sans-serif",
    padding: 15,
    textAlign: "left",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "1px",
  },
  specsORA: {
    fontFamily: "FordAntennaCondRegular, sans-serif",
    padding: 15,
    fontSize: "12px",
    textAlign: "left",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1px",
  },
  accepted: {
    backgroundColor: "#f2fff7",
    padding: 10,
    fontFamily: "FordAntennaSemiBold, sans-serif",
    fontSize: "10px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.7px",
    textAlign: "center",
    color: "#00ad46",
  },
  rejected: {
    backgroundColor: "#fff2f2",
    padding: 15,
    fontFamily: "FordAntennaSemiBold, sans-serif",
    fontSize: "10px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.7px",
    textAlign: "center",
    color: "#a51426",
  },
  created: {
    backgroundColor: "#f7f7f7",
    padding: 15,
    fontFamily: "FordAntennaSemiBold, sans-serif",
    fontSize: "10px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.7px",
    textAlign: "center",
    color: "#4a4a4a",
  },
  consentPending: {
    backgroundColor: "#f7f7f7",
    padding: 8,
    fontFamily: "FordAntennaSemiBold, sans-serif",
    fontSize: "8px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.7px",
    textAlign: "center",
    color: "#4a4a4a",
  },
  submitted: {
    backgroundColor: "#f7f7f7",
    padding: 15,
    fontFamily: "FordAntennaSemiBold, sans-serif",
    fontSize: "10px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.7px",
    textAlign: "center",
    color: "#4a4a4a",
  },
  pending: {
    backgroundColor: "#ffffff",
    padding: 10,
    fontFamily: "FordAntennaSemiBold, sans-serif",
    fontSize: "10px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.7px",
    textAlign: "center",
    color: "#ed8231",
  },
  status: {
    padding: 0,
    textAlign: "center",
    borderLeft: "1px solid #989898",
  },
  convert: {
    padding: 0,
    textAlign: "center",
    borderLeft: "1px solid #989898",
  },
  nullConvert: {
    padding: 0,
    textAlign: "center",
    borderRight: "1px solid #989898",
  },
  fee: {
    padding: 15,
    textAlign: "center",
  },
  details: {
    fontFamily: "FordAntennaCondBold, sans-serif",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "center",
    letterSpacing: "1.88px",
    textDecoration: "underline",
    borderLeft: "1px solid #989898",
  },
  archiveDetails: {
    fontFamily: "FordAntennaCondBold, sans-serif",
    fontSize: 10,
    fontWeight: "bold",
    color: "#102b4e",
    textAlign: "center",
    letterSpacing: "1.88px",
    textDecoration: "underline",
  },
  dialog: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  archiveSold: {
    padding: 15,
    fontFamily: "FordAntennaSemiBold, sans-serif",
    fontSize: "10px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.7px",
    textAlign: "center",
    color: "#00ad46",
  },
  archiveRejected: {
    padding: 15,
    fontFamily: "FordAntennaSemiBold, sans-serif",
    fontSize: "10px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.7px",
    textAlign: "center",
    color: "#a51426",
  },
  archiveSubmitted: {
    padding: 15,
    fontFamily: "FordAntennaSemiBold, sans-serif",
    fontSize: "10px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.7px",
    textAlign: "center",
    color: "#4a4a4a",
  },
};

export default stylesOverride;
