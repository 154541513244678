import PNG from "../imgs/FordOval_Logo-trimmed.png";
import React from "react";

const FordLogo = (props) => {
  return (
    <img
      data-testid="ford-logo"
      className={props.className}
      alt="FORD"
      src={PNG}
      width={props.width}
      height={props.height}
    />
  );
};

export default FordLogo;
