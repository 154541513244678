const stylesOverride = {
  typeDropDown: {
    height: 38,
    width: 300,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "FordAntenna-Regular, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "#102b4e",
  },
  dealershipDropDown: {
    height: 38,
    width: 300,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "FordAntenna-Regular, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "#102b4e",
  },
  dealershipDropDownDisabled: {
    height: 38,
    width: 300,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "FordAntenna-Regular, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "grey",
    borderColor: "grey",
  },
  marketAreaRegionDropDown: {
    height: 38,
    width: 195,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "FordAntenna-Regular, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "#102b4e",
  },
  marketAreaRegionDropDownDisabled: {
    height: 38,
    width: 195,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "FordAntenna-Regular, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "grey",
    borderColor: "grey",
  },
  zoneDropDown: {
    height: 38,
    width: 195,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "FordAntenna-Regular, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "#102b4e",
  },
  zoneDropDownDisabled: {
    height: 38,
    width: 195,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "FordAntenna-Regular, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "grey",
    borderColor: "grey",
  },
  autoDateDropDown: {
    height: 38,
    width: 200,
    textAlign: "left",
    borderRadius: 3,
    border: "solid 1px #4d4d4d",
    backgroundColor: "white",
    fontFamily: "FordAntenna-Regular, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "#102b4e",
  },
  icon: {
    top: "calc(50% - 12px)",
    right: 0,
    color: "#102b4e",
    position: "absolute",
    paddingRight: 7,
    cursor: "pointer",
    pointerEvents: "none",
  },
  item: {
    fontFamily: "FordAntenna-Regular, sans-serif",
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "#102b4e",
  },
  reportLocationGridItem: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
  },
  dealershipGridItem: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    fontFamily: "FordAntennaCond-Regular, sans-serif",
    fontSize: "13px",
    letterSpacing: 1,
    fontWeight: ".5",
    color: "#383838",
  },
  runReportGridItem: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    fontFamily: "FordAntennaCond-Regular, sans-serif",
    fontSize: "13px",
    letterSpacing: 1,
    fontWeight: ".5",
    color: "#383838",
  },
  gridItem: {
    textAlign: "left",
    fontFamily: "FordAntennaCond-Medium, sans-serif",
    fontSize: "13px",
    color: "#4a4a4a",
  },
};

export default stylesOverride;
