import axios from "axios";
import { BASE_URL } from "../utils/EmpUtil";
import { getHeaders } from "../utils/AuthorizationUtil";

export default class DeliveryClient {
  static baseUrl = BASE_URL;

  static async getEmployees(token, dealerId) {
    const url = `${this.baseUrl}/delivery/${dealerId}/employees`;

    return await axios
      .get(url, getHeaders(token))
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static updateAppointment(token, dealershipCode, appointment, formData) {
    const url = `${this.baseUrl}/dealer/deliverytool/update-appointment`;
    const { id, date } = appointment;
    const { specialist, newDate } = formData;
    const dateToSend = newDate ? newDate : date;
    const dealershipEmployee = specialist.hasOwnProperty("userName")
      ? specialist["userName"]
      : specialist["dealershipEmployee"];

    return axios.put(
      url,
      {
        dealershipEmployee,
        id,
        date: dateToSend,
        dealershipCode,
      },
      getHeaders(token)
    );
  }

  static getPreDeliveryTasks(token, paCode) {
    const url = `${this.baseUrl}/dealer/predelivery-tasks?paCode=${paCode}`;

    return axios.get(url, getHeaders(token));
  }

  static addPreDeliveryTask(token, paCode, payload) {
    const url = `${this.baseUrl}/dealer/predelivery-tasks/save?paCode=${paCode}`;
    const { taskType, taskName, taskDescription, taskUrl } = payload;

    return axios.post(
      url,
      {
        paCode,
        taskType,
        taskName,
        taskDescription,
        taskUrl,
      },
      getHeaders(token)
    );
  }

  static updatePreDeliveryTask(token, paCode, payload) {
    const url = `${this.baseUrl}/dealer/predelivery-tasks/update?paCode=${paCode}`;
    const { id, taskType, taskName, taskDescription, taskUrl } = payload;

    return axios.put(
      url,
      {
        id,
        paCode,
        taskType,
        taskName,
        taskDescription,
        taskUrl,
      },
      getHeaders(token)
    );
  }

  static removePreDeliveryTask(token, { id }) {
    const url = `${this.baseUrl}/dealer/predelivery-tasks/delete/${id}`;
    return axios.delete(url, getHeaders(token));
  }
}
