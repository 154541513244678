import { ReactComponent as OverflowComponent } from "../../../../imgs/overflow-menu.svg";
import React, { Component } from "react";
import fordStyles from "./DealerNotes.module.scss";
import lincolnStyles from "./DealerNotes-Lincoln.module.scss";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import { withTranslation } from "react-i18next";
import DealerNotesClient from "../../../../clients/DealerNotesClient";
import EMPButton from "../../../../shared/components/EMPButton/EMPButton";
import EMPTextAreaField from "../../../../shared/components/EMPTextAreaField/EMPTextAreaField";
import { Box, Grid } from "@material-ui/core";

class DealerNote extends Component {
  constructor(props) {
    super(props);
    this.MAX_CHARACTERS = 250;
    this.state = {
      showReplyTextarea: false,
      newReplyContent: "",
    };
  }

  handleReplyChange = (event) => {
    this.setState({ newReplyContent: event.target.value });
  };

  toggleReplyTextBox = () => {
    this.setState({
      showReplyTextarea: !this.state.showReplyTextarea,
    });
  };

  addReply = async (noteId) => {
    if (!this.state.newReplyContent) return;

    const requestBody = {
      dealerNotes: this.state.newReplyContent,
      orderId: this.props.orderId,
      userId: this.props.user.userId,
      replyTo: noteId,
    };

    try {
      const result = await DealerNotesClient.saveDealerNotes(
        this.props.user.token,
        this.props.user.getEncodedCommonId(),
        requestBody
      );
      this.setState({ newReplyContent: "" });
      if (result)
        this.props.setDealerNotes(result.dealerNotes, result.pagination);

      if (this.props.openViewAllDialog) {
        const viewAllResult = await DealerNotesClient.getDealerNotes(
          this.props.user.token,
          this.props.orderId,
          this.props.pagination.currentPage,
          this.props.pageSize
        );

        if (result)
          this.props.setDealerNotesForViewAll(
            viewAllResult.dealerNotes,
            viewAllResult.pagination
          );
      }
    } catch (e) {
      this.props.setErrorStatus(true);
    } finally {
      this.toggleReplyTextBox();
    }
  };

  cancelReply = (noteId) => {
    this.setState({ newReplyContent: "" });
    this.toggleReplyTextBox(noteId);
  };

  getReplies = (replies) => {
    if (!replies) return [];

    replies.sort((a, b) => new Date(a.date) - new Date(b.date));

    return replies;
  };

  render() {
    const { note, replies, isFordBrand, popperId, toggleOverflowMenu } =
      this.props;
    const dealerNotesStyles = isFordBrand ? fordStyles : lincolnStyles;

    return (
      <div
        className={dealerNotesStyles.dealerNote}
        key={note.id}
        data-testid="dealerNoteDiv"
      >
        <div className={dealerNotesStyles.dealerNoteHead}>
          <Grid container alignItems="center">
            <Grid item>
              <span className={dealerNotesStyles.username}>{note.userId}</span>
            </Grid>
            <Grid item>
              <span className={dealerNotesStyles.timestamp}>{note.date}</span>
              {note.modifiedFlag && (
                <span className={dealerNotesStyles.timestamp}>
                  ({this.props.t("DealerNotes.edited")})
                </span>
              )}
            </Grid>
          </Grid>
          <HasPermissionTo
            perform={["dealerNotesSubmit"]}
            permissions={this.props.user.permissions.rolePermissions}
            condition={note.userId === this.props.user.userId}
            render={() => {
              return (
                <div className={dealerNotesStyles.buttonRow}>
                  <button
                    data-testid="dealerNoteOverflowButton"
                    aria-describedby={popperId}
                    className={dealerNotesStyles.overflowButton}
                    onClick={(event) => toggleOverflowMenu(note, event)}
                  >
                    <OverflowComponent />
                  </button>
                </div>
              );
            }}
          />
        </div>
        <div className={dealerNotesStyles.content}>{note.dealerNotes}</div>

        {this.getReplies(replies).map((reply) => (
          <div
            className={dealerNotesStyles.replyNote}
            key={reply.id}
            data-testid="dealerNoteReplyDiv"
          >
            <div className={dealerNotesStyles.dealerNoteHead}>
              <div className={dealerNotesStyles.userInfo}>
                <span className={dealerNotesStyles.username}>
                  {reply.userId}
                </span>
                <span className={dealerNotesStyles.timestamp}>
                  {reply.date}
                </span>
                {reply.modifiedFlag && (
                  <span className={dealerNotesStyles.timestamp}>
                    ({this.props.t("DealerNotes.edited")})
                  </span>
                )}
              </div>
              <HasPermissionTo
                perform={["dealerNotesSubmit"]}
                permissions={this.props.user.permissions.rolePermissions}
                condition={reply.userId === this.props.user.userId}
                render={() => {
                  return (
                    <div className={dealerNotesStyles.buttonRow}>
                      <button
                        data-testid="dealerNoteOverflowButton"
                        aria-describedby={popperId}
                        className={dealerNotesStyles.overflowButton}
                        onClick={(event) => toggleOverflowMenu(reply, event)}
                      >
                        <OverflowComponent />
                      </button>
                    </div>
                  );
                }}
              />
            </div>
            <div className={dealerNotesStyles.content}>{reply.dealerNotes}</div>
          </div>
        ))}

        <HasPermissionTo
          perform={["dealerNotesSubmit"]}
          permissions={this.props.user.permissions.rolePermissions}
          render={() =>
            !this.state.showReplyTextarea && (
              <Grid container>
                <Grid item xs={12} sm={4} md={4}>
                  <Box mt={2}>
                    <EMPButton
                      onClick={() => this.toggleReplyTextBox(note.id)}
                      buttonType="outlined"
                      buttonText={this.props.t("DealerNotes.reply")}
                    ></EMPButton>
                  </Box>
                </Grid>
              </Grid>
            )
          }
        />

        <HasPermissionTo
          perform={["dealerNotesSubmit"]}
          permissions={this.props.user.permissions.rolePermissions}
          condition={this.state.showReplyTextarea}
          render={() => {
            return (
              <>
                <div className={dealerNotesStyles.textAreaContainer}>
                  <EMPTextAreaField
                    hasCharacterCount
                    value={this.state.newReplyContent}
                    onChange={this.handleReplyChange}
                    minRows={4}
                    placeholder="Add reply"
                    maxCharacters={this.MAX_CHARACTERS}
                  ></EMPTextAreaField>
                </div>
                <div className={dealerNotesStyles.saveButtonRow}>
                  <div className={dealerNotesStyles.buttonRow}>
                    <Box mr={1}>
                      <EMPButton
                        onClick={() => this.cancelReply(note.id)}
                        buttonText={this.props.t("DealerNotes.cancel")}
                        buttonType="text"
                      ></EMPButton>
                    </Box>

                    <EMPButton
                      onClick={() => this.addReply(note.id)}
                      buttonText={this.props.t("DealerNotes.submit")}
                      buttonType="contained"
                    ></EMPButton>
                  </div>
                </div>
              </>
            );
          }}
        />
      </div>
    );
  }
}

export default withTranslation("emp")(DealerNote);
