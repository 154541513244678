import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { withTranslation } from "react-i18next";
import {
  StyledExpansionPanel,
  StyledExpansionPanelSummary,
} from "../../../../../shared/ExpansionPanels/ExpansionPanel";
import HasPermissionTo from "../../../../../common/HasPermissionTo";
import { InfoDetail } from "../../../orderDetails/InfoDetail";
import {
  DEALER_ACTIONS,
  PENDING_CREDIT_APP,
  PURCHASE_REQUEST_STATUS,
} from "../../../../../common/Constants";
import UpperCaseText from "../../../../../utils/UpperCaseText";
import VehicleHoldReleaseDialog from "./releaseDialog/VehicleHoldReleaseDialog";
import CustomerReservationsClient from "../../../../../clients/CustomerReservationsClient";
import {
  displayError,
  ERROR_PROCESSING,
} from "../../../../../errors/ErrorConstants";
import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import applicationStatus from "../../../../../utils/GetApplicationStatus";

import { IS_FORD_BRAND } from "../../../../../utils/EmpUtil";
import vhStylesFord from "./VehicleHoldStyles";
import vhStylesLincoln from "./VehicleHoldStylesLincoln";
import fordStyles from "./VehicleHold.module.scss";
import lincolnStyles from "./VehicleHoldLincoln.module.scss";
import DataCard from "../../../../../shared/components/DataCard/DataCard";

const StyledTooltip = withStyles({
  popper: {
    opacity: 1,
    pointerEvents: "auto",
  },
  tooltip: {
    background: "white",
    boxShadow: "1px 1px 9px 0 rgba(0, 0, 0, 0.27)",
    paddingTop: 28,
    paddingLeft: 28,
    paddingRight: 28,
    paddingBottom: 18,
    maxWidth: 350,
  },
})(Tooltip);

class VehicleHold extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleHoldExpansion: false,
      openReleaseDialog: false,
      hasError: [],
    };
  }

  componentDidMount() {
    this.setState({ vehicleHoldExpansion: this.props.expandAccordian });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({
        vehicleHoldExpansion: this.props.expandAccordian,
      });
    }
  }

  toggleReleaseDialog = (e) => {
    e.stopPropagation();
    this.setState({ openReleaseDialog: !this.state.openReleaseDialog });
  };

  flipIcon() {
    this.setState({
      vehicleHoldExpansion: !this.state.vehicleHoldExpansion,
    });
  }

  releaseVehicleHold = () => {
    const releaseVehicleHoldRequest = {
      reservationId: this.props.customerReservation.id,
      dealerAction: DEALER_ACTIONS.VEHICLE_HOLD_RELEASE,
    };
    this.props.hideOrShow(true);
    CustomerReservationsClient.updateOrder(
      this.props.token,
      this.props.customerReservation.dealerId,
      releaseVehicleHoldRequest,
      this.props.user.userId
    )
      .catch(() => {
        this.setState({ hasError: [ERROR_PROCESSING] });
      })
      .finally(() => {
        this.setState({ openReleaseDialog: false });
        this.props.refreshReservationDetailsWithNotifications();
        this.props.hideOrShow(false);
      });
  };

  isPurchaseRequestCancelled = () => {
    const purchaseInfo = this.props.customerReservation.purchaseInfo;
    return (
      purchaseInfo &&
      purchaseInfo.acceptRejectStatus &&
      PURCHASE_REQUEST_STATUS.CANCELED ===
        UpperCaseText(purchaseInfo.acceptRejectStatus)
    );
  };

  isPurchaseRequestAccepted = () => {
    const purchaseInfo = this.props.customerReservation.purchaseInfo;
    return (
      purchaseInfo &&
      purchaseInfo.acceptRejectStatus &&
      PURCHASE_REQUEST_STATUS.ACCEPTED ===
        UpperCaseText(purchaseInfo.acceptRejectStatus)
    );
  };

  isPendingCreditApplication = () => {
    const paymentDescription =
      this.props.customerReservation.paymentDescription;
    return (
      paymentDescription &&
      applicationStatus(paymentDescription) === PENDING_CREDIT_APP
    );
  };

  render() {
    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    const vhStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? vhStylesFord
        : vhStylesLincoln;

    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    const { vehicleHoldData } = this.props.customerReservation;
    return (
      <div>
        <StyledExpansionPanel
          onChange={() => this.flipIcon()}
          expanded={this.state.vehicleHoldExpansion}
        >
          <StyledExpansionPanelSummary
            style={vhStyles.headerBarBg}
            data-testid="vehicleHold"
          >
            <div className={styles.headerBar}>
              <div className={styles.header}>
                {this.props.t("OrderDetails.vehicleHold.title")}
              </div>
              <div>
                {this.state.vehicleHoldExpansion ? (
                  <ExpandLessIcon data-testid="lessConsumerDeposit" />
                ) : (
                  <ExpandMoreIcon data-testid="moreConsumerDeposit" />
                )}
              </div>
            </div>
          </StyledExpansionPanelSummary>
          <div className={styles.vehicleHoldGrid}>
            <Grid container spacing={2}>
              <Grid item className={styles.cardGridItemWrapper}>
                <DataCard
                  cardHeader={this.props.t("OrderDetails.vehicleHold.title")}
                  headerDetail={
                    <HasPermissionTo
                      perform={["releaseHold:write"]}
                      permissions={this.props.user.permissions.rolePermissions}
                      condition={!vehicleHoldData.holdReleaseDate}
                      render={() => {
                        return this.isPurchaseRequestAccepted() ||
                          this.isPendingCreditApplication() ? (
                          <button
                            className={styles.releaseHold}
                            onClick={this.toggleReleaseDialog}
                            data-testid="release"
                            id="release"
                          >
                            {this.props.t("OrderDetails.vehicleHold.release")}
                          </button>
                        ) : (
                          <StyledTooltip
                            placement="bottom-start"
                            title={
                              <div>
                                <div className={styles.bodyContent}>
                                  {this.props.t(
                                    "OrderDetails.vehicleHold.toolTip"
                                  )}
                                </div>
                              </div>
                            }
                          >
                            <button
                              className={styles.releaseHoldDisabled}
                              id="release"
                              data-testid="release"
                            >
                              {this.props.t("OrderDetails.vehicleHold.release")}
                            </button>
                          </StyledTooltip>
                        );
                      }}
                    />
                  }
                >
                  <InfoDetail
                    isFordBrand={
                      this.props.user && IS_FORD_BRAND(this.props.user.brand)
                    }
                    header={this.props.t("OrderDetails.vehicleHold.holdStatus")}
                    value={
                      vehicleHoldData.holdReleaseDate
                        ? this.props.t("OrderDetails.vehicleHold.released")
                        : this.props.t("OrderDetails.vehicleHold.onHold")
                    }
                  />

                  <InfoDetail
                    isFordBrand={
                      this.props.user && IS_FORD_BRAND(this.props.user.brand)
                    }
                    header={this.props.t("OrderDetails.vehicleHold.startDate")}
                    value={vehicleHoldData.holdStartDate}
                  />

                  <InfoDetail
                    isFordBrand={
                      this.props.user && IS_FORD_BRAND(this.props.user.brand)
                    }
                    header={this.props.t("OrderDetails.vehicleHold.releasedOn")}
                    value={vehicleHoldData.holdReleaseDate}
                  />

                  <InfoDetail
                    isFordBrand={
                      this.props.user && IS_FORD_BRAND(this.props.user.brand)
                    }
                    header={this.props.t(
                      "OrderDetails.vehicleHold.dealerUserId"
                    )}
                    value={
                      vehicleHoldData.dealerActionDetails
                        ? vehicleHoldData.dealerActionDetails.dealerActionUserId
                        : this.isPurchaseRequestCancelled() &&
                          this.props.customerReservation.purchaseInfo
                            .purchaseRequestDealerAction &&
                          this.props.customerReservation.purchaseInfo
                            .purchaseRequestDealerAction.dealerActionUserId
                    }
                  />
                </DataCard>
              </Grid>
            </Grid>
          </div>
        </StyledExpansionPanel>
        {this.state.openReleaseDialog && (
          <VehicleHoldReleaseDialog
            {...this.props}
            open={this.state.openReleaseDialog}
            onClose={this.toggleReleaseDialog}
            onReleaseHold={this.releaseVehicleHold}
          />
        )}
      </div>
    );
  }
}

export default withTranslation("emp")(VehicleHold);
