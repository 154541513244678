import React, { useState, useEffect } from "react";
import styles from "./NAOrderDetailsNewVersion.module.scss";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CustomerReservationsClient from "../../../clients/CustomerReservationsClient";
import {
  ACCEPTED,
  APPROVED,
  CANCELED,
  CANCELLED,
  CONDITIONED_CREDIT_APP,
  DEALER_ACTIONS,
  DENIED_CREDIT_APP,
  DENIED_CREDIT_APP_ERROR,
  PENDING_CREDIT_APP,
  PURCHASE_REQUEST_STATUS,
  PURCHASE_TYPES,
} from "../../../common/Constants";
import UpperCaseText from "../../../utils/UpperCaseText";
import HasPermissionTo from "../../../common/HasPermissionTo";
import { withTranslation } from "react-i18next";
import {
  ACCEPT_ELIGIBLE_PURCHASE_TYPES,
  CANCELLED_STATUSES,
  IS_FORD_BRAND,
} from "../../../utils/EmpUtil";
import { StyledBasicExpansionPanel } from "../../../shared/ExpansionPanels/ExpansionPanel";
import applicationStatus from "../../../utils/GetApplicationStatus";
import { InfoDetail } from "./InfoDetail";
import { AccordionSummary, withStyles, Grid } from "@material-ui/core";
import SharedToolTip from "../../../shared/sharedToolTip/SharedToolTip";
import CancelOrderDialog from "../CancelOrderDialog";
import ConfirmDialog from "../../../shared/confirmDialog/ConfirmDialog";
import DataCard from "../../../shared/components/DataCard/DataCard";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import { useQuery, useMutation } from "@tanstack/react-query";
import EMPCheckbox from "../../../shared/components/EMPCheckbox/EMPCheckbox";
import * as AnalyticsService from "../../../shared/services/analytics/AnalyticsService";

const StyledAccordionSummary = withStyles({
  root: {
    fontFamily: "FordAntennaRegular",
    fontSize: 24,
    paddingLeft: 24,
    paddingRight: 24,
    color: "#1C1C1E",
  },
  content: {
    marginTop: 16,
    marginBottom: 16,
  },
})(AccordionSummary);

const PurchaseInformation = ({
  customerReservation,
  user,
  t,
  refreshReservationDetailsWithNotifications,
  expandAccordian,
}) => {
  const [expandPurchaseInfo, setExpandPurchaseInfo] = useState(false);
  const [openConfirmDialogue, setOpenConfirmDialogue] = useState(false);
  const [appointmentAccepted, setAppointmentAccepted] = useState(false);
  const [openAcceptPurchaseDialog, setOpenAcceptPurchaseDialog] =
    useState(false);
  const [openCancelPurchaseDialog, setOpenCancelPurchaseDialog] =
    useState(false);

  useEffect(() => {
    if (expandAccordian !== expandPurchaseInfo) {
      setExpandPurchaseInfo(expandAccordian);
    }
  }, [expandAccordian]);

  const shouldFetchAppointmentStatus =
    customerReservation &&
    customerReservation.purchaseInfo &&
    customerReservation.purchaseInfo.preferredAppointmentDate &&
    customerReservation.purchaseInfo.preferredAppointmentDate !== "null" &&
    customerReservation.purchaseInfo.preferredAppointmentSlot &&
    customerReservation.purchaseInfo.preferredAppointmentSlot !== "null";

  const { isFetching, isError, error } = useQuery({
    queryKey: ["appointmentStatus"],
    queryFn: async () => {
      const { dealerAction } =
        await CustomerReservationsClient.getAppointmentStatus(
          user.token,
          customerReservation.dealerId,
          customerReservation.id
        );

      return dealerAction === DEALER_ACTIONS.APPOINTMENT_FULFILLED
        ? true
        : false;
    },
    enabled: !!shouldFetchAppointmentStatus,
    onSuccess: (status) => setAppointmentAccepted(status),
  });

  const submitOrderAction = useMutation({
    mutationFn: async (actionData) =>
      await CustomerReservationsClient.actionOnOrder(
        user.token,
        customerReservation.dealerId,
        actionData,
        user.userId
      ),
    retry: 3,
    onSuccess: () => {
      setOpenAcceptPurchaseDialog(false);
      setOpenCancelPurchaseDialog(false);
      refreshReservationDetailsWithNotifications();
    },
    // eslint-disable-next-line no-console
    onError: (error) => console.log(error),
  });

  const submitAppointmentAction = useMutation({
    mutationFn: async (actionData) =>
      await CustomerReservationsClient.actionOnOrder(
        user.token,
        customerReservation.dealerId,
        actionData,
        user.userId
      ),
    onSuccess: () => {
      setAppointmentAccepted(!appointmentAccepted);
      setOpenConfirmDialogue(false);
    },
    // eslint-disable-next-line no-console
    onError: (error) => console.log(error),
  });

  const getAcceptPurchaseDialogProps = () => {
    return {
      title: t("PurchaseDetails.acceptPurchaseDialogText.title"),
      body: t("PurchaseDetails.acceptPurchaseDialogText.body"),
      checkbox: t("PurchaseDetails.acceptPurchaseDialogText.checkbox"),
      checkboxList: [
        t("PurchaseDetails.acceptPurchaseDialogText.checkboxList0"),
        t("PurchaseDetails.acceptPurchaseDialogText.checkboxList1"),
        t("PurchaseDetails.acceptPurchaseDialogText.checkboxList2"),
        t("PurchaseDetails.acceptPurchaseDialogText.checkboxList3"),
      ],
      confirm: t("PurchaseDetails.acceptPurchaseDialogText.confirm"),
      cancel: t("PurchaseDetails.acceptPurchaseDialogText.cancel"),
    };
  };

  const getCancelPurchaseDialogProps = () => {
    return {
      title: t("PurchaseDetails.cancelPurchaseDialogText.title"),
      body: t("PurchaseDetails.cancelPurchaseDialogText.body"),
      checkbox: t("PurchaseDetails.cancelPurchaseDialogText.checkbox"),
      checkboxList: [
        t("PurchaseDetails.cancelPurchaseDialogText.checkboxList1"),
        t("PurchaseDetails.cancelPurchaseDialogText.checkboxList2"),
        t("PurchaseDetails.cancelPurchaseDialogText.checkboxList3"),
        t("PurchaseDetails.cancelPurchaseDialogText.checkboxList4"),
      ],
      confirm: t("PurchaseDetails.cancelPurchaseDialogText.confirm"),
      cancel: t("PurchaseDetails.cancelPurchaseDialogText.cancel"),
    };
  };

  const isAcceptPurchaseEligible = (customerReservation) => {
    const soldOrCancelledPast30Days =
      customerReservation &&
      customerReservation.vehicleSoldOrCancelledPast30Days;
    const isEligiblePurchaseType =
      customerReservation.purchaseInfo &&
      customerReservation.purchaseInfo.purchaseType &&
      ACCEPT_ELIGIBLE_PURCHASE_TYPES.includes(
        UpperCaseText(customerReservation.purchaseInfo.purchaseType)
      );
    const isDeniedCreditApp =
      customerReservation.paymentDescription &&
      (applicationStatus(customerReservation.paymentDescription) ===
        DENIED_CREDIT_APP ||
        applicationStatus(customerReservation.paymentDescription) ===
          DENIED_CREDIT_APP_ERROR);
    const isAbandonedCheckout =
      customerReservation.paymentDescription &&
      applicationStatus(customerReservation.paymentDescription) === APPROVED &&
      customerReservation.purchaseInfo.purchaseType === null;
    const isConditionedCreditApp =
      customerReservation.paymentDescription &&
      applicationStatus(customerReservation.paymentDescription) ===
        CONDITIONED_CREDIT_APP;
    const isEligiblePurchaseRequestStatus =
      customerReservation.purchaseInfo.acceptRejectStatus &&
      (PURCHASE_REQUEST_STATUS.CREATED ===
        UpperCaseText(customerReservation.purchaseInfo.acceptRejectStatus) ||
        PURCHASE_REQUEST_STATUS.SOLD ===
          UpperCaseText(customerReservation.purchaseInfo.acceptRejectStatus));
    const isNotPendingCreditApp =
      customerReservation.paymentDescription &&
      applicationStatus(customerReservation.paymentDescription) !==
        PENDING_CREDIT_APP;
    const isPurchaseRequestDealerActionAccepted =
      customerReservation.purchaseInfo &&
      customerReservation.purchaseInfo.purchaseRequestDealerAction &&
      customerReservation.purchaseInfo.purchaseRequestDealerAction
        .dealerAction === "purchaserequestaccepted";

    return (
      (isDeniedCreditApp ||
        isAbandonedCheckout ||
        isEligiblePurchaseType ||
        isConditionedCreditApp) &&
      isEligiblePurchaseRequestStatus &&
      isNotPendingCreditApp &&
      !isPurchaseRequestDealerActionAccepted &&
      !soldOrCancelledPast30Days
    );
  };

  const isCancelPurchaseEligible = (customerReservation) => {
    const soldOrCancelledPast30Days =
      customerReservation &&
      customerReservation.vehicleSoldOrCancelledPast30Days;
    return (
      !soldOrCancelledPast30Days &&
      customerReservation.purchaseInfo &&
      customerReservation.purchaseInfo.acceptRejectStatus &&
      PURCHASE_REQUEST_STATUS.ACCEPTED ===
        UpperCaseText(customerReservation.purchaseInfo.acceptRejectStatus) &&
      customerReservation.paymentDescription &&
      applicationStatus(customerReservation.paymentDescription) !==
        PENDING_CREDIT_APP
    );
  };

  function getStatus(customerReservation) {
    if (CANCELLED_STATUSES.includes(customerReservation.status)) {
      return customerReservation.status === "RESERVATION_CANCELLED"
        ? t("OrderDetails.businessStatusCustomer")
        : t("OrderDetails.businessStatusDealer");
    } else {
      return customerReservation.businessStatus;
    }
  }

  const openCancelPurchaseRequestDialog = (customerReservation) => {
    AnalyticsService.trackPageLoadDetailEvent(
      "fv:emp:customer handling:purchase request details:accept purchase:cancel popup",
      "emp:purchase request:order id",
      customerReservation.id,
      user
    );

    setOpenCancelPurchaseDialog(true);
  };

  const closeCancelPurchaseRequestDialog = () => {
    AnalyticsService.trackOnClickDetailEvent(
      "fv:emp:customer handling:purchase request details:accept purchase:cancel popup",
      `emp:purchase request:order id:${customerReservation.id}`,
      "emp:purchase request:close button selected",
      customerReservation.id,
      "emp:customer handling:purchase request details:accept purchase:cancel popup:content:close",
      "fv:emp:customer handling:purchase request details:accept purchase:cancel popup:content action",
      user
    );

    setOpenCancelPurchaseDialog(false);
  };

  const confirmCancelPurchaseRequest = (cancelReason) => {
    AnalyticsService.trackOnClickDetailEvent(
      "fv:emp:customer handling:purchase request details:accept purchase:cancel popup",
      `emp:purchase request:order id:${customerReservation.id}`,
      `emp:purchase request:cancel reason:${cancelReason}`,
      customerReservation.id,
      "emp:customer handling:purchase request details:accept purchase:cancel popup:content:cancel",
      "fv:emp:customer handling:purchase request details:accept purchase:cancel popup:content action",
      user
    );
    submitOrderAction.mutate({
      reservationId: customerReservation.id,
      acceptedDate: customerReservation.todayDate,
      dealerAction: DEALER_ACTIONS.PURCHASE_REQUEST_CANCELLED,
      dealerRejectedReason: cancelReason,
    });
  };

  const openAcceptPurchaseRequestDialog = (customerReservation) => {
    AnalyticsService.trackPageLoadDetailEvent(
      "fv:emp:customer handling:purchase request details:accept purchase popup",
      "emp:purchase request:order id",
      customerReservation.id,
      user
    );

    setOpenAcceptPurchaseDialog(true);
  };

  const closeAcceptPurchaseRequestDialog = () => {
    AnalyticsService.trackOnClickDetailEvent(
      "fv:emp:customer handling:purchase request details:accept purchase popup",
      "emp:purchase request:order id",
      `emp:purchase request:order id:${customerReservation.id}`,
      customerReservation.id,
      "emp:customer handling:purchase request details:accept purchase popup:content:close",
      "fv:emp:customer handling:purchase request details:accept purchase popup:content action",
      user
    );

    setOpenAcceptPurchaseDialog(false);
  };

  const confirmAcceptPurchaseRequest = () => {
    AnalyticsService.trackOnClickDetailEvent(
      "fv:emp:customer handling:purchase request details:accept purchase popup",
      "emp:purchase request:order id",
      `emp:purchase request:order id:${customerReservation.id}`,
      customerReservation.id,
      "emp:customer handling:purchase request details:accept purchase popup:content:confirm",
      "fv:emp:customer handling:purchase request details:accept purchase popup:content action",
      user
    );
    submitOrderAction.mutate({
      reservationId: customerReservation.id,
      acceptedDate: customerReservation.todayDate,
      dealerAction: DEALER_ACTIONS.PURCHASE_REQUEST_ACCEPTED,
    });
  };

  const confirmDialogProps = {
    title: t("PurchaseDetails.appointmentConfirmTitle"),
    bodyText: appointmentAccepted
      ? t("PurchaseDetails.appointmentCancelText")
      : t("PurchaseDetails.appointmentAcceptText"),
    additionalBodyText: appointmentAccepted
      ? t("PurchaseDetails.appointmentCancelAdditionalText")
      : t("PurchaseDetails.appointmentAcceptAdditionalText"),
    confirm: "Submit",
    cancel: "Cancel",
  };

  const purchaseTypeIsValid =
    customerReservation &&
    customerReservation.purchaseInfo &&
    customerReservation.purchaseInfo.purchaseType;

  const purchaseTypeIsCash =
    purchaseTypeIsValid &&
    customerReservation.purchaseInfo.purchaseType === PURCHASE_TYPES.CASH;

  const purchaseTypeIsSignAtDealer =
    purchaseTypeIsValid &&
    customerReservation.purchaseInfo.purchaseType ===
      PURCHASE_TYPES.SIGN_AT_DEALER;

  const purchaseTypeIsContinueAtDealer =
    purchaseTypeIsValid &&
    customerReservation.purchaseInfo.purchaseType ===
      PURCHASE_TYPES.CONTINUE_AT_DEALER;

  const purchaseTypeIsDiscussWithDealer =
    purchaseTypeIsValid &&
    customerReservation.purchaseInfo.purchaseType ===
      PURCHASE_TYPES.DISCUSS_WITH_DEALER;

  const purchaseTypeIsThirdParty =
    purchaseTypeIsValid &&
    customerReservation.purchaseInfo.purchaseType ===
      PURCHASE_TYPES.THIRD_PARTY;

  const hasJourneyType = customerReservation && customerReservation.journeyType;

  const showMultipleRequestsOnVin =
    (customerReservation.journeyType !== "Model-E" || !purchaseTypeIsCash) &&
    !purchaseTypeIsSignAtDealer;

  const showAppointmentFields =
    ((hasJourneyType && customerReservation.journeyType !== "Model-E") ||
      !purchaseTypeIsCash) &&
    !purchaseTypeIsSignAtDealer &&
    (purchaseTypeIsThirdParty ||
      purchaseTypeIsDiscussWithDealer ||
      purchaseTypeIsContinueAtDealer);

  const showRequiresTestDrive =
    ((hasJourneyType && customerReservation.journeyType !== "Model-E") ||
      !purchaseTypeIsCash) &&
    (purchaseTypeIsThirdParty ||
      purchaseTypeIsDiscussWithDealer ||
      purchaseTypeIsContinueAtDealer ||
      purchaseTypeIsSignAtDealer);

  const showCustomerComments =
    ((hasJourneyType && customerReservation.journeyType !== "Model-E") ||
      !purchaseTypeIsCash) &&
    (purchaseTypeIsThirdParty ||
      purchaseTypeIsDiscussWithDealer ||
      purchaseTypeIsContinueAtDealer ||
      purchaseTypeIsSignAtDealer);

  const showDealerUserID =
    (hasJourneyType && customerReservation.journeyType !== "Model-E") ||
    !purchaseTypeIsCash;

  const showDeliveryRequested =
    !purchaseTypeIsCash &&
    !purchaseTypeIsThirdParty &&
    !purchaseTypeIsDiscussWithDealer &&
    !purchaseTypeIsContinueAtDealer &&
    !purchaseTypeIsSignAtDealer;

  return (
    <StyledBasicExpansionPanel
      onChange={() => setExpandPurchaseInfo(!expandPurchaseInfo)}
      expanded={expandPurchaseInfo}
      defaultExpanded
    >
      <StyledAccordionSummary
        expandIcon={<ExpandLessIcon />}
        IconButtonProps={{ disableRipple: true }}
        id="purchaseExpansion"
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            {t("PurchaseDetails.purchaseInformation")}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            container
            spacing={1}
            justifyContent="flex-end"
            alignItems="center"
          >
            <HasPermissionTo
              perform={["acceptPurchaseRequestNA:write"]}
              permissions={user.permissions.rolePermissions}
              condition={isAcceptPurchaseEligible(customerReservation)}
              render={() => {
                return (
                  <Grid item>
                    <EMPButton
                      buttonElement="button"
                      buttonType="contained"
                      onClick={openAcceptPurchaseRequestDialog}
                      buttonText={t(
                        "PurchaseDetails.acceptPurchaseButtonCaption"
                      )}
                    ></EMPButton>
                  </Grid>
                );
              }}
            />

            <HasPermissionTo
              perform={["cancelPurchaseRequestNA:write"]}
              permissions={user.permissions.rolePermissions}
              condition={isCancelPurchaseEligible(customerReservation)}
              render={() => {
                return (
                  <Grid item>
                    <EMPButton
                      buttonType="text"
                      buttonElement="button"
                      onClick={openCancelPurchaseRequestDialog}
                      buttonText={
                        !IS_FORD_BRAND(user.brand)
                          ? t(
                              "PurchaseDetails.cancelPurchaseButtonCaptionLincoln"
                            )
                          : t("PurchaseDetails.cancelPurchaseButtonCaption")
                      }
                    ></EMPButton>
                  </Grid>
                );
              }}
            />
          </Grid>
        </Grid>
      </StyledAccordionSummary>
      {customerReservation.purchaseInfo ? (
        <div className={styles.customerInfoGrid}>
          {customerReservation ? (
            <Grid container spacing={2}>
              <Grid item className={styles.cardGridItemWrapper}>
                <DataCard cardHeader={t("PurchaseDetails.purchaseDetails")}>
                  <InfoDetail
                    header={t("PurchaseDetails.purchaseTypes.header")}
                    value={
                      customerReservation.paymentDescription &&
                      applicationStatus(
                        customerReservation.paymentDescription
                      ) === PENDING_CREDIT_APP
                        ? t(
                            "PurchaseDetails.purchaseTypes.PENDING_CREDIT_APPLICATION"
                          )
                        : applicationStatus(
                            customerReservation.paymentDescription
                          ) === CONDITIONED_CREDIT_APP
                        ? t(
                            "PurchaseDetails.purchaseTypes.CONDITIONED_CREDIT_APPLICATION"
                          )
                        : applicationStatus(
                            customerReservation.paymentDescription
                          ) === DENIED_CREDIT_APP ||
                          applicationStatus(
                            customerReservation.paymentDescription
                          ) === DENIED_CREDIT_APP_ERROR
                        ? t(
                            "PurchaseDetails.purchaseTypes.DENIED_CREDIT_APPLICATION"
                          )
                        : applicationStatus(
                            customerReservation.paymentDescription
                          ) === APPROVED &&
                          customerReservation.purchaseInfo.purchaseType === null
                        ? t("PurchaseDetails.purchaseTypes.abandoned_checkout")
                        : customerReservation.purchaseInfo.purchaseType &&
                          t(
                            `PurchaseDetails.purchaseTypes.${UpperCaseText(
                              customerReservation.purchaseInfo.purchaseType
                            )}`
                          )
                    }
                  />
                  <InfoDetail
                    header={t("PurchaseDetails.vin")}
                    value={customerReservation.purchaseInfo.vin}
                  />
                  {showMultipleRequestsOnVin && (
                    <InfoDetail
                      header={t("PurchaseDetails.multipleRequestsOnVin")}
                      value={
                        customerReservation.purchaseInfo.isMultipleVinAssociated
                          ? t("PurchaseDetails.yes")
                          : t("PurchaseDetails.no")
                      }
                      headerTooltip={
                        <SharedToolTip
                          user={user}
                          openTestid="multipleVINTooltipFlagOpen"
                          closeTestid="multipleVINTooltipFlagClose"
                          body={t(
                            "PurchaseDetails.multipleVINRequestsToolTipBody"
                          )}
                        />
                      }
                    />
                  )}
                  <InfoDetail
                    header={t("PurchaseDetails.stockId")}
                    value={customerReservation.purchaseInfo.stockId}
                  />
                  <InfoDetail
                    header={t("PurchaseDetails.purchaseRequestDate")}
                    value={customerReservation.purchaseInfo.purchaseRequestDate}
                  />
                  <InfoDetail
                    header={t("PurchaseDetails.status")}
                    value={customerReservation.purchaseInfo.acceptRejectStatus}
                  />
                  {UpperCaseText(
                    customerReservation.purchaseInfo.acceptRejectStatus
                  ) === ACCEPTED && (
                    <InfoDetail
                      header={t("PurchaseDetails.acceptanceDate")}
                      value={customerReservation.purchaseInfo.dealerActionDate}
                    />
                  )}
                  {(UpperCaseText(
                    customerReservation.purchaseInfo.acceptRejectStatus
                  ) === CANCELLED ||
                    UpperCaseText(
                      customerReservation.purchaseInfo.acceptRejectStatus
                    ) === CANCELED) && (
                    <InfoDetail
                      header={t("PurchaseDetails.cancellationDate")}
                      value={customerReservation.purchaseInfo.dealerActionDate}
                    />
                  )}
                  {showDealerUserID && (
                    <InfoDetail
                      header={t("PurchaseDetails.dealerUserId")}
                      value={
                        customerReservation.purchaseInfo
                          .purchaseRequestDealerAction &&
                        customerReservation.purchaseInfo
                          .purchaseRequestDealerAction.dealerActionUserId
                      }
                    />
                  )}
                  <InfoDetail
                    header={t("PurchaseDetails.cancellationReason")}
                    value={
                      customerReservation.purchaseInfo
                        .purchaseRequestDealerAction &&
                      customerReservation.purchaseInfo
                        .purchaseRequestDealerAction.dealerRejectedReason
                    }
                  />
                  <InfoDetail
                    header={t("PurchaseDetails.soldDate")}
                    value={customerReservation.purchaseInfo.soldDate}
                  />
                  <InfoDetail
                    header={t("PurchaseDetails.channel")}
                    value={customerReservation.purchaseInfo.channel}
                  />

                  <InfoDetail
                    header={t("PurchaseDetails.AXZPlan")}
                    value={
                      customerReservation.purchaseInfo.axzPlanPin ||
                      customerReservation.purchaseInfo.axzPlanValidationType
                    }
                  />
                  {showAppointmentFields && (
                    <>
                      <InfoDetail
                        header={t("PurchaseDetails.preferredApptDate")}
                        value={
                          customerReservation.purchaseInfo
                            .preferredAppointmentDate
                        }
                      />
                      <InfoDetail
                        header={t("PurchaseDetails.preferredApptSlot")}
                        value={
                          customerReservation.purchaseInfo
                            .preferredAppointmentSlot
                        }
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 16,
                        }}
                      >
                        {isFetching ? (
                          "Loading..."
                        ) : isError ? (
                          "Error getting appointment data..." + error.message
                        ) : (
                          <EMPCheckbox
                            checked={appointmentAccepted}
                            name="chkBxAppointment"
                            id="chkBxAppointment"
                            handleChange={() => setOpenConfirmDialogue(true)}
                            label={t(
                              "PurchaseDetails.appointmentFulfilledText"
                            )}
                          ></EMPCheckbox>
                        )}
                      </div>
                    </>
                  )}
                  {showRequiresTestDrive && (
                    <InfoDetail
                      header={t("PurchaseDetails.testDrive")}
                      value={
                        customerReservation.purchaseInfo.testDriveRequested
                          ? t("PurchaseDetails.yes")
                          : t("PurchaseDetails.no")
                      }
                    />
                  )}
                  {showCustomerComments && (
                    <InfoDetail
                      header={t("PurchaseDetails.customerComments")}
                      value={customerReservation.purchaseInfo.customerComments}
                    />
                  )}
                  {showDeliveryRequested && (
                    <InfoDetail
                      header={t("PurchaseDetails.deliveryRequest")}
                      value={
                        customerReservation.purchaseInfo.requestforDelivery
                          ? t("PurchaseDetails.yes")
                          : t("PurchaseDetails.no")
                      }
                    />
                  )}
                </DataCard>
              </Grid>

              <Grid item className={styles.cardGridItemWrapper}>
                <DataCard cardHeader={t("PurchaseDetails.orderDetails")}>
                  <InfoDetail
                    header={t("PurchaseDetails.orderId")}
                    value={customerReservation.id}
                  />
                  <InfoDetail
                    header={t("PurchaseDetails.customerSubmissionDate")}
                    value={customerReservation.date}
                  />

                  <InfoDetail
                    header={t("PurchaseDetails.status")}
                    value={
                      customerReservation.orderStatus
                        ? customerReservation.orderStatus
                        : getStatus(customerReservation)
                    }
                  />

                  <InfoDetail
                    header={t("PurchaseDetails.channel")}
                    value={customerReservation.orderChannel}
                  />

                  {showDealerUserID && (
                    <InfoDetail
                      header={t("PurchaseDetails.dealerUserId")}
                      value={
                        customerReservation.orderDetailsDealerAction &&
                        customerReservation.orderDetailsDealerAction
                          .dealerActionUserId
                      }
                    />
                  )}
                  {customerReservation.webdoId && (
                    <InfoDetail
                      header={t("OrderDetails.webdoId")}
                      value={customerReservation.webdoId}
                    />
                  )}
                </DataCard>
              </Grid>

              {customerReservation.conversionDate && (
                <Grid item className={styles.cardGridItemWrapper}>
                  <DataCard
                    cardHeader={t("PurchaseDetails.reservationDetails")}
                  >
                    <InfoDetail
                      header={t("PurchaseDetails.reservationDate")}
                      value={customerReservation.reservationDate}
                    />
                    <InfoDetail
                      header={t("OrderDetails.status")}
                      value={
                        customerReservation.reservationStatus
                          ? customerReservation.reservationStatus
                          : getStatus(customerReservation)
                      }
                    />

                    <InfoDetail
                      header={t("PurchaseDetails.channel")}
                      value={customerReservation.reservationChannel}
                    />

                    {showDealerUserID && (
                      <InfoDetail
                        header={t("PurchaseDetails.dealerUserId")}
                        value={
                          customerReservation.reservationDealerAction &&
                          customerReservation.reservationDealerAction
                            .dealerActionUserId
                        }
                      />
                    )}
                  </DataCard>
                </Grid>
              )}
            </Grid>
          ) : (
            <span className={styles.labelError}>
              {t("PurchaseDetails.errorLoadingPurchaseInfo")}
            </span>
          )}
        </div>
      ) : (
        <div className={styles.customerInfoGrid}>No data found</div>
      )}
      {/*/!*Dialog to ACCEPT Purchase Request*!/*/}
      <CancelOrderDialog
        user={user}
        t={t}
        dialogProps={getAcceptPurchaseDialogProps()}
        open={openAcceptPurchaseDialog}
        onClose={closeAcceptPurchaseRequestDialog}
        handleConfirm={confirmAcceptPurchaseRequest}
        loading={submitOrderAction.isLoading}
        transportError={submitOrderAction.isError}
      />

      {/*Dialog to CANCEL Purchase Request*/}
      <CancelOrderDialog
        user={user}
        t={t}
        dialogProps={getCancelPurchaseDialogProps()}
        open={openCancelPurchaseDialog}
        onClose={closeCancelPurchaseRequestDialog}
        handleConfirm={confirmCancelPurchaseRequest}
        showDropdown
        loading={submitOrderAction.isLoading}
        cancelType="purchaseRequest"
        purchaseRequestCancel
        transportError={submitOrderAction.isError}
      />

      <ConfirmDialog
        open={openConfirmDialogue}
        dialogProps={confirmDialogProps}
        onConfirm={() =>
          submitAppointmentAction.mutate({
            reservationId: customerReservation.id,
            acceptedDate: customerReservation.todayDate,
            dealerAction: appointmentAccepted
              ? DEALER_ACTIONS.APPOINTMENT_CANCELLED
              : DEALER_ACTIONS.APPOINTMENT_FULFILLED,
          })
        }
        onCancel={() => setOpenConfirmDialogue(false)}
        showWarningIcon
        onClose={() => setOpenConfirmDialogue(false)}
        additionalDescription={!appointmentAccepted ? true : false}
        user={user}
        loading={submitAppointmentAction.isLoading}
      />
    </StyledBasicExpansionPanel>
  );
};

export default withTranslation("emp")(PurchaseInformation);
