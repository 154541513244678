const initUserzoom = () => {
  const script = document.createElement("script");
  script.src =
    ("https:" === document.location.protocol ? "https://" : "http://") +
    "cdn5.userzoom.com/trueintent/js/uz_til_us.js?cuid=20A67C3971CDE41180C90050569444FB";
  script.async = true;

  window._uzactions = [];
  window._uzactions.push(["_setID", "BCE4BCC0EBF0EE11AA94D5EF45E835CB"]);
  window._uzactions.push(["_setSID", "45C49FBAEBF0EE11AA94D5EF45E835CB"]);
  window._uzactions.push(["_start"]);

  const s = document.getElementsByTagName("script")[0];

  if (window._uzactions.length && s) {
    s.parentNode.insertBefore(script, s);
  } else {
    document.head.appendChild(script);
  }
};

export { initUserzoom };
