import React, { Component } from "react";
import fordStyles from "../NAOrderDetailsNewVersion.module.scss";
import lincolnStyles from "../NAOrderDetailsNewVersionLincoln.module.scss";
import SharedToolTip from "../../../../shared/sharedToolTip/SharedToolTip";
import { withTranslation } from "react-i18next";
import CancelOrderDialog from "../../CancelOrderDialog";
import CustomerReservationsClient from "../../../../clients/CustomerReservationsClient";
import { logMsg } from "../../../../common/Logger";
import { InfoDetail } from "../InfoDetail";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import LowerCaseText from "../../../../utils/LowerCaseText";
import OfflineRefundCalendarDialog from "../offlineRefundDialog/OfflineRefundCalendarDialog";
import {
  DEALER_ACTIONS,
  FINANCE_PRODUCT_TYPE,
  PURCHASE_TYPES,
  REFUND_TYPE,
  RESERVATION_DEPOSIT_STATUS,
} from "../../../../common/Constants";
import OfflineRefundConfirmDialog from "../offlineRefundDialog/OfflineRefundConfirmDialog";
import {
  displayError,
  ERROR_PROCESSING,
  is400,
} from "../../../../errors/ErrorConstants";
import { IS_FORD_BRAND } from "../../../../utils/EmpUtil";
import isOlderThan730Days from "../../../../utils/DateRange";
import DataCard from "../../../../shared/components/DataCard/DataCard";
import * as AnalyticsService from "../../../../shared/services/analytics/AnalyticsService";

class ReservationDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openRefund: false,
      hasError: [],
    };
  }

  toggleDialog = (e) => {
    if (this.state.openRefund) {
      AnalyticsService.trackOnClickDetailEvent(
        "fv:emp:customer handling:orders details:refund reservation deposit popup",
        "emp:order details:order id",
        `emp:order details:order id:${this.props.customerReservation.id}`,
        this.props.customerReservation.id,
        "emp:customer handling:orders details:refund reservation deposit popup:content:close",
        "fv:emp:customer handling:orders details:refund reservation deposit popup:content action",
        this.props.user
      );
    }

    e.stopPropagation();
    this.setState({ openRefund: !this.state.openRefund });
  };

  trackOpenRefundDialog = () => {
    AnalyticsService.trackPageLoadDetailEvent(
      "fv:emp:customer handling:orders details:refund reservation deposit popup",
      "emp:order details:order id",
      this.props.customerReservation.id,
      this.props.user
    );
  };
  submitRefund = () => {
    AnalyticsService.trackOnClickDetailEvent(
      "fv:emp:customer handling:orders details:refund reservation deposit popup",
      "emp:order details:order id",
      `emp:order details:order id:${this.props.customerReservation.id}`,
      this.props.customerReservation.id,
      "emp:customer handling:orders details:refund reservation deposit popup:content:confirm",
      "fv:emp:customer handling:orders details:refund reservation deposit popup:content action",
      this.props.user
    );
    const refundOrderactionInfo = {
      reservationId: this.props.customerReservation.id,
      dealerAction: DEALER_ACTIONS.RESERVATION_DEPOSIT_REFUND,
    };
    this.props.hideOrShow(true);
    CustomerReservationsClient.updateOrder(
      this.props.user.token,
      this.props.customerReservation.dealerId,
      refundOrderactionInfo,
      this.props.user.userId
    )
      .catch((error) => {
        if (is400(error)) {
          this.props.processFordPayError(error);
        } else {
          this.setState({ hasError: [ERROR_PROCESSING] });
        }
        logMsg("the error is: ", error);
      })
      .finally(() => {
        this.setState({ openRefund: false });
        this.props.refreshReservationDetailsWithNotifications();
        this.props.hideOrShow(false);
      });
  };

  openOfflineRefundCalendarDialog = () => {
    this.setState({
      openOfflineRefundPurchaseDepositDialog: true,
      openOfflineRefundPurchaseDepositConfirmDialog: false,
    });
  };

  trackOpenOfflineRefundCalendarDialog = () => {
    AnalyticsService.trackPageLoadDetailEvent(
      "fv:emp:customer handling:orders details:refund reservation deposit offline popup",
      "emp:order details:order id",
      this.props.customerReservation.id,
      this.props.user
    );
  };
  goBackOfflineRefundDialog = () => {
    AnalyticsService.trackOnClickDetailEvent(
      "fv:emp:customer handling:orders details:refund reservation deposit offline popup",
      "emp:order details:order id",
      `emp:order details:order id:${this.props.customerReservation.id}`,
      this.props.customerReservation.id,
      "emp:customer handling:orders details:refund reservation deposit offline popup:content:go back",
      "fv:emp:customer handling:orders details:refund reservation deposit offline popup:content action",
      this.props.user
    );
    this.setState({
      openOfflineRefundPurchaseDepositDialog: false,
    });
  };

  closeOfflineRefundDialog = () => {
    AnalyticsService.trackOnClickDetailEvent(
      "fv:emp:customer handling:orders details:refund reservation deposit offline popup",
      "emp:order details:order id",
      `emp:order details:order id:${this.props.customerReservation.id}`,
      this.props.customerReservation.id,
      "emp:customer handling:orders details:refund reservation deposit offline popup:content:close",
      "fv:emp:customer handling:orders details:refund reservation deposit offline popup:content action",
      this.props.user
    );
    this.setState({
      openOfflineRefundPurchaseDepositDialog: false,
    });
  };

  openOfflineRefundConfirmDialog = (date) => {
    this.refundDate = date;
    this.setState({
      openOfflineRefundPurchaseDepositDialog: false,
      openOfflineRefundPurchaseDepositConfirmDialog: true,
    });
  };

  trackOpenOfflineRefundConfirmDialog = () => {
    AnalyticsService.trackPageLoadDetailEvent(
      "fv:emp:customer handling:orders details:refund offline:are you sure popup",
      "emp:order details:order id",
      this.props.customerReservation.id,
      this.props.user
    );
  };
  closeOfflineRefundConfirmDialog = () => {
    this.setState({
      openOfflineRefundPurchaseDepositConfirmDialog: false,
    });
  };

  goBackOfflineRefundConfirmDialog = () => {
    AnalyticsService.trackOnClickDetailEvent(
      "fv:emp:customer handling:orders details:refund offline:are you sure popup",
      "emp:order details:order id",
      `emp:order details:order id:${this.props.customerReservation.id}`,
      this.props.customerReservation.id,
      "emp:customer handling:orders details:refund reservation deposit offline:are you sure popup:content:go back",
      "emp:customer handling:orders details:refund reservation deposit offline:are you sure popup:content action",
      this.props.user
    );
    this.setState({
      openOfflineRefundPurchaseDepositDialog: true,
      openOfflineRefundPurchaseDepositConfirmDialog: false,
    });
  };

  isRefunded = () => {
    return (
      this.props.customerReservation.reservationPaymentInfo &&
      this.props.customerReservation.reservationPaymentInfo.refundAmount
    );
  };

  isRefundDeclinedOrDenied = () => {
    if (
      LowerCaseText(
        this.props.customerReservation.reservationPaymentInfo &&
          this.props.customerReservation.reservationPaymentInfo.refundStatus
      ) === RESERVATION_DEPOSIT_STATUS.DECLINED ||
      LowerCaseText(
        this.props.customerReservation.reservationPaymentInfo &&
          this.props.customerReservation.reservationPaymentInfo.refundStatus
      ) === RESERVATION_DEPOSIT_STATUS.DENIED
    ) {
      return true;
    }
  };

  getInvoice(value) {
    return value ? value.substr(value.length - 12, value.length) : null;
  }

  getRefundDialogProps() {
    return {
      title: this.props.t("OrderDetails.refundReservationTitle"),
      body: this.props.t("OrderDetails.refundReservationBodyText"),
      checkbox: this.props.t("OrderDetails.refundReservationCheckbox"),
      checkboxList: [
        this.props.t("OrderDetails.refundReservationCheckboxList1"),
        this.props.t("OrderDetails.refundReservationCheckboxList2"),
        this.props.t("OrderDetails.refundReservationCheckboxList3"),
        this.props.t("OrderDetails.refundReservationCheckboxList4"),
        this.props.t("OrderDetails.refundReservationCheckboxList5"),
        this.props.t("OrderDetails.refundReservationCheckboxList6"),
      ],
      confirm: this.props.t("OrderDetails.refundReservationConfirm"),
      cancel: this.props.t("OrderDetails.refundReservationCancel"),
    };
  }

  render() {
    const { customerReservation } = this.props;

    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    const disableDealerActionsFlag =
      customerReservation &&
      customerReservation.purchaseInfo &&
      customerReservation.purchaseInfo.disableDealerActionsFlag;

    const isOlderThan2Years =
      customerReservation && isOlderThan730Days(customerReservation.date);

    const isPurchaseRequestOlderThan2Years =
      customerReservation &&
      customerReservation.purchaseInfo &&
      customerReservation.purchaseInfo.purchaseRequestDate &&
      isOlderThan730Days(customerReservation.purchaseInfo.purchaseRequestDate);

    const showroomJourneyType =
      customerReservation && customerReservation.showroomJourneyType;
    const isShowroomPurchaseRequest = showroomJourneyType !== false;

    let disableRefundCTA;
    if (isShowroomPurchaseRequest) {
      disableRefundCTA = isPurchaseRequestOlderThan2Years;
    } else {
      disableRefundCTA =
        !customerReservation.isReservationRefundEligible ||
        disableDealerActionsFlag ||
        isOlderThan2Years;
    }

    const hasJourneyType =
      customerReservation && customerReservation.journeyType;

    const purchaseTypeIsCash =
      this.props.customerReservation &&
      this.props.customerReservation.purchaseInfo &&
      this.props.customerReservation.purchaseInfo.purchaseType ===
        PURCHASE_TYPES.CASH;

    const showDealerUserID = !(
      hasJourneyType &&
      this.props.customerReservation.journeyType === "Model-E" &&
      (purchaseTypeIsCash ||
        this.props.customerReservation.financeDataQuote.financeProductType ===
          FINANCE_PRODUCT_TYPE.THIRD_PARTY ||
        this.props.customerReservation.financeDataQuote.financeProductType ===
          FINANCE_PRODUCT_TYPE.DEALER_FINANCING_OPTIONS)
    );

    return (
      <DataCard
        cardHeader={this.props.t(
          "OrderDetailsConsumerDeposit.reservationDeposit"
        )}
        headerDetail={
          <>
            <HasPermissionTo
              perform={["refundOrderNA:write"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={!this.isRefunded() && !this.isRefundDeclinedOrDenied()}
              render={() => {
                return (
                  <button
                    className={styles.refundDepositNA}
                    onClick={this.toggleDialog}
                    disabled={disableRefundCTA}
                    data-testid="refundReservationDeposit"
                    id="refundReservationDeposit"
                  >
                    {this.props.t("OrderDetails.refundDeposit")}
                  </button>
                );
              }}
            />

            <HasPermissionTo
              perform={["refundOrderNA:write"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={!this.isRefunded() && !this.isRefundDeclinedOrDenied()}
              render={() => {
                return (
                  <button
                    className={styles.refundDepositNA}
                    onClick={this.openOfflineRefundCalendarDialog}
                    disabled={disableRefundCTA}
                    data-testid="offlineRefundReservationDeposit"
                    id="offlineRefundReservationDeposit"
                  >
                    {this.props.t("OrderDetailsConsumerDeposit.offlineRefund")}
                  </button>
                );
              }}
            />
          </>
        }
      >
        <InfoDetail
          header={this.props.t("OrderDetailsConsumerDeposit.reservationAmount")}
          value={
            customerReservation.reservationPaymentInfo &&
            customerReservation.reservationPaymentInfo.depositAmount
          }
        />
        <InfoDetail
          header={this.props.t("OrderDetailsConsumerDeposit.last4DigitsofCc")}
          value={
            customerReservation.reservationPaymentInfo &&
            customerReservation.reservationPaymentInfo.cardLastFourDigits
          }
        />

        <HasPermissionTo
          permissions={this.props.user.permissions.rolePermissions}
          perform={["transactionId:view"]}
          render={() => {
            return (
              <InfoDetail
                header={this.props.t(
                  "OrderDetailsConsumerDeposit.reservationInvoice"
                )}
                value={this.getInvoice(
                  customerReservation.reservationPaymentInfo &&
                    customerReservation.reservationPaymentInfo
                      .paymentTransactionId
                )}
                headerTooltip={
                  <SharedToolTip
                    {...this.props}
                    openTestid="reservationInvoiceTooltipOpen"
                    closeTestid="reservationInvoiceTooltipClose"
                    title={this.props.t(
                      "OrderDetailsConsumerDeposit.invoiceTooltipHeader"
                    )}
                    body={this.props.t(
                      "OrderDetailsConsumerDeposit.invoiceTooltipBody"
                    )}
                  />
                }
              />
            );
          }}
        />

        <InfoDetail
          header={this.props.t(
            "OrderDetailsConsumerDeposit.reservationDepositStatus"
          )}
          value={this.props.t(
            "OrderDetailsConsumerDeposit.reservationdepositaccepted"
          )}
        />
        {customerReservation.date && (
          <InfoDetail
            header={this.props.t(
              "OrderDetailsConsumerDeposit.reservationDepositAcceptedDate"
            )}
            value={customerReservation.date}
          />
        )}

        {Object.values(RESERVATION_DEPOSIT_STATUS)
          .slice(1, 4)
          .includes(
            customerReservation.reservationPaymentInfo &&
              LowerCaseText(
                customerReservation.reservationPaymentInfo.refundStatus
              )
          ) ? (
          <InfoDetail
            header={this.props.t(
              "OrderDetailsConsumerDeposit.reservationRefundStatus"
            )}
            value={this.props.t(
              `OrderDetailsConsumerDeposit.${LowerCaseText(
                customerReservation.reservationPaymentInfo &&
                  customerReservation.reservationPaymentInfo.refundStatus
              )}`
            )}
            tooltip={
              <SharedToolTip
                {...this.props}
                openTestid="failedReservationPaymentOpen"
                closeTestid="failedReservationPaymentClose"
                title={this.props.t(
                  `OrderDetailsConsumerDeposit.${LowerCaseText(
                    customerReservation.reservationPaymentInfo &&
                      customerReservation.reservationPaymentInfo.refundStatus
                  )}`
                )}
                body={this.props.t(
                  `OrderDetailsConsumerDeposit.tooltip${LowerCaseText(
                    customerReservation.reservationPaymentInfo &&
                      customerReservation.reservationPaymentInfo.refundStatus
                  )}`
                )}
              />
            }
          />
        ) : (
          <InfoDetail
            header={this.props.t(
              "OrderDetailsConsumerDeposit.reservationRefundStatus"
            )}
            value={
              customerReservation.reservationPaymentInfo &&
              customerReservation.reservationPaymentInfo.refundStatus &&
              this.props.t(
                `OrderDetailsConsumerDeposit.${LowerCaseText(
                  customerReservation.reservationPaymentInfo &&
                    customerReservation.reservationPaymentInfo.refundStatus
                )}`
              )
            }
          />
        )}

        <InfoDetail
          header={this.props.t(
            "OrderDetailsConsumerDeposit.reservationRefundDate"
          )}
          value={
            customerReservation.reservationPaymentInfo &&
            customerReservation.reservationPaymentInfo.refundDate
          }
        />
        <InfoDetail
          header={this.props.t(
            "OrderDetailsConsumerDeposit.reservationRefundedAmount"
          )}
          value={
            customerReservation.reservationPaymentInfo &&
            customerReservation.reservationPaymentInfo.refundAmount
          }
        />
        <InfoDetail
          header={this.props.t(
            "OrderDetailsConsumerDeposit.reservationRefundInvoiceNumber"
          )}
          value={this.getInvoice(
            customerReservation.reservationPaymentInfo &&
              customerReservation.reservationPaymentInfo.refundTransactionId
          )}
          headerTooltip={
            <SharedToolTip
              {...this.props}
              openTestid="reservationRefundInvoiceTooltipOpen"
              closeTestid="reservationRefundInvoiceTooltipClose"
              title={this.props.t(
                "OrderDetailsConsumerDeposit.invoiceTooltipHeader"
              )}
              body={this.props.t(
                "OrderDetailsConsumerDeposit.invoiceTooltipBody"
              )}
            />
          }
        />

        {showDealerUserID && (
          <InfoDetail
            header={this.props.t("OrderDetails.dealerUserId")}
            value={
              customerReservation.reservationPaymentInfo &&
              customerReservation.reservationPaymentInfo.refundDealerUserId
            }
          />
        )}

        {this.state.openRefund && (
          <CancelOrderDialog
            {...this.props}
            isRefund={true}
            dialogProps={this.getRefundDialogProps()}
            open={true}
            onClose={this.toggleDialog}
            handleConfirm={this.submitRefund}
            showDropdown={false}
            onEnter={this.trackOpenRefundDialog}
          />
        )}

        {this.state.openOfflineRefundPurchaseDepositDialog && (
          <OfflineRefundCalendarDialog
            {...this.props}
            refundType={REFUND_TYPE.RESERVATION_DEPOSIT}
            open={this.state.openOfflineRefundPurchaseDepositDialog}
            todayDate={this.props.customerReservation.todayDate}
            onToggle={this.openOfflineRefundConfirmDialog}
            onClose={this.closeOfflineRefundDialog}
            onGoBack={this.goBackOfflineRefundDialog}
            onEnter={this.trackOpenOfflineRefundCalendarDialog}
          />
        )}

        {this.state.openOfflineRefundPurchaseDepositConfirmDialog && (
          <OfflineRefundConfirmDialog
            {...this.props}
            open={this.state.openOfflineRefundPurchaseDepositConfirmDialog}
            refundDate={this.refundDate}
            refundType={REFUND_TYPE.RESERVATION_DEPOSIT}
            isReservationDeposit
            onToggle={this.goBackOfflineRefundConfirmDialog}
            onClose={this.closeOfflineRefundConfirmDialog}
            onEnter={this.trackOpenOfflineRefundConfirmDialog}
          />
        )}
      </DataCard>
    );
  }
}

export default withTranslation("emp")(ReservationDeposit);
