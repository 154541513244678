import { withStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

const LincolnMenuItem = withStyles({
  root: {
    "&$selected:hover": {
      backgroundColor: "#324047",
      color: "white",
    },
    "&:hover": {
      backgroundColor: "#324047",
      color: "white",
    },
  },
  selected: {},
})(MenuItem);

export default LincolnMenuItem;
