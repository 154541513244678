import React, { Component } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { withTranslation } from "react-i18next";
import ProtectionPlanBody from "./ProtectionPlanBody";
import {
  StyledTableRow,
  StyledBigTableHeader,
} from "../../../../shared/Tables/TableRow";
import {
  StyledExpansionPanel,
  StyledExpansionPanelSummary,
} from "../../../../shared/ExpansionPanels/ExpansionPanel";
import { formatPriceValue } from "../../../../utils/FormatPrice";
import fordSharedStyles from "../../orderDetails/NAOrderDetailsNewVersion.module.scss";
import lincolnSharedStyles from "../../orderDetails/NAOrderDetailsNewVersionLincoln.module.scss";
import crStylesFord from "../../crStyles";
import crStylesLincoln from "../../crStylesLincoln";
import fordStyles from "../addOns/PurchaseAccessories.module.scss";
import lincolnStyles from "../addOns/PurchaseAccessoriesLincoln.module.scss";
import { IS_FORD_BRAND } from "../../../../utils/EmpUtil";

class ProtectionPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandProtectionPlans: false,
      expandIncludedProtectionPlan: true,
      expandCustomerSelectedProtectionPlans: true,
    };
    this.defaultCurrency =
      this.props.localizedInfo &&
      this.props.localizedInfo.defaultCurrency &&
      this.props.localizedInfo.defaultCurrency.symbol;
  }
  componentDidMount() {
    this.setState({ expandProtectionPlans: this.props.expandAccordian });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({
        expandProtectionPlans: this.props.expandAccordian,
      });
    }
  }
  expandProtectionPlans() {
    this.setState((prevState) => ({
      expandProtectionPlans: !prevState.expandProtectionPlans,
    }));
  }

  expandIncludedProtectionPlan() {
    this.setState((prevState) => ({
      expandIncludedProtectionPlan: !prevState.expandIncludedProtectionPlan,
    }));
  }

  expandCustomerSelectedProtectionPlans() {
    this.setState((prevState) => ({
      expandCustomerSelectedProtectionPlans:
        !prevState.expandCustomerSelectedProtectionPlans,
    }));
  }
  render() {
    const { protectionPlansData } = this.props;
    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    const sharedStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordSharedStyles
        : lincolnSharedStyles;

    const crStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? crStylesFord
        : crStylesLincoln;

    return (
      <StyledExpansionPanel
        defaultExpanded={false}
        onChange={() => this.expandProtectionPlans()}
        expanded={this.state.expandProtectionPlans}
        data-testid="protectionPlanDropDown"
      >
        <StyledExpansionPanelSummary style={crStyles.headerBarBg}>
          <div className={sharedStyles.headerBar}>
            <div
              className={sharedStyles.header}
              data-testid="customerOptOfflineTitle"
            >
              {this.props.t("ProtectionPlans.header")}
            </div>
            <div className={styles.acceptRejectDiv}>
              {this.state.expandProtectionPlans ? (
                <ExpandLessIcon id="less" data-testid="ProtectionPlanLess" />
              ) : (
                <ExpandMoreIcon id="more" data-testid="ProtectionPlanMore" />
              )}
            </div>
          </div>
        </StyledExpansionPanelSummary>
        <StyledExpansionPanel
          defaultExpanded={true}
          onChange={() => this.expandIncludedProtectionPlan()}
        >
          <StyledExpansionPanelSummary
            style={crStyles.headerBarNeutral}
            data-testid="includedProtection"
          >
            <div className={sharedStyles.headerBar}>
              <div
                className={sharedStyles.header}
                data-testid="customerOptOfflineTitle"
              >
                {this.props.t("ProtectionPlans.includedProtection")}
              </div>
              <div className={styles.acceptRejectDiv}>
                {formatPriceValue(0)}
                {this.state.expandIncludedProtectionPlan ? (
                  <ExpandLessIcon id="less" />
                ) : (
                  <ExpandMoreIcon id="more" />
                )}
              </div>
            </div>
          </StyledExpansionPanelSummary>

          <StyledTableRow
            isLincolnBrand={
              this.props.user && !IS_FORD_BRAND(this.props.user.brand)
            }
            text={this.props.t("ProtectionPlans.newVehicleLimitedWarranty")}
            data={this.props.t("ProtectionPlans.included")}
            index={0}
            indent={true}
          />
        </StyledExpansionPanel>
        {/*customer selected Protection plan*/}
        <StyledExpansionPanel
          defaultExpanded={true}
          onChange={() => this.expandCustomerSelectedProtectionPlans()}
        >
          <StyledExpansionPanelSummary
            style={crStyles.headerBarNeutral}
            data-testid="customerSelectedPlan"
          >
            <div className={sharedStyles.headerBar}>
              <div className={sharedStyles.header}>
                {this.props.t("ProtectionPlans.customerSelected")}
              </div>
              <div className={styles.acceptRejectDiv}>
                {protectionPlansData &&
                  protectionPlansData.protectionPlans &&
                  formatPriceValue(
                    protectionPlansData && protectionPlansData.totalPrice
                  )}
                {this.state.expandCustomerSelectedProtectionPlans ? (
                  <ExpandLessIcon
                    id="less"
                    data-testid="customerSelectedPlanLess"
                  />
                ) : (
                  <ExpandMoreIcon
                    id="more"
                    data-testid="customerSelectedPlanMore"
                  />
                )}
              </div>
            </div>
          </StyledExpansionPanelSummary>

          {protectionPlansData && protectionPlansData.protectionPlans ? (
            protectionPlansData.protectionPlans.map((item, index) => {
              return (
                <ProtectionPlanBody
                  {...this.props}
                  key={index}
                  protectionPlan={item}
                  defaultCurrency={this.defaultCurrency}
                  styles={styles}
                  sharedStyles={sharedStyles}
                  crStyles={crStyles}
                />
              );
            })
          ) : (
            <StyledTableRow
              isLincolnBrand={
                this.props.user && !IS_FORD_BRAND(this.props.user.brand)
              }
              text={this.props.t("ProtectionPlans.noneSelected")}
              index={0}
              indent={true}
            />
          )}
        </StyledExpansionPanel>
        <StyledBigTableHeader
          isLincolnBrand={
            this.props.user && !IS_FORD_BRAND(this.props.user.brand)
          }
          text={this.props.t("ProtectionPlans.plansTotal")}
          data={formatPriceValue(
            protectionPlansData && protectionPlansData.totalPrice
          )}
          testID="protectionPlanTotal"
        />
      </StyledExpansionPanel>
    );
  }
}

export default withTranslation("emp")(ProtectionPlan);
