import React, { Component } from "react";
import fordStyles from "../NAOrderDetailsNewVersion.module.scss";
import lincolnStyles from "../NAOrderDetailsNewVersionLincoln.module.scss";
import SharedToolTip from "../../../../shared/sharedToolTip/SharedToolTip";
import HasPermissionTo from "../../../../common/HasPermissionTo";
import { withTranslation } from "react-i18next";
import CancelOrderDialog from "../../CancelOrderDialog";
import CustomerReservationsClient from "../../../../clients/CustomerReservationsClient";
import { logMsg } from "../../../../common/Logger";
import { InfoDetail } from "../InfoDetail";
import OfflineRefundCalendarDialog from "../offlineRefundDialog/OfflineRefundCalendarDialog";
import {
  DEALER_ACTIONS,
  FINANCE_PRODUCT_TYPE,
  ORDER_DEPOSIT_STATUS,
  PURCHASE_TYPES,
  REFUND_TYPE,
} from "../../../../common/Constants";
import OfflineRefundConfirmDialog from "../offlineRefundDialog/OfflineRefundConfirmDialog";
import UpperCaseText from "../../../../utils/UpperCaseText";
import LowerCaseText from "../../../../utils/LowerCaseText";
import {
  displayError,
  ERROR_PROCESSING,
  is400,
} from "../../../../errors/ErrorConstants";
import { IS_FORD_BRAND } from "../../../../utils/EmpUtil";
import isOlderThan730Days from "../../../../utils/DateRange";
import DataCard from "../../../../shared/components/DataCard/DataCard";
import * as AnalyticsService from "../../../../shared/services/analytics/AnalyticsService";

class OrderDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openRefund: false,
      hasError: [],
    };
  }

  handleRefundOpen = (e) => {
    AnalyticsService.trackPageLoadDetailEvent(
      "fv:emp:customer handling:orders details:refund order deposit popup",
      "emp:order details:order id",
      this.props.customerReservation.id,
      this.props.user
    );
    this.toggleDialog(e);
  };

  handleRefundClose = () => {
    if (!this.props.isPurchaseOrder) {
      AnalyticsService.trackOnClickDetailEvent(
        "fv:emp:customer handling:orders details:refund order deposit popup",
        "emp:order details:order id",
        `emp:order details:order id:${this.props.customerReservation.id}`,
        this.props.customerReservation.id,
        "emp:customer handling:orders details:refund order deposit popup:content:close",
        "fv:emp:customer handling:orders details:refund order deposit popup:content action",
        this.props.user
      );
    }
  };

  handleRefundConfirm = () => {
    if (!this.props.isPurchaseOrder) {
      AnalyticsService.trackOnClickDetailEvent(
        "fv:emp:customer handling:orders details:refund order deposit popup",
        "emp:order details:order id",
        `emp:order details:order id:${this.props.customerReservation.id}`,
        this.props.customerReservation.id,
        "emp:customer handling:orders details:refund order deposit popup:content:confirm",
        "fv:emp:customer handling:orders details:refund order deposit popup:content action",
        this.props.user
      );
    }
    this.submitRefund();
  };

  handleOfflineRefundCalendarDialogOpen = () => {
    AnalyticsService.trackPageLoadDetailEvent(
      "fv:emp:customer handling:orders details:refund order deposit offline popup",
      "emp:order details:order id",
      this.props.customerReservation.id,
      this.props.user
    );

    this.openOfflineRefundCalendarDialog();
  };

  handleOfflineRefundConfirmDialogOpen = (date) => {
    if (!this.props.isPurchaseOrder) {
      AnalyticsService.trackPageLoadDetailEvent(
        "fv:emp:customer handling:orders details:refund order deposit offline:are you sure popup",
        "emp:order details:order id",
        this.props.customerReservation.id,
        this.props.user
      );
    }
    this.openOfflineRefundConfirmDialog(date);
  };

  handleOfflineRefundConfirmDialogGoBack = () => {
    if (!this.props.isPurchaseOrder) {
      AnalyticsService.trackOnClickDetailEvent(
        "fv:emp:customer handling:orders details:refund order deposit offline:are you sure popup",
        "emp:order details:order id",
        `emp:order details:order id:${this.props.customerReservation.id}`,
        this.props.customerReservation.id,
        "emp:customer handling:orders details:refund order deposit offline:are you sure popup:content:go back",
        "fv:emp:customer handling:orders details:refund order deposit offline:are you sure popup:content action",
        this.props.user
      );
    }
    this.openOfflineRefundCalendarDialog();
  };

  toggleDialog = () => this.setState({ openRefund: !this.state.openRefund });

  submitRefund = () => {
    const refundOrderactionInfo = {
      reservationId: this.props.customerReservation.id,
      dealerAction: DEALER_ACTIONS.ORDER_DEPOSIT_REFUND,
    };
    this.props.hideOrShow(true);
    CustomerReservationsClient.updateOrder(
      this.props.user.token,
      this.props.customerReservation.dealerId,
      refundOrderactionInfo,
      this.props.user.userId
    )
      .catch((error) => {
        if (is400(error)) {
          this.props.processFordPayError(error);
        } else {
          this.setState({ hasError: [ERROR_PROCESSING] });
        }
        logMsg("the error is: ", error);
      })
      .finally(() => {
        this.setState({ openRefund: false });
        this.props.refreshReservationDetailsWithNotifications();
        this.props.hideOrShow(false);
      });
  };

  getInvoice(value) {
    return value ? value.substr(value.length - 12, value.length) : null;
  }

  getRefundDialogProps() {
    return {
      title: this.props.t("OrderDetails.refundOrderTitle"),
      body: this.props.t("OrderDetails.refundOrderBodyText"),
      checkbox: this.props.t("OrderDetails.refundOrderCheckbox"),
      checkboxList: [
        this.props.t("OrderDetails.refundOrderCheckboxList1"),
        this.props.t("OrderDetails.refundOrderCheckboxList2"),
        this.props.t("OrderDetails.refundOrderCheckboxList3"),
        this.props.t("OrderDetails.refundOrderCheckboxList4"),
        this.props.t("OrderDetails.refundOrderCheckboxList5"),
      ],
      confirm: this.props.t("OrderDetails.refundOrderConfirm"),
      cancel: this.props.t("OrderDetails.refundOrderCancel"),
    };
  }

  openOfflineRefundConfirmDialog = (date) => {
    this.refundDate = date;
    this.setState({
      openOfflineRefundPurchaseDepositDialog: false,
      openOfflineRefundPurchaseDepositConfirmDialog: true,
    });
  };

  openOfflineRefundCalendarDialog = () => {
    this.setState({
      openOfflineRefundPurchaseDepositDialog: true,
      openOfflineRefundPurchaseDepositConfirmDialog: false,
    });
  };

  closeOfflineRefundDialog = () => {
    AnalyticsService.trackOnClickDetailEvent(
      "fv:emp:customer handling:orders details:refund order deposit offline popup",
      "emp:order details:order id",
      `emp:order details:order id:${this.props.customerReservation.id}`,
      this.props.customerReservation.id,
      "emp:customer handling:orders details:refund order deposit offline popup:content:close",
      "fv:emp:customer handling:orders details:refund order deposit offline popup:content action",
      this.props.user
    );
    this.setState({
      openOfflineRefundPurchaseDepositDialog: false,
    });
  };

  closeOfflineRefundConfirmDialog = () => {
    this.setState({
      openOfflineRefundPurchaseDepositConfirmDialog: false,
    });
  };
  isRefunded = () => {
    return (
      this.props.customerReservation.orderPaymentInfo &&
      this.props.customerReservation.orderPaymentInfo.refundAmount
    );
  };

  isRefundDeclinedOrDenied = () => {
    return (
      this.props.customerReservation.orderPaymentInfo &&
      (LowerCaseText(
        this.props.customerReservation.orderPaymentInfo.refundStatus
      ) === ORDER_DEPOSIT_STATUS.DECLINED ||
        LowerCaseText(
          this.props.customerReservation.orderPaymentInfo.refundStatus
        ) === ORDER_DEPOSIT_STATUS.DENIED)
    );
  };

  isInStoreOrder = () => {
    return (
      UpperCaseText(this.props.customerReservation.orderChannel) === "INSTORE"
    );
  };

  render() {
    const { customerReservation } = this.props;

    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    if (displayError(this.state.hasError))
      throw new Error(this.state.hasError[0]);

    const disableDealerActionsFlag =
      customerReservation &&
      customerReservation.purchaseInfo &&
      customerReservation.purchaseInfo.disableDealerActionsFlag;

    const isOlderThan2Years =
      customerReservation && isOlderThan730Days(customerReservation.date);

    const isPurchaseRequestOlderThan2Years =
      customerReservation &&
      customerReservation.purchaseInfo &&
      customerReservation.purchaseInfo.purchaseRequestDate &&
      isOlderThan730Days(customerReservation.purchaseInfo.purchaseRequestDate);

    const showroomJourneyType =
      customerReservation && customerReservation.showroomJourneyType;
    const isShowroomPurchaseRequest = showroomJourneyType !== false;

    let disableRefundCTA;
    if (isShowroomPurchaseRequest) {
      disableRefundCTA = isPurchaseRequestOlderThan2Years;
    } else {
      disableRefundCTA =
        !customerReservation.isOrderRefundEligible ||
        disableDealerActionsFlag ||
        isOlderThan2Years;
    }

    const hasJourneyType =
      this.props.customerReservation &&
      this.props.customerReservation.journeyType;

    const purchaseTypeIsCash =
      this.props.customerReservation &&
      this.props.customerReservation.purchaseInfo &&
      this.props.customerReservation.purchaseInfo.purchaseType ===
        PURCHASE_TYPES.CASH;

    const showDealerUserID = !(
      hasJourneyType &&
      this.props.customerReservation.journeyType === "Model-E" &&
      (purchaseTypeIsCash ||
        this.props.customerReservation.financeDataQuote.financeProductType ===
          FINANCE_PRODUCT_TYPE.THIRD_PARTY ||
        this.props.customerReservation.financeDataQuote.financeProductType ===
          FINANCE_PRODUCT_TYPE.DEALER_FINANCING_OPTIONS)
    );

    return (
      <DataCard
        cardHeader={this.props.t("OrderDetailsConsumerDeposit.orderDeposit")}
        headerDetail={
          <>
            <HasPermissionTo
              perform={["refundOrderNA:write"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={
                !this.props.isPurchaseOrder &&
                !this.isRefunded() &&
                !this.isRefundDeclinedOrDenied() &&
                !this.isInStoreOrder()
              }
              render={() => {
                return (
                  <button
                    className={styles.refundDepositNA}
                    onClick={this.handleRefundOpen}
                    disabled={disableRefundCTA}
                    data-testid="refund"
                    id="refund"
                  >
                    {this.props.t("OrderDetails.refundDeposit")}
                  </button>
                );
              }}
            />
            <HasPermissionTo
              perform={["refundOrderNA:write"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={
                !this.props.isPurchaseOrder &&
                !this.isRefundDeclinedOrDenied() &&
                !this.isRefunded() &&
                !this.isInStoreOrder()
              }
              render={() => {
                return (
                  <button
                    className={styles.refundDepositNA}
                    onClick={this.handleOfflineRefundCalendarDialogOpen}
                    disabled={disableRefundCTA}
                    data-testid="offlineRefundOrderDeposit"
                    id="offlineRefundOrderDeposit"
                  >
                    {this.props.t("OrderDetailsConsumerDeposit.offlineRefund")}
                  </button>
                );
              }}
            />

            <HasPermissionTo
              perform={["refundPurchaseDepositNA:write"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={
                this.props.isPurchaseOrder &&
                !this.isRefunded() &&
                !this.isRefundDeclinedOrDenied()
              }
              render={() => {
                return (
                  <button
                    className={styles.refundDepositNA}
                    disabled={disableRefundCTA}
                    onClick={this.toggleDialog}
                    data-testid="refund"
                    id="refund"
                  >
                    {this.props.t("OrderDetails.refundButtonCaptionNA")}
                  </button>
                );
              }}
            />

            <HasPermissionTo
              perform={["refundPurchaseDepositNA:write"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={
                this.props.isPurchaseOrder &&
                !this.isRefunded() &&
                !this.isRefundDeclinedOrDenied()
              }
              render={() => {
                return (
                  <button
                    className={styles.refundDepositNA}
                    disabled={disableRefundCTA}
                    onClick={this.openOfflineRefundCalendarDialog}
                    data-testid="offlineRefundPurchaseDeposit"
                    id="offlineRefundPurchaseDeposit"
                  >
                    {this.props.t("OrderDetailsConsumerDeposit.offlineRefund")}
                  </button>
                );
              }}
            />
          </>
        }
      >
        <InfoDetail
          header={this.props.t("OrderDetailsConsumerDeposit.orderAmount")}
          value={
            customerReservation.orderPaymentInfo &&
            customerReservation.orderPaymentInfo.depositAmount
          }
        />
        <InfoDetail
          header={this.props.t("OrderDetailsConsumerDeposit.last4DigitsofCc")}
          value={
            customerReservation.orderPaymentInfo &&
            customerReservation.orderPaymentInfo.cardLastFourDigits
          }
        />
        <HasPermissionTo
          permissions={this.props.user.permissions.rolePermissions}
          perform={["transactionId:view"]}
          render={() => {
            return (
              <InfoDetail
                header={this.props.t(
                  "OrderDetailsConsumerDeposit.orderInvoice"
                )}
                value={this.getInvoice(
                  customerReservation.orderPaymentInfo &&
                    customerReservation.orderPaymentInfo.paymentTransactionId
                )}
                headerTooltip={
                  <SharedToolTip
                    {...this.props}
                    openTestid="orderInvoiceTooltipOpen"
                    closeTestid="orderInvoiceTooltipClose"
                    body={this.props.t(
                      "OrderDetailsConsumerDeposit.invoiceTooltipBody"
                    )}
                  />
                }
              />
            );
          }}
        />

        <InfoDetail
          header={this.props.t(
            "OrderDetailsConsumerDeposit.orderDepositStatus"
          )}
          value={
            !this.isInStoreOrder() &&
            this.props.t("OrderDetailsConsumerDeposit.orderdepositaccepted")
          }
        />

        {customerReservation.orderConfirmedDate && (
          <InfoDetail
            header={this.props.t(
              "OrderDetailsConsumerDeposit.orderDepositAcceptedDate"
            )}
            value={new Date(customerReservation.orderConfirmedDate)
              .toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/\//g, "-")}
          />
        )}

        {Object.values(ORDER_DEPOSIT_STATUS)
          .slice(1, 4)
          .includes(
            LowerCaseText(
              customerReservation.orderPaymentInfo &&
                customerReservation.orderPaymentInfo.refundStatus
            )
          ) ? (
          <InfoDetail
            header={this.props.t(
              "OrderDetailsConsumerDeposit.orderRefundStatus"
            )}
            value={this.props.t(
              `OrderDetailsConsumerDeposit.${LowerCaseText(
                customerReservation.orderPaymentInfo &&
                  customerReservation.orderPaymentInfo.refundStatus
              )}`
            )}
            tooltip={
              <SharedToolTip
                {...this.props}
                openTestid="failedOrderPaymentOpen"
                closeTestid="failedOrderPaymentClose"
                title={this.props.t(
                  `OrderDetailsConsumerDeposit.${LowerCaseText(
                    customerReservation.orderPaymentInfo &&
                      customerReservation.orderPaymentInfo.refundStatus
                  )}`
                )}
                body={this.props.t(
                  `OrderDetailsConsumerDeposit.tooltip${LowerCaseText(
                    customerReservation.orderPaymentInfo &&
                      customerReservation.orderPaymentInfo.refundStatus
                  )}`
                )}
              />
            }
          />
        ) : (
          <InfoDetail
            header={this.props.t(
              "OrderDetailsConsumerDeposit.orderRefundStatus"
            )}
            value={
              customerReservation.orderPaymentInfo &&
              customerReservation.orderPaymentInfo.refundStatus &&
              this.props.t(
                `OrderDetailsConsumerDeposit.${LowerCaseText(
                  customerReservation.orderPaymentInfo &&
                    customerReservation.orderPaymentInfo.refundStatus
                )}`
              )
            }
          />
        )}

        <InfoDetail
          header={this.props.t("OrderDetailsConsumerDeposit.orderRefundDate")}
          value={
            customerReservation.orderPaymentInfo &&
            customerReservation.orderPaymentInfo.refundDate
          }
        />

        <InfoDetail
          header={this.props.t(
            "OrderDetailsConsumerDeposit.orderRefundedAmount"
          )}
          value={
            customerReservation.orderPaymentInfo &&
            customerReservation.orderPaymentInfo.refundAmount
          }
        />
        <InfoDetail
          header={this.props.t(
            "OrderDetailsConsumerDeposit.orderRefundInvoice"
          )}
          value={this.getInvoice(
            customerReservation.orderPaymentInfo &&
              customerReservation.orderPaymentInfo.refundTransactionId
          )}
          headerTooltip={
            <SharedToolTip
              {...this.props}
              openTestid="orderRefundInvoiceTooltipOpen"
              closeTestid="orderRefundInvoiceTooltipOpen"
              body={this.props.t(
                "OrderDetailsConsumerDeposit.invoiceTooltipBody"
              )}
            />
          }
        />

        {showDealerUserID && (
          <InfoDetail
            header={this.props.t("OrderDetails.dealerUserId")}
            value={
              customerReservation.orderPaymentInfo &&
              customerReservation.orderPaymentInfo.refundDealerUserId
            }
          />
        )}
        {this.state.openRefund && (
          <CancelOrderDialog
            {...this.props}
            isRefund
            dialogProps={this.getRefundDialogProps()}
            open={this.state.openRefund}
            onClose={() => {
              this.handleRefundClose();
              this.toggleDialog();
            }}
            handleConfirm={this.handleRefundConfirm}
          />
        )}

        {this.state.openOfflineRefundPurchaseDepositDialog && (
          <OfflineRefundCalendarDialog
            {...this.props}
            refundType={REFUND_TYPE.ORDER_DEPOSIT}
            open={this.state.openOfflineRefundPurchaseDepositDialog}
            todayDate={this.props.customerReservation.todayDate}
            onToggle={this.handleOfflineRefundConfirmDialogOpen}
            onClose={this.closeOfflineRefundDialog}
            onGoBack={this.closeOfflineRefundDialog}
          />
        )}

        {this.state.openOfflineRefundPurchaseDepositConfirmDialog && (
          <OfflineRefundConfirmDialog
            {...this.props}
            open={this.state.openOfflineRefundPurchaseDepositConfirmDialog}
            refundDate={this.refundDate}
            isOrderDeposit={true}
            refundType={REFUND_TYPE.ORDER_DEPOSIT}
            onToggle={this.handleOfflineRefundConfirmDialogGoBack}
            onClose={this.closeOfflineRefundConfirmDialog}
          />
        )}
      </DataCard>
    );
  }
}

export default withTranslation("emp")(OrderDeposit);
