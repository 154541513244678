import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  CircularProgress,
  InputLabel,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Available Props:
// fieldLabel: String
// noOptionsText: String
// fieldName: String
// onFetch: Func that returns a promise
// dataKey: string
// transportFormData: Func
// disabledItem: String

const useStyles = makeStyles(
  {
    root: {},
    label: {
      fontFamily: "'FordAntennaRegular', sans-serif",
      fontSize: 14,
      color: "#000000",
      lineHeight: "24px",
    },
    input: {
      fontFamily: "'FordAntennaRegular', sans-serif",
      fontSize: 14,
      color: "#000000",
      borderColor: "#4d4d4d",
    },
    inputRoot: {
      backgroundColor: "white",
      borderRadius: 8,
      padding: "8px 65px 8px 10px !important",
    },
    notchedOutline: {
      borderColor: "#4d4d4d",
    },
    listbox: {
      fontFamily: "'FordAntennaRegular', sans-serif",
      fontSize: 14,
      color: "#000000",
      maxHeight: 150,
      overflowY: "scroll",
    },
    paper: {
      borderRadius: 8,
    },
    noOptions: {
      fontFamily: "'FordAntennaRegular', sans-serif",
      fontSize: 14,
      color: "#5c5c5c",
    },
  },
  {
    name: "EMPTypeAheadField",
  }
);

const EMPTypeAheadField = (props) => {
  const classes = useStyles(props);
  const {
    fieldLabel,
    noOptionsText,
    fieldName,
    onFetch,
    dataKey,
    transportFormData,
    disabledItem,
  } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [hasError, setHasError] = useState(false);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    onFetch()
      .then((value) => {
        if (hasError) {
          setHasError(false);
        }
        if (active) {
          setOptions(value[dataKey]);
        }
      })
      .catch(() => {
        active = false;
        setOpen(false);
        setHasError(true);
      })
      .finally(() => {
        active = false;
      });
  }, [loading, onFetch, dataKey, hasError]);

  return (
    <>
      <InputLabel shrink={false} htmlFor={fieldName}>
        <Typography variant="body1" classes={{ body1: classes.label }}>
          {fieldLabel}
        </Typography>
      </InputLabel>
      <Autocomplete
        data-testid="type-ahead-field"
        blurOnSelect
        clearOnBlur
        classes={{
          root: classes.root,
          noOptions: classes.noOptions,
          listbox: classes.listbox,
          input: classes.input,
          inputRoot: classes.inputRoot,
          paper: classes.paper,
        }}
        options={options}
        noOptionsText={noOptionsText}
        popupIcon={<ExpandMoreIcon size="small" />}
        loading={loading}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(e, value) => transportFormData(value)}
        getOptionSelected={(option, value) =>
          option.fullName === value.fullName
        }
        getOptionDisabled={(option) => option.fullName === disabledItem}
        getOptionLabel={(option) => option.fullName}
        renderInput={(params) => (
          <TextField
            {...params}
            name={fieldName}
            size="small"
            variant="outlined"
            error={hasError}
            readOnly={hasError}
            helperText={hasError ? "Something went wrong" : ""}
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{
              ...params.InputProps,
              classes: {
                notchedOutline: classes.notchedOutline,
              },
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      ></Autocomplete>
    </>
  );
};

export default EMPTypeAheadField;
