import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import stylesOverride from "./DealerSearchResultStylesOverride";
import styles from "./DealerSearchResults.module.scss";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { publish } from "../../common/PubSub";
import {
  IS_CANADA_MARKET,
  IS_FORD_BRAND,
  IS_USA_MARKET,
} from "../../utils/EmpUtil";
import {
  BRAND_NAMES,
  FORD_ENGLISH,
  LINCOLN_ENGLISH,
} from "../../common/Constants";
import TitleCase from "../../utils/TitleCase";
import EMPEmptyState from "../../shared/components/EMPEmptyState/EMPEmptyState";
import EMPButton from "../../shared/components/EMPButton/EMPButton";
import MotionChevronRight from "../../shared/Icons/MotionChevronRight";

const onSubmit = (props, dealer) => {
  props.user.updateCurrentDealer(dealer);
  props.user.updateBrand(dealer.commonId.charAt(dealer.commonId.length - 1));
  publish("brandChanged", dealer.commonId.charAt(dealer.commonId.length - 1));
  if (IS_USA_MARKET(props.user.market)) {
    if (!IS_FORD_BRAND(props.user.brand)) {
      props.i18n.changeLanguage(LINCOLN_ENGLISH);
    } else {
      props.i18n.changeLanguage(FORD_ENGLISH);
    }
  }
  props.user.setLanguageCode(props.i18n.language);
};

const formatPostalCode = (postalCode) => {
  if (postalCode) {
    return postalCode.substring(0, 5);
  }
};

const DealerSearchResults = (props) => {
  const market = props.user.market;
  const getDealerTypeText = (dealerType) => {
    if (dealerType.toUpperCase() === "HUB") {
      return props.t("fieldView.mainDealer");
    }
    if (dealerType.toUpperCase() === "BRAN") {
      return props.t("fieldView.branchDealer");
    }
  };

  return (
    <div>
      <TableContainer component={Paper} square>
        <Table>
          <TableHead className={styles.tableHead}>
            <TableRow
              style={
                props.dealerBrand === "F"
                  ? stylesOverride.tableHeaderFord
                  : stylesOverride.tableHeaderLincoln
              }
            >
              <TableCell style={stylesOverride.cellHeader}>
                {props.t("fieldView.dealerName")}
              </TableCell>
              <TableCell style={stylesOverride.cellHeader}>
                {props.t("fieldView.salesCode")}
              </TableCell>
              <TableCell style={stylesOverride.cellHeader}>
                {props.t("fieldView.paCode")}
              </TableCell>
              {IS_CANADA_MARKET(market) && (
                <>
                  <TableCell style={stylesOverride.cellHeader}>
                    {props.t("fieldView.dealerType")}
                  </TableCell>
                  <TableCell style={stylesOverride.cellHeader}>
                    {props.t("fieldView.brand")}
                  </TableCell>
                </>
              )}
              <TableCell style={stylesOverride.cellHeader}>
                {props.t("fieldView.dealerAddress")}
              </TableCell>
              <TableCell style={stylesOverride.cellHeader} />
            </TableRow>
          </TableHead>
          <TableBody className={styles.tableBody}>
            {props.dealers.map((dealer, index) => (
              <TableRow id={"row" + index} key={index}>
                <TableCell style={stylesOverride.cellBody}>
                  {dealer.dealerName}
                </TableCell>
                <TableCell style={stylesOverride.cellBody}>
                  {dealer.salesCode || <EMPEmptyState />}
                </TableCell>
                <TableCell style={stylesOverride.cellBody}>
                  {dealer.paCode || <EMPEmptyState />}
                </TableCell>
                {IS_CANADA_MARKET(market) && (
                  <>
                    <TableCell style={stylesOverride.cellBody}>
                      {dealer.dealerType ? (
                        getDealerTypeText(dealer.dealerType)
                      ) : (
                        <span style={{ paddingLeft: "20%" }}>-</span>
                      )}
                    </TableCell>
                    <TableCell style={stylesOverride.cellBody}>
                      {dealer.commonId ? (
                        IS_FORD_BRAND(
                          dealer.commonId.charAt(dealer.commonId.length - 1)
                        ) ? (
                          TitleCase(BRAND_NAMES.FORD)
                        ) : (
                          TitleCase(BRAND_NAMES.LINCOLN)
                        )
                      ) : (
                        <EMPEmptyState />
                      )}
                    </TableCell>
                  </>
                )}
                <TableCell style={stylesOverride.cellBody}>
                  <div className={styles.dealerAddress}>
                    {dealer.address && dealer.address.street !== null ? (
                      <>
                        <span>{dealer.address.street}</span>
                        <span>
                          {dealer.address.city},{" "}
                          {dealer.address.state || dealer.address.province}{" "}
                          {IS_USA_MARKET(market)
                            ? formatPostalCode(dealer.address.postalCode)
                            : dealer.address.postalCode}
                          {dealer.address.country
                            ? `, ${dealer.address.country}`
                            : ""}
                        </span>
                      </>
                    ) : (
                      <EMPEmptyState />
                    )}
                  </div>
                </TableCell>
                <TableCell style={stylesOverride.dealer}>
                  <EMPButton
                    testId="viewDealerLink"
                    buttonElement={Link}
                    buttonType="text"
                    rightIcon={<MotionChevronRight />}
                    buttonText={props.t("fieldView.viewDealer")}
                    link="/dashboard"
                    onClick={() => onSubmit(props, dealer)}
                  ></EMPButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default withTranslation("emp")(DealerSearchResults);
