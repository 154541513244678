import React, { Component } from "react";
import { ExpansionPanel, ExpansionPanelSummary } from "@material-ui/core";
import styles from "./Translation.module.scss";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import { Grid, TextField } from "@material-ui/core";
import { LANCODES } from "../../common/Constants";

class NewComponentTranslation extends Component {
  constructor(props) {
    super(props);
    this.translation = [];
    this.state = {
      newTrans: {
        moduleKey: "",
        key: "",
        en: "",
        de: "",
        fr: "",
        nl: "",
        no: "",
        es: "",
        it: "",
        fi: "",
        da: "",
      },
      key: "",
      en: "",
      translatedValue: "",
      moduleKey: "",
    };

    this.lan = this.props.currentLan;
  }

  handleChangeKey = (event) => {
    this.setState({ key: event.target.value });
  };
  handleChangeEn = (event) => {
    this.setState({ en: event.target.value });
  };
  handleChangeTranslatedValue = (event) => {
    this.setState({ translatedValue: event.target.value });
  };
  handleChangeModuleKey = (event) => {
    this.setState({ moduleKey: event.target.value });
  };

  addComponentTranslation = () => {
    const updatedLanguage = { ...this.state.newTrans };
    updatedLanguage.en = this.state.en;
    updatedLanguage.de = this.state.en;
    updatedLanguage.fr = this.state.en;
    updatedLanguage.nl = this.state.en;
    updatedLanguage.no = this.state.en;
    updatedLanguage.es = this.state.en;
    updatedLanguage.it = this.state.en;
    updatedLanguage.fi = this.state.en;
    updatedLanguage.da = this.state.en;
    updatedLanguage.key = this.state.key;
    updatedLanguage.moduleKey = this.state.moduleKey;
    // instead of loop overwriting the value
    updatedLanguage[this.lan] = this.state.translatedValue;
    this.props.addTranslationComponent(updatedLanguage);
  };

  cancelComponentTranslation = () => {
    // this.props.showNewComponentTranslation();
    this.props.cancelTranslation();
  };

  render() {
    return (
      <div>
        <br />
        {this.props.showNewComponentTranslationvalue && (
          <ExpansionPanel expanded={true}>
            <ExpansionPanelSummary>
              <Grid item md={3}>
                <TextField
                  className={styles.inputField}
                  type="text"
                  name="topicBox"
                  variant="outlined"
                  placeholder={this.props.t("Translations.enterModule")}
                  onChange={this.handleChangeModuleKey}
                />
              </Grid>
            </ExpansionPanelSummary>

            <Grid container className={styles.translation_header}>
              <Grid item md={3} className={styles.lanHeader}>
                {this.props.t("Translations.key")}
              </Grid>
              <Grid item md={3} className={styles.lanHeader}>
                {LANCODES["en"]}
              </Grid>
              <Grid item md={3} className={styles.lanHeader}>
                {LANCODES[this.props.languageSelected]}
              </Grid>
              <Grid item md={3} className={styles.lanHeader}>
                {this.props.t("Translations.action")}
              </Grid>
            </Grid>
            <div>
              <Grid container className={styles.lan}>
                <Grid item md={3}>
                  <TextField
                    className={styles.inputField}
                    type="text"
                    name="topicBox"
                    variant="outlined"
                    placeholder={this.props.t("Translations.enterKey")}
                    onChange={this.handleChangeKey}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    className={styles.inputField}
                    type="text"
                    name="topicBox"
                    variant="outlined"
                    placeholder={this.props.t("Translations.enterEng")}
                    onChange={this.handleChangeEn}
                  />
                </Grid>

                <Grid item md={3}>
                  <TextField
                    className={styles.inputField}
                    type="text"
                    name="topicBox"
                    variant="outlined"
                    placeholder={this.props.t("Translations.enterLan")}
                    onChange={this.handleChangeTranslatedValue}
                  />
                </Grid>
                <Grid item md={3} className={styles.lanUpdate}>
                  <DoneIcon
                    fontSize="small"
                    style={{ color: "green" }}
                    className={styles.button_hover}
                    onClick={this.addComponentTranslation}
                  />
                  <ClearIcon
                    fontSize="small"
                    style={{ color: "red" }}
                    className={styles.button_hover}
                    onClick={this.cancelComponentTranslation}
                  />
                </Grid>
              </Grid>
            </div>
          </ExpansionPanel>
        )}
      </div>
    );
  }
}

export default NewComponentTranslation;
