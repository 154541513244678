import React from "react";
import { Chip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 12,
  },
  label: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  colorPrimary: {
    backgroundColor: "#E6F1FD",
    border: "1px solid #066FEF",
    color: "#000000",
  },
  colorSecondary: {
    backgroundColor: "#E5E5E5",
    border: "1px solid #808080",
    color: "#000000",
  },
});

const EMPChip = (props) => {
  const { label, testId, labelColor } = props;
  const classes = useStyles();

  return (
    <Chip
      data-testid={testId}
      size="small"
      label={label}
      clickable={false}
      color={labelColor}
      classes={{
        root: classes.root,
        colorPrimary: classes.colorPrimary,
        colorSecondary: classes.colorSecondary,
        label: classes.label,
      }}
    />
  );
};

export default EMPChip;
