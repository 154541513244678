import React, { useState } from "react";
import { makeStyles, AppBar, Tabs, Tab, Box } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

// tabItems is an array of objects with the following shape:
// {
//   id: 1,
//   route: optional,
//   title: "Tab One",
//   content: () => `<div>Tab One</div>`,
// }

const useStyles = makeStyles({
  indicator: {
    height: "4px",
    backgroundColor: "#066FEF",
  },
  tabsRoot: {
    minHeight: "unset",
    paddingTop: "16px",
    marginBottom: "8px",
  },
  tabRoot: {
    fontFamily: "'Ford Antenna Regular', sans-serif",
    fontSize: "18px",
    textTransform: "none",
    fontWeight: "500",
    lineHeight: "24px",
    padding: 0,
    letterSpacing: "normal",
    borderBottom: "2px solid #1F3047",
    opacity: 1,
    minHeight: "32px",
    height: "unset",
    paddingBottom: "8px",
    maxWidth: "none",
    marginRight: "16px",
    width: "auto",
    minWidth: "200px",

    "&:last-of-type": {
      marginRight: "0px",
    },
  },
  tabSelected: {
    color: "#066FEF",
    borderBottom: "2px solid #066FEF",
  },
  tabWrapper: {
    alignItems: "flex-start",
  },
});

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <Box
      value={value}
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
    >
      {value === index && children}
    </Box>
  );
};

const EMPTabs = (props) => {
  const { tabItems, navigation, history, disabled } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);

  return (
    <>
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          classes={{
            root: classes.tabsRoot,
            indicator: classes.indicator,
          }}
          value={navigation ? history.location.pathname : value}
          onChange={(e, newValue) => setValue(newValue)}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons="off"
        >
          {tabItems.map((item) => (
            <Tab
              classes={{
                root: classes.tabRoot,
                wrapper: classes.tabWrapper,
                selected: classes.tabSelected,
              }}
              disableRipple
              disabled={disabled}
              label={item.title}
              key={item.title}
              value={navigation ? item.route : value}
              to={navigation ? item.route : null}
              component={navigation ? RouterLink : "button"}
            />
          ))}
        </Tabs>
      </AppBar>
      {!navigation &&
        tabItems.map((item, index) => (
          <TabPanel value={item.value} index={index} key={item.value}>
            {item.content}
          </TabPanel>
        ))}
    </>
  );
};

export default EMPTabs;
