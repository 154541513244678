import React from "react";
import styles from "./RectangleEdit.module.scss";

const RectangleEdit = (props) => {
  return (
    <div
      className={styles.Edit}
      onClick={props.onEditClick}
      data-testid={props.dataTestId}
    >
      <div className={styles.RectangleStart} />
      <div className={styles.RectangleEnd} />
    </div>
  );
};

export default RectangleEdit;
