import axios from "axios";
import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";
import { getHeaders } from "../utils/AuthorizationUtil";
import { is401 } from "../errors/ErrorConstants";
import { SHARED_SERVICE_URL } from "../utils/EmpUtil";

export default class LocalizedInfoClient {
  static baseUrl = SHARED_SERVICE_URL;

  static getLocalizedInfo(user, marketID = null) {
    let url = `${this.baseUrl}/localized-info`;

    if (marketID !== null && marketID !== undefined) {
      url = url.concat(`?marketId=${marketID}`);
    }

    if (user.commonId !== null && user.commonId !== undefined) {
      const symbol = marketID === null ? "?" : "&";
      url = url.concat(symbol).concat(`commonId=${user.commonId}`);
    }

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        } else {
          user.history.push("/error");
        }

        return Promise.reject(error);
      });
  }
}
